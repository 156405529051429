<div fxLayout.xs="column" fxFlexFill class="mobile-display">
  <div fxFlex="100" class="bg-img" [ngStyle]="{'background-image': 'url(' + Overviewbg + ')'}" >
    <!-- [ngClass]="{'bg-img1': this.complaintsrc =='4'}" -->
    <div class="container-fluid" style="background: transparent;">
      <div class="container container-bg">
      </div>
    </div>
  </div>
</div>

<div class="column" fxLayout="row" fxLayout.xs="column" fxFlexFill style="background:white;">
  <div class="container-fluid df" style="background: transparent;">
    <div *ngIf="complaintsrc!='5'" style="max-width:1600px;width: 100%;">
      <div class="complhead">
        <div *ngIf="complaintsrc != '4'" style="cursor: pointer;" (click)="gotomenu('formmenu')" routerLink="/onlinecomplaint"><svg
            xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="#ffffff" class="bi bi-house-door"
            viewBox="0 0 16 16">
            <path
              d="M8.354 1.146a.5.5 0 0 0-.708 0l-6 6A.5.5 0 0 0 1.5 7.5v7a.5.5 0 0 0 .5.5h4.5a.5.5 0 0 0 .5-.5v-4h2v4a.5.5 0 0 0 .5.5H14a.5.5 0 0 0 .5-.5v-7a.5.5 0 0 0-.146-.354L13 5.793V2.5a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5v1.293L8.354 1.146ZM2.5 14V7.707l5.5-5.5 5.5 5.5V14H10v-4a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5v4H2.5Z" />
          </svg></div>
        <div *ngIf="complaintsrc == '4'" style="cursor: pointer;" (click)="gotomenu('formmenu')" routerLink="/vigilance"><svg
            xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="#ffffff" class="bi bi-house-door"
            viewBox="0 0 16 16">
            <path
              d="M8.354 1.146a.5.5 0 0 0-.708 0l-6 6A.5.5 0 0 0 1.5 7.5v7a.5.5 0 0 0 .5.5h4.5a.5.5 0 0 0 .5-.5v-4h2v4a.5.5 0 0 0 .5.5H14a.5.5 0 0 0 .5-.5v-7a.5.5 0 0 0-.146-.354L13 5.793V2.5a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5v1.293L8.354 1.146ZM2.5 14V7.707l5.5-5.5 5.5 5.5V14H10v-4a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5v4H2.5Z" />
          </svg></div>
        <div style="width: 90%;">
          <h4>{{camphtxt}}</h4>
        </div>
      </div>
      <div class="row imgbg" [ngStyle]="{'background-image': 'url(' + Vigilancechar + ')'}" >
        <!-- [ngClass]="{'Vigilancechart': complaintsrc =='4'}" -->
        <div class="col-md-12">

          <form *ngIf="complaintsrc == '2'" [formGroup]="complaintform1" (ngSubmit)="onSubmit1()" class="form-main">
            <div class='row mt-5'>
              <div class="col-md-7">
                <div class="row mt-4">
                  <div class="col-md-12 matheight"
                    [ngClass]="{'has-error': submitted && (complaintform1.controls['Vendorcode'].invalid )}">
                    <label class="col-md"><span>Vendor Code &nbsp;<span class="imprt">*</span></span></label>
                    <div class="col-md-9 inpt">
                      <input type="text" class="form-control vendorZone" (input)="onNumberinput($event , 'Vendorcode')" formControlName="Vendorcode"
                        (focusout)="vendorOnFocusOutEvent($event)">
                    </div>
                  </div>
                </div>
                <div class="row mt-4">
                  <div class="col-md-12 matheight"
                    [ngClass]="{'has-error': submitted && (complaintform1.controls['VendorName'].invalid )}">
                    <label class="col-md">Vendor Name</label>
                    <div class="col-md-9 inpt">
                      <input type="text" readonly [(ngModel)]="vendorCodeField" class="form-control"
                        formControlName="VendorName">
                    </div>
                  </div>
                </div>

                <div class="row mt-4">
                  <div class="col-md-12 matheight"
                    [ngClass]="{'has-error': submitted && (complaintform1.controls['phone'].invalid )}">
                    <label class="col-md">Phone Number</label>
                    <div class="col-md-9 inpt">
                      <input type="text" readonly [(ngModel)]="vendornumber" class="form-control" 
                        formControlName="phone" (keypress)="keyPressphone($event)">
                    </div>

                  </div>

                </div>

                <div class="row mt-4">
                  <div class="col-md-12 matheight"
                    [ngClass]="{'has-error': submitted && (complaintform1.controls['PONumber'].invalid )}">
                    <label class="col-md">PO Number</label>
                    <div class="col-md-9 selct">
                      <select class="selection" formControlName="PONumber" (change)="onPochnage()"
                        [(ngModel)]="selectedPOValue">
                        <option [ngValue]="null">Select PO Number</option>
                        <option [value]="po.PO_NUMBER" *ngFor="let po of po_options">{{po.PO_NUMBER}}</option>
                      </select>
                    </div>

                  </div>

                </div>

                <div class="row mt-4">
                  <div class="col-md-12 matheight "
                    [ngClass]="{'has-error': submitted && (complaintform1.controls['AreaofOp'].invalid )}">
                    <label class="col-md"><span>Area of Operation&nbsp;<span class="imprt">*</span></span></label>
                    <div class="col-md-9 selct">
                      <select [(ngModel)]="selectedarea" class="selection" (change)="onOptionChange()"
                        formControlName="AreaofOp">
                        <option [ngValue]="null">Select Area of Operation</option>
                        <option [ngValue]="area" *ngFor="let area of grievancedrop">{{area.business_name}}</option>
                      </select>

                    </div>

                  </div>

                </div>

                <div class="row mt-4">
                  <div class="col-md-12 matheight">
                    <label class="col-md">Location</label>
                    <div class="col-md-9 inpt">
                      <input type="text" readonly [(ngModel)]="showlocation" class="form-control"
                        formControlName="city_nmshow">
                    </div>
                  </div>
                </div>

                <div class="row mt-4">
                  <div class="col-md-12 matheight"
                    [ngClass]="{'has-error': submitted && (complaintform1.controls['NofComplaint'].invalid )}">
                    <label class="col-md"><span>Nature of Complaint&nbsp;<span class="imprt">*</span></span></label>
                    <div class="col-md-9 selct">
                      <select [(ngModel)]="selectednature" class="selection" formControlName="NofComplaint">
                        <option [ngValue]="null">Select Nature of Complaint</option>
                        <option [value]="nature.nature_id" *ngFor="let nature of areaofnature">{{nature.nature_type}}
                        </option>
                      </select>
                    </div>
                  </div>
                </div>
                <div class="row mt-4">
                  <div class="col-md-12 matheight"
                    [ngClass]="{'has-error': submitted && (complaintform1.controls['PreAward'].invalid )}">
                    <label class="col-md"><span>Pre-Award/ Post-Award&nbsp;<span class="imprt">*</span></span></label>
                    <div class="col-md-9 inpt">
                      <input type="text" class="form-control" formControlName="PreAward" (input)="ontextinput($event , 'PreAward')">
                    </div>
                  </div>
                </div>
                <div class="row mt-4">
                  <div class="col-md-12 matheight"
                    [ngClass]="{'has-error': submitted && (complaintform1.controls['Email'].invalid )}">
                    <label class="col-md"><span>Email Address&nbsp;<span class="imprt">*</span></span></label>
                    <div class="col-md-9 inpt">
                      <input type="email" class="form-control" formControlName="Email" (input)="onInputEmail($event)">
                      <span *ngIf="submitted && complaintform1.controls['Email'].invalid" class="fileup"
                        style="font-size: 12px;"><span>*</span>Invalid Email</span>
                    </div>
                  </div>
                </div>
                <div class="row mt-4">
                  <div class="col-md-12 matheight">
                    <label class="col-md">Upload if any</label>
                    <div class="col-md-9 inpt" style="height: 45px;">
                      <input type="file" class="form-control file" formControlName="Upload"
                        (change)="onFileSelected($event)" accept=".jpeg, .png , .pdf ," />
                      <span class="fileup" style="font-size: 12px;"><span>*</span>File size should not be more than
                        500kb</span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md"></div>
            </div>

            <div class="row mt-5">
              <div class="col-md-10 matheight"
                [ngClass]="{'has-error': submitted && (complaintform1.controls['Details'].invalid )}">
                <label class="col-md-2" style="display: flex;
                  align-items: flex-start;"><span>Details &nbsp;<span class="imprt">*</span></span></label>
                <div class="col-md-10" style="padding-left: 21px;">
                  <textarea rows="4" class="form-control" formControlName="Details" (input)="ontextinput($event , 'Details')"></textarea>
                  <span class="speacialchar"><span>*</span> Special Characters allowed , - _ . only</span>
                  <span class="maxcar" style="margin-left: 10px;"> <span>*</span> Max Chara Limit 1000 </span>
                </div>
                <!-- <div class="col-md-2"></div> -->
              </div>
            </div>
            <div class="row">
              <div class="col-md-7">

                <div class="row mt-4">
                  <div class="col-md-12 matheight"
                    [ngClass]="{'has-error': submitted && (complaintform1.controls['MobileNumber'].invalid )}">
                    <label class="col-md-3"><span>Mobile Number&nbsp;<span class="imprt">*</span></span></label>
                    <div class="col-md-5 inpt df" style="position: relative;">
                      <input type="text"  #num class="form-control mob" formControlName="MobileNumber" maxlength="10"
                        minlength="10" (keypress)="keyPressNumbers($event)" (keydown)="keydownnum($event)"  (input)="onNumberinput($event , 'MobileNumber')">
                      <span class="otpbtn df" (click)="getotp()" [ngClass]="{ 'disabled': buttonDisabled }"><img
                          src="../../../assets/submitForm/otpgen.svg" alt="otp" width="14px"></span>
                      <span class="maxcar" style="position: absolute;bottom: -20px;left: 20px;"> <span>*</span> Click on
                        Blue arrow to get an OTP</span>
                      <span (click)="getotp()" class="resend" *ngIf="timerInterval && timer == 0">Resend</span>
                      <span class="timer" style="color:#0784C7;" *ngIf="timerInterval && timer > 0">{{ formatTime(timer)
                        }}</span>
                    </div>
                  </div>
                </div>
                <div class="row mt-4">
                  <div class="col-md-12 matheight" style="margin-top: 10px;"
                    [ngClass]="{'has-error': submitted && (complaintform1.controls['Otp'].invalid )}">
                    <label class="col-md-3"><span>Enter OTP&nbsp;<span class="imprt">*</span></span></label>
                    <div class="col-md-5 inpt">
                      <input type="text" class="form-control" formControlName="Otp" maxlength="6" minlength="6" (input)="onNumberinput($event , 'Otp')" [readonly]="EnterOtp_Send == false" [ngClass]="{'disabled-button': EnterOtp_Send == false}">
                      <span *ngIf="submitted && complaintform1.controls['Otp'].invalid" class="fileup"
                        style="font-size: 12px;"><span>*</span>Otp required</span>
                    </div>
                  </div>
                </div>
                <div class="row" style="margin-top: 2rem; margin-bottom: 1.5rem;">
                  <div class="col-md-12 subbtn">
                    <button class="submit" type="submit">Submit</button>
                  </div>
                </div>
              </div>
              <div class="col-md"></div>
            </div>

          </form>

          <!-- customer -->
          <form *ngIf="complaintsrc == '3'" [formGroup]="complaintform2" (ngSubmit)="onSubmit2()" class="form-main">
            <div class='row mt-5'>
              <div class="col-md-7">
                <div class="row mt-4">
                  <div class="col-md-12 matheight"
                    [ngClass]="{'has-error': submitted && (complaintform2.controls['CustomerName'].invalid )}">
                    <label class="col-md"><span>Customer Name&nbsp;<span class="imprt">*</span></span></label>
                    <div class="col-md-9 inpt">
                      <input type="text" class="form-control" formControlName="CustomerName" (input)="ontextinput($event , 'CustomerName')">
                    </div>
                  </div>
                </div>
                <div class="row mt-4">
                  <div class="col-md-12 matheight"
                    [ngClass]="{'has-error': submitted && (complaintform2.controls['CustomerType'].invalid )}">
                    <label class="col-md"><span>Customer Type&nbsp;<span class="imprt">*</span></span></label>
                    <div class="col-md-9 selct">
                      <select [(ngModel)]="selectedcustype" class="selection" (change)="onTypeOpChange()"
                        formControlName="CustomerType">
                        <option [ngValue]="null">Select Customer Type</option>
                        <option [value]="custmer.customer_id" *ngFor="let custmer of cust_type">
                          {{custmer.customer_type}}</option>
                      </select>
                    </div>
                  </div>
                </div>

                <div class="row mt-5 mb-5 redirection" *ngIf="cmptypeforbtn == true">
                  <div class="col-md-12"
                    style="text-align: center;display: flex;align-items: center;justify-content: flex-end;">
                    <span>
                      <p>For Domestic PNG Complaints click the button below:</p>
                      <a href="https://customer.gailgaspng.com/" target="_blank" class="icon"
                        style="padding: 10px 28px ; margin-top: 10px;" id="applybtn">Register Complaint</a>
                    </span>
                  </div>
                </div>

                <div *ngIf="cmptypeforbtn == false">
                  <div class="row mt-4">
                    <div class="col-md-12 matheight"
                      [ngClass]="{'has-error': submitted && (complaintform2.controls['BP_Number'].invalid )}">
                      <label class="col-md"><span>BP/ Consumer Number&nbsp;<span *ngIf="setmand == true"
                            class="imprt">*</span></span></label>
                      <div class="col-md-9 inpt">
                        <input type="text" class="form-control" (keypress)="keyPressBP($event)"  (input)="ontextinput($event , 'BP_Number')"
                          formControlName="BP_Number">
                      </div>
                    </div>
                  </div>


                  <div class="row mt-4">
                    <div class="col-md-12 matheight"
                      [ngClass]="{'has-error': submitted && (complaintform2.controls['AreaofOp'].invalid )}">
                      <label class="col-md"><span>Area of Operation&nbsp;<span class="imprt">*</span></span></label>
                      <div class="col-md-9 inpt">
                        <select [(ngModel)]="selectedarea" class="selection" (change)="onOptionChange()"
                          formControlName="AreaofOp">
                          <option [ngValue]="null">Select Area of Operation</option>
                          <option [ngValue]="area" *ngFor="let area of grievancedrop">{{area.business_name}}</option>
                        </select>
                        <!-- <input type="text" class="form-control" formControlName="AreaofOp"> -->
                      </div>

                    </div>

                  </div>



                  <div class="row mt-4">
                    <div class="col-md-12 matheight">
                      <label class="col-md">Location</label>
                      <div class="col-md-9 inpt">
                        <input type="text" readonly [(ngModel)]="showlocation" class="form-control"
                          formControlName="city_nmshow">
                      </div>
                    </div>
                  </div>

                  <!-- <div class="row mt-4" >
                  <div class="col-md-12 matheight"
                    [ngClass]="{'has-error': submitted && (complaintform2.controls['NofComplaint'].invalid )}">
                    <label class="col-md">Nature of Complaint:</label>
                    <div class="col-md-9 selct">
                      <select readonly [(ngModel)]="selectednature" class="selection" formControlName="NofComplaint">
                        <option [ngValue]="null">Select Nature of Complaint</option>
                        <option [value]="nature.nature_id" *ngFor="let nature of areaofnature">{{nature.nature_type}}
                        </option>
                      </select>
                    </div>

                  </div>
                </div> -->

                  <div class="row mt-4">
                    <div class="col-md-12 matheight"
                      [ngClass]="{'has-error': submitted && (complaintform2.controls['Email'].invalid )}">
                      <label class="col-md"><span>Email Address&nbsp;<span class="imprt">*</span></span></label>
                      <div class="col-md-9 inpt">
                        <input type="email" class="form-control" formControlName="Email" (input)="onInputEmail($event)">
                        <span *ngIf="submitted && complaintform2.controls['Email'].invalid" class="fileup"
                          style="font-size: 12px;"><span>*</span>Invalid Email</span>
                      </div>
                    </div>
                  </div>


                  <div class="row mt-4">
                    <div class="col-md-12 matheight">
                      <label class="col-md">Upload if any</label>
                      <div class="col-md-9 inpt" style="height: 45px;cursor: pointer;">
                        <input type="file" class="form-control file" formControlName="Upload"
                          (change)="onFileSelected($event)" accept=".jpeg, .png , .pdf," />
                        <span class="fileup" style="font-size: 12px;"><span>*</span>File size should not be more than
                          500kb</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md"></div>
            </div>

            <div *ngIf="cmptypeforbtn == false">
              <div class="row mt-5">
                <div class="col-md-10 matheight"
                  [ngClass]="{'has-error': submitted && (complaintform2.controls['Details'].invalid )}">
                  <label class="col-md-2" style="display: flex;
                  align-items: flex-start;"><span>Details&nbsp;<span class="imprt">*</span></span></label>
                  <div class="col-md-10" style="padding-left: 21px;">
                    <textarea rows="4" class="form-control" formControlName="Details" (input)="ontextinput($event , 'Details')"></textarea>
                    <span class="speacialchar"><span>*</span> Special Characters allowed , - _ . only</span>
                    <span class="maxcar" style="margin-left: 10px;"> <span>*</span> Max Chara Limit 1000 </span>
                  </div>
                  <!-- <div class="col-md-2"></div> -->
                </div>
              </div>

              <div class="row">
                <div class="col-md-7">
                  <div class="row mt-4">
                    <div class="col-md-12 matheight"
                      [ngClass]="{'has-error': submitted && (complaintform2.controls['MobileNumber'].invalid )}">
                      <label class="col-md-3"><span>Mobile Number&nbsp;<span class="imprt">*</span></span></label>
                      <div class="col-md-5 inpt df" style="position: relative;">
                        <input type="text" #num class="form-control mob" formControlName="MobileNumber" maxlength="10"
                          minlength="10" (keypress)="keyPressNumbers($event)" (input)="onNumberinput($event , 'MobileNumber')" (keydown)="keydownnum($event)">
                        <span class="otpbtn df" (click)="getotp()" [ngClass]="{ 'disabled': buttonDisabled }"><img
                            src="../../../assets/submitForm/otpgen.svg" alt="otp" width="14px"></span>
                        <span class="maxcar" style="position: absolute;bottom: -20px;left: 20px;"> <span>*</span> Click
                          on
                          Blue arrow to get an OTP</span>
                        <span (click)="getotp()" class="resend" *ngIf="timerInterval && timer == 0">Resend</span>
                        <span class="timer" style="color:#0784C7;" *ngIf="timerInterval && timer > 0">{{
                          formatTime(timer)
                          }}</span>
                      </div>
                    </div>
                  </div>
                  <div class="row mt-4">
                    <div class="col-md-12 matheight" style="margin-top: 10px;"
                      [ngClass]="{'has-error': submitted && (complaintform2.controls['Otp'].invalid )}">
                      <label class="col-md-3"><span>Enter OTP&nbsp;<span class="imprt">*</span></span></label>
                      <div class="col-md-5 inpt">
                        <!-- <input type="text" class="form-control" formControlName="Otp"> -->
                        <input type="text" class="form-control" formControlName="Otp" maxlength="6" minlength="6" (input)="onNumberinput($event , 'Otp')" [readonly]="EnterOtp_Send == false" [ngClass]="{'disabled-button': EnterOtp_Send == false}">
                        <span *ngIf="submitted && complaintform2.controls['Otp'].invalid" class="fileup"
                          style="font-size: 12px;"><span>*</span>Otp required</span>
                      </div>
                    </div>
                  </div>
                  <div class="row" style="margin-top: 2rem; margin-bottom: 1.5rem;">
                    <div class="col-md-12 subbtn">
                      <button class="submit" type="submit">Submit</button>
                    </div>
                  </div>
                </div>
                <div class="col-md"></div>
              </div>
            </div>

          </form>

          <!-- vigilance -->
          <form *ngIf="complaintsrc == '4'" [formGroup]="complaintform3" (ngSubmit)="onSubmit3()" class="form-main">
            <div class='row mt-5'>
              <div class="col-md-7">
                <div class="row mt-4">
                  <div class="col-md-12 matheight"
                    [ngClass]="{'has-error': submitted && (complaintform3.controls['EnterName'].invalid )}">
                    <label class="col-md"><span>Enter Name &nbsp;&nbsp;<span class="imprt">*</span></span></label>
                    <div class="col-md-9 inpt">
                      <input type="text" class="form-control" formControlName="EnterName" (input)="ontextinput($event , 'EnterName')">
                    </div>
                  </div>

                </div>
                <div class="row mt-4">
                  <div class="col-md-12 matheight"
                    [ngClass]="{'has-error': submitted && (complaintform3.controls['EnterHouseNo'].invalid )}">
                    <label class="col-md"><span>Enter House No&nbsp;<span class="imprt">*</span></span></label>
                    <div class="col-md-9 inpt">
                      <input type="text" class="form-control" formControlName="EnterHouseNo" (input)="ontextinput($event , 'EnterHouseNo')">
                    </div>

                  </div>
                </div>

                <div class="row mt-4">
                  <div class="col-md-12 matheight"
                    [ngClass]="{'has-error': submitted && (complaintform3.controls['BuildingStreet'].invalid )}">
                    <label class="col-md"><span>Building/Street&nbsp;<span class="imprt">*</span></span></label>
                    <div class="col-md-9 inpt">
                      <input type="text" class="form-control" formControlName="BuildingStreet" (input)="ontextinput($event , 'BuildingStreet')">
                    </div>

                  </div>

                </div>

                <div class="row mt-4">
                  <div class="col-md-12 matheight"
                    [ngClass]="{'has-error': submitted && (complaintform3.controls['Area'].invalid )}">
                    <label class="col-md"><span>Area&nbsp;<span class="imprt">*</span></span></label>
                    <div class="col-md-9 inpt">
                      <input type="text" class="form-control" formControlName="Area" (input)="ontextinput($event , 'Area')">
                    </div>
                  </div>
                </div>


                <div class="row mt-4">
                  <div class="col-md-12 matheight"
                    [ngClass]="{'has-error': submitted && (complaintform3.controls['Country'].invalid )}">
                    <label class="col-md"><span>Country&nbsp;<span class="imprt">*</span></span></label>
                    <div class="col-md-9 inpt">
                      <select [(ngModel)]="selectecuntry" (change)="onSelectcuntry()" class="selection"
                        formControlName="Country">
                        <option [ngValue]="country" *ngFor="let country of cuntryoption">
                          {{country.country_name}}</option>
                      </select>
                    </div>
                  </div>
                </div>


                <div class="row mt-4">
                  <div class="col-md-12 matheight"
                    [ngClass]="{'has-error': submitted && (complaintform3.controls['State'].invalid )}">
                    <label class="col-md"><span>State&nbsp;<span class="imprt">*</span></span></label>
                    <div class="col-md-9 selct">
                      <select [(ngModel)]="selectestate" class="selection" formControlName="State">
                        <option Value="">Select State</option>
                        <option [value]="sts.state_name" *ngFor="let sts of Stateoption">
                          {{sts.state_name}}</option>
                      </select>
                    </div>
                  </div>
                </div>


                <div class="row mt-4">
                  <div class="col-md-12 matheight"
                    [ngClass]="{'has-error': submitted && (complaintform3.controls['City'].invalid )}">
                    <label class="col-md"><span>City&nbsp;<span class="imprt">*</span></span></label>
                    <div class="col-md-9 inpt">
                      <input type="text" class="form-control" formControlName="City" (input)="ontextinput($event , 'City')" >
                    </div>
                  </div>
                </div>

                <div class="row mt-4">
                  <div class="col-md-12 matheight"
                    [ngClass]="{'has-error': submitted && (complaintform3.controls['PinCode'].invalid )}">
                    <label class="col-md"><span>Pin Code&nbsp;<span class="imprt">*</span></span></label>
                    <div class="col-md-9 inpt">
                      <input type="text" class="form-control" minlength="6" maxlength="6" formControlName="PinCode"
                        (keypress)="keyPressphone($event)" (input)="onNumberinput($event , 'PinCode')">
                      <span *ngIf="submitted && complaintform3.controls['Email'].invalid" class="fileup"
                        style="font-size: 12px;"><span>*</span>Only numbers are allowed</span>
                    </div>
                  </div>
                </div>


                <div class="row mt-4">
                  <div class="col-md-12 matheight"
                    [ngClass]="{'has-error': submitted && (complaintform3.controls['Subject'].invalid )}">
                    <label class="col-md"><span>Subject&nbsp;<span class="imprt">*</span></span></label>
                    <div class="col-md-9 inpt">
                      <input type="text" class="form-control" formControlName="Subject" (input)="ontextinput($event , 'Subject')">
                    </div>
                  </div>
                </div>

                <div class="row mt-4">
                  <div class="col-md-12 matheight"
                    [ngClass]="{'has-error': submitted && (complaintform3.controls['Email'].invalid )}">
                    <label class="col-md"><span>Email Address&nbsp;<span class="imprt">*</span></span></label>
                    <div class="col-md-9 inpt">
                      <input type="email" class="form-control" formControlName="Email" (input)="onInputEmail($event)">
                      <span *ngIf="submitted && complaintform3.controls['Email'].invalid" class="fileup"
                        style="font-size: 12px;"><span>*</span>Invalid Email</span>
                    </div>
                  </div>
                </div>
                <div class="row mt-4">
                  <div class="col-md-12 matheight">
                    <label class="col-md">Upload if any</label>
                    <div class="col-md-9 inpt" style="height: 45px;">
                      <input type="file" class="form-control file" formControlName="Upload"
                        (change)="onFileSelected($event)" accept=".jpeg, .png , .pdf ," />
                      <span class="fileup" style="font-size: 12px;"><span>*</span>File size should not be more than
                        500kb</span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md"></div>
            </div>

            <div class="row mt-5">
              <div class="col-md-10 matheight"
                [ngClass]="{'has-error': submitted && (complaintform3.controls['Details'].invalid )}">
                <label class="col-md-2" style="display: flex;
                  align-items: flex-start;"><span>Details&nbsp;<span class="imprt">*</span></span></label>
                <div class="col-md-10" style="padding-left: 21px;">
                  <textarea rows="4" class="form-control" formControlName="Details" (input)="ontextinput($event , 'Details')"></textarea>
                  <span class="speacialchar"><span>*</span> Special Characters allowed , - _ . only</span>
                  <span class="maxcar" style="margin-left: 10px;"> <span>*</span> Max Chara Limit 1000 </span>
                </div>
                <!-- <div class="col-md-2"></div> -->
              </div>
            </div>
            <div class="row">
              <div class="col-md-7">

                <div class="row mt-4">
                  <div class="col-md-12 matheight"
                    [ngClass]="{'has-error': submitted && (complaintform3.controls['MobileNumber'].invalid )}">
                    <label class="col-md-3"><span>Mobile Number&nbsp;<span class="imprt">*</span></span></label>
                    <div class="col-md-5 inpt df" style="position: relative;">
                      <input type="text" #num class="form-control mob" formControlName="MobileNumber" maxlength="10"
                        minlength="10" (keypress)="keyPressNumbers($event)" (keydown)="keydownnum($event)" (input)="onNumberinput($event , 'MobileNumber')">
                      <span class="otpbtn df" (click)="getotp()" [ngClass]="{ 'disabled': buttonDisabled }"><img
                          src="../../../assets/submitForm/otpgen.svg" alt="otp" width="14px"></span>
                      <span class="maxcar" style="position: absolute;bottom: -20px;left: 20px;"> <span>*</span> Click on
                        Blue arrow to get an OTP</span>
                      <span (click)="getotp()" class="resend" *ngIf="timerInterval && timer == 0">Resend</span>
                      <span class="timer" style="color:#0784C7;" *ngIf="timerInterval && timer > 0">{{ formatTime(timer)
                        }}</span>
                    </div>
                  </div>
                </div>
                <div class="row mt-4">
                  <div class="col-md-12 matheight" style="margin-top: 10px;"
                    [ngClass]="{'has-error': submitted && (complaintform3.controls['Otp'].invalid )}">
                    <label class="col-md-3"><span>Enter OTP&nbsp;<span class="imprt">*</span></span></label>
                    <div class="col-md-5 inpt" style="position: relative;">
                      <input type="text" class="form-control" formControlName="Otp" maxlength="6" minlength="6"  (input)="onNumberinput($event , 'Otp')"  [readonly]="EnterOtp_Send == false" [ngClass]="{'disabled-button': EnterOtp_Send == false}">
                      <span *ngIf="submitted && complaintform3.controls['Otp'].invalid" class="fileup"
                        style="font-size: 12px;"><span>*</span>Otp required</span>
                    </div>
                  </div>
                </div>
                <div class="row" style="margin-top: 2rem; margin-bottom: 1.5rem;">
                  <div class="col-md-12 subbtn">
                    <button class="submit" type="submit">Submit</button>
                  </div>
                </div>
              </div>
              <div class="col-md"></div>
            </div>
          </form>
        </div>
      </div>
    </div>

    <!-- track complaints -->
    <div *ngIf="complaintsrc=='5'" style="max-width:1600px;width: 100%;">
      <div class="trackhead"><span routerLink="/onlinecomplaint">Home</span>/<span>Track Complaint</span></div>
      <div class="complhead trackh">
        <div c style="width: 90%;">
          <h4>{{camphtxt}}</h4>
        </div>
        <div class="df"><span type="button" class="addnew df" routerLink="/onlinecomplaint" (click)="gotohome()">Add
            New</span></div>
      </div>
      <div *ngIf="!nocomplaints">
        <div style="overflow-x:scroll;margin-bottom: 5rem;">
          <table mat-table matSort #EmpaneledSort [dataSource]="dataSource" class="mat-elevation-z8"
            style="width: 100%;border: none;">

            <ng-container matColumnDef="Sn">
              <th mat-header-cell *matHeaderCellDef class="table-header"> S.No
              </th>
              <td mat-cell *matCellDef="let element; let i=index" class="tableData">{{ (paginatorRef1.pageIndex *
                paginatorRef1.pageSize) + (i + 1) }}</td>
            </ng-container>

            <ng-container matColumnDef="complaintid">
              <th mat-header-cell *matHeaderCellDef class="table-header">Complaint ID</th>
              <td mat-cell *matCellDef="let element" class="tableData"> {{element.complaint_id}} </td>
            </ng-container>

            <ng-container matColumnDef="Date">
              <th mat-header-cell *matHeaderCellDef class="table-header">Date</th>
              <td mat-cell *matCellDef="let element" class="tableData">{{element.c_date}}</td>
            </ng-container>

            <ng-container matColumnDef="View">
              <th mat-header-cell *matHeaderCellDef class="table-header">View</th>
              <td mat-cell *matCellDef="let element" class="tableData">
                <span (click)="viewdata(element)" style="cursor: pointer;">
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor"
                    class="bi bi-eye-fill" viewBox="0 0 16 16">
                    <path d="M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0z" />
                    <path
                      d="M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8zm8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7z" />
                  </svg>
                </span>
              </td>
            </ng-container>

            <ng-container matColumnDef="Status">
              <th mat-header-cell *matHeaderCellDef class="table-header">Status</th>
              <td mat-cell *matCellDef="let element" class="tableData"> {{element.status}} </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumnsNew"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumnsNew;"></tr>
          </table>
          <mat-paginator #trackpagenatore="matPaginator" #paginatorRef1 [length]="100" style-paginator [pageSize]="25"
            [pageSizeOptions]="[25, 50, 75, 100]" showFirstLastButtons></mat-paginator>


        </div>
      </div>
      <h6 *ngIf="nocomplaints" style="margin-top: 3rem;margin-bottom: 3rem;text-align: center;font-weight: 600;padding-right: 150px;">
        No complaints found
      </h6>
    </div>




  </div>
</div>
<div>
</div>


<div class="modal fade" style="backdrop-filter: blur(16px);" style="backdrop-filter: blur(16px);" id="sumitsuccess"
  tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true" data-backdrop="static" data-keyboard="false">
  <div class="modal-dialog modal-lg modal-dialog-centered" style="padding: 8px 40px;">
    <div class="modal-content">
      <div class="modal-header" style="border: none;">
        <button type="button" style="color:#ffffff;" class="close" (click)="closemodel()"
          style="position: absolute;top:0px;right:0 ;font-size: 45px;" data-dismiss="modal" aria-label="Close">
          <span style="margin-right: 16px;" aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body" style="padding-left: 36px;">
        <div>Thank you for contacting GAIL Gas ! </div>
        <div>Your Complaint Number <span style="font-weight: 900; color: black;">{{successmsg}}</span> has been
          registered and forwarded to concenred authority
        </div>
      </div>
    </div>
  </div>
</div>


<!-- <div class="modal fade" style="backdrop-filter: blur(16px);" style="backdrop-filter: blur(16px);" id="sumitsuccess2"
  tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg modal-dialog-centered" style="padding: 8px 40px;">
    <div class="modal-content">
      <div class="modal-header" style="border: none;">
        <button type="button" style="color:#ffffff;" class="close" (click)="closemodel2()"
          style="position: absolute;top:0px;right:0 ;font-size: 45px;" data-dismiss="modal" aria-label="Close">
          <span style="margin-right: 16px;" aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body" style="padding-left: 36px;">
        <div>Thank you for contacting GAIL Gas ! </div>
        <div>Your Complaint Number <span style="font-weight: 900; color: black;">{{successmsg}}</span> has been registered and
          forwarded to concenred authority
        </div>
      </div>
    </div>
  </div>
</div> -->



<div class="modal fade" id="viewcompmodel" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true"
  data-bs-backdrop="static" data-bs-keyboard="false">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content" style="min-width: 32vw !important; ">
      <div class="matcardHeader shadow m-0"
        style="padding: 0;font-weight: 600;background-color: #0295C1;color: #ffffff;">
        <div class="text-center" style="padding: 15px;">
          <div>Complaint details </div>
          <button type="button" class="close closerm" data-dismiss="modal" aria-label="Close">
            <span style="position: relative;top: -22px;left: -3px;color: #ffffff !important;"
              aria-hidden="true">&times;</span>
          </button>
        </div>
      </div>
      <div class="modal-body" style="display: flex;align-items: center; justify-content: center;">
        <div style="width: 100%;" class="text-center d-flex flex-column justify-content-center">

          <div *ngIf="identitikey == 'CUSTOMER'">
            <div class="left">
              <div>Customer name</div><span>:</span>
              <div>{{viewcmp.name}}</div>
            </div>
            <div class="left">
              <div>Customer type</div> <span>:</span>
              <div>{{viewcmp.customer_type}}</div>
            </div>
            <div class="left">
              <div>BP/ Consumer Number</div> <span>:</span>
              <div *ngIf="viewcmp.bp_number">{{viewcmp.bp_number}}</div>
              <div *ngIf="!viewcmp.bp_number">NA</div>
            </div>
            <div class="left">
              <div>Area of Operation</div> <span>:</span>
              <div>{{viewcmp.business_name}}</div>
            </div>
            <div class="left">
              <div>Mobile Number</div><span>:</span>
              <div>{{viewcmp.mobile_no}}</div>
            </div>
            <div class="left">
              <div>Email</div> <span>:</span>
              <div>{{viewcmp.email}}</div>
            </div>
            <div class="left">
              <div>Details</div> <span>:</span>
              <div>{{viewcmp.complaint_desc}}</div>
            </div>
            <div class="left">
              <div>Upload</div> <span>:</span>
              <div *ngIf="fileurl"> <a href="{{apiUrl + imgurl}}" target="_blank">view</a> </div>
              <div *ngIf="!fileurl">NA</div>
            </div>
            <div class="left">
              <div>Remarks</div> <span>:</span>
              <div><span>{{vieremarks}}</span></div>
            </div>
          </div>

          <div *ngIf="identitikey == 'VENDOR'">
            <div class="left">
              <div>Vendor Code</div><span>:</span>
              <div>{{viewcmp.vendor_code}}</div>
            </div>
            <div class="left">
              <div>Vendor Name</div><span>:</span>
              <div>{{viewcmp.name}}</div>
            </div>
            <div class="left">
              <div>PO Number</div><span>:</span>
              <div>{{viewcmp.po_number}}</div>
            </div>
            <div class="left">
              <div>Vendor phone number</div><span>:</span>
              <div>{{viewcmp.vendor_phone_no}}</div>
            </div>
            <div class="left">
              <div>Nature of Complaint</div><span>:</span>
              <div>{{viewcmp.nature_type}}</div>
            </div>
            <div class="left">
              <div>Pre-Award/ Post-Award</div> <span>:</span>
              <div>{{viewcmp.award_type}}</div>
            </div>
            <div class="left">
              <div>Area of Operation</div> <span>:</span>
              <div>{{viewcmp.business_name}}</div>
            </div>
            <div class="left">
              <div>Mobile Number</div><span>:</span>
              <div>{{viewcmp.mobile_no}}</div>
            </div>
            <div class="left">
              <div>Email</div> <span>:</span>
              <div>{{viewcmp.email}}</div>
            </div>
            <div class="left">
              <div>Details</div> <span>:</span>
              <div>{{viewcmp.complaint_desc}}</div>
            </div>
            <div class="left">
              <div>Upload</div> <span>:</span>
              <div *ngIf="fileurl"> <a href="{{apiUrl + imgurl}}" target="_blank">view</a> </div>
              <div *ngIf="!fileurl">NA</div>
            </div>
            <div class="left">
              <div>Remarks</div> <span>:</span>
              <div><span>{{vieremarks}}</span></div>
            </div>
          </div>

          <div *ngIf="identitikey == 'VIGILANCE'">
            <div class="left">
              <div>Vigilance Name</div><span>:</span>
              <div>{{viewcmp.name}}</div>
            </div>
            <div class="left">
              <div>House No</div><span>:</span>
              <div>{{viewcmp.house_no}}</div>
            </div>
            <div class="left">
              <div>Building/ Street</div><span>:</span>
              <div>{{viewcmp.street}}</div>
            </div>
            <div class="left">
              <div>Area</div> <span>:</span>
              <div>{{viewcmp.area}}</div>
            </div>
            <div class="left">
              <div>City</div> <span>:</span>
              <div>{{viewcmp.city}}</div>
            </div>
            <div class="left">
              <div>State</div><span>:</span>
              <div>{{viewcmp.state}}</div>
            </div>
            <div class="left">
              <div>Pin Code</div> <span>:</span>
              <div>{{viewcmp.pincode}}</div>
            </div>
            <div class="left">
              <div>Country</div><span>:</span>
              <div>{{viewcmp.country}}</div>
            </div>
            <div class="left">
              <div>Subject</div> <span>:</span>
              <div>{{viewcmp.subject}}</div>
            </div>
            <div class="left">
              <div>Mobile Number</div><span>:</span>
              <div>{{viewcmp.mobile_no}}</div>
            </div>
            <div class="left">
              <div>Email</div> <span>:</span>
              <div>{{viewcmp.email}}</div>
            </div>
            <div class="left">
              <div>Details</div> <span>:</span>
              <div>{{viewcmp.complaint_desc}}</div>
            </div>
            <div class="left">
              <div>Upload</div> <span>:</span>
              <div *ngIf="fileurl"> <a href="{{apiUrl + imgurl}}" target="_blank">view</a> </div>
              <div *ngIf="!fileurl">NA</div>
            </div>
            <div class="left">
              <div>Remarks</div> <span>:</span>
              <div><span>{{vieremarks}}</span></div>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</div>




<div id="overlay">
  <div class="loader"></div>
</div>