import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-disclaimer',
  templateUrl: './disclaimer.component.html',
  styleUrls: ['./disclaimer.component.scss']
})
export class DisclaimerComponent implements OnInit {

  language: string;
  menuItem: any;
  disc: any;
  disAry: any;
  liabiltyAry: any;
  no: any;
  gail: any;

  constructor(public router:Router,private translateService: TranslateService) { 
   
  }

  ngOnInit(): void {
    this.language = localStorage.getItem('language');
    console.log(this.language , "Overview com");

    if(this.language == 'od' || this.language == 'kn'){
      this.language = 'en';
    }else {
      this.language = this.language;
    }

    this.translateService.setDefaultLang(this.language);

    this.translateService.get(["disclaimer"]).subscribe(values => {
      const data = Object.keys(values).map(key => values[key]);
      this.menuItem = data[0];
      this.disc = this.menuItem[0];
      
      this.disAry = this.disc.disclaimerKey;

      this.liabiltyAry = this.disc.liabilityKey;
      this.no = this.disc.noliabilityKey;
      this.gail = this.disc.gail;
      console.log(this.disAry);
    });

  }

}
