import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-privacy-policy-c',
  templateUrl: './privacy-policy-c.component.html',
  styleUrls: ['./privacy-policy-c.component.scss']
})
export class PrivacyPolicyCComponent implements OnInit {
  router: any;

  constructor() {

  }


  ngOnInit(): void {
  }

  

}
