import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Title, Meta } from '@angular/platform-browser';
import {PdfService} from './../services/pdf.service';


@Component({
  selector: 'app-code-of-conduct',
  templateUrl: './code-of-conduct.component.html',
  styleUrls: ['./code-of-conduct.component.scss']
})
export class CodeOfConductComponent implements OnInit {
  title = 'GAIL Gas Limited - Code Of Conduct';

  language: string;
  menuItem: any;
  disc: any;
  intro: any;
  definition: any;
  applicability: any;
  requirements: any;
  Compliance: any;
  Directorships: any;
  Trading1: any;
  Trading2: any;
  Information: any;
  Assets: any;
  Conduct: any;
  Amendments: any;
  website: any;
  Reporting: any;
  Consequences: any;
  Acknowledgement: any;
  ApplicabilityKeyList: any;
  RequirementsKeyList: any;
  InformationKeyList: any;

  constructor(public router:Router,private translateService: TranslateService, private titleService: Title, private metaTagService: Meta,  private PdfService: PdfService) { 
   
  }

  ngOnInit(): void {
    this.titleService.setTitle(this.title);
    this.metaTagService.updateTag(
      { name: 'description', content: 'GAIL Gas Limited - Code of Conduct' }
    );
    this.metaTagService.addTags([
      { name: 'keywords', content: '' },
    ]);

    this.PdfService.footer.emit();

    this.language = localStorage.getItem('language');
    console.log(this.language , "Overview com");

    if(this.language == 'od' || this.language == 'kn'){
      this.language = 'en';
    }else {
      this.language = this.language;
    }

    this.translateService.setDefaultLang(this.language);

    this.translateService.get(["codeOfConduct"]).subscribe(values => {
      const data = Object.keys(values).map(key => values[key]);
      this.menuItem = data[0];
      this.disc = this.menuItem[0];
      console.log(this.disc , "@@@@@@@@");
      this.intro = this.disc.introductionKey;
  
      this.definition = this.disc.DefinitionKey;

      this.applicability = this.disc.ApplicabilityKey;
      
      this.ApplicabilityKeyList = this.disc.ApplicabilityKeyList;

      this.requirements = this.disc.RequirementsKey;

      this.RequirementsKeyList = this.disc.RequirementsKeyList;

      this.Compliance = this.disc.ComplianceKey;

      this.Directorships = this.disc.DirectorshipsKey;

      this.Trading1 = this.disc.TradingKey1;
      
      this.Trading2 = this.disc.TradingKey2;

      this.Information = this.disc.InformationKey;

      this.InformationKeyList = this.disc.InformationKeyList;

      this.Assets = this.disc.AssetsKey;

      this.Conduct = this.disc.ConductKey;

      this.Amendments = this.disc.AmendmentsKey;

      this.website = this.disc.websiteKey;

      this.Reporting = this.disc.ReportingKey;

      this.Consequences = this.disc.ConsequencesKey;

      this.Acknowledgement = this.disc.AcknowledgementKey;
      
    });

  }

}
