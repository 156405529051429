<div class="footer-bgm footer">

    <div class="container-fluid">

        <div class="row">
            <div class="col-md-1"></div>
            <div class="col-md-10">

                <div fxLayout="row wrap" fxLayout.xs="column">



                    <div fxFlex="19%;" fxFlex.sm="30%" fxFlex.xs="80%" *ngIf="menuItem2">
                        <p class="Products" *ngFor="let plst of menuItem2[0].product" routerLink="{{plst.url}}"
                            style="cursor: pointer;">
                            <img src="./../../assets/images/menu-right-icon.svg">&nbsp;&nbsp;{{plst.p_nm}}
                        </p>

                        <p class="Products" style="cursor: pointer;">
                            <img src="./../../assets/images/menu-right-icon.svg">&nbsp;&nbsp;{{menuItem1[0].name}}
                        </p>
                        <form>
                            <div class="form-group">
                                <mat-select class="form-control select-styles" id="exampleFormControlSelect1"
                                    [(value)]="selectedOption">

                                    <mat-option value="1" disabled *ngIf="selectLocEnable === false">Select Location
                                    </mat-option>
                                    <mat-option *ngFor="let plst of menuItem1[0].product" [(value)]="plst.p_nm"
                                        routerLink="{{plst.url}}">
                                        {{plst.p_nm}}</mat-option>
                                    <!-- (onSelectionChange)="locationChange($event)" -->
                                    <!-- [(value)]="plst.p_nm"  -->

                                </mat-select>
                            </div>
                        </form>
                    </div>




                    <div fxFlex="16%;" fxFlex.sm="30%" fxFlex.xs="80%" *ngIf="menuItem">
                        <p class="Products">{{menuItem[0].name}}</p>
                        <div class="CNG" *ngFor="let plst of menuItem[0].product" routerLink="{{plst.url}}"
                            style="cursor: pointer;">{{plst.p_nm}}</div>
                    </div>

                    <!-- <div fxFlex="25%;" fxFlex.sm="30%" fxFlex.xs="80%" *ngIf="menuItem1">
                        <p class="Products">{{menuItem1[0].name}}</p>
                        <div fxLayout="row wrap" fxLayout.xs="column">
                            <div fxFlex="50%;" fxFlex.xs="80%">
                                <div class="CNG" *ngFor="let plst of menuItem1[0].product" routerLink="{{plst.url}}"
                                    style="cursor: pointer;">{{plst.p_nm}}</div>
                            </div>
        
                            <div fxFlex="50%;" fxFlex.sm="25%" fxFlex.xs="80%">
                                <div class="CNG" *ngFor="let plst of menuItem1[0].product1" routerLink="{{plst.url}}"
                                    style="cursor: pointer;">{{plst.p_nm}}</div>
                            </div>
                        </div>
                    </div> -->



                    <!-- <div fxFlex="1%;" fxFlex.sm="2%">
                    </div> -->

                    <div fxFlex="18%;" fxFlex.sm="30%" fxFlex.xs="80%" style="margin-left: -28px;">
                        <img src="./../../assets/images/loc-marker.svg">&nbsp;&nbsp;<span class="Products">{{'ftrcp' |
                            translate}}</span>
                        <p class="CNG">
                            <span>{{'ftradd1' | translate}}<br>
                                {{'ftradd2' | translate}}<br>
                                {{'ftradd3' | translate}}<br>
                                {{'ftradd4' | translate}}<br>
                                {{'ftradd5' | translate}}</span><br>
                            <span>{{'ftrcin' | translate}}</span>
                        </p>
                    </div>
                    <div *ngIf="screenWidth <= 768">
                        <br><br><br><br>
                    </div>

                    <!-- <div fxFlex="1%;" fxFlex.sm="2%">
                    </div> -->

                    <div fxFlex="18%;" fxFlex.sm="35%" fxFlex.xs="80%">
                        <img src="./../../assets/images/contact-phone.svg">&nbsp;&nbsp;<span
                            class="Products">{{'cnctNum' |
                            translate}}</span>
                        <p class="CNG" style="cursor: pointer;"><span>+91-120-2446400</span> /
                            <span>+91-120-4862400</span>
                        </p>
                        <img src="./../../assets/images/contact-phone.svg">&nbsp;&nbsp;<span
                            class="Products">{{'queries' |
                            translate}}</span>
                        <p class="CNG" style="cursor: pointer;">1800 102 9282</p>
                    </div>

                    <!-- <div fxFlex="2%;" fxFlex.sm="2%">
                    </div> -->


                    <div fxFlex="12%;" fxFlex.sm="35%" fxFlex.xs="80%">
                        <div class="d-flex">
                            <span style="margin-left: -7px;">
                                <p class="Products" style="text-align: center;"> {{'footerDownload' | translate}} <br>
                                    {{'footerPng' | translate}} <br> {{'footerBrk' | translate}} </p>
                            </span>
                            <span class="ml-3">
                                <br>
                                <img src="./../../assets/images/QRCode.png" style="float: right;width:7rem;">
                                <a style="float: right;margin-top: 8%;"
                                    href="https://play.google.com/store/apps/details?id=com.gailgas.pngcustomer"
                                    target="_blank">
                                </a>
                            </span>
                            <br>
                            <span class="mt-3 ml-3" style="width:7rem;">
                                <br>
                                <span class="mb-3">
                                    <img src="./../../assets/images/Playstore.svg" style="width:7rem;">
                                </span>
                                <span>
                                    <a style="float: right;margin-top: 10%;"
                                        href="https://apps.apple.com/in/app/sahaj-png-mitra/id1548453566"
                                        target="_blank">
                                        <img src="./../../assets/images/Appstore.svg" style="width:7rem;">
                                    </a>
                                </span>
                            </span>
                        </div>
                    </div>

                </div>
                <hr>
                <div fxLayout="row wrap" fxLayout.xs="column">

                    <div fxFlex="15%;" fxFlex.sm="30%" fxFlex.xs="80%" class="CNG" style="cursor: pointer;">
                        <a href="https://gailonline.com/#" target="blank"
                            style="cursor: pointer;text-decoration: none;color: white;">{{'gailIndia' |translate}}</a>
                    </div>
                    <div fxFlex="6%"></div>

                    <div fxFlex="10%;" fxFlex.sm="30%" fxFlex.xs="80%" class="CNG" style="cursor: pointer;"
                        routerLink="/faq">
                        {{'faq' |translate}}
                    </div>

                    <div fxFlex="2%"></div>

                    <div fxFlex="10%;" fxFlex.sm="30%" fxFlex.xs="80%" class="CNG" style="cursor: pointer;"
                        routerLink="/rti">
                        {{'rti' |translate}}
                    </div>

                    <div fxFlex="2%"></div>

                    <div fxFlex="15%;" fxFlex.sm="30%" fxFlex.xs="80%" class="CNG" style="cursor: pointer;"
                        routerLink="/disclaimer">
                        {{'ftrdisclaimer' |translate}}
                    </div>

                    <!-- <div fxFlex="2%"></div> -->

                    <div fxFlex="20%;" fxFlex.sm="30%" fxFlex.xs="80%" class="CNG" style="cursor: pointer;"
                        routerLink="/code-of-conduct">
                        {{'codeConduct' |translate}}
                    </div>

                    <!-- <div fxFlex="15%;" fxFlex.sm="30%" fxFlex.xs="80%" class="CNG"
                        style="cursor: pointer; text-decoration: none;" routerLink="">
                        <a class="privacy-txt"
                            href="https://gailgaspdfdownloads.s3.ap-south-1.amazonaws.com/GAIL+Privacy+Policy.pdf"
                            target="_blank">{{'privacyPolicy' |translate}}</a>
                    </div> -->


                    <div fxFlex="15%;" fxFlex.sm="30%" fxFlex.xs="80%" class="CNG"
                        style="cursor: pointer; text-decoration: none;" routerLink="/privacy-policy">
                        <a class="privacy-txt">{{'privacyPolicy' |translate}}</a>
                    </div>








                    <!-- <div fxFlex="40%;" fxFlex.sm="30%" fxFlex.xs="80%" style="cursor: pointer;">
                        <a href="https://gailgaspdfdownloads.s3.ap-south-1.amazonaws.com/details+for+uploading.pdf"
                            target="_blank" class="CNG anchor">
                            {{'details' |translate}}
                        </a>
        
                    </div> -->

                </div>

                <div class="mt-5">

                    <div fxFlex="50" fxFlex.sm="100" class.gt-xs="sec1" fxFlex.xs="100">
                        <div class="footer-text">
                            <p class="text-left">© {{'allRights' | translate}}</p>
                        </div>
                    </div>

                    <div fxFlex="50" fxFlex.sm="100" class.gt-xs="sec1" fxFlex.xs="100">
                        <!-- <div class="footer-text">
                            <a href="https://dataevolve.in/" target="blank"
                                style="text-decoration: none;cursor: pointer;">
                                <p class="text-right">{{'developerBy' | translate}}
                                    <img src="./../../assets/heart.svg">
                                    {{'developerBy1' | translate}}
                                </p>
                            </a>
                        </div> -->
                    </div>

                </div>



            </div>
            <div class="col-md-1"></div>
        </div>


    </div>
</div>