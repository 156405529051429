<!-- <mat-dialog-actions align="end">
  <button mat-button mat-dialog-close class="close-img">
    <img class="background-cross">
  </button>
</mat-dialog-actions>
<br>
<mat-dialog-content class="mat-typography dialog-container-styles">
  <div style="max-height: 100%; max-width: 100%;">
    <img src="{{image}}" id="imageStyles" class="imageR">
  </div>

</mat-dialog-content>
<mat-dialog-actions align="end" class="mat-dialog">
  <button mat-button (click)="imageClick('minus')" class="background-minus-main">
    <img class="background-minus">
  </button>
  <button mat-button (click)="imageClick('plus')" class="background-plus-main">
    <img class="background-plus">
  </button>
</mat-dialog-actions> -->

<div class="modal-main">

  <mat-dialog-actions>
    <!-- <button mat-button mat-dialog-close class="close-img">
    <img class="background-cross">
  </button> -->

    <svg mat-dialog-close xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor"
      class="bi bi-x-circle" viewBox="0 0 16 16" style="margin-left: auto;
    margin-right: 1%;">
      <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
      <path
        d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
    </svg>

  </mat-dialog-actions>

  <br>
  <br>

  <iframe [src]="image" type="application/pdf" width="100%" height="100%" style="border:none;" *ngIf="newTab == false">
      </iframe>

  <!-- <object [data]="image" embeded="true" type="application/pdf" width="100%" height="100%" *ngIf="newTab == false">
  </object> -->


  <!-- <div *ngIf="newTab == true">
    <p style="text-align: center;font-size: 20px;">Your web browser doesn't have a PDF plugin.
      Instead you can
      <br>
      <a [href]="image" target="_blank" mat-dialog-close> Please Click here to
        View the PDF file.</a></p>
  </div> -->

  <!-- <object width="100%" height="100%"
    data="../../assets/operationModule/maps/dehradun.pdf"></object> -->

  <!-- <object [data]="image" embeded="true" type="application/pdf" width="100%" height="100%">
      <p style="text-align: center;font-size: 20px;">Your web browser doesn't have a PDF plugin.
      Instead you can 
      <br>
      <a [href]="image" target="_blank"> Please Click here to
      View the PDF file.</a></p>
    </object> -->

  <!-- <object data="../../assets/operationModule/maps/banglore.png" type="application/pdf" width="100%" height="100%">
  </object> -->

  <!-- <iframe src="../../assets/operationModule/maps/dehradun.pdf" width="100%" height="100%" style="border:none;">
  </iframe> -->


  <!-- <mat-dialog-content class="mat-typography dialog-container-styles"> -->

  <!-- <drag-scroll >
    <div >
      <img src="{{image}}" id="imageStyles" class="imageR">
      
    </div>
  </drag-scroll> -->


  <!-- <iframe [src]="image" style="width: -webkit-fill-available;
  height: -webkit-fill-available;"></iframe> -->

</div>
<!-- </mat-dialog-content> -->


<!-- <mat-dialog-actions align="end" class="mat-dialog">
  <button mat-button (click)="imageClick('minus')" class="background-minus-main">
    <img class="background-minus">
  </button>
  <button mat-button (click)="imageClick('plus')" class="background-plus-main">
    <img class="background-plus">
  </button>
</mat-dialog-actions> -->



<!-- <drag-scroll>
  <div>
    <img src="{{image}}" id="imageStyles" class="imageR">
  </div>
</drag-scroll> -->