import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { PdfService } from '../services/pdf.service';
import { Title, Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-rti',
  templateUrl: './rti.component.html',
  styleUrls: ['./rti.component.scss']
})
export class RtiComponent implements OnInit {
  title = 'GAIL Gas Limited - RTI';

  language: string;
  internalization: any;
  table: any;
  projectLst: any;
  outletAdd: any;
  table1: any;
  table2: any;
  displayedColumns: string[] = [ "designation", "rtiCentral", "rtiCentral3", "rtiCentral1", "rtiCentral2"];
  dataSource: any;
  Overviewbg: string;

  constructor(private translateService: TranslateService, private pdfService: PdfService, private titleService: Title, private metaTagService: Meta) {
    this.language = localStorage.getItem('language');
    console.log(this.language , "Overview com");

    if(this.language == 'od' || this.language == 'kn'){
      this.language = 'en';
    }else {
      this.language = this.language;
    }

    this.translateService.setDefaultLang(this.language);
   }

  ngOnInit(): void {


    const lang = localStorage.getItem('language')
    if(lang == 'en'){
      this.Overviewbg = "./../../../assets/headersEOIRTIContactCareer.jpg"
    }else{
      this.Overviewbg = "../../../assets/EOIbghindi.jpg"
    }
    
    this.titleService.setTitle(this.title);
    this.metaTagService.updateTag(
      { name: 'description', content: 'GAIL Gas Limited - RTI Structure in GAIL Gas Limited Corporate Office (NOIDA)' }
    );
    this.metaTagService.addTags([
      { name: 'keywords', content: 'Regional Office (NOIDA), Site Office, gail office, rti gail gas' },
    ]);

    this.pdfService.footer.emit();
    this.translateService.get(["corporateTable"]).subscribe(values => {
      var data = Object.keys(values).map(key => values[key]);
      this.internalization = data[0][0];
      
      this.table1 = this.internalization.table1;
      console.log(this.table1);
      this.dataSource = new MatTableDataSource(this.table1);
    });


    this.translateService.get(["regionalTable"]).subscribe(values => {
      var data = Object.keys(values).map(key => values[key]);
      this.internalization = data[0][0];
      
      this.table2 = this.internalization.table2;
      console.log(this.table2);
    });
    
  }

}
