import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { PdfService } from '../services/pdf.service';
import { Router, NavigationEnd } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { Location } from '@angular/common';
declare var $: any;
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-png-new-c',
  templateUrl: './png-new-c.component.html',
  styleUrls: ['./png-new-c.component.scss']
})
export class PngNewCComponent implements OnInit {
  currentRoute: string;
  selectLocEnable: boolean = false;
  FirstCard: boolean = false;
  screenWidth: number;
  language: string;
  schemeData: any;
  points: any;
  tableData: any;
  tableHead: any;
  firstOne: { id: number; name: string; image: string; }[];
  GetDatajson: any;
  selectedWeekData: any[];
  Imgeshow: any;
  imageName: string;
  ImageList: any[] = []
  mp4Urls: SafeResourceUrl[];
  i: any;
  mult: any[] = []
  weekList: any[] = [];
  ImagesList_data: any[] = [];
  LocationImgList: any[] = [];
  ModalName: any;
  Week_Name: any;
  ModalBoolean: boolean = false;
  WeekCard: boolean = false;
  LocationCard: boolean = false;
  Title: boolean = true;
  backweek: boolean = false;
  backlocate: boolean = false;
  constructor(private translateService: TranslateService, private pdfservice: PdfService, private router: Router,
    private location: Location, private http: HttpClient, private sanitizer: DomSanitizer) { }

  ngOnInit(): void {
    $('#staticBackdrop').modal('hide');
    this.fetchData();
    this.screenWidth = window.innerWidth;
    this.pdfservice.langEvent.subscribe((event) => {
      if (event == 'initial') {
        this.language = localStorage.getItem('language');
        this.translateService.setDefaultLang(this.language);
        this.currentLanguage();
      } else {
        this.language = event;
        this.translateService.setDefaultLang(this.language);
        this.currentLanguage();
      }

      // console.log(this.ImagList)
    });
  }

  fetchData(): void {
    // this.http.get<any>('https://gailgaspdfdownloads.s3.ap-south-1.amazonaws.com/PNGCampaign/pngimages.json')
    this.http.get<any>('https://gailgaspdfdownloads.s3.ap-south-1.amazonaws.com/pngimages.json')
      .subscribe(
        (data) => {
          this.GetDatajson = data.PNGCampaign;
          console.log(this.GetDatajson, "Saii")
        },
        (error) => {
          console.error(error);
        }
      );
  }

  currentLanguage() {
    this.translateService.get(["PngNew"]).subscribe(values => {
      const data = Object.keys(values).map(key => values[key]);
      let cngData = data[0]
      this.schemeData = cngData[0];
    });
  }

  FilteredLocation: any[] = [];
  selectedLocations: string[];

  FirstWek(weekData: any[], WEEKNAME) {
    console.log("week")
    // console.log(weekData)
    this.LocationCard = false;
    this.backlocate = false;
    this.WeekCard = true;
    this.backweek = true;
    this.ModalBoolean = false;
    this.Week_Name = WEEKNAME.name
    this.ImagesList_data = []
    this.weekList = weekData;
    this.selectedLocations = [];
    this.separateByLocation(weekData);
    for (let index = 0; index < weekData.length; index++) {
    }
  }

  separateByLocation(data) {
    const separatedData = {};
    data.forEach(obj => {
      if (!separatedData[obj.location]) {
        separatedData[obj.location] = [];
      }
      separatedData[obj.location].push(obj);
    });
    let array = Object.keys(separatedData).map(key => separatedData[key]);
    this.LocationImgList = array
    console.log(this.LocationImgList)

  }

  weekback() {
    this.WeekCard = false;
    this.LocationCard = true;
    this.backweek = false;
    this.Title = true;
    this.ModalBoolean = false;
    this.backlocate = false;
  }

  locateback() {
    this.Title=true;
    this.WeekCard = true;
    this.LocationCard = false;
    this.ModalBoolean = false;
    this.backlocate = false;
  }


  isDuplicateLocation(location: string, currentIndex: number, campaign: any): boolean {
    if (campaign && currentIndex < campaign.length) {
      for (let j = 0; j < currentIndex; j++) {
        if (campaign[j] && campaign[j].location === location) {
          return true;
        }
      }
    }
    return false;
  }

  clickedLocations: Set<string> = new Set<string>();

  Location(Data: any, location_name: any, id: string) {
    console.log("location")
    this.backlocate = true;
    this.ModalBoolean = true
    this.LocationCard = true
    this.Title = false
    // document.getElementById(id)?.scrollIntoView();
    this.ModalName = location_name
    this.ImagesList_data = []
    for (let index = 0; index < Data.length; index++) {
      if (Data[index].location == location_name) {
        this.ImagesList_data.push(Data[index])

      }
    }
  }

  getColumnWidth(): string {
    const weekLength = this.GetDatajson.length > 0 ? this.GetDatajson.length : 0;
    switch (weekLength) {
      case 2:
        return '6'; // Each column will take up 50% width
      case 3:
        return '4';
      case 4:
        return '3';// Each column will take up 33.33% width      
      default:
        return '12'; // Fallback to full width
    }
  }

  clickedImageIndex: number;

  Modal(ele, selectedWeek) {
    // this.Imgeshow = ele.url;
    this.clickedImageIndex = selectedWeek.findIndex(item => item.url === ele.url);
    this.ImageList = selectedWeek.map(item => ({ url: item.url }));
    $('#staticBackdrop').modal('show');
  }

  ImgDown() {
    // console.log(this.Imgeshow);
    // return;
    // const downloadUrl = `/api/download-image?url=${encodeURIComponent(this.Imgeshow)}`;
    this.http.get(this.Imgeshow, { responseType: 'blob' }).subscribe(blob => {
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = 'image.jpg'; // You can specify the filename here
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }, error => {
      console.error('Error downloading image:', error);
    });
  }

  previousVideo: HTMLVideoElement | null = null;

  handlePlay(video: HTMLVideoElement) {
    console.log(video)
    if (this.previousVideo && this.previousVideo !== video) {
      this.previousVideo.pause(); // Pause previous video
    }
    this.previousVideo = video;
  }


}
