<div fxLayout.xs="column" fxFlexFill class="mobile-display">
    <div fxFlex="100" class="bg-img">
        <div class="container-fluid container-p-t" style="background: transparent;">
            <div class="container container-bg">
                <!-- <p class="banner-text">
                        Fueling <span class="banner-txt-growth"> Growth </span>
                        <span> With <span class="banner-txt-growth"> Green Energy </span></span>
                    </p> -->
            </div>
        </div>
    </div>
</div>

<div class="section mobile-p-t" style="background:#f4f4f9;" *ngIf="disc">
    <div class="column" fxLayout="row" fxLayout.xs="column" fxFlexFill>
        <div class="container-fluid" style="background: transparent;">
            <div class="container mobile-screens" style="background: transparent;">
                <div fxFlex="100">
                    <p class="location-text">
                        <span routerLink="/home" style="cursor: pointer;">{{disc.homedesc}}</span>
                        <span class="location-home"> &nbsp;<img
                                src="./../../assets/operationModule/dewas/ic-chevron-right.svg">&nbsp;
                            <span class="location-arrow"> {{disc.disclaimer}} </span>
                        </span>
                    </p>
                </div>

                <div class="row">
                    <div class="col-sm" style="height: 10px;"></div>
                    <div class="col-md" style="height: 20px;"></div>
                </div>
            </div>
        </div>
    </div>


    <div class="column" fxLayout="row" fxLayout.xs="column" fxFlexFill>
        <div class="container-fluid" style="background: transparent;">
            <div class="container mobile-screens" style="background: transparent;">
                <div fxFlex="100">
                    <p class="dewas-text">
                        <img src="../../assets/gileModule/overview/overview-leave.svg"> {{disc.introduction}}
                    </p>
                    <!-- <hr class="hr-1"> -->
                </div>
            </div>
            <div class="row">
                <div class="col-sm" style="height: 10px;"></div>
                <div class="col-md" style="height: 20px;"></div>
            </div>

        </div>
    </div>

    <!-- <div class="row">
            <div class="col-sm" style="height: 10px;"></div>
            <div class="col-md" style="height: 20px;"></div>
        </div> -->

    <div class="column" fxLayout="row" fxLayout.xs="column" fxFlexFill>
        <div class="container-fluid" style="background: transparent;">
            <div class="container mobile-screens" style="background: transparent;">

                <div fxFlex="100" class="data-txt">
                    <div *ngFor="let a of intro">
                        <!-- style="display: flex;" -->
                        <p class="disclaimer-txt-main">
                            <span class="number-styles"> {{a.id}} </span>
                            <span class="disclaimer-txt">
                                {{a.data}}
                            </span>
                        </p>

                        <!-- <p class="disclaimer-txt-main">
                            <span class="number-styles"> {{a.id}} </span>
                        </p>

                        <p class="disclaimer-txt-main" style="padding-left: 11px;">
                            <span class="disclaimer-txt">
                                {{a.data}}
                            </span>
                        </p> -->
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-sm" style="height: 10px;"></div>
                <div class="col-md" style="height: 50px;"></div>
            </div>

        </div>
    </div>



    <div class="column" fxLayout="row" fxLayout.xs="column" fxFlexFill>
        <div class="container-fluid" style="background: transparent;">
            <div class="container mobile-screens" style="background: transparent;">
                <div fxFlex="100">
                    <p class="dewas-text">
                        <img src="../../assets/gileModule/overview/overview-leave.svg"> {{disc.Definition}}
                    </p>
                </div>
            </div>

            <div class="row">
                <div class="col-sm" style="height: 10px;"></div>
                <div class="col-md" style="height: 20px;"></div>
            </div>

        </div>
    </div>



    <div class="column" fxLayout="row" fxLayout.xs="column" fxFlexFill>
        <div class="container-fluid" style="background: transparent;">
            <div class="container mobile-screens" style="background: transparent;">

                <div fxFlex="100" class="data-txt">
                    <div *ngFor="let a of definition">
                        <!-- style="display: flex;" -->
                        <p class="disclaimer-txt-main">
                            <span class="number-styles"> {{a.id}} </span>
                            <span class="disclaimer-txt">
                                {{a.data}}
                            </span>
                        </p>

                        <!-- <p class="disclaimer-txt-main">
                            <span class="number-styles"> {{a.id}} </span>
                        </p>

                        <p class="disclaimer-txt-main" style="padding-left: 11px;">
                            <span class="disclaimer-txt">
                                {{a.data}}
                            </span>
                        </p> -->
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-sm" style="height: 10px;"></div>
                <div class="col-md" style="height: 50px;"></div>
            </div>
        </div>
    </div>




    <div class="column" fxLayout="row" fxLayout.xs="column" fxFlexFill>
        <div class="container-fluid" style="background: transparent;">
            <div class="container mobile-screens" style="background: transparent;">
                <div fxFlex="100">
                    <p class="dewas-text">
                        <img src="../../assets/gileModule/overview/overview-leave.svg"> {{disc.Applicability}}
                    </p>
                </div>
            </div>

            <div class="row">
                <div class="col-sm" style="height: 10px;"></div>
                <div class="col-md" style="height: 20px;"></div>
            </div>
        </div>
    </div>



    <div class="column" fxLayout="row" fxLayout.xs="column" fxFlexFill>
        <div class="container-fluid" style="background: transparent;">
            <div class="container mobile-screens" style="background: transparent;">

                <div fxFlex="100" class="data-txt">
                    <div *ngFor="let a of applicability">
                        <p class="disclaimer-txt-main">
                            <span class="number-styles"> {{a.id}} </span>
                            <span class="disclaimer-txt">
                                {{a.data}}
                            </span>

                            <ul>
                                <li *ngFor="let b of ApplicabilityKeyList">
                                    <!-- <span class="number-styles" > {{b.id}} </span>   -->
                                    <span class="disclaimer-txt">{{b.data}} </span>
                                </li>
                            </ul>

                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-sm" style="height: 10px;"></div>
                <div class="col-md" style="height: 50px;"></div>
            </div>
        </div>
    </div>



    <div class="column" fxLayout="row" fxLayout.xs="column" fxFlexFill>
        <div class="container-fluid" style="background: transparent;">
            <div class="container mobile-screens" style="background: transparent;">
                <div fxFlex="100">
                    <p class="dewas-text">
                        <img src="../../assets/gileModule/overview/overview-leave.svg"> {{disc.Requirements}}
                    </p>
                </div>
            </div>

            <div class="row">
                <div class="col-sm" style="height: 10px;"></div>
                <div class="col-md" style="height: 20px;"></div>
            </div>
        </div>
    </div>



    <div class="column" fxLayout="row" fxLayout.xs="column" fxFlexFill>
        <div class="container-fluid" style="background: transparent;">
            <div class="container mobile-screens" style="background: transparent;">

                <div fxFlex="100" class="data-txt">
                    <div *ngFor="let a of requirements">
                        <p class="disclaimer-txt">
                            <!-- <span class="number-styles"> {{a.id}} </span> -->
                            <span class="disclaimer-txt">
                                {{a.data}}
                            </span>

                            <ul>
                                <li *ngFor="let b of RequirementsKeyList">
                                    <!-- <span class="number-styles" > {{b.id}} </span>   -->
                                    <span class="disclaimer-txt">{{b.data}} </span>
                                </li>
                            </ul>

                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-sm" style="height: 10px;"></div>
                <div class="col-md" style="height: 20px;"></div>
            </div>
        </div>
    </div>


    <div class="column" fxLayout="row" fxLayout.xs="column" fxFlexFill>
        <div class="container-fluid" style="background: transparent;">
            <div class="container mobile-screens" style="background: transparent;">
                <div fxFlex="100">
                    <p class="dewas-text">
                        <img src="../../assets/gileModule/overview/overview-leave.svg"> {{disc.Compliance}}
                    </p>
                </div>
            </div>

            <div class="row">
                <div class="col-sm" style="height: 10px;"></div>
                <div class="col-md" style="height: 20px;"></div>
            </div>
        </div>
    </div>



    <div class="column" fxLayout="row" fxLayout.xs="column" fxFlexFill>
        <div class="container-fluid" style="background: transparent;">
            <div class="container mobile-screens" style="background: transparent;">

                <div fxFlex="100" class="data-txt">
                    <div *ngFor="let a of Compliance">
                        <p class="disclaimer-txt"> {{a.data}} </p>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-sm" style="height: 10px;"></div>
                <div class="col-md" style="height: 20px;"></div>
            </div>
        </div>
    </div>


    <div class="column" fxLayout="row" fxLayout.xs="column" fxFlexFill>
        <div class="container-fluid" style="background: transparent;">
            <div class="container mobile-screens" style="background: transparent;">
                <div fxFlex="100">
                    <p class="dewas-text">
                        <img src="../../assets/gileModule/overview/overview-leave.svg"> {{disc.Directorships}}
                    </p>
                </div>
            </div>

            <div class="row">
                <div class="col-sm" style="height: 10px;"></div>
                <div class="col-md" style="height: 20px;"></div>
            </div>
        </div>
    </div>



    <div class="column" fxLayout="row" fxLayout.xs="column" fxFlexFill>
        <div class="container-fluid" style="background: transparent;">
            <div class="container mobile-screens" style="background: transparent;">

                <div fxFlex="100" class="data-txt">
                    <div *ngFor="let a of Directorships">
                        <p class="disclaimer-txt"> {{a.data}} </p>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-sm" style="height: 10px;"></div>
                <div class="col-md" style="height: 20px;"></div>
            </div>
        </div>
    </div>

    <div class="column" fxLayout="row" fxLayout.xs="column" fxFlexFill>
        <div class="container-fluid" style="background: transparent;">
            <div class="container mobile-screens" style="background: transparent;">
                <div fxFlex="100">
                    <p class="dewas-text">
                        <img src="../../assets/gileModule/overview/overview-leave.svg"> {{disc.Trading1}}
                    </p>
                </div>
            </div>

            <div class="row">
                <div class="col-sm" style="height: 10px;"></div>
                <div class="col-md" style="height: 20px;"></div>
            </div>
        </div>
    </div>



    <div class="column" fxLayout="row" fxLayout.xs="column" fxFlexFill>
        <div class="container-fluid" style="background: transparent;">
            <div class="container mobile-screens" style="background: transparent;">

                <div fxFlex="100" class="data-txt">
                    <div *ngFor="let a of Trading1">
                        <p class="disclaimer-txt"> {{a.data}} </p>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-sm" style="height: 10px;"></div>
                <div class="col-md" style="height: 20px;"></div>
            </div>
        </div>
    </div>

    <div class="column" fxLayout="row" fxLayout.xs="column" fxFlexFill>
        <div class="container-fluid" style="background: transparent;">
            <div class="container mobile-screens" style="background: transparent;">
                <div fxFlex="100">
                    <p class="dewas-text">
                        <img src="../../assets/gileModule/overview/overview-leave.svg"> {{disc.Trading2}}
                    </p>
                </div>
            </div>

            <div class="row">
                <div class="col-sm" style="height: 10px;"></div>
                <div class="col-md" style="height: 20px;"></div>
            </div>
        </div>
    </div>



    <div class="column" fxLayout="row" fxLayout.xs="column" fxFlexFill>
        <div class="container-fluid" style="background: transparent;">
            <div class="container mobile-screens" style="background: transparent;">

                <div fxFlex="100" class="data-txt">
                    <div *ngFor="let a of Trading2">
                        <p class="disclaimer-txt"> {{a.data}} </p>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-sm" style="height: 10px;"></div>
                <div class="col-md" style="height: 20px;"></div>
            </div>
        </div>
    </div>

    <div class="column" fxLayout="row" fxLayout.xs="column" fxFlexFill>
        <div class="container-fluid" style="background: transparent;">
            <div class="container mobile-screens" style="background: transparent;">
                <div fxFlex="100">
                    <p class="dewas-text">
                        <img src="../../assets/gileModule/overview/overview-leave.svg"> {{disc.Information}}
                    </p>
                </div>
            </div>

            <div class="row">
                <div class="col-sm" style="height: 10px;"></div>
                <div class="col-md" style="height: 20px;"></div>
            </div>
        </div>
    </div>



    <div class="column" fxLayout="row" fxLayout.xs="column" fxFlexFill>
        <div class="container-fluid" style="background: transparent;">
            <div class="container mobile-screens" style="background: transparent;">

                <div fxFlex="100" class="data-txt">
                    <div *ngFor="let a of Information">
                        <p class="disclaimer-txt">
                            <!-- <span class="number-styles"> {{a.id}} </span> -->
                            <span class="disclaimer-txt">
                                {{a.data}}
                            </span>

                            <ul>
                                <li *ngFor="let b of ApplicabilityKeyList">
                                    <!-- <span class="number-styles" > {{b.id}} </span>   -->
                                    <span class="disclaimer-txt">{{b.data}} </span>
                                </li>
                            </ul>

                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-sm" style="height: 10px;"></div>
                <div class="col-md" style="height: 20px;"></div>
            </div>
        </div>
    </div>

    <div class="column" fxLayout="row" fxLayout.xs="column" fxFlexFill>
        <div class="container-fluid" style="background: transparent;">
            <div class="container mobile-screens" style="background: transparent;">
                <div fxFlex="100">
                    <p class="dewas-text">
                        <img src="../../assets/gileModule/overview/overview-leave.svg"> {{disc.Assets}}
                    </p>
                </div>
            </div>

            <div class="row">
                <div class="col-sm" style="height: 10px;"></div>
                <div class="col-md" style="height: 20px;"></div>
            </div>
        </div>
    </div>



    <div class="column" fxLayout="row" fxLayout.xs="column" fxFlexFill>
        <div class="container-fluid" style="background: transparent;">
            <div class="container mobile-screens" style="background: transparent;">

                <div fxFlex="100" class="data-txt">
                    <div *ngFor="let a of Assets">
                        <p class="disclaimer-txt"> {{a.data}} </p>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-sm" style="height: 10px;"></div>
                <div class="col-md" style="height: 20px;"></div>
            </div>
        </div>
    </div>


    <div class="column" fxLayout="row" fxLayout.xs="column" fxFlexFill>
        <div class="container-fluid" style="background: transparent;">
            <div class="container mobile-screens" style="background: transparent;">
                <div fxFlex="100">
                    <p class="dewas-text">
                        <img src="../../assets/gileModule/overview/overview-leave.svg"> {{disc.Conduct}}
                    </p>
                </div>
            </div>

            <div class="row">
                <div class="col-sm" style="height: 10px;"></div>
                <div class="col-md" style="height: 20px;"></div>
            </div>
        </div>
    </div>



    <div class="column" fxLayout="row" fxLayout.xs="column" fxFlexFill>
        <div class="container-fluid" style="background: transparent;">
            <div class="container mobile-screens" style="background: transparent;">

                <div fxFlex="100" class="data-txt">
                    <div *ngFor="let a of Conduct">
                        <p class="disclaimer-txt"> {{a.data}} </p>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-sm" style="height: 10px;"></div>
                <div class="col-md" style="height: 20px;"></div>
            </div>
        </div>
    </div>

    <div class="column" fxLayout="row" fxLayout.xs="column" fxFlexFill>
        <div class="container-fluid" style="background: transparent;">
            <div class="container mobile-screens" style="background: transparent;">
                <div fxFlex="100">
                    <p class="dewas-text">
                        <img src="../../assets/gileModule/overview/overview-leave.svg"> {{disc.Amendments}}
                    </p>
                </div>
            </div>

            <div class="row">
                <div class="col-sm" style="height: 10px;"></div>
                <div class="col-md" style="height: 20px;"></div>
            </div>
        </div>
    </div>



    <div class="column" fxLayout="row" fxLayout.xs="column" fxFlexFill>
        <div class="container-fluid" style="background: transparent;">
            <div class="container mobile-screens" style="background: transparent;">

                <div fxFlex="100" class="data-txt">
                    <div *ngFor="let a of Amendments">
                        <p class="disclaimer-txt"> {{a.data}} </p>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-sm" style="height: 10px;"></div>
                <div class="col-md" style="height: 20px;"></div>
            </div>
        </div>
    </div>

    <div class="column" fxLayout="row" fxLayout.xs="column" fxFlexFill>
        <div class="container-fluid" style="background: transparent;">
            <div class="container mobile-screens" style="background: transparent;">
                <div fxFlex="100">
                    <p class="dewas-text">
                        <img src="../../assets/gileModule/overview/overview-leave.svg"> {{disc.website}}
                    </p>
                </div>
            </div>

            <div class="row">
                <div class="col-sm" style="height: 10px;"></div>
                <div class="col-md" style="height: 20px;"></div>
            </div>
        </div>
    </div>



    <div class="column" fxLayout="row" fxLayout.xs="column" fxFlexFill>
        <div class="container-fluid" style="background: transparent;">
            <div class="container mobile-screens" style="background: transparent;">

                <div fxFlex="100" class="data-txt">
                    <div *ngFor="let a of website">
                        <p class="disclaimer-txt"> {{a.data}} </p>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-sm" style="height: 10px;"></div>
                <div class="col-md" style="height: 20px;"></div>
            </div>
        </div>
    </div>

    <div class="column" fxLayout="row" fxLayout.xs="column" fxFlexFill>
        <div class="container-fluid" style="background: transparent;">
            <div class="container mobile-screens" style="background: transparent;">
                <div fxFlex="100">
                    <p class="dewas-text">
                        <img src="../../assets/gileModule/overview/overview-leave.svg"> {{disc.Reporting}}
                    </p>
                </div>
            </div>

            <div class="row">
                <div class="col-sm" style="height: 10px;"></div>
                <div class="col-md" style="height: 20px;"></div>
            </div>
        </div>
    </div>



    <div class="column" fxLayout="row" fxLayout.xs="column" fxFlexFill>
        <div class="container-fluid" style="background: transparent;">
            <div class="container mobile-screens" style="background: transparent;">

                <div fxFlex="100" class="data-txt">
                    <div *ngFor="let a of Reporting">
                        <p class="disclaimer-txt"> {{a.data}} </p>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-sm" style="height: 10px;"></div>
                <div class="col-md" style="height: 20px;"></div>
            </div>
        </div>
    </div>

    <div class="column" fxLayout="row" fxLayout.xs="column" fxFlexFill>
        <div class="container-fluid" style="background: transparent;">
            <div class="container mobile-screens" style="background: transparent;">
                <div fxFlex="100">
                    <p class="dewas-text">
                        <img src="../../assets/gileModule/overview/overview-leave.svg"> {{disc.Consequences}}
                    </p>
                </div>
            </div>

            <div class="row">
                <div class="col-sm" style="height: 10px;"></div>
                <div class="col-md" style="height: 20px;"></div>
            </div>
        </div>
    </div>



    <div class="column" fxLayout="row" fxLayout.xs="column" fxFlexFill>
        <div class="container-fluid" style="background: transparent;">
            <div class="container mobile-screens" style="background: transparent;">

                <div fxFlex="100" class="data-txt">
                    <div *ngFor="let a of Consequences">
                        <p class="disclaimer-txt"> {{a.data}} </p>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-sm" style="height: 10px;"></div>
                <div class="col-md" style="height: 20px;"></div>
            </div>
        </div>
    </div>

    <div class="column" fxLayout="row" fxLayout.xs="column" fxFlexFill>
        <div class="container-fluid" style="background: transparent;">
            <div class="container mobile-screens" style="background: transparent;">
                <div fxFlex="100">
                    <p class="dewas-text">
                        <img src="../../assets/gileModule/overview/overview-leave.svg"> {{disc.Acknowledgement}}
                    </p>
                </div>
            </div>

            <div class="row">
                <div class="col-sm" style="height: 10px;"></div>
                <div class="col-md" style="height: 20px;"></div>
            </div>
        </div>
    </div>



    <div class="column" fxLayout="row" fxLayout.xs="column" fxFlexFill>
        <div class="container-fluid" style="background: transparent;">
            <div class="container mobile-screens" style="background: transparent;">

                <div fxFlex="100" class="data-txt">
                    <div *ngFor="let a of Acknowledgement">
                        <p class="disclaimer-txt"> {{a.data}} </p>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-sm" style="height: 10px;"></div>
                <div class="col-md" style="height: 20px;"></div>
            </div>
        </div>
    </div>

    <div class="column" fxLayout="row" fxLayout.xs="column" fxFlexFill>
        <div class="container-fluid" style="background: transparent;">
            <div class="container btn-head" style="background: transparent;" >

                <div fxFlex="20" fxFlex.xs="50">
                    <a href="https://gailgaspdfdownloads.s3.ap-south-1.amazonaws.com/appendix1.pdf" 
                    target="_blank">
                        <button type="button" class="btn appendix"> {{disc.appendix}} 1 </button>
                    </a>
                </div>
                <div fxFlex="5"></div>
                <div fxFlex="20" fxFlex.xs="50">
                    <a href="https://gailgaspdfdownloads.s3.ap-south-1.amazonaws.com/appendix2.pdf"
                    target="_blank">
                        <button type="button" class="btn appendix"> {{disc.appendix}} 2 </button>
                    </a>
                </div>
                <div fxFlex="5"></div>
                <div fxFlex="20" fxFlex.xs="50">
                    <a href="https://gailgaspdfdownloads.s3.ap-south-1.amazonaws.com/appendix3.pdf"
                    target="_blank">
                        <button type="button" class="btn appendix"> {{disc.appendix}} 3 </button>
                    </a>
                </div>
                <div fxFlex="5"></div>
                <div fxFlex="20" fxFlex.xs="50">
                    <a href="https://gailgaspdfdownloads.s3.ap-south-1.amazonaws.com/appendix4.pdf"
                    target="_blank">
                        <button type="button" class="btn appendix"> {{disc.appendix}} 4 </button>
                    </a>
                </div>

            </div>

            <div class="row">
                <div class="col-sm" style="height: 10px;"></div>
                <div class="col-md" style="height: 20px;"></div>
            </div>
        </div>
    </div>
</div>