<div fxLayout.xs="column" fxFlexFill class="mobile-display">
    <div fxFlex="100" class="bg-img">
        <div class="container-fluid" style="background: transparent;">
            <div class="container container-bg">
                <!-- <p class="banner-text">
                        Fueling <span class="banner-txt-growth"> Growth </span>
                        <span> With <span class="banner-txt-growth"> Green Energy </span></span>
                    </p> -->
            </div>
        </div>
    </div>
</div>



<div class="column" fxLayout="row" fxLayout.xs="column" fxFlexFill style="background:white;">
    <div class="container-fluid" style="background: transparent;">
        <div class="container mobile-screens" style="background: transparent;">
            <div fxFlex="100">

                <div *ngIf="schemeData">

                    <br>
                    <h3 class="overview-heading">
                        <img src="../../../assets/gileModule/overview/overview-leave.svg" class="leave-style">
                        {{schemeData['cngHeading'] | translate}}
                    </h3>


                    <!-- <div class="overview-subHeading">
                        <p class="overview-subHeading"> {{schemeData['cngSubHeading'] | translate}} </p>
                    </div> -->

                    <div class="cngSchemePoints">
                        <ul *ngFor="let point of points" class="cngSchemePoints">
                            <li>{{point['data']}}</li>
                        </ul>
                        
                    </div>


                    <h3 class="overview-heading">
                        <img src="../../../assets/gileModule/overview/overview-leave.svg" class="leave-style">
                        {{schemeData['detailsHeading'] | translate}}
                    </h3>

                    <!-- <div class="detailsScheme">
                        <p class="detailsHeading"> {{schemeData['detailsHeading'] | translate}} </p>
                    </div> -->

                    <div class="cngSchemePoints">
                        <div class="cngSchemePoints">
                            {{schemeData['detailsData'] | translate}}
                        </div>
                    </div>

                    <br>

                    <div class="table table-bordered">
                        <thead class="table-header ">
                            
                               <tr *ngFor="let data of tableHead">
                                 <td>{{data.s_no}}</td>
                                 <td>{{data.vehicle_type}}</td>
                                 <td>{{data.denomination}}</td>
                                 <td>{{data.daily_usage}}</td>
                                 <td>{{data.monthly_usage}}</td>
                                 <td>{{data.validity}}</td>
                               </tr>
                            
                        </thead>
                        <tbody class="table-data">
                            <tr *ngFor="let data of tableData">
                                <td>{{data.id}}</td>
                                <td>{{data.vehicle_type}}</td>
                                <td>{{data.denomination}}</td>
                                <td>{{data.daily_usage}}</td>
                                <td>{{data.monthly_usage}}</td>
                                <td>{{data.validity_card}}</td>
    
                            </tr>
                        </tbody>
                    </div>
                    <div>
                        <p *ngIf="language=='en'" style="font-weight: bold;font-size: 12px;">* TDS as per Section-194R
                            to be deducted, if applicable</p>
                        <p *ngIf="language=='hn'" style="font-weight: bold;font-size: 12px;">* यदि लागू हो तो धारा-194आर
                            के अनुसार टीडीएस काटा जाएगा</p>
                    </div>
                    <!-- <table class="table table-bordered">
                        <thead>
                            <tr class="cngSchemePoints-head">
                                <th>S.No</th>
                                <th>Type of Vehicle </th>
                                <th>Denomination of the Card</th>
                                <th>Daily Usage Limit</th>
                                <th>Monthly Usage Limit</th>
                                <th>Maximum Validity of the card from the date of activation</th>
                            </tr>
                        </thead>
                        <tbody class="cngSchemePoints">
                            <tr>
                                <td>1</td>
                                <td>New Passenger Auto Rickshaw (3W)</td>
                                <td>Rs.14,000/-</td>
                                <td>Rs.250/-</td>
                                <td>Rs.7750/-</td>
                                <td>180 days</td>
                            </tr>
                            <tr>
                                <td>2</td>
                                <td>New Cargo Auto Rickshaw (3W)</td>
                                <td>Rs. 25,000/-</td>
                                <td>Rs.250/-</td>
                                <td>Rs.7750/-</td>
                                <td>180 days</td>
                            </tr>
                            <tr>
                                <td>3</td>
                                <td>New Taxi/ Private Car</td>
                                <td>Rs. 25,000/-</td>
                                <td>Rs.500/-</td>
                                <td>Rs.10000/-</td>
                                <td>180 days </td>
                            </tr>

                            <tr>
                                <td>4</td>
                                <td>New LCV/HCV</td>
                                <td>Rs. 25,000/-</td>
                                <td>Rs.1000/-</td>
                                <td>Rs.10000/-</td>
                                <td>180 days</td>
                            </tr>
                            <tr>
                                <td>5</td>
                                <td>Retro Fitted Passenger Auto Rickshaw (3W)</td>
                                <td>Rs. 23,000/-</td>
                                <td>Rs.250/-</td>
                                <td>Rs.7750/-</td>
                                <td>180 days</td>
                            </tr>
                            <tr>
                                <td>6</td>
                                <td>Retro Fitted Taxi</td>
                                <td>Rs. 23,000/-</td>
                                <td>Rs.500/-</td>
                                <td>Rs.10000/-</td>
                                <td>180 days</td>
                            </tr>
                            <tr>
                                <td>7</td>
                                <td>Retro fitted Private Car</td>
                                <td>Rs. 25,000/-</td>
                                <td>Rs.500/-</td>
                                <td>Rs.10000/-</td>
                                <td>180 days</td>
                            </tr>
                        </tbody>
                    </table> -->

                    <br>

                    <div class="cngSchemePoints">
                        <div class="cngSchemePoints"> {{schemeData['availScheme'] | translate}} </div>
                    </div>

                    <br>

                    <div class="cngSchemePoints">
                        <div class="cngSchemePoints"> {{schemeData['detailedTerms'] | translate}} </div>
                    </div>

                    <br>

                    <div class="cngSchemePoints">
                        <div class="cngSchemePoints"> {{schemeData['optingCng'] | translate}} </div>
                    </div>

                    <br>

                    <div class="cngSchemePoints">
                        <div class="cngSchemePoints"> {{schemeData['environment'] | translate}} </div>
                    </div>

                    <br>

                    <div class="cngSchemePoints">
                        <div class="cngSchemePoints"> {{schemeData['moreConvenience'] | translate}} </div>
                    </div>

                    <br> <br>

                    <div fxLayout="row">
                        <div fxFlex="50">

                            <div class="cngSchemePoints">
                                <ul>
                                    <li>
                                        <a href="https://gailgaspdfdownloads.s3.ap-south-1.amazonaws.com/Terms+%26+Conditions.pdf"
                                            target="_blank">
                                            {{schemeData['bullet1'] | translate}}
                                        </a>
                                    </li>
                                    <br>
                                    <li>
                                        <a href="https://gailgaspdfdownloads.s3.ap-south-1.amazonaws.com/Authorised+Dealers.pdf"
                                            target="_blank">
                                            {{schemeData['bullet2'] | translate}}
                                        </a>
                                    </li>
                                </ul>


                            </div>
                        </div>

                        <div fxFlex="50">

                            <div class="cngSchemePoints">

                                <ul>
                                    <li>
                                        <a href="https://gailgaspdfdownloads.s3.ap-south-1.amazonaws.com/list+of+retrofitters.pdf"
                                            target="_blank">
                                            {{schemeData['bullet3'] | translate}}
                                        </a>
                                    </li>
                                    <br>
                                    <li>
                                        <a href="https://gailgaspdfdownloads.s3.ap-south-1.amazonaws.com/CNG+Station+List.pdf"
                                            target="_blank">
                                            {{schemeData['bullet4'] | translate}}
                                        </a>
                                    </li>
                                </ul>



                            </div>

                        </div>
                        <!-- <div fxFlex="50">

                            <div class="cngSchemePoints">

                                <ul>
                                    
                                    <li>
                                        <a href="https://gailgaspdfdownloads.s3.ap-south-1.amazonaws.com/Extension+of+CNG+promotional+Scheme.pdf"
                                            target="_blank">
                                            {{schemeData['bullet5'] | translate}}
                                        </a>
                                    </li>
                                </ul>
                            </div>

                        </div> -->
                    </div>

                    <br><br><br>
                </div>

            </div>
        </div>
    </div>
</div>