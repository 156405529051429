import { EventEmitter, Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PdfService {
  langEvent = new BehaviorSubject('initial');

  setTitle(title: string) {
    this.langEvent.next(title);
  }

  public footer = new EventEmitter<any>();

  constructor() { }
}
