<br><br><br><br><br>
<div class="container">
    <div>
        <h4>Privacy Policy</h4>
        <p class="mt-4">We at GAIL Gas Limited respect the privacy of everyone who visits this website/mobile
            application
            and are committed to maintain the privacy and security of the personal information of all visitors to
            this website/mobile application. Our policy on the collection and use of personal information and
            other information is outlined below.
        </p>
        <p class="mt-4">
            In case of visiting this website/mobile application to read or download information, it must be
            known that GAIL Gas Limited collects and stores a standard set of internet-related information, such
            as an Internet Protocol (IP) address, the date and time, the type of browser and operating system
            used, the pages(s) visited etc. All information is collected to help GAIL Gas Limited for making this
            site more useful to its customer(s) and only used for statistical purposes
        </p>
        <p class="mt-4">
            By submitting your personal data to us, you will be treated as having given your permission for
            disclosures referred to in this policy. The Privacy Policy covers our use of your personal information
            which is collected through or in connection with this site. For the purposes of this Policy, "this site"
            means the top site, which links to other GAIL Gas websites.
        </p>
        <h4 class="mt-4">Special Notice - If You Are Less Than 18 Years Of Age</h4>
        <p class="mt-4">Please do not send us your personal information (for example, your name, address and
            email address). If you wish to ask a question or use this site in anyway which requires you to
            submit your personal information please get your parent or guardian to do so on your behalf.</p>

        <h4 class="mt-4">Information Collection & Use
            Personal Information
        </h4>

        <span>
            <ul>
                <li>We collect and use personal information (including name, address, telephone number,
                    email, Documents like Identify Proofs) to :
                    Respond to queries or requests submitted by you
                </li>
                <li>Process orders or applications submitted by you

                </li>
                <li>Administer or otherwise carry out our obligations in relation to any agreement you have
                    with us anticipate and resolve problems with any goods or services supplied to you
                </li>
                <li>Create products or services that may meet your needs
                </li>
            </ul>
        </span>

        <p class="mt-4">Except as set out in this Privacy Policy, we will not disclose any personally identifiable
            information
            without your permission unless we are legally entitled or required to do so (for example, if required
            to do so by legal process or for the purposes of prevention of fraud or other crime) or if we believe
            that such action is necessary to protect and/or defend our rights, property or personal safety and
            those of our users/customers etc.</p>

        <h4 class="mt-4">Non-personal Information
        </h4>

        <p class="mt-4">We may automatically collect non-personal information about you such as the type of internet
            browsers you use or the website from which you linked to our site. We may also aggregate details
            which you have submitted to the site (for example, your age and the town where you live). You
            cannot be identified from this information and it is only used to assist us in providing an effective
            service on this website. We may from time to time supply third parties with this non-personal or
            aggregated data for uses in connection with this site.</p>
        <h4 class="mt-4">Information Collected while Using the Application
        </h4>

        <p class="mt-4">While using our Application, in order to provide features of our Application, we may collect,
            with
            Your prior permission:
        </p>

        <span>
            <ul>
                <li>Information regarding your location</li>
                <li>Information from your Device's phone book (contacts list)
                </li>
                <li>Pictures and other information from your Device's camera and photo library</li>
            </ul>
        </span>


        <p class="mt-4">We use this information to provide features of our Service, to improve and customize our
            Service.
            The information may be uploaded to the Company's servers and/or a Service Provider's server or it
            may be simply stored on your device. You can enable or disable access to this information at any
            time, through Your Device settings.</p>

        <h4 class="mt-4">Use of Your Personal Data
        </h4>
        <p class="mt-4">The Company may use Personal Data for the following purposes:
        </p>

        <span class="mt-4">
            <ul>
                <li><span style="font-weight: bold;">To provide and maintain our service</span>, including monitoring of
                    the usage of our Service.</li>
                <li><span style="font-weight: bold;">To manage your Account:</span>To manage your registration, as a
                    user of the Service. The
                    Personal Data provided can give you access to different functionalities of the Service that are
                    available to you as a registered user.</li>
                <li><span style="font-weight: bold;">For the performance of a contract:</span>The development,
                    compliance and undertaking of the
                    purchase contract for the products, items or services You have purchased or of any other
                    contract with us through the Service.</li>
                <li><span style="font-weight: bold;">To contact You:</span>To contact you by email, telephone calls,
                    SMS, or other equivalent forms of
                    electronic communication, such as a mobile application's push notifications regarding
                    updates or informative communications related to the functionalities, products or
                    contracted services, including the security updates, when necessary or reasonable for their
                    implementation.</li>
                <li><span style="font-weight: bold;">To provide You</span>with news, special offers and general
                    information about other goods,
                    services and events which we offer that are similar to those that you have already purchased
                    or enquired about unless You have opted not to receive such information.</li>
                <li><span style="font-weight: bold;">To manage Your requests:</span>To attend and manage your requests
                    to us</li>
                <li><span style="font-weight: bold;">For business transfers:</span> We may use your information to
                    evaluate or conduct a merger,
                    divestiture, restructuring, reorganization, dissolution, or other sale or transfer of some or all
                    of Our assets, whether as a going concern or as part of bankruptcy, liquidation, or similar
                    proceeding, in which Personal Data held by us about our service users is among the assets
                    transferred.
                </li>
                <li><span style="font-weight: bold;">For other purposes:</span>We may use your information for other
                    purposes, such as data analysis,
                    identifying usage trends, determining the effectiveness of our promotional campaigns and to
                    evaluate and improve our Service, products, services, marketing and your experience</li>
                <p class="mt-4">We may share your personal information in the following situations:</p>
                <li><span style="font-weight: bold;">With Service Providers: </span>We may share your personal
                    information with Service Providers to
                    monitor and analyze the use of our Service, to contact you</li>
                <li><span style="font-weight: bold;">For business transfers: </span>We may share or transfer your
                    personal information in connection
                    with, or during negotiations of, any merger, sale of Company assets, financing, or acquisition
                    of all or a portion of our business to another company.</li>
                <li><span style="font-weight: bold;">With Affiliates:</span>We may share your information with our
                    affiliates, in which case we will
                    require those affiliates to honour this Privacy Policy. Affiliates include our parent company
                    and any other subsidiaries, joint venture partners or other companies that We control or
                    that are under common control with Us.</li>
                <li><span style="font-weight: bold;">With business partners:</span>We may share Your information with
                    Our business partners to offer
                    You certain products, services or promotions.</li>
                <li><span style="font-weight: bold;">With other users:</span> when you share personal information or
                    otherwise interact in the public
                    areas with other users, such information may be viewed by all users and may be publicly
                    distributed outside. If You interact with other users or register through a Third-Party Social
                    Media Service, your contacts on the Third-Party Social Media Service may see your name,
                    profile, pictures and description of your activity. Similarly, other users will be able to view
                    descriptions of Your activity, communicate with you and view your profile.</li>
                <li><span style="font-weight: bold;">With Your consent:</span>We may disclose your personal information
                    for any other purpose with
                    your consent.</li>
            </ul>
        </span>

        <h4 class="mt-3">Use of Your Personal Information Submitted To Other Websites</h4>
        <p class="mt-1">We cannot be responsible for the privacy policies and practices of other websites even if:</p>
        <span>
            <ol>
                <li>You accessed the third party website using links from our website; or</li>
                <li>You linked to our website from a third party website.</li>
            </ol>
        </span>
        <p>We recommend that you check the policy of each site you visit and contact the owner or operator of
            such website if you have any concerns or questions.</p>

        <h4 class="mt-3">Submission of Others' Personal Information</h4>
        <p>From time to time this site may ask you to provide personal details of your family or friends, for
            example, so that we can send them details of our products and services. Before providing such
            information, please ensure that you have obtained their consent to their personal data being used in
            this way. We will not use these details in any manner which is incompatible with this privacy policy.
        </p>
        <h4 class="mt-3">Additional Information & Sharing of Information</h4>
        <p>From time to time we would like to use your personal information for purposes other than as set out
            in the above section. We believe that these additional uses will be of benefit to you.
        </p>
        <h4 class="mt-3">Retention of Your Personal Data </h4>
        <p>The Company will retain your Personal Data only for as long as is necessary for the purposes set out
            in this Privacy Policy. We will retain and use your Personal Data to the extent necessary to comply
            with our legal obligations (for example, if we are required to retain your data to comply with
            applicable laws), resolve disputes, and enforce our legal agreements and policies.
            The Company will also retain Usage Data for internal analysis purposes. Usage Data is generally
            retained for a shorter period of time, except when this data is used to strengthen the security or to
            improve the functionality of our Service, or We are legally obligated to retain this data for longer
            time periods.

        </p>
        <h4 class="mt-3">Transfer of Your Personal Data </h4>
        <p>Your information, including Personal Data, is processed at the Company's operating offices and in
            any other places where the parties involved in the processing are located. It means that this
            information may be transferred to — and maintained on — computers located outside of Your state,
            province, country or other governmental jurisdiction where the data protection laws may differ than
            those from Your jurisdiction.<br><span>Your consent to this Privacy Policy followed by Your submission of
                such information represents Your
                agreement to that transfer.</span><br><span>The Company will take all steps reasonably necessary to
                ensure that Your data is treated securely
                and in accordance with this Privacy Policy and no transfer of Your Personal Data will take place to an
                organization or a country unless there are adequate controls in place including the security of Your
                data and other personal information.</span>
        </p>

        <h4 class="mt-3">Disclosure of Your Personal Data
        </h4>
        <span>
            <ul>
                <li>Business Transactions: If the Company is involved in a merger, acquisition or asset sale, Your
                    Personal Data may be transferred. We will provide notice before your Personal Data is
                    transferred and becomes subject to a different Privacy Policy.</li>
                <li>Law enforcement: Under certain circumstances, the Company may be required to disclose
                    Your Personal Data if required to do so by law or in response to valid requests by public
                    authorities (e.g. a court or a government agency).</li>
                <li>Other legal requirements: The Company may disclose your Personal Data in the good faith
                    belief that such action is necessary to:
                    <span>
                        <ul>
                            <li>Comply with a legal obligation</li>
                            <li>Protect and defend the rights or property of the Company</li>
                            <li>Prevent or investigate possible wrongdoing in connection with the Service</li>
                            <li>Protect the personal safety of Users of the Service or the public</li>
                            <li>Protect against legal liability
                            </li>
                        </ul>
                    </span>
                </li>
            </ul>
        </span>

        <h4 class="mt-3">Use of Cookies
        </h4>
        <p>We may store some information (commonly known as a "cookie") on your computer when you look
            at our site. We are able to read these cookies for information purposes when you revisit our
            website. The type of information we collect as a result of a cookie being accepted by you is specific
            to your PC and includes the IP address, the date and time the PC visited the website, what parts of
            our website were looked at and whether the web pages requested were delivered successfully. This
            information is anonymous; it represents a computer rather than a person.</p>
        <p>We use the cookie information to improve our knowledge of the use of our website and to enable us
            to be able to ascertain whether the website is operating at an optimal level. This allows us to
            enhance our web offerings to you and to provide an enjoyable and an innovative online experience.
            You can erase or block this information by changing the settings on your computer (please refer to
            your help screens or manuals). If you do erase or block this information you may not be able to
            utilise some features of the site.
        </p>


        <h4 class="mt-3">Data Security
        </h4>
        <p>We have implemented technology and policies with the objective of protecting your privacy from
            unauthorised access and improper use and will update these measures as new technology becomes
            available, as appropriate.</p>

        <h4 class="mt-3">Change in Privacy Policy
        </h4>
        <p>From time to time we may make changes to this Privacy Policy. If we make any substantial changes
            to this Privacy Policy and the way in which we use your personal data we will post these changes on
            this page and will do our best to notify you of any significant changes. Please check our Privacy Policy
            on a regular basis.</p>
    </div>
</div>