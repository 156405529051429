
<div fxLayout.xs="column" fxFlexFill class="mobile-display">
    <div fxFlex="100" class="bg-img" [ngStyle]="{'background-image': 'url(' + Overviewbg + ')'}">
        <div class="container-fluid" style="background: transparent;">
            <div class="container container-bg">
            </div>
        </div>
    </div>
</div>

<div class="column" fxLayout="row" fxLayout.xs="column" fxFlexFill style="background:white;">
    <div class="container-fluid df" style="background: transparent;">
        <div style="max-width:1600px;width: 100%;padding-top: 1.5rem;">
           


            <p class="intro" *ngIf="eng == 'en'">Introduction</p>
            <p class="introdec" *ngIf="eng == 'en'">Do not pay bribes. If any employee/ personnel of this office asks for
                bribe or if you have any information on corruption in this office or if you are a victim of corruption
                in this office, you can complain to the Head of this department, or the Chief Vigilance Officer/ The
                Superintendent of Police, Central Bureau of Investigation and the Secretary, Central Vigilance
                Commission </p>
            <p class="intro" *ngIf="eng != 'en'">परिचय</p>
            <p class="introdec" *ngIf="eng != 'en'">रिश्वत न दें। यदि इस कार्यालय का कोई कर्मचारी रिश्वत मांगता है या यदि आपको इस कार्यालय में भ्रष्टाचार के बारे में कोई जानकारी है या यदि आप इस कार्यालय में भ्रष्टाचार के शिकार हैं, तो आप इस विभाग के प्रमुख, मुख्य सतर्कता अधिकारी/ पुलिस अधीक्षक, केंद्रीय जांच ब्यूरो या सचिव, केंद्रीय सतर्कता आयोग को शिकायत कर सकते हैं। </p>

            <div style="display: flex;align-items: center;justify-content: center;padding: 0;">
                <div class="scroll-container">
                    <div class="table table-bordered"
                        style="margin-top: 3rem;min-width: 1024px; max-width:1600px; width: 100%;font-weight: 600;">
                        <thead class="table-header">
                            <td>S.No.</td>
                            <td *ngIf="eng != 'en'">पदनाम और पता </td>
                            <td *ngIf="eng == 'en'">Designation and Address</td>
                            <td style="width: 331px !important;">Phone No and email </td>
                        </thead>
                        <tbody class="table-data" style="width: 100%;">
                            <tr>
                                <td>01</td>
                                <td *ngIf="eng != 'en'">मुख्य कार्यकारी अधिकारी तीसरी मंजिल,<br> इन्फोहब बिल्डिंग, गेल
                                    जुबली टॉवर बी - 35 और 36,
                                    सेक्टर -1, नोएडा -201301, (उत्तर प्रदेश) </td>
                                <td *ngIf="eng == 'en'">Chief Executive Officer <br> 3rd Floor, Infohub Building, GAIL
                                    Jubilee Tower B- 35 & 36,
                                    Sector -1, NOIDA -201301, (U.P) </td>
                                <td>
                                    <p>+91-120-2446400, +91-120-4862400</p>
                                    <p>ceogailgas@gail.co.in</p>
                                </td>
                            </tr>
                            <tr>
                                <td>02</td>
                                <td *ngIf="eng != 'en'">मुख्य सतर्कता अधिकारी <br> 16, भीकाएजी कमा प्लेस, नई दिल्ली -
                                    ११००६६ </td>
                                <td *ngIf="eng == 'en'">Chief Vigilance Officer <br> 16, Bhikaiji Cama Place, New Delhi
                                    - 110066 </td>
                                <td>
                                    <p>Tel No +91- 11-26194153</p>
                                    <p>cvogail@gail.co.in </p>
                                </td>

                            </tr>
                            <tr>
                                <td>03</td>
                                <td *ngIf="eng != 'en'">पुलिस अधीक्षक (ए सी बी ) <br> केंद्रीय अन्वेषण ब्यूरो भ्रष्टाचार
                                    विरोधी शाखा सी जी ओ काम्प्लेक्स - 1 हापुड़ रोड कमला नेहरू नगर, ग़ज़िआबाद (यु पी) </td>
                                <td *ngIf="eng == 'en'">Superintendent of Police (ACB) <br> Central Bureau of
                                    Investigation Anti-Corruption Branch CGO Complex-1, Hapur Road,
                                    Kamla Nehru Nagar, Ghaziabad (U.P) </td>

                                <td>
                                    <p>Tel No: +91-120-2704011 , Fax No; +91-120-2752825 </p>
                                    <p> hobacgzd@cbi.gov.in </p>
                                </td>
                            </tr>
                            <tr>
                                <td>04</td>
                                <td *ngIf="eng != 'en'">सचिव <br> केंद्रीय सतर्कता आयोग सतर्कता भवन , ब्लॉक - A जी पी ओ
                                    काम्प्लेक्स, आई एन ए, नई दिल्ली - 110023 </td>
                                <td *ngIf="eng == 'en'">Secretary <br> Central Vigilance Commission, Satarkta Bhawan,
                                    Block-A, GPO Complex, INA, New Delhi - 110023</td>
                                <td>
                                    <p>Tel No +91-11-24651020 , Toll Free 1800110180, 1964</p>
                                    <p>Fax: +91-11 24651186 </p>
                                </td>
                            </tr>
                        </tbody>
                    </div>
                </div>
            </div>

            <div class="flexboxrow" style="margin-top: 5rem;" #formmenu>
                
                <div class="complaintsbox" [class.complainactive]="active == '4'" (click)="complaint('4')">
                    <div class="imgcontain">
                        <img *ngIf="active != '4'" src="../../assets/submitForm/Vigilance Complaints blue.svg"
                            width="60px" alt="Vigilance Complaints">
                        <img *ngIf="active == '4'" src="../../assets/submitForm/vigilance white.svg" width="60px"
                            alt="Vigilance Complaints">
                    </div>
                    <div style=" padding: 5px 0px;">Vigilance Complaints</div>
                </div>

            </div>
            <div style="margin-top: 3rem;margin-bottom: 2rem;font-size: 18px;">
                <div *ngIf="active == '1'">
                    <p style="padding: 5px 10px;">{{complaintdec[0].complaintdec1}}</p>
                </div>
                <div *ngIf="active == '2'">
                    <p style="padding: 5px 10px;">{{complaintdec[0].complaintdec2}}</p>
                    <div class="df" style="margin-top: 1.5rem;">
                        <navigate routerLink="/complaints" class="proceed" (click)="proceed('2')">Proceed To File
                            Complaint</navigate>
                    </div>
                </div>

                <div *ngIf="active == '4'">
                    <p style="padding: 5px 10px;">{{complaintdec[0].complaintdec4}}</p>
                    <div class="df" style="margin-top: 0rem;">
                        <navigate routerLink="/complaints" class="proceed" (click)="proceed('4')">Proceed To File
                            Complaint</navigate>
                    </div>
                </div>
              
            </div>
        </div>
    </div>
</div>
<div id="overlay">
    <div class="loader"></div>
</div>







<div id="overlay">
    <div class="loader"></div>
</div>







