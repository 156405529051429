<br><br><br><br>
<div class="container-fluid" *ngIf="schemeData">
    <div>
        <img src="../../assets/png_new_AA.png" alt="Image description"
            style="width: 100%; height: 100%; object-fit: contain;">
    </div>

    <div class="row mt-3">
        <div class="col-md-1"></div>
        <div class="col-md-10">
            <div>
                <h3 class="overview-heading">
                    <img src="../../../assets/gileModule/overview/overview-leave.svg" class="leave-style">
                    {{schemeData['pngTitle'] | translate}}
                </h3>
                <p class="txt">
                    {{schemeData['pngtxt'] | translate}}
                </p>
            </div>

            <h2 class="overview-heading"><img src="../../../assets/gileModule/overview/overview-leave.svg"
                    class="leave-style">&nbsp;{{schemeData['pngTitle1'] | translate}}</h2>
            <div class="row">
                <div class="col-md-2"></div>
                <div class="col-md-4">
                    <ul *ngIf="language == 'en'">
                        <li>Meerut</li>
                        <li>Sonipat</li>
                        <li>Dewas</li>
                        <li>TTZ( Agra, Firozabad & Bharatpur)</li>
                        <li>Bengaluru</li>
                        <li>Dehradun</li>
                        <li>Giridih- Dhanbad</li>
                    </ul>
                    <ul *ngIf="language == 'hn'">
                        <li>मेरठ</li>
                        <li>सोनीपत</li>
                        <li>देवास</li>
                        <li>टीटीजेड (आगरा, फिरोजाबाद और भरतपुर)</li>
                        <li>बेंगलुरु</li>
                        <li>देहरादून</li>
                        <li>गिरिडीह-धनबाद</li>
                    </ul>

                </div>
                <div class="col-md-4">
                    <ul *ngIf="language == 'en'">
                        <li>Saraikela- Karsawan & West Singhbhum ( 2 GAs)</li>
                        <li>Mirzapur- Chandauli- Sonbhadra</li>
                        <li>Raisen- Sehore- Shajapur</li>
                        <li>Puri- Ganjam- Nayagarh</li>
                        <li>Sundergarh- Jharsuguda</li>
                        <li>Gajapati</li>
                        <li>Dakshin Kannada</li>
                    </ul>

                    <ul *ngIf="language == 'hn'">
                        <li>सराइकेला- करसावां और पश्चिम सिंहभूम ( 2 जीएस)</li>
                        <li>मिर्ज़ापुर- चंदौली- सोनभद्र</li>
                        <li>रायसेन- सीहोर- शाजापुर</li>
                        <li>पुरी- गंजम- नयागढ़</li>
                        <li>सुंदरगढ़- झारसुगुड़ा</li>
                        <li>गजापति</li>
                        <li>दक्षिण कन्नड़</li>
                    </ul>

                </div>
                <div class="col-md-2"></div>
            </div>
        </div>
        <div class="col-md-1"></div>
    </div>

    <!-- Week Cards -->
    <div class="row mt-5">
        <div class="col-md-1"></div>
        <div class="col-md-10">
            <!-- <div class="d-flex">
                <span class="spantxt" *ngIf="backweek" (click)="weekback()">{{Week_Name}}</span>
                <span class="spantxt" style="margin-left: 8px;" *ngIf="backlocate" (click)="locateback()"> /
                    back to locations</span>
            </div> -->

            <h2 *ngIf="Title" class="overview-heading mt-2"><img
                    src="../../../assets/gileModule/overview/overview-leave.svg" class="leave-style">&nbsp;
                {{schemeData['pngTitle2'] | translate}} </h2>

            <div *ngIf="ModalBoolean">
                <h2 class="overview-heading mt-2"><img src="../../../assets/gileModule/overview/overview-leave.svg"
                        class="leave-style">Glimpse of the Event {{Week_Name}} in {{ModalName}} </h2>
            </div>

            <div class="d-flex">
                <span class="spantxt" *ngIf="backweek" (click)="weekback()">{{Week_Name}}</span>
                <span class="spantxt" style="margin-left: 8px;" *ngIf="backlocate" (click)="locateback()"> /
                    back to locations</span>
            </div>
            <div class="row">
                <!-- class="col-md-{{getColumnWidth()}}" -->
                <div [hidden]="WeekCard" class="col-md-4" *ngFor="let campaign of GetDatajson"
                    (click)="FirstWek(campaign.week,campaign)" style="padding: 2%;">
                    <div class="row" style="border: 1px solid #0093d0;">
                        <ng-container *ngFor="let ele of campaign.week; let i=index">
                            <ng-container *ngIf="i < 4 && !ele.url.endsWith('.mp4')">
                                <div class="col-md-6" style="padding:0px;">
                                    <img [src]="ele.url" alt="" class="imgCardthumb">
                                </div>
                            </ng-container>
                        </ng-container>
                    </div>
                    <span class="Txt">
                        {{ campaign.name }}<br>
                    </span>
                    <span class="Txt">
                        {{campaign.date}}
                    </span>
                </div>
                <!-- LocationImgList -->
                <div class="col-md-3" [hidden]="LocationCard" *ngFor="let campaign of LocationImgList"
                    style="padding:2% 0%;" (click)="Location(weekList, campaign[0].location,'NavigateModal')">
                    <!-- <div class="row" style="border: 1px solid #0093d0;">
                        <ng-container *ngFor="let ele of campaign; let i=index">
                            <ng-container *ngIf="i < 2 && !ele.url.endsWith('.mp4')">
                                <div class="col-md-6" style="padding:0px;">
                                    <img [src]="ele.url" alt="" class="imgCardthumb">
                                </div>
                            </ng-container>
                        </ng-container>
                    </div> -->
                    <div *ngFor="let ele of campaign; let i=index" style="cursor: pointer;" data-aos="fade-up"
                        data-aos-once="true" data-aos-easing="linear" data-aos-duration="1500">
                        <ng-container *ngIf="i ==0 ">
                            <span style="font-weight: bold;">
                                <img *ngIf="!isDuplicateLocation(ele.location, i, campaign)"
                                    style="width: 100%; height: 100%;" [src]="ele.location_image" alt="">
                            </span>
                            <br>
                            <span style="font-weight: bold;">
                                <div class="row" style="padding: 0px 85px;">
                                    <div class="col-md-3"></div>
                                    <div class="col-md-">
                                        {{ isDuplicateLocation(ele.location, i, campaign) ? '' : ele.location }}
                                    </div>
                                    <div class="col-md-3"></div>
                                </div>
                            </span>
                        </ng-container>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-1"></div>
    </div>

    <!-- Display Images -->
    <div class="row mt-5 mb-4" id="NavigateModal" *ngIf="ModalBoolean">
        <div class="col-md-1"></div>
        <div class="col-md-10">
            <!-- <div>
                <h2 class="overview-heading mt-2"><img src="../../../assets/gileModule/overview/overview-leave.svg"
                        class="leave-style">Glimpse of the Event {{Week_Name}} in {{ModalName}} </h2>
            </div> -->
            <div class="row">
                <div class="col-md-4 mb-5" *ngFor="let ele of ImagesList_data;let i = index" style="padding: 3%;">
                    <img *ngIf="!ele.url.endsWith('.mp4')" [src]="ele.url" alt="" class="imgCard1"
                        (click)="Modal(ele, ImagesList_data)">
                    <video #videoPlayer (play)="handlePlay($event.target)" controls *ngIf="ele.url.endsWith('.mp4')"
                        width="100%" height="250px">
                        <source src="{{ele.url}}" type="video/mp4" />
                    </video>
                    <br>
                </div>
            </div>
        </div>
        <div class="col-md-1"></div>
    </div>

    <!-- Modal -->
    <div>
        <div class="modal fade" id="staticBackdrop" data-backdrop="static" data-keyboard="false" tabindex="-1"
            aria-labelledby="staticBackdropLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered modal-lg">
                <div class="modal-content">
                    <div class="modal-header">
                        <!-- <span><img src="../../assets/news-media/downoad-arrow.svg" alt="" style="cursor: pointer;"
                        (click)="ImgDown()"></span> -->
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div id="carouselExampleControlsNoTouching" class="carousel slide" data-touch="false"
                            data-interval="false">
                            <div class="carousel-inner" *ngIf="ImageList && ImageList.length > 0">
                                <div class="carousel-item" *ngFor="let image of ImageList; let i = index"
                                    [class.active]="i === clickedImageIndex">
                                    <img *ngIf="!image.url.endsWith('.mp4')" [src]="image.url" alt="" class="imgCard2">
                                    <video *ngIf="image.url.endsWith('.mp4')" controls width="100%" height="250px">
                                        <source src="{{image.url}}" type="video/mp4" />
                                    </video>
                                </div>
                            </div>

                            <button class="carousel-control-prev" type="button"
                                data-target="#carouselExampleControlsNoTouching" data-slide="prev">
                                <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                                <span class="sr-only">Previous</span>
                            </button>
                            <button class="carousel-control-next" type="button"
                                data-target="#carouselExampleControlsNoTouching" data-slide="next">
                                <span class="carousel-control-next-icon" aria-hidden="true"></span>
                                <span class="sr-only">Next</span>
                            </button>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>


</div>