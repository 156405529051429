import { Component, OnInit, Inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { PdfService } from 'src/app/services/pdf.service';
import { Title, Meta } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogData } from 'src/app/news-media-m/media-events/media-events.component';

@Component({
  selector: 'app-vendor-zone',
  templateUrl: './vendor-zone.component.html',
  styleUrls: ['./vendor-zone.component.scss']
})
export class VendorZoneComponent implements OnInit {
  title = 'GAIL Gas Limited - Vendor Zone';

  language: string;
  stakeholderZoneList: any;
  vendorZoneCntnt: any;
  vendorZoneCntntObj: any;
  tenderContent: any;
  generalContract: any;
  Overviewbg: string;

  constructor(private translateService: TranslateService, private pdfService: PdfService, private titleService: Title, private metaTagService: Meta,
    public dialog: MatDialog,
    public router:Router) { }

  ngOnInit(): void {

    const lang = localStorage.getItem('language')
    if(lang == 'en'){
      this.Overviewbg = "../../../assets/stakeholder/VendorBg.jpg"
    }else{
      this.Overviewbg = "../../../assets/stakeholder/hindivendor.jpg"
    }


    this.titleService.setTitle(this.title);
    this.metaTagService.updateTag(
      { name: 'description', content: 'GAIL Gas Limited - India’s Leading CGD Company' }
    );
    this.metaTagService.addTags([
      { name: 'keywords', content: 'GAIL Gas Limited, Vendor Zone, Tender Information System, General Conditions of Contract' },
    ]);

    this.pdfService.footer.emit();
    this.language = localStorage.getItem('language');
    console.log(this.language , "Overview com");

    if(this.language == 'od' || this.language == 'kn'){
      this.language = 'en';
    }else {
      this.language = this.language;
    }

    this.translateService.setDefaultLang(this.language);

    console.log(this.language);

    this.translateService.get(["stakeholderZones"]).subscribe(values => {
      const data = Object.keys(values).map(key => values[key]);
      this.stakeholderZoneList = data[0];
      this.vendorZoneCntnt = this.stakeholderZoneList[0].zoneContent;
      this.vendorZoneCntntObj = this.stakeholderZoneList[0].zoneContentObjective;
      this.tenderContent = this.stakeholderZoneList[0].tenderCntnt;
      this.generalContract = this.stakeholderZoneList[0].generalContract
    });
  }

  reloadCurrentRoute() {
    let currentUrl = this.router.url;
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.router.navigate([currentUrl]);
    });
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(DialogOverviewExampleDialog);

    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
    });
  }
}

@Component({
  selector: 'dialog-overview-example-dialog',
  templateUrl: '../../dialog-overview-example-dialog.html',
})
export class DialogOverviewExampleDialog {
  constructor(
    public dialogRef: MatDialogRef<DialogOverviewExampleDialog>
  ) {}

  onNoClick(): void {
    this.dialogRef.close();
  }
}