import { CommonModule } from '@angular/common';

import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import {  ReactiveFormsModule} from '@angular/forms';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent, DialogContentExampleDialog } from './app.component';
import { HeaderComponent } from './header/header.component';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { FooterComponent } from './footer/footer.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatDialogModule, MAT_DIALOG_DEFAULT_OPTIONS } from '@angular/material/dialog';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { MatButtonModule } from '@angular/material/button';
import { FormsModule } from '@angular/forms';
import { DisclaimerComponent } from './disclaimer/disclaimer.component';
import { RtiComponent } from './rti/rti.component';
import { FaqComponent } from './faq/faq.component';
import { CdkRowDirective } from './faq/cdk-row-directive';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatFormFieldModule, MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatExpansionModule } from '@angular/material/expansion';
import { FlexLayoutModule } from '@angular/flex-layout';
import { CodeOfConductComponent } from './code-of-conduct/code-of-conduct.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { ImageViewModalComponent } from './image-view-modal/image-view-modal.component';

import { MatSortModule } from '@angular/material/sort';
import { DragScrollModule } from 'ngx-drag-scroll';
import { MatSelectModule } from '@angular/material/select';
import { EmailServiceComponent } from './email-service/email-service.component';
import { MatInputModule } from '@angular/material/input';
import { ToastrModule } from 'ngx-toastr';
import { CngSchemeCComponent } from './cng-scheme-c/cng-scheme-c.component';

import {SubmitFormModalDialog} from './email-service/email-service.component'


import { from } from 'rxjs';
import { DialogOverviewExampleDialog } from './stakeholder-zone-m/vendor-zone/vendor-zone.component';
import { FeedbackGrievanceCComponent } from './feedback-grievance-c/feedback-grievance-c.component';
// import {NgxMatFileInputModule} from '@angular-material-components/file-input';
import { PinchZoomModule } from 'ngx-pinch-zoom';
import { PrivacyPolicyCComponent } from './privacy-policy-c/privacy-policy-c.component';
import { ComplaintshomeComponent } from './feedback_complaints/complaintshome/complaintshome.component';
import { ComplaintsFormsComponent } from './feedback_complaints/complaints-forms/complaints-forms.component';
import { VigilanceComponent } from './feedback_complaints/vigilance/vigilance.component';
import { PngNewCComponent } from './png-new-c/png-new-c.component';
import { PersonalDetailsCComponent } from './personal-details-c/personal-details-c.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { CngPromotionalComponent } from './cng-promotional/cng-promotional.component';

export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient, '/assets/i18n/');
}

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    DialogContentExampleDialog,
    DisclaimerComponent,
    FaqComponent,
    CdkRowDirective,
    RtiComponent,
    CodeOfConductComponent,
    PageNotFoundComponent,
    ImageViewModalComponent,
    EmailServiceComponent,
    CngSchemeCComponent,
    SubmitFormModalDialog,
    DialogOverviewExampleDialog,
    FeedbackGrievanceCComponent,
    PrivacyPolicyCComponent,
    ComplaintshomeComponent,
    ComplaintsFormsComponent,
    VigilanceComponent,
    PngNewCComponent,
    PersonalDetailsCComponent,
    CngPromotionalComponent
  ],
  imports: [
    CommonModule,
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FlexLayoutModule,
    BrowserAnimationsModule,
    MatDialogModule,
    NgbModule,
    FormsModule,
    MatButtonModule,
    FormsModule,
    MatPaginatorModule,
    MatTableModule,
    MatTabsModule,
    MatIconModule,
    MatFormFieldModule,
    MatExpansionModule,
    DragScrollModule,
    MatSelectModule,
    ReactiveFormsModule,
    MatInputModule,
    MatSortModule,
    MatDatepickerModule,
    MatNativeDateModule,
    PinchZoomModule,
    ToastrModule.forRoot(),
    // NgxMatFileInputModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient] 
      }
    })
  ], 
  exports: [MatIconModule],
  entryComponents: [DialogContentExampleDialog,SubmitFormModalDialog, DialogOverviewExampleDialog],
  providers: [
    // {provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: {hasBackdrop: false}}
    // {provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: {appearance: 'fill'}}
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
