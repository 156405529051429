<div fxLayout.xs="column" fxFlexFill class="mobile-display">
    <div fxFlex="100" class="bg-img" [ngStyle]="{'background-image': 'url(' + Overviewbg + ')'}">
        <div class="container-fluid" style="background: transparent;">
            <div class="container container-bg">
                <!-- <p class="banner-text">
                    Fueling <span class="banner-txt-growth"> Growth </span>
                    <span> With <span class="banner-txt-growth"> Green Energy </span></span>
                </p> -->
            </div>
        </div>
    </div>
</div>






<!-- <div class="column" fxLayout="row" fxLayout.xs="column" fxFlexFill style="background:#ededed;"
    *ngIf="stakeholderZoneList">
    <div class="container-fluid" style="background: transparent;">

    </div>
</div> -->

<!-- drop down -->
<div class="column" fxLayout="row" fxLayout.xs="column" fxFlexFill style="background:#ededed;"
    *ngIf="stakeholderZoneList">
    <div class="container-fluid" style="background: transparent;">
        <div class="row">
            <div class="col-md-1"></div>
            <div class="col-md-10">
                <div class="container-fluid mobile-screens" style="background: transparent;padding-bottom: 2%;">
                    <!-- <div fxFlex="2" fxFlex.md="2" fxFlex.lg="2" fxFlex.xl="5"></div> -->

                    <div fxFlex="30" class="sec1" fxFlex.md="25" fxFlex.lg="25" fxFlex.xl="25" fxFlex.xs="100">
                        <div class="container mobile-screens" style="background: transparent;" style="padding:0px;">
                            <div fxFlex="100">
                                <p class="location-text">
                                    <span routerLink="/home">{{'home' | translate}}</span>
                                    <span class="location-home"> &nbsp; > {{'stakeholder' | translate}} &nbsp; > &nbsp;
                                        <span class="location-arrow"> {{stakeholderZoneList[0].zone_nm}}</span>
                                    </span>
                                </p>
                            </div>
                        </div>
                        <div fxFlexOrder="1" fxLayout="row" fxLayout.xs="column" class="left-menu zone-sm">
                            <div style="display: grid;" class="mobile-sidemenu">
                                <div class="list-border" routerLink="{{shzl.link}}"
                                    *ngFor="let shzl of stakeholderZoneList; let last = last"
                                    routerLinkActive="selectedMenu">
                                    <span mat-button class="menu_list" translate>
                                        {{shzl.zone_nm}}
                                    </span>
                                    <hr class="hr" *ngIf="!last">
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- <div fxFlex="2" fxFlex.md="1" fxFlex.lg="2" fxFlex.xl="2"></div> -->
                    <div fxFlex="71" fxFlex.md="75" fxFlex.lg="75" fxFlex.xl="75" fxFlex.xs="100"
                        class="box arrow1-left mt-4" *ngIf="stakeholderZoneList">
                        <div class="row">
                            <div class="col-md" style="height:30px;"></div>
                            <div class="col-sm" style="height:15px;"></div>
                        </div>
                        <div class="sec2-right">
                            <div class="right-panel">
                                <h3 class="overview-heading">
                                    <img src="../../../assets/gileModule/overview/overview-leave.svg"
                                        class="leave-style">
                                    {{stakeholderZoneList[0].zone_nm}}
                                </h3>
                            </div>

                            <div class="right-text " *ngFor="let shzLst of vendorZoneCntnt">
                                <div class="mt-3">
                                    <p class="right-text-sub">
                                        {{shzLst.cntnt_txt}}
                                    </p>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-sm" style="height: 20px;"></div>
                                <div class="col-md" style="height: 30px;"></div>
                            </div>


                            <div class="objective">
                                <h3 class="overview-heading">
                                    <img src="../../../assets/gileModule/overview/overview-leave.svg"
                                        class="leave-style">
                                    {{stakeholderZoneList[0].Objective}}
                                </h3>

                            </div>
                            <div class="row">
                                <div class="col-sm" style="height: 20px;"></div>
                                <div class="col-md" style="height: 30px;"></div>
                            </div>

                            <div class="right-text" *ngFor="let shzLst of vendorZoneCntntObj">
                                <div>
                                    <p class="right-text-sub">
                                        {{shzLst.cntnt_txt}}
                                    </p>
                                </div>
                            </div>

                        </div>
                        <br>
                        <div class="sec2-right">
                            <div class="right-panel">
                                <h3 class="overview-heading">
                                    <img src="../../../assets/gileModule/overview/overview-leave.svg"
                                        class="leave-style">
                                    {{'tenderInfo' | translate}}
                                </h3>
                            </div>

                            <div class="right-text" *ngFor="let tndr of tenderContent">
                                <div>
                                    <p class="right-text-sub">
                                        {{tndr.tender_txt}}
                                    </p>
                                </div>
                            </div>

                        </div>
                        <br>
                        <div fxLayout="row wrap" fxLayout.xs="column" fxLayoutAlign="space-around center"
                            fxLayoutGap="10px">

                            <div fxFlex="calc(16%-25px)" fxFlex.sm="calc(97%-25px)"
                                class="tender-cards tender-resp genl-space">
                                <div class="tendor-txt">
                                    <div>{{'tendorPortal' | translate}}</div>
                                    <div> {{'Portal' | translate}}</div>

                                    <!-- <a href=" http://gailgastenders.in/gglTender/home.asp" target="blank"
                                        style="text-decoration: none;cursor: pointer;">
                                        <div class="download"><span>{{'visit' | translate}}</span></div>
                                    </a> -->

                                    <a href="https://gailgastenders.gail.co.in/" target="blank"
                                        style="text-decoration: none;cursor: pointer;">
                                        <div class="download"><span>{{'visit' | translate}}</span></div>
                                    </a>

                                    
                                </div>
                            </div>

                            <div fxFlex="calc(16%-25px)" fxFlex.sm="calc(97%-25px)"
                                class="tender-cards tender-resp genl-space">
                                <div class="tendor-txt">
                                    <div>{{'ETendorPortal' | translate}}</div>
                                    <div> {{'Portal' | translate}}</div>
                                    <a href="https://etendergailgas.gail.co.in/irj/portal?com=gailgas" target="blank"
                                        style="text-decoration: none;cursor: pointer;">
                                        <div class="download"><span>{{'visit' | translate}}</span></div>
                                    </a>
                                </div>
                            </div>

                            <div fxFlex="calc(16%-25px)" fxFlex.sm="calc(97%-25px)"
                                class="tender-cards tender-resp genl-space">
                                <div class="tendor-txt">
                                    <div>{{'emkt' | translate}}</div>
                                    <div>{{'GeM' | translate}}</div>
                                    <a href="https://gem.gov.in/" target="blank"
                                        style="text-decoration: none;cursor: pointer;">
                                        <div class="download"><span>{{'visit' | translate}}</span></div>
                                    </a>
                                </div>
                            </div>

                            <div fxFlex="calc(16%-25px)" fxFlex.sm="calc(97%-25px)"
                                class="tender-cards tender-resp genl-space">
                                <div class="tendor-txt">
                                    <div>{{'nod' | translate}}</div>
                                    <div>{{'nod1' | translate}}</div>
                                    <a href="https://gailgaspdfdownloads.s3.ap-south-1.amazonaws.com/Nomination_Order.pdf"
                                        target="blank" style="text-decoration: none;cursor: pointer;">
                                        <div class="download"><img
                                                src="./../../../assets/stakeholder/tender-dowload.svg">&nbsp;<span>{{'download'
                                                | translate}}</span>
                                        </div>
                                    </a>
                                </div>
                            </div>

                            <div fxFlex="calc(16%-25px)" fxFlex.sm="calc(97%-25px)"
                                class="tender-cards tender-resp genl-space" style="position: relative;">
                                <div class="tendor-txt">
                                    <div>{{'pfm' | translate}}</div>
                                    <div>{{'pfm1' | translate}}</div>

                                    <a href="https://gailgaspdfdownloads.s3.ap-south-1.amazonaws.com/ProcurementFromMSEs.pdf"
                                        target="blank" style="text-decoration: none;cursor: pointer;">
                                        <div class="download"><img
                                                src="./../../../assets/stakeholder/tender-dowload.svg">&nbsp;<span>{{'download'
                                                | translate}}</span>
                                        </div>
                                    </a>

                                </div>
                            </div>
                            <div fxFlex="calc(16%-25px)" fxFlex.sm="calc(97%-25px)"
                                class="tender-cards tender-resp genl-space">
                                <div class="tendor-txt">
                                    <div>{{'VendorE'| translate}}</div>
                                    <div>{{'Empanelment'| translate}}</div>
                                    <a routerLink="/empanelment/empanelLand"
                                        style="text-decoration: none;cursor: pointer;">
                                        <div class="download"><span>{{'visit' | translate}}</span></div>
                                    </a>
                                </div>
                            </div>

                            <!--  -->

                            <div fxFlex.sm="calc(50%-25px)">
                                <div>

                                </div>
                            </div>

                        </div>
                        <br>
                        <div class="sec2-right">
                            <div class="right-panel">
                                <h3 class="overview-heading">
                                    <img src="../../../assets/gileModule/overview/overview-leave.svg"
                                        class="leave-style">
                                    {{'gcc' | translate}}
                                </h3>
                            </div>

                            <div class="right-text" *ngFor="let tndr of tenderContent">
                                <div>
                                    <p class="right-text-sub">
                                        {{tndr.tender_txt}}
                                    </p>
                                </div>
                            </div>

                            <div class="row">
                                <!-- <div class="general-contract">{{'agreement' | translate}}</div> -->

                                <div class="col-12 col-md" *ngFor="let i of generalContract;let j=index;">


                                    <mat-card style="height: 170px;border: 1px solid #0295c1;padding: 5px 5px 0px 5px;">
                                        <div class="col-md p-3 text-center mb-5">
                                            <div style="height:100px;">
                                                <span
                                                    class="general-contract p-0">{{generalContract[j].gen_cnt_txt}}</span>
                                            </div>
                                            <div>
                                                <span class="p-0">
                                                    <a href="{{generalContract[j].gen_cnt_link}}" target="blank"
                                                        class="linkurl">
                                                        <div style="color: #0295c1;font-weight: bold;"><img
                                                                src="./../../../assets/stakeholder/contract-download.svg">&nbsp;<span>{{'download'
                                                                | translate}}</span>
                                                        </div>
                                                    </a>
                                                </span>
                                            </div>
                                        </div>
                                    </mat-card>
                                    <!-- <mat-card class="mask-general-contract" style="min-height: 120px;position: relative;">
                                        <div>
                                            <div class="general-contract" style="height:50px;">
                                                {{generalContract[j].gen_cnt_txt}}</div>
                                            <a href="{{generalContract[j].gen_cnt_link}}" target="blank" class="linkurl">
                                                <div class="general-contract-download"
                                                    style="position: absolute; bottom: 10px;right: 64px;"><img
                                                        src="./../../../assets/stakeholder/contract-download.svg">&nbsp;<span>{{'download'
                                                        | translate}}</span>
                                                </div>
                                            </a>
                                        </div>
                                    </mat-card> -->
                                </div>
                            </div>

                            <!-- <div fxLayout="row wrap" fxLayout.xs="column" fxLayoutAlign="space-around center"
                                        fxLayoutGap="25px">
                                        <mat-card fxFlex="calc(33%-25px)" fxFlex.sm="calc(50%-25px)" class="mask-general-contract">
                                            <div class="general-contract">{{generalContract[0].gen_cnt_txt}}</div>
                                            <div class="general-contract">{{'agreement' | translate}}</div>
                                            <a href="https://gailgaspdfdownloads.s3.ap-south-1.amazonaws.com/Contract_Agreement_Services_GAIL_GAS.PDF"
                                                target="blank" class="linkurl">
                                                <div class="general-contract-download"><img
                                                        src="./../../../assets/stakeholder/contract-download.svg">&nbsp;<span>{{'download' | translate}}</span>
                                                </div>
                                            </a>
                                        </mat-card>
                                        <mat-card fxFlex="calc(33%-25px)" fxFlex.sm="calc(50%-25px)" class="mask-general-contract">
                                            <div class="general-contract">{{generalContract[1].gen_cnt_txt}}</div>
                                            <a href="https://gailgaspdfdownloads.s3.ap-south-1.amazonaws.com/General-Conditions-of-Contract-Works(GCC-Works)-English-version.pdf"
                                                target="blank" class="linkurl">
                                                <div class="general-contract-download"><img
                                                        src="./../../../assets/stakeholder/contract-download.svg">&nbsp;<span>{{'download' | translate}}</span>
                                                </div>
                                            </a>
                                        </mat-card>
                                        <mat-card fxFlex="calc(33%-25px)" fxFlex.sm="calc(50%-25px)" class="mask-general-contract">
                                            <div class="general-contract">{{generalContract[2].gen_cnt_txt}}</div>
                                            <a href="https://gailgaspdfdownloads.s3.ap-south-1.amazonaws.com/General-Conditions-of-Contract-Goods(GCC-Goods)-English.pdf "
                                                target="blank" class="linkurl">
                                                <div class="general-contract-download"><img
                                                        src="./../../../assets/stakeholder/contract-download.svg">&nbsp;<span>{{'download' | translate}}</span>
                                                </div>
                                            </a>
                                        </mat-card><br>
                                        <mat-card fxFlex="calc(33%-25px)" fxFlex.sm="calc(50%-25px)" class="mask-general-contract">
                                            <div class="general-contract">{{generalContract[3].gen_cnt_txt}}</div>
                                            <a href="https://gailgaspdfdownloads.s3.ap-south-1.amazonaws.com/General-Conditions-of-Contract-Services(GCC-Services)-English-version.pdf "
                                                target="blank" class="linkurl">
                                                <div class="general-contract-download"><img
                                                        src="./../../../assets/stakeholder/contract-download.svg">&nbsp;<span>{{'download' | translate}}</span>
                                                </div>
                                            </a>
                                        </mat-card>
                                        <mat-card fxFlex="calc(33%-25px)" fxFlex.sm="calc(50%-25px)" class="mask-general-contract">
                                            <div class="general-contract">{{generalContract[4].gen_cnt_txt}}</div>
                                            <a href="https://gailgaspdfdownloads.s3.ap-south-1.amazonaws.com/General-Conditions-of-Contract-Consultancy-(GCC-Consulancy)-English-version.pdf"
                                                target="blank" class="linkurl">
                                                <div class="general-contract-download"><img
                                                        src="./../../../assets/stakeholder/contract-download.svg">&nbsp;<span>{{'download' | translate}}</span>
                                                </div>
                                            </a>
                
                                        </mat-card>
                                        <mat-card fxFlex="calc(33%-25px)" fxFlex.sm="calc(50%-25px)"
                                            style="background-color: transparent;">
                
                                        </mat-card>
                                    </div> -->
                        </div>
                        <br>
                        <div class="row fxlayoutspace">
                            <!-- fxLayoutGap="25px" -->

                            <div class="col-md-3 p-3 text-center">
                                <div class="procedure-evl-card prcdur-responsive">
                                    <div class="procedure-evaluation" style="height:150px;">
                                        {{'Procedure' | translate}}
                                    </div>
                                    <div class="SSSS">
                                        <span class="p-0">
                                            <a href="https://gailgaspdfdownloads.s3.ap-south-1.amazonaws.com/EvaluationProcedure.pdf"
                                                target="blank" class="linkurl">
                                                <img
                                                    src="./../../../assets/stakeholder/procedure-download.svg">&nbsp;<span
                                                    style="color:white;">{{'download'
                                                    | translate}}</span>

                                            </a>
                                        </span>
                                    </div>
                                </div>
                            </div>


                            <div class="col-md-3 p-3 text-center">
                                <div class="procedure-evl-card prcdur-responsive">
                                    <div class="procedure-evaluation" style="height:150px;">
                                        {{'procedureCorrupt' | translate}}
                                    </div>
                                    <div class="SSSS">
                                        <span class="p-0">
                                            <a href="https://gailgaspdfdownloads.s3.ap-south-1.amazonaws.com/ProcedureforAction.pdf"
                                                target="blank" class="linkurl">
                                                <img
                                                    src="./../../../assets/stakeholder/procedure-download.svg">&nbsp;<span
                                                    style="color:white;">{{'download'
                                                    | translate}}</span>

                                            </a>
                                        </span>
                                    </div>
                                </div>
                            </div>


                            <div class="col-md-3 p-3 text-center">
                                <div class="procedure-evl-card prcdur-responsive">
                                    <div class="procedure-evaluation" style="height:150px;">
                                        {{'Conciliation' | translate}}
                                    </div>
                                    <div class="SSSS">
                                        <span class="p-0">
                                            <a href="https://gailgaspdfdownloads.s3.ap-south-1.amazonaws.com/Conciliation+Rules+2013+_FINAL_.pdf"
                                                target="blank" class="linkurl">
                                                <img
                                                    src="./../../../assets/stakeholder/procedure-download.svg">&nbsp;<span
                                                    style="color:white;">{{'download'
                                                    | translate}}</span>

                                            </a>
                                        </span>
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-3 p-3 text-center">
                                <div class="procedure-evl-card prcdur-responsive"
                                    routerLink="/stakeholder-zone/integrity-zone">
                                    <div class="procedure-evaluation" style="height:150px;">
                                        {{'Integrity' | translate}}
                                    </div>
                                    <div>
                                        <span class="p-0">
                                            <!-- <a href="https://gailgaspdfdownloads.s3.ap-south-1.amazonaws.com/Conciliation+Rules+2013+_FINAL_.pdf"
                                                target="blank" class="linkurl">
                                                <img src="./../../../assets/stakeholder/procedure-download.svg">&nbsp;<span
                                                    style="color:white;">{{'download'
                                                    | translate}}</span>
        
                                            </a> -->
                                        </span>
                                    </div>
                                </div>
                            </div>


                            <!-- <div class="procedure-evl-card prcdur-responsive">
                                <div class="procedure-evaluation">
                                    {{'Procedure' | translate}}
                                </div>
                                <a href="https://gailgaspdfdownloads.s3.ap-south-1.amazonaws.com/EvaluationProcedure.pdf"
                                    target="blank" class="linkurl">
                                    <img src="./../../../assets/stakeholder/procedure-download.svg"
                                        style="padding-left: 26px;">&nbsp;&nbsp;<span
                                        class="procedure-download">Download</span>
                                </a>
        
                            </div> -->


                            <!-- <div fxFlex="20">
                                <div class="procedure-evl-card">
                                    <div class="procedure-evaluation pb-two">
                                        {{'procedureCorrupt' | translate}}<br>
                                    </div>
                                    <a href="https://gailgaspdfdownloads.s3.ap-south-1.amazonaws.com/ProcedureforAction.pdf"
                                        target="blank" class="linkurl">
                                        <img src="./../../../assets/stakeholder/procedure-download.svg"
                                            style="padding-left: 26px;">&nbsp;&nbsp;<span
                                            class="procedure-download">Download</span>
                                    </a>
        
                                </div>
                            </div>
        
                            <div fxFlex="20">
                                <div class="procedure-evl-card">
                                    <div class="procedure-evaluation pb-three">
                                        {{'Conciliation' | translate}}<br>
                                    </div>
                                    <a href="https://gailgaspdfdownloads.s3.ap-south-1.amazonaws.com/Conciliation+Rules+2013+_FINAL_.pdf"
                                        target="blank" class="linkurl">
                                        <img src="./../../../assets/stakeholder/procedure-download.svg"
                                            style="padding-left: 26px;">&nbsp;&nbsp;<span
                                            class="procedure-download">Download</span>
                                    </a>
        
                                </div>
                            </div>
        
                            <div fxFlex="20">
                                <div class="procedure-evl-card" routerLink="/stakeholder-zone/integrity-zone">
        
                                    <div class="procedure-evaluation pb-three">
                                        <div class="pb-txt-four">
                                            {{'Integrity' | translate}}<br>
                                        </div>
                                    </div>
                                    <a href="https://gailgaspdfdownloads.s3.ap-south-1.amazonaws.com/Conciliation+Rules+2013+_FINAL_.pdf"
                                                target="blank" class="linkurl">
                                                <img src="./../../../assets/stakeholder/procedure-download.svg"
                                                style="padding-left: 26px;">&nbsp;&nbsp;<span class="procedure-download">Download</span>
                                            </a>
        
                                </div>
                            </div> -->
                        </div>

                    </div>
                    <!-- <div fxFlex="2" fxFlex.md="2" fxFlex.lg="2" fxFlex.xl="5"></div> -->
                </div>




            </div>
            <div class="col-md-1"></div>
        </div>

    </div>

</div>