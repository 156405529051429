import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators, RequiredValidator, FormGroupDirective } from '@angular/forms';

import { OperationService } from '../../../app/services/operation.service';

import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';

declare var $: any

@Component({
  selector: 'app-complaintshome',
  templateUrl: './complaintshome.component.html',
  styleUrls: ['./complaintshome.component.scss']
})
export class ComplaintshomeComponent implements OnInit {
  @ViewChild('number') numberInput: ElementRef;
  @ViewChild('digit1') digit1!: ElementRef;
  @ViewChild('digit2') digit2!: ElementRef;
  @ViewChild('digit3') digit3!: ElementRef;
  @ViewChild('digit4') digit4!: ElementRef;
  @ViewChild('digit5') digit5!: ElementRef;
  @ViewChild('digit6') digit6!: ElementRef;
  @ViewChild('number') number!: ElementRef;
  result: string = '';
  digitInputs: any;

  FeedbackformData: FormGroup;
  LocationDropDown: string[] = ["Vijayawada", "Hyderabad"]
  feedbackTypeDropDown: string[] = ["1", "2"]
  geoGraphy: any = [];
  copypasteError: boolean;
  complaintdec1: string;
  complaintdec2: string;
  otp: string[] = [];
  newotp: any;
  isDisabled: boolean = false;
  dataSharingService: any;
  timerInterval: any;
  timer: number = 120;
  buttonDisabled: boolean = false;
  resendDisabled: boolean = true;
  eng: string;
  hin: string;
  @ViewChild('formmenu', { static: true }) formmenu: ElementRef;
  Overviewbg: string;
  EnterOtp_Send: boolean = false;

  get emailFormControl() {
    return this.FeedbackformData.get('CustomerEmail');
  }
  get emailName() {

    return this.FeedbackformData.get('CustomerEmail')
  }
  get mobileNumber() {

    return this.FeedbackformData.get('CustomerNumber')
  }

  // constructor(public service: OperationService, public toastr: ToastrService, public router: Router, public dialog: MatDialog,private SpinnerService: NgxSpinnerService) { 
  constructor(public service: OperationService, public toastr: ToastrService, public router: Router, public dialog: MatDialog, private detect: ChangeDetectorRef) {
    this.FeedbackData();
    let obj = {};
    this.service.geoGraphyArea(obj).subscribe((res: any) => {
      if (res.code === 200) {
        // console.log(res.data);
        this.geoGraphy = res.data;
      } else {
        console.log(res);
      }
    }, (err) => {
      console.log(err);
    });

    // this.hin = localStorage.getItem('language')
  }


  ngOnInit(): void {


    const lang = localStorage.getItem('language')
    if(lang == 'en'){
      this.Overviewbg = "../../../assets/submitForm/Griveancebg.jpg"
    }else{
      this.Overviewbg = "../../../assets/submitForm/grievancehindi.jpg"
    }
    
    localStorage.setItem('lngset', "en")

    const formmenuid = localStorage.getItem('ghomeid')

    if (formmenuid) {
      setTimeout(() => {
        this.formmenu.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' });
        localStorage.removeItem('ghomeid');
      }, 0)
    }


    this.eng = localStorage.getItem('language')
    // this.hin = localStorage.getItem('language')
  }
  FeedbackData() {
    this.FeedbackformData = new FormGroup({
      Location: new FormControl("", [Validators.required]),
      feedbackType: new FormControl("", [Validators.required]),
      CustomerName: new FormControl("", [Validators.required]),
      CustomerEmail: new FormControl("", [Validators.required, Validators.email, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]),
      CustomerNumber: new FormControl("", [Validators.required]),
      FeedbackDesc: new FormControl("", [Validators.required])
    })
  }
  Feedbackupload() {
    if (!this.FeedbackformData.value.Location) {
      return this.toastr.error("Please Enter Location", "", {
        titleClass: "center",
        messageClass: "center"
      });
    }
    if (!this.FeedbackformData.value.feedbackType) {
      return this.toastr.error("Please Enter FeedbackType", "", {
        titleClass: "center",
        messageClass: "center"
      });
    }
    if (!this.FeedbackformData.value.CustomerName) {
      return this.toastr.error("Please Enter Name", "", {
        titleClass: "center",
        messageClass: "center"
      });
    }
    if (this.emailName.invalid == true) {
      if (!this.FeedbackformData.value.CustomerEmail) {
        return this.toastr.error("Please Enter Email", "", {
          titleClass: "center",
          messageClass: "center"
        });
      }
      return this.toastr.error("Please Enter Valid Email", "", {
        titleClass: "center",
        messageClass: "center"
      });
    }

    if (this.mobileNumber.invalid == true) {
      if (!this.FeedbackformData.value.CustomerNumber) {
        return this.toastr.error("Please Enter CustomerNumber", "", {
          titleClass: "center",
          messageClass: "center"
        });
      }
      return this.toastr.error("Please Enter Valid Number", "", {
        titleClass: "center",
        messageClass: "center"
      });
    }

    if (!this.FeedbackformData.value.FeedbackDesc) {
      return this.toastr.error("Please Enter FeedbackDesc", "", {
        titleClass: "center",
        messageClass: "center"
      });
    }
    this.copypasteError = false
    var regex = new RegExp("^[a-zA-Z0-9]+$");

    if (!regex.test(this.FeedbackformData.value.CustomerName) || !regex.test(this.FeedbackformData.value.FeedbackDesc)) {
      this.copypasteError = true

    } else {
      this.copypasteError = false
    }

    if (this.copypasteError) {
      // return this.toastr.error("Invalid Text Formate")
    }

    let obj = {
      "Location_ID": this.FeedbackformData.value.Location,

      "Feedback_Type": this.FeedbackformData.value.feedbackType,

      "customer_name": this.FeedbackformData.value.CustomerName,

      "Email_ID": this.FeedbackformData.value.CustomerEmail,

      "Mobile_No": this.FeedbackformData.value.CustomerNumber,

      "Feedback_Desc": this.FeedbackformData.value.FeedbackDesc
    }

    console.log(obj)
    // this.SpinnerService.show();
    this.loaderOn()
    this.service.feedback_submit(obj).subscribe((res: any) => {
      // this.SpinnerService.hide();
      this.loaderOff()
      if (res.code === 200) {
        console.log(res.data);
        this.toastr.success(res.message, "", {
          titleClass: "center",
          messageClass: "center"
        });
        this.reloadCurrentRoute();
        this.copypasteError = false;
      } else {
        this.toastr.error(res.message);
      }
    }, (err) => {
      console.log(err);
    });
  }
  reloadCurrentRoute() {
    let currentUrl = this.router.url;
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.router.navigate([currentUrl]);
    });
  }
  restrictChar(event): boolean {

    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode == 34 || charCode == 39 || charCode == 44) {
      return false;
    }
    return true;
  }
  inputText(event) {
    // console.log(event)
    if (event.inputType == "insertFromPaste") {
      let data = this.FeedbackformData.value.FeedbackDesc
      data = data.split("\"").join("")
      data = data.split("\'").join("")
      data = data.split(",").join(" ")
      data = data.split("  ").join(" ")
      // console.log(data)
      this.FeedbackformData.get("FeedbackDesc").setValue(data);
    }
  }
  loaderOn() {
    document.getElementById("overlay").style.display = "block";
  }

  loaderOff() {
    document.getElementById("overlay").style.display = "none";
  }

  removeSpecialCharacter(event) {
    var regex = new RegExp("^[a-zA-Z0-9]+$");
    if (event.charCode == 32) {
      return true
    }
    var key = String.fromCharCode(!event.charCode ? event.which : event.charCode)
    if (!regex.test(key)) {
      event.preventDefault();
      return false;
    }
  }
  NumbersOnly(event) {
    var regex = new RegExp("^[0-9]+$");
    var key = String.fromCharCode(!event.charCode ? event.which : event.charCode)
    if (!regex.test(key)) {
      event.preventDefault();
      return false;
    }
  }
  emailFormate(event) {
    var regex = new RegExp("^[a-zA-Z0-9]+$");
    if (event.charCode == 64 || event.charCode == 46) {
      return true
    }
    var key = String.fromCharCode(!event.charCode ? event.which : event.charCode)
    if (!regex.test(key)) {
      event.preventDefault();
      return false;
    }
  }



  active: string = '0';
  complaint(id: string) {
    this.active = id;
    if (this.active) {
      setTimeout(() => {
        this.formmenu.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'center' });
      }, 0)
    }     
    console.log("cliked parent of image" , id)
    if(this.active == '5'){
      $("#Trackmodal").modal('show');
    }
  }
  // clearactiveid() {
  //   this.active = "0"
  //   this.detect.detectChanges();
  // }
  img() {
    console.log("cliked imge")
  }
  complaintdec = [
    {
      complaintdec1: "If the complaint is regarding redressal of grievance relating to employees, then we request you to approach the authority concerned of the ‘Redressal Forum.’ ",
      complaintdec2: "At GAIL Gas, we have always believed that Our vendors are our Partners in our commitments to serve better.",
      complaintdec3: "",
      complaintdec4: "",
    }

  ]

  proceed(procid: string) {
    localStorage.setItem("porceedid", procid)
  }


  resendotp() {
    this.resendDisabled = true
    this.timer = 120
    this.timerstart()
    this.Generateotp()
  }

  timerstart() {
    this.timerInterval = setInterval(() => {
      if (this.timer > 0) {
        this.timer--;
      } else {
        clearInterval(this.timerInterval);
        this.resendDisabled = false
      }
    }, 1000);
  }



  Generateotp() {
    if (this.number.nativeElement.value.length < 10) {
      $('.phonnum').css('border', '1px solid red');
      return
    } else {
      $('.phonnum').css('border', 'none');
      this.digit1.nativeElement.focus();
      const obj = {
        "mobile_no": this.number.nativeElement.value
      }
      console.log(obj)
      
      this.service.otpverfication(obj).subscribe((res: any) => {
        if (res.code == 200) {
          // this.newotp = res.OTP
          this.toastr.success("OTP sent to your mobile");
          this.buttonDisabled = true;
          this.timer = 120
          this.timerstart()
          this.EnterOtp_Send = true;
        } else {
          this.toastr.error(res.message);
          this.number.nativeElement.value = ""
        }
      })
    }
  }

  // Generateotp() {
  //   if (this.number.nativeElement.value.length < 10) {
  //     $('.phonnum').css('border', '1px solid red');
  //     return
  //   } else {
  //     $('.phonnum').css('border', 'none');
  //     this.digit1.nativeElement.focus();
  //     const obj = {
  //       "mobile_no": this.number.nativeElement.value
  //     }
  //     console.log(obj)
  //     this.buttonDisabled = true;
  //     this.timer = 120
  //     this.timerstart()
  //     this.service.otpverfication(obj).subscribe((res: any) => {
  //       if (res.code == 200) {
  //         this.newotp = res.OTP
  //       } else {
  //         this.toastr.error(res.message)
  //       }
  //     })
  //   }
  // }


  formatTime(seconds: number): string {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
  }

  keydownnum(event: KeyboardEvent) {
    const input = event.target as HTMLInputElement;
    if (input.value.length <= 10) {
      $('.phonnum').css('border', '1px solid red');
    }
  }

  keyPressNumbers(event: KeyboardEvent) {
    $('.phonnum').css('border', 'none');
    var charCode = (event.which) ? event.which : event.keyCode;
    if ((charCode < 48 || charCode > 57)) {
      event.preventDefault();
      return false;
    } else {
      return true;
    }
  }

  ngAfterViewInit() {
  }

  @HostListener('input', ['$event'])
  onInput(event: InputEvent) {
    const input = event.target as HTMLInputElement;
    input.value = input.value.replace(/[^0-9]/g, '');
    if (input.value.length === 1) {
      const nextInput = input.nextElementSibling as HTMLInputElement;
      if (nextInput) {
        nextInput.focus();
      }
    }
  }
  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    if (event.key === 'Backspace') {
      const input = event.target as HTMLInputElement;
      if (input.value === '' && input.previousElementSibling) {
        (input.previousElementSibling as HTMLInputElement).focus();
      }
    }
  }



  verifyOTP() {
    const digits = [this.digit1, this.digit2, this.digit3, this.digit4, this.digit5, this.digit6];
      if (digits.every(digit => digit && digit.nativeElement.value)) {
        const otp = digits.map(digit => digit.nativeElement.value).join('');
        const validOTP = /^[0-9]{6}$/;
        if (validOTP.test(otp)) {
          const newotp = parseInt(otp);
          const trackobj = {
            "mobile_no": this.number.nativeElement.value,
            "otp": newotp
          }
          this.loaderOn()
          this.service.track_complaint(trackobj).subscribe((res: any) => {
            this.loaderOff()
            if (res.code == 200) {
              this.toastr.success(res.message)
              $("#Trackmodal").modal('hide');
              this.service.sendData(res.data);
              localStorage.setItem('trackcred', JSON.stringify(this.number.nativeElement.value))
              localStorage.setItem('trackcredTimestamp', Date.now().toString());
              this.router.navigate(['/complaints']);
              digits.forEach(digit => digit.nativeElement.value = '');
            } else {
              this.toastr.error(res.message)
            }
          })
        } else {
          this.toastr.error("Only enter numbers")
          this.result = 'error';
        }
      } else {
      this.toastr.error("Please enter otp")

        this.result = 'error';
      }

  }


  
  // verifyOTP() {
  //   const digits = [this.digit1, this.digit2, this.digit3, this.digit4, this.digit5, this.digit6];
  //   if (digits.every(digit => digit && digit.nativeElement.value)) {
  //     const otp = digits.map(digit => digit.nativeElement.value).join('');
  //     const validOTP = /^[0-9]{6}$/;
  //     if (validOTP.test(otp)) {
  //       const newotp = parseInt(otp);
  //       if (this.newotp === newotp) {
  //         console.log("otp verifyed")
  //         $("#Trackmodal").modal('hide');
  //         const trackobj = {
  //           "mobile_no": this.number.nativeElement.value
  //         }
  //         this.loaderOn()
  //         this.service.track_complaint(trackobj).subscribe((res: any) => {
  //           this.loaderOff()
  //           if (res.code == 200) {
  //             // this.newotp = ''
  //             this.service.sendData(res.data);
  //             //     localStorage.setItem("tablecred" , JSON.stringify(res.data))
  //             //     this.router.navigate(['/complaints']);
  //             localStorage.setItem('trackcred', JSON.stringify(this.number.nativeElement.value))
  //             localStorage.setItem('trackcredTimestamp', Date.now().toString());
  //             this.router.navigate(['/complaints']);
  //             digits.forEach(digit => digit.nativeElement.value = '');
  //           } else {
  //             this.toastr.error(res.message)
  //           }
  //         })

  //       } else {
  //         this.toastr.error("Invalid OTP")
  //       }
  //     } else {
  //       this.result = 'error';
  //     }
  //   } else {
  //     this.result = 'error';
  //   }
  // }



  clearformndmodle() {
    this.active = "0"
    this.reloadCurrentRoute()
    localStorage.setItem('ghomeid', 'formmenu')
    // this.numberInput.nativeElement.value = '';
    // this.digit1.nativeElement.value = '';
    // this.digit2.nativeElement.value = '';
    // this.digit3.nativeElement.value = '';
    // this.digit4.nativeElement.value = '';
    // this.digit5.nativeElement.value = '';
    // this.digit6.nativeElement.value = '';
    // this.timer = 0
    // this.resendDisabled = true;
    // this.buttonDisabled = false;
    // this.detect.detectChanges();
  }

}
