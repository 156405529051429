<div fxLayout.xs="column" fxFlexFill class="mobile-display">
    <div fxFlex="100" class="bg-img">
        <div class="container-fluid container-p-t" style="background: transparent;">
            <div class="container container-bg">
                <p class="banner-text">
                    Fueling <span class="banner-txt-growth"> Growth </span>
                    <span> With <span class="banner-txt-growth"> Green Energy </span></span>
                </p>
            </div>
        </div>
    </div>
</div>

<div class="section" style="background:#f4f4f9;">
    <div class="column" fxLayout="row" fxLayout.xs="column" fxFlexFill>
        <div class="container-fluid" style="background: transparent;">
            <div class="container mobile-screens" style="background: transparent;">
                <div fxFlex="100">
                    <p class="location-text">
                        <span routerLink="/home" style="cursor: pointer;">Home</span>
                        <span class="location-home"> >
                            <span class="location-arrow"> {{faq}} </span>
                        </span>
                    </p>
                </div>

                <div fxFlex="70">

                </div>
                <div class="row">
                    <div class="col-sm" style="height: 10px;"></div>
                    <div class="col-md" style="height: 20px;"></div>
                </div>
            </div>
        </div>
    </div>

    <div class="container-fluid">
        <div class="container">
            <div class="row">
                <div class="col-xs-6 col-sm-6 col-md-6 col-lg-4 col-xl-4" (click)="carouselEvent('png')">
                    <div [ngClass]="carouselEvnt == 'png' ? 'activeCard': 'inactiveCard' ">
                        {{png}}
                    </div>
                </div>

                <div class="col-xs-6 col-sm-6 col-md-6 col-lg-4 col-xl-4" (click)="carouselEvent('cng')" style="padding-left: 5px;">
                    <div [ngClass]="carouselEvnt == 'cng' ? 'activeCard': 'inactiveCard'">
                        {{cng}}
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="container-fluid">
        <div class="container">
            <div style="margin-bottom: 20px;">
                <div *ngIf="PNG">
                    <div style="margin-top:20px">
                        <p class="faqSearchtxt">{{text}}</p>
                        <input matInput (keyup)="applyFilter($event.target.value)" placeholder="" class="faqSearchInput">
                    </div>

                    <mat-table #table [dataSource]="dataSource" multiTemplateDataRows>
                        <ng-container matColumnDef="s_no">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header></th>
                            <td mat-cell *matCellDef="let element" style="width: 5%;" class="faqacourdianheader acctd"> {{element.s_no}} </td>
                        </ng-container>

                        <ng-container matColumnDef="nm">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> Name </th>
                            <td mat-cell *matCellDef="let element" style="width: 90%;" class="faqacourdianheader" (click)="expandedElement = element"> {{element.nm}} </td>
                            <td mat-cell *matCellDef="let element" style="width: 90%;" class="faqacourdianheader" (click)="expandedElement = undefined"> {{element.nm}} </td>
                        </ng-container>

                        <ng-container matColumnDef="expandedDetail">
                            <td mat-cell *matCellDef="let detail" style=" border: 0px;" class="collapsable">
                                {{detail.content}}<br>
                                <div *ngFor="let file of detail.sub_cntnt" style="padding: 7px 0px 7px 1px;">
                                    <span style="width: 100%;">{{file.sub_cntnt_nm}}</span>
                                </div>
                                <img src="{{detail.img}}" *ngIf="detail.img" style="margin-left: 30%;">
                                <div *ngIf="detail.imgTxt" style="text-align: center;">{{detail.imgTxt}}</div>

                                <div *ngIf="detail.sub_cntnt_two">
                                    <div *ngFor="let file of detail.sub_cntnt_two">
                                        <div>
                                            {{file.sub_cntnt_nm}}
                                        </div>
                                    </div>
                                </div>

                                <div *ngIf="detail.table_cntnt">
                                    <div style="overflow-x:auto;">
                                        <table *ngIf="detail.s_no == 19 || detail.s_no == 28 || detail.s_no == 30">
                                            <tr>
                                                <th *ngFor="let thead of detail.table_cntnt[0].thead">{{thead.th}}</th>
                                            </tr>
                                            <tr *ngFor="let tbody of detail.table_cntnt[0].tbody">
                                                <td>{{tbody.activity}}</td>
                                                <td>{{tbody.rate_mtr}}</td>
                                            </tr>
                                        </table>

                                        <table *ngIf="detail.s_no == 26">
                                            <tr>
                                                <th *ngFor="let thead of detail.table_cntnt[0].thead">{{thead.th}}</th>
                                            </tr>
                                            <tr *ngFor="let tbody of detail.table_cntnt[0].tbody">
                                                <td>{{tbody.activity}}</td>
                                                <td>{{tbody.png}}</td>
                                                <td>{{tbody.lpg}}</td>
                                            </tr>
                                        </table>

                                        <table border="1" width="50%" height="50%" style="text-align: center;" *ngIf="detail.s_no == 27">
                                            <tr>
                                                <td colspan="5">{{detail.table_cntnt[0].headerContent}}</td>
                                            </tr>
                                            <tr>
                                                <th *ngFor="let thead of detail.table_cntnt[0].thead">{{thead.th}}</th>
                                            </tr>
                                            <tr *ngFor="let tbody of detail.table_cntnt[0].tbody">
                                                <td>{{tbody.activity}}</td>
                                                <td>{{tbody.png}}</td>
                                            </tr>
                                        </table>
                                    </div>

                                </div>
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="content">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> </th>
                            <td mat-cell *matCellDef="let element" style="border: 0px;">
                                <button mat-icon-button *ngIf="expandedElement != element" [class.expanded]="expandedElement == element" (click)="expandedElement = element">
                                    <mat-icon><img src="./../../assets/faq/plus.svg"></mat-icon>
                            </button>
                                <button mat-icon-button *ngIf="expandedElement == element" [class.expanded]="expandedElement != element" (click)="expandedElement = undefined">
                                    <mat-icon><img src="./../../assets/faq/minus.svg"></mat-icon>
                            </button>
                            </td>
                        </ng-container>

                        <!-- <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row> -->
                        <mat-row *matRowDef="let row; columns: displayedColumns;" matRipple class="element-row"></mat-row>
                        <mat-row *matRowDef="let row; columns: ['expandedDetail'];" [@detailExpand]="row == expandedElement ? 'expanded' : 'collapsed'" style="overflow: hidden">
                        </mat-row>
                    </mat-table>

                    <mat-paginator class="paginator" #MatPaginator pageSize="10"></mat-paginator>
                </div>



                <div *ngIf="CNG">
                    <div style="margin-top:20px">
                        <p class="faqSearchtxt">{{text}}</p>
                        <input matInput (keyup)="cngApplyFilter($event.target.value)" placeholder="" class="faqSearchInput">
                    </div>
                    <mat-table #table [dataSource]="cngDataSource" multiTemplateDataRows>
                        <ng-container matColumnDef="s_no">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header></th>
                            <td mat-cell *matCellDef="let element" style="width: 5%;" class="faqacourdianheader acctd"> {{element.s_no}} </td>
                        </ng-container>

                        <ng-container matColumnDef="nm">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> Name </th>
                            <td mat-cell *matCellDef="let element" style="width: 90%;" class="faqacourdianheader" (click)="expandedElement = element"> {{element.nm}} </td>
                            <td mat-cell *matCellDef="let element" style="width: 90%;" class="faqacourdianheader" (click)="expandedElement = undefined"> {{element.nm}} </td>
                        </ng-container>


                        <ng-container matColumnDef="expandedDetail">
                            <td mat-cell *matCellDef="let detail" style=" border: 0px;  ">
                                {{detail.content}}
                            </td>
                        </ng-container>

                        <ng-container matColumnDef="content">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> </th>
                            <td mat-cell *matCellDef="let element" style="border: 0px;">
                                <button mat-icon-button *ngIf="expandedElement != element" [class.expanded]="expandedElement == element" (click)="expandedElement = element">
                                <mat-icon><img src="./../../assets/faq/plus.svg"></mat-icon>
                        </button>
                                <button mat-icon-button *ngIf="expandedElement == element" [class.expanded]="expandedElement != element" (click)="expandedElement = undefined">
                                <mat-icon><img src="./../../assets/faq/minus.svg"></mat-icon>
                        </button>
                            </td>
                        </ng-container>

                        <mat-row *matRowDef="let row; columns: displayedColumns;" matRipple class="element-row"></mat-row>
                        <mat-row *matRowDef="let row; columns: ['expandedDetail'];" [@detailExpand]="row == expandedElement ? 'expanded' : 'collapsed'" style="overflow: hidden">
                        </mat-row>
                    </mat-table>
                </div>
            </div>
        </div>
        <!-- <div class="conatiner-fluid">
        <div class="container">
            <mat-tab-group>
                <mat-tab label="PNG">
                    <div style="margin-top:20px">
                        <p class="faqSearchtxt">Enter the term you wish to search for</p>
                        <input matInput (keyup)="applyFilter($event.target.value)" placeholder="" class="faqSearchInput">
                    </div>

                    <div *ngIf="dataSource" style="margin-bottom: 20px;">
                        <table mat-table [dataSource]="dataSource" matSort>

                            <ng-container matColumnDef="s_no">
                                <th mat-header-cell *matHeaderCellDef></th>
                                <td mat-cell *matCellDef="let element;" style="width: 5%;" class="acctd"> {{element.s_no}} </td>
                            </ng-container>

                            <ng-container matColumnDef="nm">
                                <th mat-header-cell *matHeaderCellDef> </th>
                                <td mat-cell *matCellDef="let element;" class="acctd"> {{element.nm}} </td>
                            </ng-container>

                            <mat-row *matRowDef="let row; columns: displayedColumns;" matRipple class="element-row" [cdkRow]="row" [cdkRowTpl]="tpl">
                            </mat-row>
                        </table>


                        <mat-paginator #paginator [pageSize]="10" [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
                    </div>
                    <ng-template #tpl let-element>
                        <div class="mat-row detail-row faqContent" [@detailExpand]>
                            {{element.content}}
                        </div>
                    </ng-template>

                </mat-tab>

                <mat-tab label="CNG">
                    <div>
                        <p class="faqSearchtxt">Enter the term you wish to search for</p>
                        <input matInput (keyup)="cngApplyFilter($event.target.value)" placeholder="" class="faqSearchInput">
                    </div>

                    <div *ngIf="cngDataSource" style="margin-bottom: 20px;">
                        <table mat-table [dataSource]="cngDataSource" matSort>

                            <ng-container matColumnDef="s_no">
                                <th mat-header-cell *matHeaderCellDef> No. </th>
                                <td mat-cell *matCellDef="let element;" class="faqacourdianheader" style="width: 5%;" class="acctd"> {{element.s_no}} </td>
                            </ng-container>

                            <ng-container matColumnDef="nm">
                                <th mat-header-cell *matHeaderCellDef> Name </th>
                                <td mat-cell *matCellDef="let element;" class="faqacourdianheader" class="acctd"> {{element.nm}} </td>
                            </ng-container>

                            <mat-row *matRowDef="let row; columns: displayedColumns;" matRipple class="element-row" [cdkRow]="row" [cdkRowTpl]="tpl">
                            </mat-row>

                        </table>
                        <mat-paginator #paginator [pageSize]="10" [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
                    </div>

                    <ng-template #tpl let-element>
                        <div class="mat-row detail-row faqContent" [@detailExpand] style="overflow: hidden">
                            {{element.content}}
                            <ng-container *ngFor="let file of element.sub_cntnt">
                                <div>
                                    {{file.sub_cntnt_nm}}
                                </div>
                            </ng-container>
                            <img src="{{element.img}}" *ngIf="element.img" style="margin-left: 30%;">
                            <div *ngIf="element.imgTxt" style="text-align: center;">{{element.imgTxt}}</div>

                            <div *ngIf="element.sub_cntnt_two">
                                <ng-container *ngFor="let file of element.sub_cntnt_two">
                                    <div>
                                        {{file.sub_cntnt_nm}}
                                    </div>
                                </ng-container>
                            </div>
                        </div>
                    </ng-template>
                </mat-tab>
            </mat-tab-group>
        </div>
    </div> -->