<div fxLayout.xs="column" fxFlexFill class="mobile-display">
    <div fxFlex="100" class="bg-img">
        <div class="container-fluid" style="background: transparent;">
            <div class="container container-bg">
                <!-- <p class="banner-text">
                    Fueling <span class="banner-txt-growth"> Growth </span>
                    <span> With <span class="banner-txt-growth"> Green Energy </span></span>
                </p> -->
            </div>
        </div>
    </div>
</div>

<div class="column" fxLayout="row" fxLayout.xs="column" fxFlexFill style="background:white;">
    <div class="container-fluid" style="background: transparent;">
        <div class="container mobile-screens" style="background: transparent;">
            <form [formGroup]="formData" class="form-signin">

                <div fxLayout="row">
                    <div fxFlex="80">

                        <p class="location-text">
                            Be our Pratinidhi
                        </p>

                        <div fxLayoutAlign="space-around center">
                            <mat-form-field appearance="outline" class="fieldWidhtOne">
                                <mat-label>Company Name</mat-label>
                                <input matInput  formControlName="companyName" id="companyName" name="companyName" (keypress)="removeSpecialCharacter($event)">
                            </mat-form-field>

                            <mat-form-field appearance="outline" class="fieldWidhtOne">
                                <mat-label>Type Of Pratinidhi</mat-label>
                                <mat-select (ngModelChange)='changeEvent($event)' formControlName="pratinidhiList"
                                    name="pratinidhiList">
                                    <!-- <mat-option value="" selected>
                                            Type Of Pratinidhi
                                        </mat-option> -->
                                    <mat-option *ngFor="let data of dropdownValue" [value]="data.value">
                                        {{data.value}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>

                            <!-- <mat-form-field appearance="outline" class="fieldWidhtOne">
                                <mat-label>Address</mat-label>
                                <input matInput  formControlName="address" id="address"
                                    name="address">
                            </mat-form-field> -->
                        </div>

                        <!-- <div fxLayoutAlign="space-around center">
                            <mat-form-field appearance="outline" class="fieldWidhtOne">
                                <mat-label>Sur Name</mat-label>
                                <input matInput  formControlName="surName" id="surName"
                                    name="surName">
                            </mat-form-field>

                            <mat-form-field appearance="outline" class="fieldWidhtOne">
                                <mat-label>Name</mat-label>
                                <input matInput  formControlName="Name" id="Name" name="Name">
                            </mat-form-field>
                        </div> -->

                        <!-- <div fxLayoutAlign="space-around center">
                            <mat-form-field appearance="outline" class="fieldWidhtTwo">
                                <mat-label>Email</mat-label>
                                <input type="email" matInput [formControl]="emailFormControl"
                                    placeholder="Ex. pat@example.com">
                                <mat-error
                                    *ngIf="emailFormControl.hasError('email') && !emailFormControl.hasError('required')">
                                    Please enter a valid email address
                                </mat-error>
                            </mat-form-field>
                        </div> -->
                    </div>

                    <div fxFlex="10"></div>
                    <div fxFlex="20">
                        <div class="rightImg">
                            <img src="../../assets/submitForm/cngDtlsPinkBg.svg" class="dtlsImg">
                        </div>
                    </div>
                </div>

                <div fxLayout="row">
                    <div fxFlex="100">

                        <div fxLayoutAlign="space-evenly">

                            <mat-form-field appearance="outline" class="fieldWidthThree fieldOne">
                                <mat-label>Type of Activity</mat-label>
                                <mat-select (ngModelChange)='changeEventActivity($event)' formControlName="activityType"
                                    name="activityType">
                                    <mat-option *ngFor="let data of dropdownActivity" [value]="data.value">
                                        {{data.value}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>

                            <mat-form-field appearance="outline" class="fieldWidthThree fieldTwo">
                                <mat-label>Surname</mat-label>
                                <input matInput formControlName="surName" id="surName" name="surName" (keypress)="removeSpecialCharacter($event)">
                            </mat-form-field>

                            <mat-form-field appearance="outline" class="fieldWidthThree fieldThree">
                                <mat-label>Name</mat-label>
                                <input matInput formControlName="Name" id="Name" name="Name" (keypress)="removeSpecialCharacter($event)">
                            </mat-form-field>
                        </div>

                    </div>

                </div>

                <div fxLayout="row">
                    <div fxFlex="100">

                        <div fxLayoutAlign="space-evenly">

                            <mat-form-field appearance="outline" class="fieldWidthThree fieldOne">
                                <mat-label>Email</mat-label>
                                <input type="email" matInput [formControl]="emailFormControl"
                                    placeholder="Ex. pat@example.com" (keypress)="emailFormate($event)">
                                <mat-error
                                    *ngIf="emailFormControl.hasError('email') && !emailFormControl.hasError('required')">
                                    Please enter a valid email address
                                </mat-error>
                            </mat-form-field>

                            <mat-form-field appearance="outline" class="fieldWidthThree fieldTwo">
                                <mat-label>Mobile No</mat-label>
                                <input matInput formControlName="mobile" id="mobile" name="mobile" (keypress)="NumbersOnly($event)" minlength="10" maxlength="10">
                            </mat-form-field>

                            <mat-form-field appearance="outline" class="fieldWidthThree fieldThree">
                                <mat-label>Work Phone</mat-label>
                                <input matInput formControlName="Phone" id="Phone" name="Phone" (keypress)="NumbersOnly($event)" minlength="10" maxlength="10">
                            </mat-form-field>

                            <!-- <mat-form-field appearance="outline" class="fieldWidthThree fieldThree">
                                <mat-label>Name of Geographical Area</mat-label>
                                <input matInput  formControlName="area" id="area" name="area">
                            </mat-form-field> -->
                        </div>

                    </div>

                </div>

                <div fxLayout="row">
                    <div fxFlex="100">
                        <div fxLayoutAlign="space-evenly">
                            <mat-form-field appearance="outline" class="fieldWidthThree fieldOne">
                                <mat-label>H:No</mat-label>
                                <input matInput formControlName="houseNo" id="houseNo" name="houseNo" (keypress)="removeSpecialCharacter($event)">
                            </mat-form-field>

                            <mat-form-field appearance="outline" class="fieldWidthThree fieldTwo">
                                <mat-label>Address 1</mat-label>
                                <input matInput formControlName="address" id="address" name="address" (keypress)="removeSpecialCharacter($event)">
                            </mat-form-field>

                            <mat-form-field appearance="outline" class="fieldWidthThree fieldThree">
                                <mat-label>Address 2</mat-label>
                                <input matInput formControlName="address2" id="address2" name="address2" (keypress)="removeSpecialCharacter($event)">
                            </mat-form-field>
                        </div>

                    </div>

                </div>

                <div fxLayout="row">
                    <div fxFlex="100">

                        <div fxLayoutAlign="space-evenly">
                            <mat-form-field appearance="outline" class="fieldWidthThree fieldOne">
                                <mat-label>Address 3</mat-label>
                                <input matInput formControlName="address3" id="address3" name="address3" (keypress)="removeSpecialCharacter($event)">
                            </mat-form-field>

                            <mat-form-field appearance="outline" class="fieldWidthThree fieldTwo">
                                <mat-label>Address 4</mat-label>
                                <input matInput formControlName="address4" id="address4" name="address4" (keypress)="removeSpecialCharacter($event)">
                            </mat-form-field>

                            <mat-form-field appearance="outline" class="fieldWidthThree fieldThree">
                                <mat-label>District</mat-label>
                                <input matInput formControlName="district" id="district" name="district" (keypress)="removeSpecialCharacter($event)">
                            </mat-form-field>
                        </div>

                    </div>

                </div>

                <div fxLayout="row">
                    <div fxFlex="100">

                        <div fxLayoutAlign="space-evenly">
                            <mat-form-field appearance="outline" class="fieldWidthThree fieldOne">
                                <mat-label>State</mat-label>
                                <input matInput formControlName="state" id="state" name="state" (keypress)="removeSpecialCharacter($event)">
                            </mat-form-field>

                            <mat-form-field appearance="outline" class="fieldWidthThree fieldTwo">
                                <mat-label>Pin Code</mat-label>
                                <input matInput formControlName="pinCode" id="pinCode" name="pinCode" (keypress)="NumbersOnly($event)">
                            </mat-form-field>

                            <mat-form-field appearance="outline" class="fieldWidthThree fieldThree">
                                <mat-label>Name of Geographical Area</mat-label>
                                <mat-select (ngModelChange)='changeEventArea($event)' formControlName="area"
                                    name="area">
                                    <mat-option *ngFor="let data of geoGraphy" [value]="data">
                                        {{data.ga_nm}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>


                        </div>

                    </div>

                </div>

                <div fxLayout="row">
                    <div fxFlex="100">

                        <div fxLayoutAlign="space-between center">

                            <div fxFlex="33">
                                <mat-form-field appearance="outline" class="fieldWidhtFour">
                                    <mat-label>Leave a comment</mat-label>
                                    <textarea matInput placeholder="Ex. It makes me feel..." formControlName="message"
                                        id="message" name="message" class="txtAreaHeight" (keypress)="keyPress($event)" ></textarea>
                                </mat-form-field>

                                <p class="textError" *ngIf="errorTxtEnable"> ' and "" are not allowed </p>
                            </div>

                            

                            <div fxFlex="70">
                                <div class="uploadDiv">
                                    <!-- fxLayout="column" fxLayoutAlign="space-evenly" -->
                                    <!-- <div class="">
                                    <input formControlName="file" id="file" type="file" multiple class="form-control"
                                        (change)="selectFile($event)">
                                </div> -->
                                    <mat-form-field class="fieldWidthUpload">
                                        <mat-label>
                                        </mat-label>
                                        <ng-template #newFile>
                                        </ng-template>
                                        <input matInput readonly id="file" name="file" formControlName="file">

                                        <input hidden (change)="selectFile($event)" multiple #fileInput type="file"
                                            id="file" formControlName="file" accept="image/*,application/pdf">
                                        <button mat-icon-button matSuffix (click)="fileInput.click()"
                                            [ngClass]="{'text-success':redColorEnable == true}">
                                            <img src="../../assets/submitForm/uploadIcon.svg"> Upload Doc
                                        </button>


                                    </mat-form-field>
                                    <p class="tentiveTxt" (click)="tentativeModal()" style="cursor: pointer;">
                                        Tentative List of Documents to Be Submitted/ Uploaded
                                    </p>

                                    <br><br><br><br>
                                    <div class="uploadTxt row">
                                        <div class="uploadedName col-md">
                                            <!-- <div class="plainTxt"> 
                                            Ramki
                                        </div> -->

                                            <!-- <div class="uploadedTxt" *ngIf="name1">
                                            {{name1}} <span (click)="remove(0)"> X </span>
                                        </div> -->
                                            <div class="total">
                                                <div class="static">

                                                </div>

                                                <div class="dynamic">
                                                    <div class="row ">
                                                        <div class="col-md-3 uploadFileTxt"
                                                            *ngFor="let a of uploadNames; let i=index">
                                                            {{a}} &nbsp;&nbsp; <p (click)="remove(a,i)"
                                                                class="removeIcon"> X </p>

                                                        </div>

                                                        <br>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- <div class="btn-head">
                                <div class="btn-cancel-head">
                                    <button mat-stroked-button class="cancelBtn" (click)="cancel()">Cancel</button>
                                </div>
                                <div>
                                    <button mat-stroked-button class="submitBtn" (click)="upload()">Submit</button>
                                </div>
                            </div> -->
                        </div>

                    </div>

                </div>
                <br>
                <br>

                <div fxLayout="row">
                    <div fxFlex="70" fxFlex.sm="50">
                        <div class="disclaimer">
                            <div class="disclaimer-heading">
                                Disclamer*
                            </div>

                            <div class="disclaimer-txt">
                                This page is only meant for the Information collection from Individuals/ parties
                                who/which are interested to be associated with GAIL Gas as PNG Pratinidhi, the
                                submission of information on this page does not guarantee the applicant appoint as PNG
                                Pratinidhi. This appointment shall be done based on Organizational Requirement”
                            </div>
                        </div>
                    </div>
                    <div fxFlex="30" fxFlex.sm="50">
                        <div class="btn-head">
                            <div>
                                <button mat-stroked-button class="cancelBtn" (click)="cancel($event)">Cancel</button>
                            </div> &nbsp;&nbsp;
                            <div>
                                <button mat-stroked-button class="submitBtn" (click)="upload($event)">Submit</button>
                            </div>
                        </div>
                    </div>
                </div>

                <br><br>
            </form>
        </div>
    </div>
</div>