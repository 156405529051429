<style>
    .mainDiv {
        padding: 10px 50px 50px 50px;
    }

    .contentDiv {
        overflow: hidden;
    }
    .emailStyles:hover {
       
        color: black;
    }
    .emailStyles:hover {
        cursor: pointer; 
        text-decoration: underline;
        color: blue;
    }
</style>


<div class="mainDiv">
    <mat-dialog-actions align="end">
        <!-- <button mat-button mat-dialog-close>Close</button> -->

        <button mat-icon-button aria-label="close dialog" mat-dialog-close
            style="color: red; background:white !important;">
            <mat-icon>close</mat-icon>
        </button>

    </mat-dialog-actions>



    <h2 mat-dialog-title style="padding-top: 10px; font-family: MerriweatherBold;
    font-size: 28px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: #0f4a5b;">The Independent External Monitor(IEM) </h2>
    <mat-dialog-content class="mat-typography contentDiv" style="font-family: ApexBook;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.75;
    letter-spacing: normal;
    color: #080808;">
        <p>
            The Independent External Monitor(IEM) has been appointed by GAIL Gas Limited , in terms of Integrity Pact
            (IP)
            which forms part of GAIL Tenders / Contracts.
        </p>

        <p>
            Shri Ashok Kumar Tripathy, IAS (Retd.) <br>
            <a class="emailStyles">
                <img src="../assets/email.svg" alt="email">  tripathyak@yahoo.com
            </a>
        </p>
        <p>
            Shri Ashwani Kumar <br>
            <a class="emailStyles">
                <img src="../assets/email.svg" alt="email">  ashwani.ashwani282@gmail.com
            </a>
            
        </p>

        <p>
            <a class="emailStyles" href="https://gailgaspdfdownloads.s3.ap-south-1.amazonaws.com/Integrity+Pact.pdf" target="_blank" style="color: black;">
                    Integrity Pact
            </a>
                
        </p>
    </mat-dialog-content>


</div>