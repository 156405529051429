
import { Component, OnInit, ViewChild } from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { TranslateService } from '@ngx-translate/core';
import { Title, Meta } from '@angular/platform-browser';
import {PdfService} from './../services/pdf.service';

/**
 * @title Table with expandable rows
 */
@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class FaqComponent implements OnInit {
  title = 'GAIL Gas Limited - FAQs';

  displayedColumns: string[] = ['s_no', 'nm', 'content'];
  dataSource: any;
  cngDataSource: any;
  expandedElement: any;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  cngJson: any;
  pngJson: any;

  isExpansionDetailRow = (index, row) => row.hasOwnProperty('detailRow');
  PNG: boolean = true;
  CNG: boolean;
  carouselEvnt: any;
  language: any;
  text: string;
  png: string;
  cng: string;
  faq: string;
  home: string;

  constructor(private translateService: TranslateService,  private PdfService: PdfService, private titleService: Title, private metaTagService: Meta) { }

  ngOnInit() {
    this.titleService.setTitle(this.title);
    this.metaTagService.updateTag(
      { name: 'description', content: 'GAIL Gas Limited - India’s Leading CGD Company' }
    );
    this.metaTagService.addTags([
      { name: 'keywords', content: '' },
    ]);

    this.PdfService.footer.emit();
   
    this.language = localStorage.getItem('language');
    console.log(this.language , "Overview com");

    if(this.language == 'od' || this.language == 'kn'){
      this.language = 'en';
    }else {
      this.language = this.language;
    }

    this.translateService.setDefaultLang(this.language);
    console.log(this.language)
    this.carouselEvnt = 'png'
    if(this.language == 'hn') {
      this.text = 'वह शब्द दर्ज करें जिसे आप खोजना चाहते हैं',
      this.png = "पीएनजी"
      this.cng = 'सीएनजी';
      this.faq = "सामान्य प्रश्न";
      this.home = "होम"
      this.pngJson = [{
        s_no: 1,
        nm: "प्राकृतिक गैस क्या है?",
        content: "प्राकृतिक गैस मुख्य रूप से मीथेन है - CH4 अन्य उच्च हाइड्रोकार्बन के छोटे प्रतिशत के साथ। हाइड्रोजन में कार्बन का अनुपात कम से कम मीथेन में होता है और इसलिए यह लगभग पूरी तरह से जलकर इसे सबसे स्वच्छ ईंधन बना देता है। यह तेल / गैस के कुओं से उत्पन्न होता है और पूरे देश में पाइपलाइनों के एक नेटवर्क के माध्यम से पहुँचाया जाता है। ",
        sub_cntnt: [{
          sub_cntnt_nm: ""
        }]
      }, {
        s_no: 2,
        nm: "प्राकृतिक गैस कहाँ संग्रहीत की जाती है?",
        content: "प्राकृतिक गैस को संग्रहीत नहीं किया जा सकता है यह हमेशा बहती है और पाइपलाइनों के माध्यम से लगातार आपूर्ति की जाती है।",
        sub_cntnt: [{
          sub_cntnt_nm: ""
        }]
      }, {
        s_no: 3,
        nm: "पेरेंट कंपनी, गेल (इंडिया) लिमिटेड के बारे में ",
        content: "गेल (इंडिया) लिमिटेड भारत सरकार का उपक्रम है, जो एक सबसे युवा महारत्न है, जो प्राकृतिक गैस के मूल्य श्रृंखला में कारोबार में लगा हुआ है। ",
        sub_cntnt: [{
          sub_cntnt_nm: "कंपनी गैस ट्रांसमिशन और मार्केटिंग से लेकर प्रोसेसिंग (एलपीजी, प्रोपेन, एसबीपी सॉल्वेंट और पेंटेन के अंशांकन के लिए) एलपीजी के ट्रांसमिशन और पेट्रोकेमिकल के विपणन के उत्पादन तक की गतिविधियों में शामिल है। "
        }]
      }, {
        s_no: 4,
        nm: "गेल गैस लिमिटेड के बारे में",
        content: "गेल गैस सिटी गैस वितरण नेटवर्क के कार्यान्वयन के लिए महारत्न गेल (इंडिया) लिमिटेड की पूर्ण स्वामित्व वाली सहायक कंपनी है। गेल गैस लिमिटेड को देवास (मध्य प्रदेश), कोटा (राजस्थान), सोनीपत (हरियाणा) और मेरठ (उत्तर प्रदेश), ताज ट्रेपियमियम क्षेत्र (उत्तर प्रदेश) में सिटी गैस वितरण परियोजनाओं को लागू करने के लिए पेट्रोलियम और प्राकृतिक गैस नियामक बोर्ड (PNGRB) द्वारा अधिकृत किया गया है। प्रदेश) और अब बेंगलुरु (कर्नाटक)। गेल गैस ने भारत पेट्रोलियम कॉर्पोरेशन लिमिटेड BPCL के साथ अपने कंसोर्टियम के माध्यम से हरिद्वार जिले और उत्तरी गोवा के प्राधिकरण को जीत लिया है।",
        sub_cntnt: [{
          sub_cntnt_nm: "वर्तमान में गेल गैस 10,000 से अधिक घरों में प्राकृतिक गैस की आपूर्ति कर रही है, और 450 उद्योग इसके अधिकृत जीए में हैं। गेल गैस अपने 16 परिचालन सीएनजी स्टेशनों के माध्यम से भी सीएनजी की आपूर्ति कर रही है।"
        }]
      }, {
        s_no: 5,
        nm: "गेल गैस लिमिटेड के उद्देश्य",
        content: "वितरण और प्राकृतिक गैस का विपणन।",
        sub_cntnt: [{
          sub_cntnt_nm: "घरेलू / वाणिज्यिक / औद्योगिक उद्देश्यों के लिए पाइप्ड प्राकृतिक गैस।"
        }, {
          sub_cntnt_nm: "प्राकृतिक गैस के संपीड़न के लिए सुविधाओं की स्थापना, सिटी गेट स्टेशन (नों) से उपभोग क्षेत्रों और संबंधित सुविधाओं के लिए पाइपलाइन बिछाने, सीएनजी / ऑटो एलपीजी के लिए वितरण बिंदु / खुदरा आउटलेट, मोबाइल कैस्केड / लॉरी के माध्यम से गैस का परिवहन। सीएनजी गलियारों के निर्माण के लिए भारत के विभिन्न शहरों और राष्ट्रीय राजमार्गों के साथ। "
        }, {
          sub_cntnt_nm: "डाउनस्ट्रीम वितरण परियोजनाओं के कार्यान्वयन के लिए गैस उत्पादकों / रणनीतिक भागीदारों के साथ भागीदारी के माध्यम से गैस व्यापार के अवसरों का निर्माण।"
        }, {
          sub_cntnt_nm: "पीएनजीआरबी से प्राधिकरण पर सिटी गैस वितरण परियोजनाओं का विकास।"
        }, {
          sub_cntnt_nm: "उपभोक्ताओं को समाप्त करने के लिए एनजी / आरएलएनजी का द्वितीयक वितरण।"
        }]
      }, {
        s_no: 6,
        nm: "गेल गैस लिमिटेड के संयुक्त उपक्रमों के बारे में",
        content: "पूरे देश में सीजीडी के नेटवर्क का विस्तार करने के लिए, गेल गैस लिमिटेड ने विभिन्न संयुक्त उपक्रमों का गठन किया है। ",
        sub_cntnt: [{
          sub_cntnt_nm: "वडोदरा गैस लिमिटेड: वडोदरा में वडोदरा महानगर सेवा सदन (VMSS) के साथ एक संयुक्त उद्यम"
        }, {
          sub_cntnt_nm: "केरल गेल गैस लिमिटेड: केरल में केरल राज्य औद्योगिक विकास निगम (KSIDC) के साथ एक संयुक्त उद्यम "
        }, {
          sub_cntnt_nm: "आंध्र प्रदेश गैस वितरण निगम लिमिटेड (APGDC) - आंध्र प्रदेश गैस इन्फ्रास्ट्रक्चर कॉर्पोरेशन लिमिटेड (APGIC) और आंध्र प्रदेश गैस वितरण निगम लिमिटेड (APGDC) के साथ एक संयुक्त उद्यम "
        }, {
          sub_cntnt_nm: "राजस्थान स्टेट गैस लिमिटेड- राजस्थान में राजस्थान स्टेट पेट्रोलियम कॉर्पोरेशन लिमिटेड के साथ एक संयुक्त उद्यम कंपनी है"
        }, {
          sub_cntnt_nm: "हरिद्वार नेचुरल गैस प्राइवेट लिमिटेड: - हरिद्वार जिले के लिए BPCL के साथ एक संयुक्त उद्यम "
        }]
      }, {
        s_no: 7,
        nm: "घरेलू पीएनजी कनेक्शन क्या है?",
        content: "एक घरेलू पीएनजी कनेक्शन में आवश्यक फिटिंग, दबाव नियामक, मीटर के साथ-साथ पीएनजी के लिए एलपीजी उपकरण के रूपांतरण के साथ-साथ पाइप लाइन नेटवर्क बिछाना शामिल है। "
      }, {
        s_no: 8,
        nm: "पीएनजी कनेक्शन प्रदान करने के लिए समय रेखा क्या है?",
        content: "पंजीकरण के बाद, गेल गैस तीन महीने के भीतर प्राकृतिक गैस के साथ ग्राहक के उपकरण को हल्का करने के लिए अपना सर्वश्रेष्ठ प्रयास करेगा। "
      }, {
        s_no: 9,
        nm: "कैसे गैस पाइपलाइन हमारे घर तक पहुंचती है।",
        content: "विभिन्न शहरों के लिए गेल गैस को मुख्य परिवहन पाइप के माध्यम से स्रोत प्राकृतिक गैस मिलती है और इसे शहर के गेट स्टेशन पर प्राप्त होता है और स्टील ग्रिड नेटवर्क विकसित होता है। गैस के दबाव को नियमित करने के लिए, जिला नियामक प्रणाली स्थापित है। आगे एमडीपीई का एक नेटवर्क, विभिन्न समाजों और भवन आदि तक पहुंचने के लिए विकसित किया गया है। नियामक, जिसके बाद जीआई पाइपलाइन के साथ राइजर स्थापित किया जाता है, जो एक मीटर से जुड़ा होता है। पीएनजी को स्टोव तक पहुंचाने के लिए एक तांबे की पाइप और आगे रबर की ट्यूबिंग की जाती है। ",
        sub_cntnt: [{
          sub_cntnt_nm: ""
        }],
        img: './../../assets/faq/pipe-gas-house.jpeg',
        imgTxt: "उपरोक्त आरेख / योजनाबद्ध गेल गैस प्रणाली के लिए लागू नहीं है क्योंकि हम IGL प्रणाली का अनुसरण कर रहे हैं, इसलिए इसे हटाया जा सकता है (केवल आरेख,)। "
      }, {
        s_no: 10,
        nm: "पीएनजी घरेलू पंजीकरण की प्रक्रिया क्या है?",
        content: "पंजीकरण तीन तरीकों से किया जा सकता है",
        sub_cntnt: [{
          sub_cntnt_nm: "1) ऑनलाइन"
        }, {
          sub_cntnt_nm: "भावी ग्राहक तकनीकी व्यवहार्यता के अनुरोध के रूप में गेल गैस वेबसाइट के माध्यम से ऑनलाइन आवेदन करेगा और अपने अनुरोध के लिए एक विशिष्ट पहचान संख्या प्राप्त करेगा, एक बार तकनीकी व्यवहार्यता हो जाएगी और कनेक्शन संभव हो जाएगा, ग्राहक को ऑनलाइन पंजीकरण करना होगा और आवश्यक जमा करना होगा। दस्तावेज़, डीडी / चेक और कनेक्टिविटी प्रक्रिया शुरू होगी। "
        }],
        img: './../../assets/faq/pipe-gas-house-10.jpeg',
        imgTxt: "उपरोक्त आरेख / योजनाबद्ध गेल गैस प्रणाली के लिए लागू नहीं है क्योंकि हम IGL प्रणाली का अनुसरण कर रहे हैं, इसलिए इसे हटाया जा सकता है (केवल आरेख,)। ",
        sub_cntnt_two: [{
          sub_cntnt_nm: "2) वॉक इन्स / यात्रा के माध्यम से"
        }, {
          sub_cntnt_nm: "ग्राहक हमारे शिविरों, डीएमए कार्यालय के साथ-साथ साइट कार्यालय पर भी जा सकते हैं और आवेदन करने के लिए मुद्रित प्रपत्र प्राप्त कर सकते हैं। "
        }, {
          sub_cntnt_nm: "3) डीएमए / कंपनी के प्रतिनिधि के माध्यम से"
        }, {
          sub_cntnt_nm: "डीएमए / कंपनी के प्रतिनिधि ग्राहक के घर पर जाते हैं और उन्हें फॉर्म प्रदान करते हैं। आवश्यक प्रतिनिधि और भुगतान साधन (एस) के साथ भरे हुए फॉर्म उस प्रतिनिधि को प्रदान किए जा सकते हैं। "
        }, {
          sub_cntnt_nm: "ग्राहक हमारे शिविरों, डीएमए कार्यालय और साथ ही साइट कार्यालय पर जा सकते हैं और आवेदन करने के लिए मुद्रित प्रपत्र प्राप्त कर सकते हैं।"
        }]
      }, {
        s_no: 11,
        nm: "डीएमए क्या है?",
        content: "डीएमए एक डायरेक्ट मार्केटिंग एजेंसी है जो गेल गैस द्वारा अपने अधिकृत जीए में घरेलू पाइप्ड प्राकृतिक गैस उपभोक्ताओं के पंजीकरण और संबद्ध गतिविधियों के लिए नियुक्त है। "
      }, {
        s_no: 12,
        nm: "पीएनजी कनेक्शन प्रदान करने के लिए समय रेखा क्या है?",
        content: "पंजीकरण के बाद, गेल गैस तीन महीने के भीतर प्राकृतिक गैस के साथ ग्राहक के उपकरण को हल्का करने के लिए अपना सर्वश्रेष्ठ प्रयास करेगा। "
      }, {
        s_no: 13,
        nm: "पंजीकरण के लिए शुल्क क्या है?",
        content: "रु। ५ 58०० / - ग्राहक द्वारा दिया जाना है, जिसमें रु ३०० / - गैर-वापसी योग्य आवेदन शुल्क है, रु ० ५००० / - रिफंडेबल सिक्योरिटी डिपॉजिट है और ५०० / - रुपये भुगतान सुरक्षा है।", sub_cntnt: [{
          sub_cntnt_nm: "यह जोर दिया जाना चाहिए कि गेल गैस या इसके किसी भी अधिकृत प्रतिनिधि ने नकद के माध्यम से किसी भी भुगतान (अतिरिक्त पाइपिंग या बिल भुगतान सहित) को कभी स्वीकार नहीं किया है। ग्राहक से अनुरोध है कि वह किसी भी परिस्थिति में नकद भुगतान न करे। इसके अलावा ग्राहक को रसीदों को प्राप्त करने के लिए शिक्षित किया जाना चाहिए और गेल गैस या उसके अधिकृत प्रतिनिधि को भुगतान करने के लिए भुगतान करना होगा। "
        }]
      }, {
        s_no: 14,
        nm: "पंजीकरण के लिए सभी दस्तावेजों की क्या आवश्यकता है",
        content: "पहचान का प्रमाण (निम्नलिखित में से कोई एक: पैन कार्ड / पासपोर्ट / वोटर आईडी कार्ड / ड्राइविंग लाइसेंस / राशन कार्ड / आधार कार्ड) और पंजीकरण के प्रमाण के साथ पंजीकरण फॉर्म जमा करें (स्थापना की बिक्री की प्रति) / बिजली बिल / बीएसएनएल टेलीफोन बिल / सोसायटी प्रमाण पत्र आदि)"
        , sub_cntnt: [{
          sub_cntnt_nm: ""
        }]
      }, {
        s_no: 15,
        nm: "पाइप्ड नेचुरल गैस, घरेलू ईंधन के रूप में, एलपीजी से बेहतर है?",
        content: "हां, घरेलू पीएनजी एक सुरक्षित है, आपूर्ति, किफायती, रखरखाव की कम लागत, बुकिंग के लिए कोई परेशानी नहीं है, भंडारण स्थान और कम वजन वाले सिलेंडर, उपयोग के अनुसार भुगतान, पर्यावरण के अनुकूल और सुविधाजनक ईंधन। ",
        sub_cntnt: [{
          sub_cntnt_nm: "गैस की खपत के लिए कोई अग्रिम भुगतान, खपत के आधार पर दो महीने में एक बार बिलिंग की जाएगी"
        }]
      }, {
        s_no: 16,
        nm: "क्या उद्योगों के लिए भी ईंधन की आवश्यकता है?",
        content: "हां, सिटी गैस डिस्ट्रीब्यूशन के तहत ईंधन की आपूर्ति के लिए प्रति दिन 50,000 एससीएम से कम की आवश्यकता वाले उद्योगों को पूरा किया जा सकता है। ",
        sub_cntnt: [{
          sub_cntnt_nm: ""
        }]
      }, {
        s_no: 17,
        nm: "वाणिज्यिक PNG से आपका क्या अभिप्राय है?",
        content: "वाणिज्यिक इकाइयों के लिए ईंधन की आवश्यकता जैसे होटल, रेस्तरां, मॉल शॉपिंग कॉम्प्लेक्स आदि को वाणिज्यिक पीएनजी के लिए वर्गीकृत किया जा सकता है ",
        sub_cntnt: [{
          sub_cntnt_nm: ""
        }]
      }, {
        s_no: 18,
        nm: "संकेताक्षर",
        content: "", sub_cntnt: [{
          sub_cntnt_nm: "पीएनजी - पाइप्ड प्राकृतिक गैस"
        }, {
          sub_cntnt_nm: "सीएनजी - संपीड़ित प्राकृतिक गैस"
        }, {
          sub_cntnt_nm: "PNGRB - पेट्रोलियम और प्राकृतिक गैस नियामक बोर्ड"
        }, {
          sub_cntnt_nm: "सीजीडी - सिटी गैस वितरण"
        }]
      }, {
        s_no: 19,
        nm: "जीआई / कॉपर पाइपलाइन के मामले में 15 मीटर से अधिक होने पर, अतिरिक्त पाइप की लंबाई के लिए निम्नलिखित दरों का शुल्क लिया जाएगा। ",
        content: "", sub_cntnt: [{
          sub_cntnt_nm: ""
        }]
      }, {
        s_no: 20,
        nm: "क्या PNG पर स्विच करने के लिए LPG उपकरण बदलना आवश्यक है?",
        content: "", sub_cntnt: [{
          sub_cntnt_nm: "PNG LPG की तरह ही खाना पकाने के उपकरण पर काम करता है। उपकरण को बदलने की आवश्यकता नहीं है, मामूली संशोधनों के साथ एलपीजी उपकरण को आसानी से पीएनजी में परिवर्तित किया जा सकता है। "
        }]
      }, {
        s_no: 21,
        nm: "क्या पीएनजी की आपूर्ति नियमित है?",
        content: "", sub_cntnt: [{
          sub_cntnt_nm: "हां, आपूर्ति बिल्कुल नियमित है। पाइपलाइन वितरण नेटवर्क एक ऑन-लाइन आपूर्ति प्रणाली पर आधारित है जिसमें सुरक्षा वाल्व और नियामक होते हैं जो गैस आपूर्ति और दबाव को नियंत्रित करते हैं और सिस्टम लीक की पहचान करने में सहायता करते हैं। इस प्रकार एक निरंतर दबाव में एक बिना बाधित आपूर्ति का आश्वासन दिया जाता है। गैस 24x7 उपलब्ध है। "
        }, {
          sub_cntnt_nm: "यहां उदाहरण मुंबई और सूरत में संबंधित कंपनियों द्वारा क्रमशः 2005 और 2006 में बाढ़ के दौरान भी पीएनजी की निर्बाध आपूर्ति का दिया जा सकता है। "
        }]
      }, {
        s_no: 22,
        nm: "क्या खाना पकाने के अलावा किसी अन्य उद्देश्य के लिए पीएनजी का उपयोग किया जाता है?",
        content: "", sub_cntnt: [{
          sub_cntnt_nm: "PNG का उपयोग हीटिंग, एयर कंडीशनिंग, बिजली उत्पादन आदि के लिए किया जा सकता है।"
        }]
      }, {
        s_no: 23,
        nm: "खाना पकाने के उपकरण में परिवर्तित होने के बाद एलपीजी सिलिंडर का क्या हो जाता है?",
        content: "", sub_cntnt: [{
          sub_cntnt_nm: "एलपीजी कनेक्शन को संबंधित तेल कंपनी को प्राप्त किया जा सकता है और प्राप्त सिक्योरिटी डिपॉजिट रिफंड "
        }]
      }, {
        s_no: 24,
        nm: "एलपीजी सरेंडर करने के लिए क्या दिशा-निर्देश हैं",
        content: "", sub_cntnt: [{
          sub_cntnt_nm: "ग्राहक कृपया द लिक्विफाइड पेट्रोलियम गैस (आपूर्ति और वितरण का विनियमन) आदेश, 2000 के प्रावधान के अनुसार नोट कर सकते हैं, क्योंकि संशोधित अधिसूचना दिसंबर 16,2013 में सार्वजनिक वितरण प्रणाली (पीडीएस) के तहत एलपीजी की आपूर्ति का उल्लेख किया गया है। पीएनजी घरेलू उपभोक्ता को 'निषिद्ध गतिविधि' के रूप में सब्सिडी दी गई। हालांकि, 09 जनवरी 2014 को एलपीजी नियंत्रण आदेश पत्र के साथ आप एक एलपीजी कनेक्शन रख सकते हैं और सरकारी तेल कंपनियां गैर-रियायती दर पर रिफिल प्रदान करेंगी। "
        }]
      }, {
        s_no: 25,
        nm: "भारत के अन्य प्रमुख शहरों में घरेलू पीएनजी उपलब्ध हैं?",
        content: "", sub_cntnt: [{
          sub_cntnt_nm: "घरेलू पीएनजी दिल्ली, नोएडा, गाजियाबाद, ग्रेटर नोएडा, मुंबई, ठाणे, नवी मुंबई, पुणे, कानपुर, लखनऊ, बरेली, आगरा, फिरोजाबाद, मेरठ, सोनीपत, कोटा, देवास, अहमदाबाद, वडोदरा, राजकोट, अगरतला, सूरत में उपलब्ध है। , इंदौर, हैदराबाद, उज्जैन, विजयवाड़ा और बेंगलुरु आदि",
        }]
      }, {
        s_no: 26,
        nm: "एलपीजी पर पीएनजी के क्या लाभ हैं",
        content: "", sub_cntnt: [{
          sub_cntnt_nm: "ग्राहक कृपया द लिक्विफाइड पेट्रोलियम गैस (आपूर्ति और वितरण का विनियमन) आदेश, 2000 के प्रावधान के अनुसार नोट कर सकते हैं, क्योंकि संशोधित अधिसूचना दिसंबर 16,2013 में सार्वजनिक वितरण प्रणाली (पीडीएस) के तहत एलपीजी की आपूर्ति का उल्लेख किया गया है। पीएनजी घरेलू उपभोक्ता को 'निषिद्ध गतिविधि' के रूप में सब्सिडी दी गई। हालांकि, 09 जनवरी 2014 को एलपीजी नियंत्रण आदेश पत्र के साथ आप एक एलपीजी कनेक्शन रख सकते हैं और सरकारी तेल कंपनियां गैर-रियायती दर पर रिफिल प्रदान करेंगी। "
        }]
      }, {
        s_no: 27,
        nm: "बिक्री के बाद की सेवाएं जो गेल गैस प्रदान करती हैं।",
        content: "", sub_cntnt: [{
          sub_cntnt_nm: "जीआई / कॉपर पाइपलाइन के मामले में 15 मीटर से अधिक होने पर, अतिरिक्त पाइप की लंबाई के लिए निम्नलिखित दरों का शुल्क लिया जाएगा। "
        }]
      }, {
        s_no: 28,
        nm: "पीएनजी ग्राहक के लिए क्या करें और क्या न करें",
        content: "", sub_cntnt: [{
          sub_cntnt_nm: ""
        }]
      }, {
        s_no: 29,
        nm: "आपातकाल के लिए निर्देश क्या हैं?",
        content: "", sub_cntnt: [{
          sub_cntnt_nm: "1. पीएनजी मीटर और नियामक के पास गैस लाइन पर उपलब्ध वाल्व बंद करें।"
        }, {
          sub_cntnt_nm: "2.क्षेत्र के सभी दरवाजे और खिड़कियां खोलें।"
        }, {
          sub_cntnt_nm: "3.सभी आग उपकरणों को तुरंत बंद करें।"
        }, {
          sub_cntnt_nm: "4.किसी भी बिजली के उपकरणों को चालू / बंद न करें।"
        }, {
          sub_cntnt_nm: "5.गैस रिसाव बिंदु को गीले कपड़े से ढकें।"
        }, {
          sub_cntnt_nm: "6. खुली लौ का उपयोग न करें।"
        }, {
          sub_cntnt_nm: "7. लोगों को लीकेज एरिया से दूर रखें।"
        }, {
          sub_cntnt_nm: "8. आपातकाल के मामले में घबराएं नहीं और आपातकालीन क्षेत्र से दूर रहें।"
        }, {
          sub_cntnt_nm: "9.आपातकाल के मामले में अफवाह न फैलाएं।"
        }, {
          sub_cntnt_nm: "10. Iआपातकालीन स्थिति के बारे में तुरंत गेल गैस क्षेत्रीय कार्यालय को सूचित करें।"
        }]
      }, {
        s_no: 30,
        nm: "विभिन्न अधिकृत शहरों में प्रभारित क्षेत्र",
        content: "", sub_cntnt: [{
          sub_cntnt_nm: ""
        }]
      }];
  
      this.cngJson = [{
        s_no: 1,
        nm: "सीएनजी क्या है?",
        content: "सीएनजी का मतलब संपीड़ित प्राकृतिक गैस से है। यह एक गैसीय ईंधन है और 89% से 90% की सीमा में मुख्य रूप से मीथेन हाइड्रोकार्बन का मिश्रण है। इसके कम घनत्व के कारण, यह वाहन पर बोर्ड भंडारण क्षमता को बढ़ाने के लिए 200-250 किलोग्राम / सेमी g (जी) के दबाव के लिए संकुचित है। "
      }, {
        s_no: 2,
        nm: "क्या सीएनजी सुरक्षित है? ",
        content: "सीएनजी के गुण इसे एक सुरक्षित ईंधन बनाते हैं। यह हवा की तुलना में हल्का है, इसलिए रिसाव के मामले में यह ऊपर उठता है और वातावरण में फैल जाता है। इसके अलावा, पेट्रोल के 360 डिग्री सेंटीग्रेड के मुकाबले 540 डिग्री सेंटीग्रेड का उच्च ऑटो-इग्निशन तापमान इसे एक सुरक्षित ईंधन बनाता है। साथ ही, रिसाव के मामले में, यदि हवा में एनजी की एकाग्रता 5% से कम या 15% से अधिक है, तो एक चिंगारी की उपस्थिति में भी गैस नहीं जलेगी। "
      }, {
        s_no: 3,
        nm: "एक वाहन को सीएनजी-फ्रेंडली में बदलने में क्या शामिल है? ",
        content: "सभी स्पार्क-प्रज्वलित इंजनों को सीएनजी में परिवर्तित किया जा सकता है, लेकिन इस उद्देश्य के लिए विशेष रूप से डिज़ाइन की गई रूपांतरण किट की आवश्यकता होती है। किट में कार के बूट और इंजन में गैस प्रवाह की अनुमति देने के लिए एक सिलेंडर होता है। "
      }, {
        s_no: 4,
        nm: "क्या CNG के खत्म होने के बाद CNG रूपांतरण किट से लैस कार पेट्रोल पर चल सकती है? ",
        content: "हां, सीएनजी में रूपांतरण के बाद भी, वाहन को पेट्रोल कार्बोरेटर और ईंधन टैंक के साथ फिट किया जाता है। इसलिए सीएनजी या पेट्रोल पर, जब भी वांछित हो, केवल डैशबोर्ड पर एक स्विच को फ्लश करके वाहन दोहरे ईंधन पर चल सकता है। "
      }, {
        s_no: 5,
        nm: "वाहन को सीएनजी-फ्रेंडली में परिवर्तित करने की लागत क्या है? ",
        content: "वाहन को सीएनजी में बदलने की लागत उसके प्रकार और बनाने पर निर्भर करती है। मोटे तौर पर, यह 30,000 रुपये से 50,000 रुपये (लगभग) के बीच भिन्न होता है।"
      }, {
        s_no: 6,
        nm: "सीएनजी सिलेंडर के आयाम और वजन क्या हैं? ",
        content: "सीएनजी सिलेंडर एक विशेष इस्पात मिश्र धातु से निर्मित होते हैं और निर्माण में निर्बाध होते हैं। उनका कॉम्पैक्ट आकार उन्हें छोटी कार में भी आसानी से फिट होने की अनुमति देता है। 50 लीटर-जल-वहन क्षमता वाले खाली सीएनजी सिलेंडर का वजन 48 किलो (लगभग) होता है, जिसकी लंबाई 835 मिमी और व्यास 316 मिमी होता है। 50 लीटर क्षमता वाला सिलेंडर सबसे अधिक नियमित रूप से उपयोग किया जाने वाला है; 45 लीटर, 55 लीटर, 60 लीटर और 65 लीटर क्षमता वाले सिलेंडरों का उपयोग किया जाता है।",
      }, {
        s_no: 7,
        nm: "एक भराव से एक सिलेंडर और माइलेज की क्षमता क्या है? सिलेंडर में बची सीएनजी की मात्रा का पता कैसे चलता है?",
        content: "50 लीटर की जल-वहन क्षमता वाला एक सिलेंडर लगभग 9 किलोग्राम सीएनजी ले जाने में सक्षम है। यह 12.5 लीटर पेट्रोल के बराबर है और लगभग 150-160 किमी की दूरी पर मध्यम आकार की 1300 सीसी कार चलाने की अनुमति देगा। रूपांतरण किट के हिस्से के रूप में डैशबोर्ड पर लगे इलेक्ट्रॉनिक ईंधन गेज सिलेंडर में छोड़ी गई सीएनजी की मात्रा को इंगित करता है। "
      }, {
        s_no: 8,
        nm: "एक सिलेंडर में सीएनजी का दबाव कितना होता है? क्या सिलेंडर का ईंधन भरना सुरक्षित है, दबाव दिया गया?",
        content: "CNG सिलिंडर को इस तरह बनाया और बनाया गया है ताकि उच्च दबाव का सामना किया जा सके। CNG सिलेंडर में अधिकतम दबाव 200 kg / cm2 (g) (लगभग 2840 पाउंड प्रति वर्ग इंच (गेज) या साई [g]) तक होता है। सीएनजी सिलेंडर सुरक्षित हैं क्योंकि वे विशिष्ट आवश्यकताओं के अनुसार निर्मित होते हैं और उपयोग से पहले परीक्षण किए जाते हैं, अंतरराष्ट्रीय विनिर्देशों और मानकों के अनुसार, और विस्फोटक नियंत्रक द्वारा मुख्य रूप से अनुमोदित हैं। इसके अलावा, उन्हें एक दबाव राहत उपकरण (पीआरडी) प्रदान किया जाता है जिसमें एक फ्यूजिबल प्लग और एक फट डिस्क होती है जो अनजाने उच्च दबाव और तापमान के मामले में फट जाती है। "
      }, {
        s_no: 9,
        nm: "सीएनजी में रूपांतरण के बाद बचत कितनी होगी?",
        content: "वाहन के औसत चलने के आधार पर ईंधन बिल लगभग 50 प्रतिशत कम हो जाता है। किट की स्थापना की लागत एक वर्ष के भीतर वसूल की जाती है, ड्राइविंग में शामिल औसत दूरी।"
      }, {
        s_no: 10,
        nm: "क्या सीएनजी किट में सर्विसिंग की आवश्यकता होती है?",
        content: "सीएनजी किट वर्षों तक परेशानी से मुक्त ऑपरेशन देती है। इसके लिए बार-बार सर्विसिंग की आवश्यकता नहीं होती है। बस के रूप में अन्य सभी ईंधन के लिए, यह नियमित सेवाओं के लिए सलाह दी जाती है। सेवा के लिए एक अधिकृत व्यक्ति से परामर्श किया जाना चाहिए। गैस सिलेंडर नियम, 1981 के अनुसार, दबाव सहनशीलता की जांच के लिए सिलेंडर को हर 3 साल में हाइड्रो-स्ट्रेच परीक्षण से गुजरना चाहिए। "
      }, {
        s_no: 11,
        nm: "क्या सीएनजी का इंजन पर कोई हानिकारक प्रभाव पड़ता है?",
        content: "सीएनजी की विशिष्ट विशेषताओं के कारण, यह क्रैंककेस तेल को दूषित या पतला नहीं करता है, जिससे इंजन को जीवन का एक नया पट्टा मिलता है। CNG में किसी भी लीड कंटेंट का न होना, प्लग के लीड फ्यूलिंग से बचने में मदद करता है, जिससे प्लग की लाइफ बढ़ जाती है। सीएनजी का एक महत्वपूर्ण लाभ यह है कि गैस के रूप में इंजन में प्रवेश करने पर (और अन्य ईंधन की तरह स्प्रे या धुंध के रूप में नहीं), यह इंजन में चिकनाई तेल की उपस्थिति को परेशान नहीं करता है, और संभावना कम कर देता है टूट - फूट।"
      }, {
        s_no: 12,
        nm: "सीएनजी से परिवर्तित वाहन के प्रदर्शन की तुलना पेट्रोल से चलने वाले वाहन से कैसे की जाती है?",
        content: "सीएनजी आसान शुरुआती, विश्वसनीय सुस्ती और चिकनी त्वरण प्रदान करता है। सीएनजी वाहन का त्वरण थोड़ा धीमा है, जो 5-15% की बिजली की हानि के कारण है। सीएनजी पर उचित ट्यूनिंग द्वारा इसे कम किया जा सकता है (जैसे कि स्पार्क समय को आगे बढ़ाना) ईंधन के उच्च ऑक्टेन रेटिंग का लाभ उठाने के लिए। "
      }, {
        s_no: 13,
        nm: "क्या डीजल वाहनों को सीएनजी में बदला जा सकता है?",
        content: "हां, डीजल वाहन को सीएनजी पर चलाने के लिए परिवर्तित किया जा सकता है, लेकिन रूपांतरण लागत पेट्रोल वाहनों के रूपांतरण से अधिक है।"
      }, {
        s_no: 14,
        nm: "सीएनजी से चलने वाले वाहनों में एग्जॉस्ट फ्यूम इतने कम क्यों होते हैं?",
        content: "सीएनजी पर्यावरण के अनुकूल ईंधन है। प्राकृतिक गैस मुख्य रूप से मीथेन से बना है और इसके निकास उत्सर्जन में जल वाष्प और कार्बन मोनोऑक्साइड का एक नगण्य अंश शामिल है। कार्बन और अन्य पार्टिकुलेट की अनुपस्थिति से निकास धुएं में हानिकारक तत्वों की नगण्य मात्रा होती है। "
      }, {
        s_no: 15,
        nm: "बड़े पैमाने पर सीएनजी के उपयोग से देश को क्या फायदा होता है?",
        content: "तरल ईंधन की तुलना में सीएनजी कम कार्बन डाइऑक्साइड और अन्य प्रदूषकों का उत्सर्जन करता है। इस प्रकार, सीएनजी वायु प्रदूषण को कम करने में योगदान देता है, आयातित तरल ईंधन के विकल्प के रूप में कार्य करके भुगतान संतुलन में सुधार करने में भी मदद करता है, एक नए क्षेत्र के उद्घाटन के साथ अधिक व्यापार और नौकरी के अवसर प्रदान करता है, और प्रतिस्पर्धी रूप से कीमत होने के कारण, यह मोटर चालकों को आकर्षित करता है। "
      }];
    } else {
      this.text = 'Enter the term you wish to search for';
      this.png = "PNG";
      this.cng = 'CNG';
      this.faq = "FAQ's";
      this.home = "home";

      this.pngJson = [{
        s_no: 1,
        nm: "What is Natural Gas?",
        content: "Natural Gas is mainly Methane - CH4 with a small percentage of other higher hydrocarbons. The ratio of carbon to hydrogen is least in methane and hence it burns almost completely making it the cleanest fuel. It is produced from the oil / gas wells and transported through a network of pipelines across the country.",
        sub_cntnt: [{
          sub_cntnt_nm: ""
        }]
      }, {
        s_no: 2,
        nm: "Where is Natural Gas stored?",
        content: "Natural Gas cannot be stored it is always flowing & continuously supplied through pipelines.",
        sub_cntnt: [{
          sub_cntnt_nm: ""
        }]
      }, {
        s_no: 3,
        nm: "About Parent Company, GAIL (India) Limited",
        content: "GAIL (India) Limited is a Government of India Undertaking, a youngest Maharatna, which is engaged in the business across the value chain of Natural Gas.",
        sub_cntnt: [{
          sub_cntnt_nm: "Company is involved in activities ranging from Gas transmission and marketing to processing (for fractionating LPG, Propane, SBP Solvent and Pentane) transmission of LPG and production of marketing of petrochemicals."
        }]
      }, {
        s_no: 4,
        nm: "About GAIL Gas Limited",
        content: "GAIL Gas is a wholly owned subsidiary of Maharatna GAIL (India) Limited for implementation of City Gas Distribution network. GAIL Gas Limited has been authorized by Petroleum and Natural Gas Regulatory Board (PNGRB) for implementing City Gas Distribution projects in Dewas (Madhya Pradesh), Kota (Rajasthan), Sonepat (Haryana) and Meerut (Uttar Pradesh), Taj Trapezium Zone (Uttar Pradesh) and now Bengaluru (Karnataka). GAIL Gas has won the authorization of Haridwar District and North Goa through its consortium with Bharat Petroleum Corporation Limited BPCL.",
        sub_cntnt: [{
          sub_cntnt_nm: "Presently GAIL Gas is supplying Natural Gas to more than 10,000 houses, and 450 Industries in its authorized GAs. GAIL Gas is also supplying CNG through its 16 operational CNG stations.."
        }]
      }, {
        s_no: 5,
        nm: "Objectives of GAIL Gas Limited",
        content: "Distribution & Marketing of Natural Gas.",
        sub_cntnt: [{
          sub_cntnt_nm: "Piped Natural Gas for domestic/ commercial/ industrial purposes."
        }, {
          sub_cntnt_nm: "Setting up of facilities for compression of Natural Gas, laying of the pipelines from City Gate Station(s) to the consumption areas and associated facilities, distribution points/retail outlets for CNG/Auto LPG, transportation of gas through mobile cascades/lorries in the various cities of India and along the national highways for building CNG corridors."
        }, {
          sub_cntnt_nm: "Building gas business opportunities through participation with Gas Producers/ Strategic partners for implementation of the downstream distribution projects."
        }, {
          sub_cntnt_nm: "Development of City Gas Distribution projects on authorization from PNGRB."
        }, {
          sub_cntnt_nm: "Secondary distribution of NG/ RLNG to end consumers."
        }]
      }, {
        s_no: 6,
        nm: "About the Joint Ventures of GAIL Gas Limited",
        content: "In order to expand the network of CGD across the nation, GAIL Gas Limited has formed various Joint Ventures.",
        sub_cntnt: [{
          sub_cntnt_nm: "Vadodara Gas Limited : A Joint Venture with Vadodara Mahanagar Seva Sadan ( VMSS) at Vadodara"
        }, {
          sub_cntnt_nm: "Kerala GAIL Gas Limited : A Joint Venture with Kerala State Industrial Development Corporation ( KSIDC) in Kerala"
        }, {
          sub_cntnt_nm: "Andhra Pradesh Gas Distribution Corporation Limited (APGDC) – A Joint Venture with Andhra Pradesh Gas Infrastructure Corporation Limited (APGIC) and Andhra Pradesh Gas Distribution Corporation Limited (APGDC) in Andhra Pradesh"
        }, {
          sub_cntnt_nm: "Rajasthan State Gas Limited- A Joint Venture Company with Rajasthan State Petroleum Corporation Ltd in Rajasthan"
        }, {
          sub_cntnt_nm: "Haridwar Natural Gas Pvt Limited:- A Joint Venture with BPCL , for Haridwar District"
        }]
      }, {
        s_no: 7,
        nm: "What is a Domestic PNG connection?",
        content: "A domestic PNG connection includes laying of pipeline network up to appliance along with necessary fittings, pressure regulator, meter and conversion of LPG appliance to PNG.",
      }, {
        s_no: 8,
        nm: "What is the time line for providing PNG Connection?",
        content: "Upon the registration GAIL Gas shall put its best efforts to lighten up the appliance of customer with Natural Gas within three months."
      }, {
        s_no: 9,
        nm: "How Does Gas pipeline reaches to our house.",
        content: "For various cities GAIL Gas gets the source natural gas through the main transportation pipe and receives it at city gate station and steel grid network is developed. To regularize the pressure of the gas, District Regulatory System is installed. Further a network of MDPE, is developed to reach to different societies and building etc. The regulator, followed by raiser with GI pipeline is installed which is connected to a meter. A copper pipe and further rubber tubing is done to get PNG reach to stoves.",
        sub_cntnt: [{
          sub_cntnt_nm: ""
        }],
        img: './../../assets/faq/pipe-gas-house.jpeg',
        imgTxt: "The above diagram/schematic is not applicable for GAIL Gas system as we are following IGL system, hence it may be removed (only diagram,)."
      }, {
        s_no: 10,
        nm: "What is the procedure for PNG Domestic Registration?",
        content: "The Registration can be done in three ways",
        sub_cntnt: [{
          sub_cntnt_nm: "1) Online"
        }, {
          sub_cntnt_nm: "The prospective customer will apply online through GAIL Gas website as a request for the technical feasibility and will get a unique identification number for his request, once the technical feasibility will be done and connection is feasible, the customer need to register online and submit the required document, DD/ Cheque and connectivity process will start."
        }],
        img: './../../assets/faq/pipe-gas-house-10.jpeg',
        imgTxt: "The above diagram/schematic is not applicable for GAIL Gas system as we are following IGL system, hence it may be removed (only diagram,).",
        sub_cntnt_two: [{
          sub_cntnt_nm: "2) Walk Inns/ Through Visit"
        }, {
          sub_cntnt_nm: "Customer can visit to our camps, DMA Office as well as the Site office and can get the Printed forms for applying."
        }, {
          sub_cntnt_nm: "3) Through Visit of DMA/Company representative"
        }, {
          sub_cntnt_nm: "The DMA/ company representative visits the customer house and provide them form(s). The filled forms along with the necessary documents & payment instrument(s) may be provided to that representative."
        }, {
          sub_cntnt_nm: "Customer can visit to our camps, DMA Office as well as the Site office and can get the Printed forms for applying."
        }]
      }, {
        s_no: 11,
        nm: "What is DMA?",
        content: "DMA is a Direct Marketing Agency appointed by GAIL Gas for registration & allied activities of Domestic Piped Natural Gas consumers in its authorized GAs."
      }, {
        s_no: 12,
        nm: "What is the time line for providing PNG Connection?",
        content: "Upon the registration GAIL Gas shall put its best efforts to lighten up the appliance of customer with Natural Gas within three months."
      }, {
        s_no: 13,
        nm: "What is the fee for Registration?",
        content: "Rs 5800/- is to be given by the customer wherein Rs 300/- is non-refundable application fee, Rs 5000/- is Refundable Security Deposit and Rs 500/- is Payment Security.", sub_cntnt: [{
          sub_cntnt_nm: "It is to be emphasized that GAIL Gas or any of its authorized representative never accepts any payment (including extra piping or bill payment) through cash. The customer is requested not to pay cash in any circumstances. Further the customer is to be educated to obtain the receipts and preserve the same for ay payment he/she has done to GAIL Gas or its authorized representative."
        }]
      }, {
        s_no: 14,
        nm: "What all documents are required for registration",
        content: "Submit Registration form along with Proof of Identity (Any one of the following: PAN Card/ Passport/Voter Id Card/ Driving License/Ration Card/ Aadhar Card) and Proof of ownership of the establishment (Copy of Sale Deed Agreement/Rent Agreement/Electricity bill/BSNL Telephone bill/Society Certificate etc.)"
        , sub_cntnt: [{
          sub_cntnt_nm: ""
        }]
      }, {
        s_no: 15,
        nm: "Is Piped Natural Gas, as a domestic fuel, better than LPG?",
        content: "Yes, Domestic PNG is a safe, continues supply, economical, less cost of maintenance, no hassle for booking, storage space and underweight cylinders, payment as per the usage, environment friendly and convenient fuel.",
        sub_cntnt: [{
          sub_cntnt_nm: "No advance payment for consumption of gas, billing will be done once in two months based on consumption"
        }]
      }, {
        s_no: 16,
        nm: "Is PNG required as fuel for Industries as well?",
        content: "Yes, Industries with the requirement less than 50,000 SCM per day can be catered for supplying fuel under City Gas Distribution.",
        sub_cntnt: [{
          sub_cntnt_nm: ""
        }]
      }, {
        s_no: 17,
        nm: "What do you mean by Commercial PNG?",
        content: "Requirement of Fuel for Commercial Units like hotels, restaurants, malls shopping complex etc can be categorized for commercial PNG",
        sub_cntnt: [{
          sub_cntnt_nm: ""
        }]
      }, {
        s_no: 18,
        nm: "Abbreviations",
        content: "", sub_cntnt: [{
          sub_cntnt_nm: "PNG - Piped Natural Gas"
        }, {
          sub_cntnt_nm: "CNG - Compressed Natural Gas"
        }, {
          sub_cntnt_nm: "PNGRB - Petroleum and Natural Gas Regulatory Board"
        }, {
          sub_cntnt_nm: "CGD - City Gas Distribution"
        }]
      }, {
        s_no: 19,
        nm: "In case of GI/ Copper Pipeline exceeds beyond 15 Meters, the following rates shall be charged for the extra pipe length.",
        content: "", sub_cntnt: [{
          sub_cntnt_nm: ""
        }],
        table_cntnt: [{
          thead: [{
            th: 'Activity'
          }, {
            th: '(Rate In Rs)'
          }],
          tbody: [{
            activity: 'GI (1/2")',
            rate_mtr: '330/Mtr'
          }, {
            activity: 'GI (3/4")',
            rate_mtr: '350/Mtr'
          }, {
            activity: '12 MM Copper',
            rate_mtr: '265/Mtr'
          }]
        }]
      }, {
        s_no: 20,
        nm: "Is it required to change the LPG appliance for switch over to PNG?",
        content: "", sub_cntnt: [{
          sub_cntnt_nm: "PNG works on a cooking appliance just like LPG does. It is not required to change the appliance, with minor modifications LPG appliance can be easily converted to PNG."
        }]
      }, {
        s_no: 21,
        nm: "Is the supply of PNG regular?",
        content: "", sub_cntnt: [{
          sub_cntnt_nm: "Yes, the supply is absolutely regular. The pipeline distribution network is based on an on-line supply system that consists of safety valves and regulators that control and monitor the gas supply and pressure, and assist in identifying system leaks. Thus an un-interrupted supply at a constant pressure is assured. The gas is available 24x7."
        }, {
          sub_cntnt_nm: "Here the example can be given of uninterrupted supply of PNG by respective companies in Mumbai & Surat respectively in 2005 & 2006 even during heavy flood."
        }]
      }, {
        s_no: 22,
        nm: "Is PNG be used for any other purpose apart from cooking?",
        content: "", sub_cntnt: [{
          sub_cntnt_nm: "PNG can be used for heating, air conditioning, power generation etc."
        }]
      }, {
        s_no: 23,
        nm: "What becomes of the LPG cylinders after the cooking appliance gets converted?",
        content: "", sub_cntnt: [{
          sub_cntnt_nm: "The LPG connection can be surrendered to the respective oil company and security deposit refund obtained"
        }]
      }, {
        s_no: 24,
        nm: "What are the guidelines to surrender LPG",
        content: "", sub_cntnt: [{
          sub_cntnt_nm: "Customer may kindly take note of the provision of The Liquefied Petroleum Gas (Regulation of Supply and Distribution) order, 2000 as amended vide notification dated December 16,2013 which has mentioned of supply of LPG under Public Distribution System (PDS) ie. subsidized to a PNG domestic consumer as 'prohibited activity. However, with LPG control order letter dated 09th January 2014 you can retain one LPG connection and the Government oil companies shall provide the refill at the non-subsidized rate."
        }]
      }, {
        s_no: 25,
        nm: "In which other major cities of India Domestic PNG are available?",
        content: "", sub_cntnt: [{
          sub_cntnt_nm: "Domestic PNG is available in Delhi, Noida, Ghaziabad, Greater Noida, Mumbai, Thane, Navi Mumbai, Pune, Kanpur, Lucknow, Bareilly, Agra, Firozabad, Meerut, Sonipat, Kota, Dewas, Ahmedabad, Vadodara, Rajkot, Agartala, Surat, Indore, Hyderabad, Ujjain, Vijaywada and Bengaluru etc."
        }]
      }, {
        s_no: 26,
        nm: "What are the benefits of PNG over LPG",
        content: "", sub_cntnt: [{
          sub_cntnt_nm: "Customer may kindly take note of the provision of The Liquefied Petroleum Gas (Regulation of Supply and Distribution) order, 2000 as amended vide notification dated December 16,2013 which has mentioned of supply of LPG under Public Distribution System (PDS) ie. subsidized to a PNG domestic consumer as 'prohibited activity. However, with LPG control order letter dated 09th January 2014 you can retain one LPG connection and the Government oil companies shall provide the refill at the non-subsidized rate."
        }], table_cntnt: [{
          thead: [{
            th: ''
          }, {
            th: 'PNG'
          }, {
            th: 'LPG'
          }],
          tbody: [{
            activity: 'Safety',
            png: 'Being lighter than air escapes to atmosphere in case of leakage.',
            lpg: "Many instances of cylinder blast causing fatal accidents and damaging human life & property"
          }, {
            activity: 'Pilferage',
            png: 'No possibility of theft',
            lpg: "Chances of short weight cylinder delivery"
          }, {
            activity: 'Adulteration',
            png: 'No possibility of adulteration',
            lpg: "Adulteration possible"
          }, {
            activity: 'Quantity loss',
            png: 'Only Pay for gas entirely used as per meter. No loss.',
            lpg: "loss of gas left out at bottom of the cylinder specially during winter."
          }, {
            activity: 'Convenience',
            png: '24×7 Uninterrupted supply. No tension of booking for refill.',
            lpg: "Headache of booking cylinders. Fear of cylinder getting emptied in odd hours."
          }, {
            activity: 'Payment',
            png: 'Postpaid',
            lpg: "Prepaid"
          }, {
            activity: 'Price of Gas	',
            png: 'No price difference for high usage. Rate- only Rs.26.11 per scm',
            lpg: "Charged at higher price(Non subsidized) for use beyond 12 cylinders per year"
          }, {
            activity: 'Rate comparison',
            png: 'Cost of equivalent Domestic PNG - Rs.445/-',
            lpg: "Cost of Non subsidized cylinder(14.2 Kgs)- Rs.490/-"
          }, {
            activity: 'Rate comparison',
            png: 'Cost of equivalent Domestic PNG - Rs.595/-',
            lpg: "Cost of commercial cylinder(19 Kgs)- Rs.957/-"
          }]
        }]
      }, {
        s_no: 27,
        nm: "What are the after sales services that GAIL Gas offers.",
        content: "", sub_cntnt: [{
          sub_cntnt_nm: "In case of GI/ Copper Pipeline exceeds beyond 15 Meters, the following rates shall be charged for the extra pipe length."
        }],
       
        table_cntnt: [{
          headerContent:"After Sales Service Charges - GAIL GAS LTD",
          thead: [ {
            th: 'Particulars / Activity'
          }, {
            th: 'GAIL Gas Ltd (Rate In Rs)'
          }],
          tbody: [{
            activity: 'Providing Bathroom Point',
            png: 'GI(1/2)"-330/Mtr, Cu(3/4") -350/Mtr, 12 MM Copper-265/Mtr',
          }, {
            activity: 'Internal GI pipe and installation Removal for Renovation',
            png: '250 per Removal',
          }, {
            activity: 'External/Riser GI pipe Removal for Renovation/Building Extension',
            png: '250 per Removal',
          }, {
            activity: 'GI Internal pipe Re-installation/Modification Charges',
            png: 'As per Actual length of work @ 350 /per meter subject to a minimum of Rs 500/-if same or lesser material is reinstalled',
          }, {
            activity: 'PE pipeline laying through Boring',
            png: '350',
          }, {
            activity: 'Wire braded flexible Hose replacement',
            png: '250 per Removal',
          }, {
            activity: 'New Stove Conversion from LPG to PNG and vice-versa',
            png: 'Rs 50 per burner and Rs 300/- for stove with 4 burner +Oven +Grill',
          }, {
            activity: 'Gas Tap replacement /Appliance Valve/Isolation Value	',
            png: '200',
          }, {
            activity: 'Disconnection/Deactivation for Supply of gas',
            png: '100',
          }, {
            activity: 'Reconnection /Restoration for Supply of Gas',
            png: '100',
          },{
            activity: 'Name Transfer Charges(Except Death)',
            png: '250',
          }]
        }]
      }, {
        s_no: 28,
        nm: "Do's and Don't for PNG Customer",
        content: "", sub_cntnt: [{
          sub_cntnt_nm: ""
        }], table_cntnt: [{
          thead: [{
            th: "Do's"
          }, {
            th: "Don'ts"
          }],
          tbody: [{
            activity: 'gas appliances.',
            rate_mtr: 'Do not use LPG cylinder in PNG burner.'
          }, {
            activity: 'Connect the gas appliances and valves correctly.',
            rate_mtr: 'Do not tamper with the PNG Pipe line'
          }, {
            activity: 'Keep the window open & exhaust fan on while using the gas stove.',
            rate_mtr: 'For any PNG Pipe line repair work inform GAIL Gas & get your work done through GAIL Gas mechanics after checking its identity.'
          }, {
            activity: 'Turn off the valve when the gas is not in use.',
            rate_mtr: 'Do not bring any burning object near the PNG Pipe line & meter'
          }, {
            activity: 'Check & replace the rubber tube if required.',
            rate_mtr: 'Do not leave the gas stove unattended after use.'
          }, {
            activity: 'Check & maintain the set up regularly',
            rate_mtr: 'Do not use any spray cans or cleaning solvents and leave any paper or clothe near gas appliances.'
          }]
        }]
      }, {
        s_no: 29,
        nm: "What are the directives for Emergency?",
        content: "", sub_cntnt: [{
          sub_cntnt_nm: "1. Turn off the valve available on gas line near PNG meter & regulator."
        }, {
          sub_cntnt_nm: "2. Open all the doors and windows of the area."
        }, {
          sub_cntnt_nm: "3. Immediately stop all the fire appliances."
        }, {
          sub_cntnt_nm: "4. Do not on/off any electrical appliances."
        }, {
          sub_cntnt_nm: "5. Cover the gas leakage point with a wet cloth."
        }, {
          sub_cntnt_nm: "6. Do not use open flame."
        }, {
          sub_cntnt_nm: "7. Keep away people from the leakage area."
        }, {
          sub_cntnt_nm: "8. Do not get panic in case of emergency & stay away from the emergency area."
        }, {
          sub_cntnt_nm: "9. Do not spread rumor in case of emergency."
        }, {
          sub_cntnt_nm: "10. Immediately inform to GAIL gas regional office regarding the emergency situation."
        }]
      }, {
        s_no: 30,
        nm: "Charged Areas in Different Authorized Cities",
        content: "", sub_cntnt: [{
          sub_cntnt_nm: ""
        }],table_cntnt: [{
          thead: [{
            th: "City"
          }, {
            th: "Charged Area"
          }],
          tbody: [{
            activity: 'Meerut.',
            rate_mtr: 'Panchwati Enclave, Raj Kamal Enclave, Garden View Colony, Shyam Enclave, Surya Palace, Satabdi Nagar, Vrindaban,Partapur I.E., Supertech Palm Green, Dev lok colony (Blocks A to C), Krishna Vatika, Shree Ram Palace, Shambhu Nagar, Vasantkunj, Raghukul Vihar, Gupta colony, Vishav Enclave, Mohkampur Ind. Area-II, Subharti University, Shastri Nagar Block-A-H, J,-L, Somdutt Vihar Colony, Takshila Colony, Shastri Nagar Sec-1-3,6,7,8,9-11, Mayur Vihar Phase-I & II, Jagriti Vihar Sec-1- 5, Moti Prayag, Prem Prayag , Damodar Colony, Kirti Palace ,Meera Enclave, Samrat Palace, Saraswati Lok, Tejpal Singh Enclave, Ratan Kunj, Garden House Colony, Mansarovar Colony, Zaidi Nagar and Vaishali Colony'
          }, {
            activity: 'Sonipat',
            rate_mtr: 'PAdarsh Nagar, Chintpurni Colony, Hemnagar, Janta Colony, Khanna Colony, Rathdana, Sector 12,13,14,15, Siddharth Enclave, Sikka Colony, HUDA Sec-23, Model Tow, Tulip Residency'
          }, {
            activity: 'Dewas',
            rate_mtr: 'Mahaveer Nagar, hate Singh Goyal Colony, Kalani Bagh, LAxman Nagar, Jawahar Nagar, Anand Rishi Nagar, Badridham Nagar, Gomti Nagar, Agarwal Nagar, Vikas Nagar, Ram Nagar Enclave, Farukh Nagar,Ganga Nagar, Seth Mishrilal Nagar etxn, Bajrang Nagar, Karoli Nagar, Mishrilal Nagar, Ram Nagar Extn, Tilak nagar, Ram nagar, Pansheel Nagar, Tarani Colony, Anand Bagh, Adarsh Colony, Civil Lines, Agrasen Nagar, Bajrang Bali Nagar, Sun City-1, Suncity-2, Jeet Mal Nagar, Mahalaxmi Nagar, Agroha Nagar, Pachunkar Colony, Madhuban Colony, Narayan Nagar, Ram Chandra Nagar, Jai Prakash Nagar, Mridul Vihar, Radha Ganj, Arjun Nagar, Chamunda Palace, Chamunda Anex, Chamunda Puri, Vishram Bagh, Dev Shree Nagar, Vivek Nagar, Ram Rahim Nagar, Gaytri Vihar'
          }, {
            activity: 'TTZ/ Firozabad',
            rate_mtr: 'Vibhav Nagar, Shuhag Nagar, Ganesh Nagar, Jain Nagar and Orchid Green Housing Complex'
          }, {
            activity: 'Bengaluru',
            rate_mtr: 'HSR Layout, Bellandur, Singsandra, Dollors Colony, Manganampaliya'
          }]
        }]
      }]
  
      this.cngJson = [{
        s_no: 1,
        nm: "What is CNG?",
        content: "CNG stands for Compressed Natural Gas. It is a gaseous fuel and is a mixture of hydrocarbons, mainly methane, in the range of 89% to 90%. Due to its low density, it is compressed to a pressure of 200-250 kg/cm² (g) to enhance the vehicle on-board storage capacity."
      }, {
        s_no: 2,
        nm: "Is CNG safe?",
        content: "The properties of CNG make it a safe fuel. It is lighter than air, so in case of a leak it just rises up and disperses into the atmosphere. Besides, a high auto-ignition temperature of 540 degrees centigrade as against petrol's 360 degrees centigrade makes it a safe fuel. Also, in case of a leak, if NG's concentration in the air is less than 5% or more than 15%, the gas will not burn even in the presence of a spark."
      }, {
        s_no: 3,
        nm: "What is involved in converting a vehicle to a CNG-friendly one?",
        content: "All spark-ignited engines can be converted to CNG, but a specially designed conversion kit is required for the purpose. The kit consists of a cylinder to be fixed in the boot of the car and other equipment to allow gas flow into the engine."
      }, {
        s_no: 4,
        nm: "Can a car fitted with CNG conversion kit run on petrol after its CNG gets over?",
        content: "Yes, even after conversion to CNG, the vehicle continues to be fitted with the petrol carburetor and fuel tank. The vehicle can therefore run on dual fuel, either on CNG or petrol, whenever desired, simply by flicking a switch on the dashboard."
      }, {
        s_no: 5,
        nm: "What is the cost of converting a vehicle to a CNG-friendly one?",
        content: "The cost of converting a vehicle to CNG depends on its type and make. Broadly, it varies between Rs 30,000 to Rs 50,000 (approximately)."
      }, {
        s_no: 6,
        nm: "What are the dimensions and weight of a CNG cylinder?",
        content: "CNG cylinders are manufactured from a special steel alloy and are seamless in construction. Their compact size allows them to easily fit even in a small car. An empty CNG cylinder with a 50 litre-water-carrying capacity weighs 48 kg (approximately), with a length of 835 mm and a diameter of 316 mm. The 50 litre capacity cylinder is the one most regularly used; cylinders with 45 litre, 55 litre, 60 litre and 65 litre capacity are used as well."
      }, {
        s_no: 7,
        nm: "What is the capacity of a cylinder and mileage from one fill? How does one get to know the quantity of CNG left in the cylinder?",
        content: "A cylinder with a 50 litre water-carrying capacity is capable of carrying approximately 9 kg of CNG. This is equivalent to 12.5 litres of petrol and will allow a run of about 150-160 km to a medium-sized 1300 CC car. An electronic fuel gauge fitted on the dashboard as part of the conversion kit indicates the quantity of CNG left in the cylinder."
      }, {
        s_no: 8,
        nm: "What is the pressure of CNG in a cylinder? Is refueling of a cylinder safe, given the pressure?",
        content: "CNG cylinders are designed and built in such a way so as to withstand high pressure. The maximum pressure in a CNG cylinder is up to 200 kg/cm2 (g) (about 2840 pounds per square inch (gauge) or psi[g] ). CNG cylinders are safe as they are manufactured as per specific requirements and tested before use, in accordance with international specifications and standards, and are duly approved by the Chief Controller of Explosives. Moreover, they are provided with a pressure relief device (PRD) that consists of a fusible plug and a burst disc that ruptures in case of inadvertent high pressure and temperature."
      }, {
        s_no: 9,
        nm: "How much will be the saving after conversion to CNG?",
        content: "The fuel bill on the basis of average running of vehicle is reduced by 50 percent approximately. The cost of installation of the kit is recovered within a year, given average distances covered in driving."
      }, {
        s_no: 10,
        nm: "Does CNG kit require servicing?",
        content: "CNG kit gives years of trouble-free operation. It doesn't require frequent servicing. Just as for all other fuels, it is advised to have routine services. An authorized person should be consulted for service. As per Gas Cylinder Rules, 1981, the cylinder should undergo hydro-stretch testing every 3 years to check pressure tolerance."
      }, {
        s_no: 11,
        nm: "Does CNG have any harmful effect on the engine?",
        content: "Owing to CNG's distinct features, it does not contaminate or dilute crankcase oil, giving a new lease of life to the engine. The absence of any lead content in CNG helps avoid lead fouling of plugs, thereby enhancing plug life. One of the important benefits of CNG is that upon entering the engine in the form of a gas (and not as a spray or mist like other fuels), it doesn't disturb the presence of lubricating oil in the engine, and reduces chances of wear and tear."
      }, {
        s_no: 12,
        nm: "How does the performance of a CNG-converted vehicle compare with that of a petrol-run vehicle?",
        content: "CNG provides easy starting, reliable idling and smooth acceleration. The acceleration of a CNG vehicle is a bit slower, which is due to a power loss of 5-15%. This can be minimized by proper tuning on CNG (e.g. advancing the spark timing) to take advantage of the high octane rating of the fuel."
      }, {
        s_no: 13,
        nm: "Can diesel vehicles be converted to CNG?",
        content: "Yes, a diesel vehicle can be converted to run on CNG, but the conversion cost is higher than the conversion of petrol vehicles."
      }, {
        s_no: 14,
        nm: "Why are exhaust fumes so small in CNG-run vehicles?",
        content: "CNG is an environment-friendly fuel. The natural gas is mainly composed of methane and its exhaust emissions consist of water vapour and a negligible fraction of carbon monoxide. The absence of carbon and other particulates result in negligible amount of harmful elements in exhaust fumes."
      }, {
        s_no: 15,
        nm: "How does large-scale CNG usage benefit the country?",
        content: "CNG emits less carbon dioxide and other pollutants as compared to liquid fuels. Thus, CNG contributes in lowering air pollution, also helps in improving the Balance of Payments by acting as a substitute for imported liquid fuels, provides more business and job opportunities with the opening up of a new sector, and being competitively priced, it appeases motorists."
      }];
  
    }
   
    this.dataSource = new MatTableDataSource(this.pngJson);
    
    setTimeout(() => {
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    }, 1000);

    this.cngDataSource = new MatTableDataSource(this.cngJson);
    this.cngDataSource.paginator = this.paginator;
    this.cngDataSource.sort = this.sort;
  }

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // Datasource defaults to lowercase matches
    this.dataSource.filter = filterValue;
  }

  cngApplyFilter(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // Datasource defaults to lowercase matches
    this.cngDataSource.filter = filterValue;
  }

  carouselEvent(event) {
    if (event == 'png') {
      this.carouselEvnt = 'png';
      this.PNG = true;
      this.CNG = false;
    } else {
      this.carouselEvnt = 'cng';
      this.PNG = false;
      this.CNG = true;
    }
  }
  selectionChange(event) {

  }

}

// export interface PeriodicElement {
//   s_no: number;
//   nm: string;
//   content: string;
//   sub_cntnt: any;
//   img: any;
//   imgTxt: any;
//   sub_cntnt_two: any;

// }

// const ELEMENT_DATA: PeriodicElement[] = [{
//   s_no: 1,
//   nm: "What is Natural Gas?",
//   content: "Natural Gas is mainly Methane - CH4 with a small percentage of other higher hydrocarbons. The ratio of carbon to hydrogen is least in methane and hence it burns almost completely making it the cleanest fuel. It is produced from the oil / gas wells and transported through a network of pipelines across the country.",
//   sub_cntnt: [{
//     sub_cntnt_nm: ""
//   }],
//   img: "",
//   imgTxt: "",
//   sub_cntnt_two: ""
// }, {
//   s_no: 2,
//   nm: "Where is Natural Gas stored?",
//   content: "Natural Gas cannot be stored it is always flowing & continuously supplied through pipelines.",
//   sub_cntnt: [{
//     sub_cntnt_nm: ""
//   }],
//   img: "",
//   imgTxt: "",
//   sub_cntnt_two: ""
// }, {
//   s_no: 3,
//   nm: "About Parent Company, GAIL (India) Limited",
//   content: "GAIL (India) Limited is a Government of India Undertaking, a youngest Maharatna, which is engaged in the business across the value chain of Natural Gas.",
//   sub_cntnt: [{
//     sub_cntnt_nm: ""
//   }],
//   img: "",
//   imgTxt: "",
//   sub_cntnt_two: ""
// }, {
//   s_no: 4,
//   nm: "About GAIL Gas Limited",
//   content: "GAIL Gas is a wholly owned subsidiary of Maharatna GAIL (India) Limited for implementation of City Gas Distribution network. GAIL Gas Limited has been authorized by Petroleum and Natural Gas Regulatory Board (PNGRB) for implementing City Gas Distribution projects in Dewas (Madhya Pradesh), Kota (Rajasthan), Sonepat (Haryana) and Meerut (Uttar Pradesh), Taj Trapezium Zone (Uttar Pradesh) and now Bengaluru (Karnataka). GAIL Gas has won the authorization of Haridwar District and North Goa through its consortium with Bharat Petroleum Corporation Limited BPCL.",
//   sub_cntnt: [{
//     sub_cntnt_nm: ""
//   }],
//   img: "",
//   imgTxt: "",
//   sub_cntnt_two: ""
// }, {
//   s_no: 5,
//   nm: "Objectives of GAIL Gas Limited",
//   content: "Distribution & Marketing of Natural Gas.",
//   sub_cntnt: [{
//     sub_cntnt_nm: "Piped Natural Gas for domestic/ commercial/ industrial purposes."
//   }, {
//     sub_cntnt_nm: "Setting up of facilities for compression of Natural Gas, laying of the pipelines from City Gate Station(s) to the consumption areas and associated facilities, distribution points/retail outlets for CNG/Auto LPG, transportation of gas through mobile cascades/lorries in the various cities of India and along the national highways for building CNG corridors."
//   }, {
//     sub_cntnt_nm: "Building gas business opportunities through participation with Gas Producers/ Strategic partners for implementation of the downstream distribution projects."
//   }, {
//     sub_cntnt_nm: "Development of City Gas Distribution projects on authorization from PNGRB."
//   }, {
//     sub_cntnt_nm: "Secondary distribution of NG/ RLNG to end consumers."
//   }],
//   img: "",
//   imgTxt: "",
//   sub_cntnt_two: ""
// }, {
//   s_no: 6,
//   nm: "About the Joint Ventures of GAIL Gas Limited",
//   content: "In order to expand the network of CGD across the nation, GAIL Gas Limited has formed various Joint Ventures.",
//   sub_cntnt: [{
//     sub_cntnt_nm: "Vadodara Gas Limited : A Joint Venture with Vadodara Mahanagar Seva Sadan ( VMSS) at Vadodara"
//   }, {
//     sub_cntnt_nm: "Kerala GAIL Gas Limited : A Joint Venture with Kerala State Industrial Development Corporation ( KSIDC) in Kerala"
//   }, {
//     sub_cntnt_nm: "Andhra Pradesh Gas Distribution Corporation Limited (APGDC) – A Joint Venture with Andhra Pradesh Gas Infrastructure Corporation Limited (APGIC) and Andhra Pradesh Gas Distribution Corporation Limited (APGDC) in Andhra Pradesh"
//   }, {
//     sub_cntnt_nm: "Rajasthan State Gas Limited- A Joint Venture Company with Rajasthan State Petroleum Corporation Ltd in Rajasthan"
//   }, {
//     sub_cntnt_nm: "Haridwar Natural Gas Pvt Limited:- A Joint Venture with BPCL , for Haridwar District"
//   }],
//   img: "",
//   imgTxt: "",
//   sub_cntnt_two: ""
// }, {
//   s_no: 7,
//   nm: "What is a Domestic PNG connection?",
//   content: "A domestic PNG connection includes laying of pipeline network up to appliance along with necessary fittings, pressure regulator, meter and conversion of LPG appliance to PNG.",
//   sub_cntnt: [{
//     sub_cntnt_nm: ""
//   }],
//   img: "",
//   imgTxt: "",
//   sub_cntnt_two: ""
// }, {
//   s_no: 8,
//   nm: "What is the time line for providing PNG Connection?",
//   content: "Upon the registration GAIL Gas shall put its best efforts to lighten up the appliance of customer with Natural Gas within three months.",
//   sub_cntnt: [{
//     sub_cntnt_nm: ""
//   }],
//   img: "",
//   imgTxt: "",
//   sub_cntnt_two: ""
// }, {
//   s_no: 9,
//   nm: "How Does Gas pipeline reaches to our house.",
//   content: "For various cities GAIL Gas gets the source natural gas through the main transportation pipe and receives it at city gate station and steel grid network is developed. To regularize the pressure of the gas, District Regulatory System is installed. Further a network of MDPE, is developed to reach to different societies and building etc. The regulator, followed by raiser with GI pipeline is installed which is connected to a meter. A copper pipe and further rubber tubing is done to get PNG reach to stoves.",
//   sub_cntnt: [{
//     sub_cntnt_nm: ""
//   }],
//   img: './../../assets/faq/pipe-gas-house.jpeg',
//   imgTxt: "The above diagram/schematic is not applicable for GAIL Gas system as we are following IGL system, hence it may be removed (only diagram,).",
//   sub_cntnt_two: ""
// }, {
//   s_no: 10,
//   nm: "What is the procedure for PNG Domestic Registration?",
//   content: "The Registration can be done in three ways",
//   sub_cntnt: [{
//     sub_cntnt_nm: "1) Online"
//   }, {
//     sub_cntnt_nm: "The prospective customer will apply online through GAIL Gas website as a request for the technical feasibility and will get a unique identification number for his request, once the technical feasibility will be done and connection is feasible, the customer need to register online and submit the required document, DD/ Cheque and connectivity process will start."
//   }],
//   img: './../../assets/faq/pipe-gas-house-10.jpeg',
//   imgTxt: "The above diagram/schematic is not applicable for GAIL Gas system as we are following IGL system, hence it may be removed (only diagram,).",
//   sub_cntnt_two: [{
//     sub_cntnt_nm: "2) Walk Inns/ Through Visit"
//   }, {
//     sub_cntnt_nm: "Customer can visit to our camps, DMA Office as well as the Site office and can get the Printed forms for applying."
//   }, {
//     sub_cntnt_nm: "3) Through Visit of DMA/Company representative"
//   }, {
//     sub_cntnt_nm: "The DMA/ company representative visits the customer house and provide them form(s). The filled forms along with the necessary documents & payment instrument(s) may be provided to that representative."
//   }, {
//     sub_cntnt_nm: "Customer can visit to our camps, DMA Office as well as the Site office and can get the Printed forms for applying."
//   }]
// }, {
//   s_no: 11,
//   nm: "What is DMA?",
//   content: "DMA is a Direct Marketing Agency appointed by GAIL Gas for registration & allied activities of Domestic Piped Natural Gas consumers in its authorized GAs.",
//   sub_cntnt: [{
//     sub_cntnt_nm: ""
//   }],
//   img: "",
//   imgTxt: "",
//   sub_cntnt_two: ""
// }, {
//   s_no: 12,
//   nm: "What is the time line for providing PNG Connection?",
//   content: "Upon the registration GAIL Gas shall put its best efforts to lighten up the appliance of customer with Natural Gas within three months.",
//   sub_cntnt: [{
//     sub_cntnt_nm: ""
//   }],
//   img: "",
//   imgTxt: "",
//   sub_cntnt_two: ""
// }, {
//   s_no: 13,
//   nm: "What is the fee for Registration?",
//   content: "Rs 5800/- is to be given by the customer wherein Rs 300/- is non-refundable application fee, Rs 5000/- is Refundable Security Deposit and Rs 500/- is Payment Security.", sub_cntnt: [{
//     sub_cntnt_nm: "It is to be emphasized that GAIL Gas or any of its authorized representative never accepts any payment (including extra piping or bill payment) through cash. The customer is requested not to pay cash in any circumstances. Further the customer is to be educated to obtain the receipts and preserve the same for ay payment he/she has done to GAIL Gas or its authorized representative."
//   }],
//   img: "",
//   imgTxt: "",
//   sub_cntnt_two: ""
// }, {
//   s_no: 14,
//   nm: "What all documents are required for registration",
//   content: "Submit Registration form along with Proof of Identity (Any one of the following: PAN Card/ Passport/Voter Id Card/ Driving License/Ration Card/ Aadhar Card) and Proof of ownership of the establishment (Copy of Sale Deed Agreement/Rent Agreement/Electricity bill/BSNL Telephone bill/Society Certificate etc.)"
//   , sub_cntnt: [{
//     sub_cntnt_nm: ""
//   }],
//   img: "",
//   imgTxt: "",
//   sub_cntnt_two: ""
// }, {
//   s_no: 15,
//   nm: "Is Piped Natural Gas, as a domestic fuel, better than LPG?",
//   content: "Yes, Domestic PNG is a safe, continues supply, economical, less cost of maintenance, no hassle for booking, storage space and underweight cylinders, payment as per the usage, environment friendly and convenient fuel.",
//   sub_cntnt: [{
//     sub_cntnt_nm: "No advance payment for consumption of gas, billing will be done once in two months based on consumption"
//   }],
//   img: "",
//   imgTxt: "",
//   sub_cntnt_two: ""
// }, {
//   s_no: 16,
//   nm: "Is PNG required as fuel for Industries as well?",
//   content: "Yes, Industries with the requirement less than 50,000 SCM per day can be catered for supplying fuel under City Gas Distribution.",
//   sub_cntnt: [{
//     sub_cntnt_nm: ""
//   }],
//   img: "",
//   imgTxt: "",
//   sub_cntnt_two: ""
// }, {
//   s_no: 17,
//   nm: "What do you mean by Commercial PNG?",
//   content: "Requirement of Fuel for Commercial Units like hotels, restaurants, malls shopping complex etc can be categorized for commercial PNG",
//   sub_cntnt: [{
//     sub_cntnt_nm: ""
//   }],
//   img: "",
//   imgTxt: "",
//   sub_cntnt_two: ""
// }, {
//   s_no: 18,
//   nm: "Abbreviations",
//   content: "", sub_cntnt: [{
//     sub_cntnt_nm: "PNG - Piped Natural Gas"
//   }, {
//     sub_cntnt_nm: "CNG - Compressed Natural Gas"
//   }, {
//     sub_cntnt_nm: "PNGRB - Petroleum and Natural Gas Regulatory Board"
//   }, {
//     sub_cntnt_nm: "CGD - City Gas Distribution"
//   }],
//   img: "",
//   imgTxt: "",
//   sub_cntnt_two: ""
// }, {
//   s_no: 19,
//   nm: "In case of GI/ Copper Pipeline exceeds beyond 15 Meters, the following rates shall be charged for the extra pipe length.",
//   content: "",
//   sub_cntnt: [{
//     sub_cntnt_nm: ""
//   }],
//   img: "",
//   imgTxt: "",
//   sub_cntnt_two: ""
// }, {
//   s_no: 20,
//   nm: "Is it required to change the LPG appliance for switch over to PNG?",
//   content: "", sub_cntnt: [{
//     sub_cntnt_nm: "PNG works on a cooking appliance just like LPG does. It is not required to change the appliance, with minor modifications LPG appliance can be easily converted to PNG."
//   }],
//   img: "",
//   imgTxt: "",
//   sub_cntnt_two: ""
// }, {
//   s_no: 21,
//   nm: "Is the supply of PNG regular?",
//   content: "", sub_cntnt: [{
//     sub_cntnt_nm: "Yes, the supply is absolutely regular. The pipeline distribution network is based on an on-line supply system that consists of safety valves and regulators that control and monitor the gas supply and pressure, and assist in identifying system leaks. Thus an un-interrupted supply at a constant pressure is assured. The gas is available 24x7."
//   }, {
//     sub_cntnt_nm: "Here the example can be given of uninterrupted supply of PNG by respective companies in Mumbai & Surat respectively in 2005 & 2006 even during heavy flood."
//   }],
//   img: "",
//   imgTxt: "",
//   sub_cntnt_two: ""
// }, {
//   s_no: 22,
//   nm: "Is PNG be used for any other purpose apart from cooking?",
//   content: "", sub_cntnt: [{
//     sub_cntnt_nm: "PNG can be used for heating, air conditioning, power generation etc."
//   }],
//   img: "",
//   imgTxt: "",
//   sub_cntnt_two: ""
// }, {
//   s_no: 23,
//   nm: "What becomes of the LPG cylinders after the cooking appliance gets converted?",
//   content: "", sub_cntnt: [{
//     sub_cntnt_nm: "The LPG connection can be surrendered to the respective oil company and security deposit refund obtained"
//   }],
//   img: "",
//   imgTxt: "",
//   sub_cntnt_two: ""
// }, {
//   s_no: 24,
//   nm: "What are the guidelines to surrender LPG",
//   content: "", sub_cntnt: [{
//     sub_cntnt_nm: "Customer may kindly take note of the provision of The Liquefied Petroleum Gas (Regulation of Supply and Distribution) order, 2000 as amended vide notification dated December 16,2013 which has mentioned of supply of LPG under Public Distribution System (PDS) ie. subsidized to a PNG domestic consumer as 'prohibited activity. However, with LPG control order letter dated 09th January 2014 you can retain one LPG connection and the Government oil companies shall provide the refill at the non-subsidized rate."
//   }],
//   img: "",
//   imgTxt: "",
//   sub_cntnt_two: ""
// }, {
//   s_no: 25,
//   nm: "In which other major cities of India Domestic PNG are available?",
//   content: "", sub_cntnt: [{
//     sub_cntnt_nm: "Domestic PNG is available in Delhi, Noida, Ghaziabad, Greater Noida, Mumbai, Thane, Navi Mumbai, Pune, Kanpur, Lucknow, Bareilly, Agra, Firozabad, Meerut, Sonipat, Kota, Dewas, Ahmedabad, Vadodara, Rajkot, Agartala, Surat, Indore, Hyderabad, Ujjain, Vijaywada and Bengaluru etc."
//   }],
//   img: "",
//   imgTxt: "",
//   sub_cntnt_two: ""
// }, {
//   s_no: 26,
//   nm: "What are the benefits of PNG over LPG",
//   content: "", sub_cntnt: [{
//     sub_cntnt_nm: "Customer may kindly take note of the provision of The Liquefied Petroleum Gas (Regulation of Supply and Distribution) order, 2000 as amended vide notification dated December 16,2013 which has mentioned of supply of LPG under Public Distribution System (PDS) ie. subsidized to a PNG domestic consumer as 'prohibited activity. However, with LPG control order letter dated 09th January 2014 you can retain one LPG connection and the Government oil companies shall provide the refill at the non-subsidized rate."
//   }],
//   img: "",
//   imgTxt: "",
//   sub_cntnt_two: ""
// }, {
//   s_no: 27,
//   nm: "What are the after sales services that GAIL Gas offers.",
//   content: "", sub_cntnt: [{
//     sub_cntnt_nm: "In case of GI/ Copper Pipeline exceeds beyond 15 Meters, the following rates shall be charged for the extra pipe length."
//   }],
//   img: "",
//   imgTxt: "",
//   sub_cntnt_two: ""
// }, {
//   s_no: 28,
//   nm: "Do's and Don't for PNG Customer",
//   content: "", sub_cntnt: [{
//     sub_cntnt_nm: ""
//   }],
//   img: "",
//   imgTxt: "",
//   sub_cntnt_two: ""
// }, {
//   s_no: 29,
//   nm: "What are the directives for Emergency?",
//   content: "", sub_cntnt: [{
//     sub_cntnt_nm: "1. Turn off the valve available on gas line near PNG meter & regulator."
//   }, {
//     sub_cntnt_nm: "2. Open all the doors and windows of the area."
//   }, {
//     sub_cntnt_nm: "3. Immediately stop all the fire appliances."
//   }, {
//     sub_cntnt_nm: "4. Do not on/off any electrical appliances."
//   }, {
//     sub_cntnt_nm: "5. Cover the gas leakage point with a wet cloth."
//   }, {
//     sub_cntnt_nm: "6. Do not use open flame."
//   }, {
//     sub_cntnt_nm: "7. Keep away people from the leakage area."
//   }, {
//     sub_cntnt_nm: "8. Do not get panic in case of emergency & stay away from the emergency area."
//   }, {
//     sub_cntnt_nm: "9. Do not spread rumor in case of emergency."
//   }, {
//     sub_cntnt_nm: "10. Immediately inform to GAIL gas regional office regarding the emergency situation."
//   }],
//   img: "",
//   imgTxt: "",
//   sub_cntnt_two: ""
// }, {
//   s_no: 30,
//   nm: "Charged Areas in Different Authorized Cities",
//   content: "", sub_cntnt: [{
//     sub_cntnt_nm: ""
//   }],
//   img: "",
//   imgTxt: "",
//   sub_cntnt_two: ""
// }]