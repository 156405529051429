import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Title, Meta } from '@angular/platform-browser';
import {PdfService} from './../services/pdf.service';


@Component({
  selector: 'app-page-not-found',
  templateUrl: './page-not-found.component.html',
  styleUrls: ['./page-not-found.component.scss']
})
export class PageNotFoundComponent implements OnInit {
  title = 'GAIL Gas Limited - Page Not Found  ';

  language: string;

  constructor(private translateService: TranslateService, private titleService: Title, private metaTagService: Meta,  private PdfService: PdfService) {
    this.language = localStorage.getItem('language');
    console.log(this.language , "Overview com");

    if(this.language == 'od' || this.language == 'kn'){
      this.language = 'en';
    }else {
      this.language = this.language;
    }

    this.translateService.setDefaultLang(this.language);
   }

  ngOnInit(): void {
    this.PdfService.footer.emit();

    this.titleService.setTitle(this.title);
    this.metaTagService.updateTag(
      { name: 'description', content: 'GAIL Gas Limited - India’s Leading CGD Company' }
    );
  }

}
