<header>
    <nav>
        <div class="main-wrapper">
            <div class="brand">
                <a (click)="goHome()">
                    <img src="../../assets/gailgas-logo.png" alt="" style="width: 80px;    cursor: pointer;">
                </a>
            </div>

            <input type="radio" name="menu-slide" id="menu-btn">
            <input type="radio" name="menu-slide" id="close-btn">
            <div class="nav-link">
                <ul class="my-nav">
                    <label for="close-btn" class="btn canecl-btn">&#215;</label>
                    <li><a class="desktop" routerLinkActive="home-active" routerLink="/home" (click)="goHome()">{{'home'
                            | translate}}</a>
                    </li>


                    <li class="language-sm" style="cursor: pointer;">
                        <label for="close-btn" class="cancel-btn listSmallScreen">
                            <div class="search-box">
                                <input class="search-txt" type="text" name="" placeholder="Type to Search">
                                <a class="search-btn" href="#">
                                    <i class="fa fa-search"></i>
                                </a>
                            </div>
                        </label>
                    </li>

                    <!-- Translation -->

                    <li class="language-sm" style="cursor: pointer;">
                        <label for="close-btn" class="cancel-btn listSmallScreen">
                            <img src="../../assets/web-icon.svg" alt="" class="icon"> <span id="applybtn"
                                (click)="switchLanguage(language, 'small')"
                                style="font-family: ApexMedium;">{{languageSm}}</span>
                        </label>
                    </li>

                    <li class="language-sm" style="cursor: pointer;">
                        <label for="close-btn" class="cancel-btn listSmallScreen">
                            <img src="../../assets/call-icon.svg" alt=""><a class="cancel-btn"
                                href="tel:1800 - 102 - 9282"
                                style="display: inline-block;font-size: 18px;padding: 0px !important;">1800 - 102 -
                                9282</a>
                        </label>
                    </li>

                    <li class="down-arrow" *ngFor="let mnus of menuItem; let ind = index; let last = last"
                        [ngClass]="mnus.mnu_id != 6 && mnus.mnu_id != 7 && mnus.mnu_id != 11 && mnus.mnu_id != 12? 'down-arrow' : 'no-down-arrow'">

                        <a routerLinkActive="active" routerLink="{{mnus.url}}" class="desktop"
                            [ngClass]="{'menuActive' : selectedMenu == mnus.mnu_id}"
                            (click)="clearActiveMenu(mnus)">{{mnus.mnu_nm}}
                            <i class="fa fa-caret-down menu-arrow"></i>
                        </a>

                        <input type="checkbox" id="{{mnus.id}}">

                        <label *ngIf="mnus.mnu_id != 6 && mnus.mnu_id != 7 && mnus.mnu_id != 11 && mnus.mnu_id != 12"
                            routerLinkActive="active" routerLink="{{mnus.url}}" for="{{mnus.id}}" class="mega-mobile"
                            [ngClass]="{'menuActive' : selectedMenu == mnus.mnu_id}">{{mnus.mnu_nm}}
                            <i class="fa fa-caret-down menu-arrow"> </i>
                        </label>


                        <div *ngIf="mnus.mnu_id == 6 || mnus.mnu_id == 7 || mnus.mnu_id == 11 || mnus.mnu_id == 12">

                            <label routerLinkActive="active" routerLink="{{mnus.url}}" class="mega-mobile cancel-btn"
                                [ngClass]="{'menuActive' : selectedMenu == mnus.mnu_id}" for="close-btn">{{mnus.mnu_nm}}
                            </label>

                        </div>

                        <div class="mega-menu"
                            [ngClass]="{'mega-menu-close': (removeClass == true && screenWidth > 769)}"
                            *ngIf="mnus.mnu_id != 6 && mnus.mnu_id != 7 && mnus.mnu_id != 11 && mnus.mnu_id != 12">
                            <div class="" [ngClass]="mnus.mnu_id != 3 ? 'menu-inner' : 'operation-inner'">
                                <div class="inner-main side_separator">
                                    <h1>{{mnus.mnu_nm}}</h1>
                                    <img src="{{mnus.mnu_logo}}">
                                    <!-- <img src="{{mnus.mnu_logo1}}" style="width:150px;height: 106px;"> -->
                                </div>

                                <div class="inner-main">
                                    <ul class="mega-links">
                                        <li class="nav-item" *ngFor="let subMnu of mnus.subMenu;">
                                            <a routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}"
                                                (click)="gotoNavMenu(mnus,subMnu, $event)" class="listBigScreen"
                                                [ngClass]="{'subMenuActive' : selectedSubMenu == subMnu.sub_mnu_id && selectedMenu == mnus.mnu_id}">{{subMnu.sub_mnu_nm}}</a>

                                            <label (click)="gotoNavMenu(mnus,subMnu, $event)" for="close-btn"
                                                class="cancel-btn listSmallScreen"
                                                [ngClass]="{'subMenuActive' : selectedSubMenu == subMnu.sub_mnu_id && selectedMenu == mnus.mnu_id}">
                                                {{subMnu.sub_mnu_nm}}
                                            </label>
                                        </li>

                                        <!-- <div class="mega-menu-sub" style="background-color: #f4f4f9;"
                                                [ngClass]="{'mega-menu-close': (removeClass == true && screenWidth > 769)}">

                                                <div class="inner-main">
                                                    <ul class="mega-links">
                                                        <li class="nav-item" *ngFor="let subMnu of mnus.subSubMenu">
                                                            <a routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}"
                                                                (click)="gotoNavMenu(mnus,subMnu, $event)" class="listBigScreen"
                                                                [ngClass]="{'subMenuActive' : selectedSubMenu == subMnu.sub_mnu_id && selectedMenu == mnus.mnu_id}">{{subMnu.sub_mnu_nm}}</a>
                                                            
                                                            <label (click)="gotoNavMenu(mnus,subMnu, $event)" for="close-btn"
                                                                class="cancel-btn listSmallScreen"
                                                                [ngClass]="{'subMenuActive' : selectedSubMenu == subMnu.sub_mnu_id && selectedMenu == mnus.mnu_id}">
                                                                {{subMnu.sub_mnu_nm}}
                                                            </label>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div> -->
                                    </ul>
                                </div>

                                <div class="inner-main">
                                    <ul class="mega-links">
                                        <li routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}"
                                            class="nav-item" *ngFor="let subMnu of mnus.subMenu1;">
                                            <a (click)="gotoNavMenu(mnus,subMnu, $event)" class="listBigScreen"
                                                routerLinkActive="active"
                                                [ngClass]="{'subMenuActive' : selectedSubMenu == subMnu.sub_mnu_id && selectedMenu == mnus.mnu_id}">{{subMnu.sub_mnu_nm}}</a>

                                            <label (click)="gotoNavMenu(mnus,subMnu, $event)" for="close-btn"
                                                class="cancel-btn listSmallScreen"
                                                [ngClass]="{'subMenuActive' : selectedSubMenu == subMnu.sub_mnu_id && selectedMenu == mnus.mnu_id}">
                                                {{subMnu.sub_mnu_nm}}
                                            </label>
                                        </li>
                                    </ul>
                                </div>

                            </div>
                        </div>
                    </li>

                </ul>
            </div>
            <label for="menu-btn" class="btn bar-btn">&#8801;</label>

            <div class="sl-nav">
                <ul>
                    <li><img src="../../assets/globe.svg" alt="" style="width:25px;padding: 1px;"> {{'language' |
                        translate}}
                        <div class="triangle"></div>
                        <ul *ngIf="language">
                            <li (click)="switchLanguage('hn','large')" *ngIf="language != 'hn'">
                                <i class="sl-flag flag-ind">
                                    <div id="germany"></div>
                                </i> <span class="active">हिन्दी</span>
                            </li>

                            <li (click)="switchLanguage('en','large')"
                                *ngIf="language != 'en' && englishLangEnable === true"><i class="sl-flag flag-usa">
                                    <div id="germany"></div>
                                </i> <span>English</span></li>

                            <li (click)="switchLanguage('od','large')"
                                *ngIf="language != 'od' && odiyaLangEnable === true">

                                <i class="sl-flag flag-usa">
                                    <div id="germany"></div>
                                </i> <span>ଓଡିଆ</span>
                            </li>

                            <li (click)="switchLanguage('kn','large')"
                                *ngIf="language != 'kn' && kanadaLangEnable === true"><i class="sl-flag flag-usa">
                                    <div id="germany"></div>
                                </i> <span>ಕನ್ನಡ</span></li>
                        </ul>
                    </li>
                </ul>
            </div>
            <p class="name"></p>

            <div class="blink" (click)="Number()">
                <fieldset>
                    <img src="../../assets/telephone.svg" alt=""
                        style="width:25px;transform: rotate(93deg);padding: 3px;" alt="">
                    <span style="font-size: 18px; font-family: 'ApexBook';">1800 - 102 - 9282</span>
                </fieldset>
            </div>

            <!-- <div class="blink_me">
                <img src="../../assets/telephone.svg" alt=""
                    style="width:25px;transform: rotate(93deg);padding: 3px;"><a href="tel:1800 - 102 - 9282"
                    style="font-size: 18px; font-family: 'ApexBook';color: blue;">1800 - 102 - 9282</a>
            </div> -->

        </div>
    </nav>
</header>