<br><br><br><br>
<div class="container-fluid" *ngIf="schemeData">
    <div>
        <img src="../../assets/promotionpdf/promotionalBanner.jpeg" alt="Image description"
            style="width: 100%; height: 100%; object-fit: contain;">
    </div>

    <div class="row mt-3">
        <div class="col-md-1"></div>
        <div class="col-md-10">
            <div>
                <h3 class="overview-heading">
                    <img src="../../../assets/gileModule/overview/overview-leave.svg" class="leave-style">
                    {{schemeData['pngTitle'] | translate}}
                </h3>
                <p class="txt">
                    {{schemeData['pngtxt'] | translate}}
                </p>
            </div>


            <div class="row">

                <div class="col-md-12">
                    <ul *ngIf="language == 'en'" class="listMarginTop">
                        <li> Ganjam, Nayagarh & Puri districts (Odisha)</li>
                        <li>Sundergarh & Jharsuguda districts (Odisha)</li>
                        <li> Giridih & Dhanbad districts (Jharkhand)</li>
                        <li>Seraikela & Kharaswan district (Jharkhand) & West Singhbhum district (Jharkhand)</li>

                    </ul>


                </div>

                <div class="col-md-2"></div>
            </div>

        </div>

    </div>

    <!-- Week Cards -->
    <div class="row ">
        <div class="col-md-1"></div>
        <div class="col-md-10">
            <!-- <div class="d-flex">
                <span class="spantxt" *ngIf="backweek" (click)="weekback()">{{Week_Name}}</span>
                <span class="spantxt" style="margin-left: 8px;" *ngIf="backlocate" (click)="locateback()"> /
                    back to locations</span>
            </div> -->

            <h2 *ngIf="Title" class="overview-heading mt-2"><img
                    src="../../../assets/gileModule/overview/overview-leave.svg" class="leave-style">&nbsp;
                {{schemeData['pngTitle2'] | translate}} </h2>

            <div *ngIf="ModalBoolean">
                <h2 class="overview-heading mt-2"><img src="../../../assets/gileModule/overview/overview-leave.svg"
                        class="leave-style">Glimpse of the Event {{Week_Name}} in {{ModalName}} </h2>
            </div>

            <div class="row">
                <div class="col-md-2"></div>
                <div class="col-md-12">
                    <ul *ngIf="language == 'en'">
                        <li>Eligibility: Purchase of NEW CNG vehicles (3W /4W) and registration of vehicle in listed
                            RTOs.</li>
                        <li>Offer will be applicable on first come first serve basis.</li>
                        <li>Fuel Card Value:</li>


                    </ul>


                </div>
                <div class="col-md-12 mb-3">
                    <table class="fuelDetails">
                        <tr>
                            <th>S/N</th>
                            <th>Particulars</th>
                            <th>No Of Cards </th>
                            <th>Promotional Scheme</th>
                        </tr>
                        <tr>
                            <td>1</td>
                            <td class="paddZero">
                                <table class="tabble tabBorderBottom tabBorderTop">
                                    <tr>
                                        <td class="widthFirst">(Category-A)</td>
                                        <td class="widthSecond">New Vehicle</td>
                                        <td class="widthThird">3 W Autos</td>
                                    </tr>
                                </table>
                            </td>
                            <td>400

                                (100 for Each GA)</td>
                            <td>
                                CNG Fuel card of <b> Rs 15,000/-</b>
                            </td>
                        </tr>
                        <tr>
                            <td>2</td>
                            <td class="paddZero">
                                <table class="tabble tabBorderBottom tabBorderTop">
                                    <tr>
                                        <td class="widthFirst">(Category-B)

                                        </td>
                                        <td class="widthSecond">New Vehicle</td>
                                        <td class="widthThird">4W Car/Taxi/SMLC4W Car/Taxi/SMLC</td>
                                    </tr>
                                </table>
                            </td>
                            <td>400

                                (100 for Each GA)</td>
                            <td>
                                CNG Fuel card of <b> Rs 25,000/ </b>
                            </td>
                        </tr>

                    </table>

                </div>
                <div class="col-md-12">

                    <p class="offerPara mb-3 paddLeft25">
                        Incentive amount in each category to be provided through sale of CNG from GAIL Gas CNG Stations
                        only with following limits from the date of issuance of Incentive amount.:
                    </p>
                    <table class="fuelDetails mb-3">
                        <tr>
                            <th>
                                Category</th>
                            <th>Usage Limit (Rs)</th>
                            <th>Time-Limit</th>

                        </tr>
                        <tr>
                            <td>3 W Autos</td>

                            <td>Rs. 250/- per day</td>
                            <td>

                                Within 180 days
                            </td>
                        </tr>

                        <tr>
                            <td>4 W Cars/SMLC</td>

                            <td>Rs. 400/- per day</td>
                            <td>

                                Within 180 days
                            </td>
                        </tr>


                    </table>
                </div>

                <div class="col-md-2"></div>
            </div>
            <div class="col-md-12">
                <p class="offerPara paddLeft20">
                    This CNG promotional offer is designed to enhance your driving experience while contributing to a
                    cleaner environment. Enjoy the benefits of using CNG fuel, known for its affordability and lower
                    emissions compared to traditional fuels.
                </p>
            </div>
            <div class="col-md-12">
                <h2 *ngIf="Title" class="overview-heading mt-2"><img
                        src="../../../assets/gileModule/overview/overview-leave.svg" class="leave-style">&nbsp;
                    {{schemeData['pngTitle3'] | translate}} </h2>


            </div>

            <div class="col-md-12">
                <ul class="lsitBlc">
                    <li>1. Purchase a new CNG vehicle from registered dealers.</li>
                    <li>2. After successful verification of documents, CNG fuel card with preloaded amount will be
                        issued through OEM dealer.</li>


                </ul>



            </div>
            <div class="col-md-12">
                <p><b> Don't miss out on this opportunity to save on fuel costs while making a positive impact on the
                        environment! Thank you for choosing CNG!</b></p>

                <p> For more information, please contact our customer service team <b>1800-102-9282</b> or visit our
                    nearest GAIL GAS office or email to: <a href="mailto:cngmarketing_gglNoid@gail.co.in"
                        target="_blank">cngmarketing_gglnoid@gail.co.in </a> </p>
                <p class="terms-list">
                    <a href="../../assets/promotionpdf/TERMSandCONDITIONS.pdf" target="_blank">Terms & Conditions </a>
                    <br />
                    <a href="../../assets/promotionpdf/ListofRTOoffices.pdf" target="_blank"> List of RTO Office </a>
                </p>



            </div>



            <!-- 
            <div class="d-flex">
                <span class="spantxt" *ngIf="backweek" (click)="weekback()">{{Week_Name}}</span>
                <span class="spantxt" style="margin-left: 8px;" *ngIf="backlocate" (click)="locateback()"> /
                    back to locations</span>
            </div>
            <div class="row">
             
                <div [hidden]="WeekCard" class="col-md-4" *ngFor="let campaign of GetDatajson"
                    (click)="FirstWek(campaign.week,campaign)" style="padding: 2%;">
                    <div class="row" style="border: 1px solid #0093d0;">
                        <ng-container *ngFor="let ele of campaign.week; let i=index">
                            <ng-container *ngIf="i < 4 && !ele.url.endsWith('.mp4')">
                                <div class="col-md-6" style="padding:0px;">
                                    <img [src]="ele.url" alt="" class="imgCardthumb">
                                </div>
                            </ng-container>
                        </ng-container>
                    </div>
                    <span class="Txt">
                        {{ campaign.name }}<br>
                    </span>
                    <span class="Txt">
                        {{campaign.date}}
                    </span>
                </div>
           
                <div class="col-md-3" [hidden]="LocationCard" *ngFor="let campaign of LocationImgList"
                    style="padding:2% 0%;" (click)="Location(weekList, campaign[0].location,'NavigateModal')">
             
                    <div *ngFor="let ele of campaign; let i=index" style="cursor: pointer;" data-aos="fade-up"
                        data-aos-once="true" data-aos-easing="linear" data-aos-duration="1500">
                        <ng-container *ngIf="i ==0 ">
                            <span style="font-weight: bold;">
                                <img *ngIf="!isDuplicateLocation(ele.location, i, campaign)"
                                    style="width: 100%; height: 100%;" [src]="ele.location_image" alt="">
                            </span>
                            <br>
                            <span style="font-weight: bold;">
                                <div class="row" style="padding: 0px 85px;">
                                    <div class="col-md-3"></div>
                                    <div class="col-md-">
                                        {{ isDuplicateLocation(ele.location, i, campaign) ? '' : ele.location }}
                                    </div>
                                    <div class="col-md-3"></div>
                                </div>
                            </span>
                        </ng-container>
                    </div>
                </div>
            </div> -->
        </div>
        <div class="col-md-1"></div>
    </div>

    <!-- Display Images -->
    <div class="row mt-5 mb-4" id="NavigateModal" *ngIf="ModalBoolean">
        <div class="col-md-1"></div>
        <div class="col-md-10">
            <!-- <div>
                <h2 class="overview-heading mt-2"><img src="../../../assets/gileModule/overview/overview-leave.svg"
                        class="leave-style">Glimpse of the Event {{Week_Name}} in {{ModalName}} </h2>
            </div> -->
            <div class="row">
                <div class="col-md-4 mb-5" *ngFor="let ele of ImagesList_data;let i = index" style="padding: 3%;">
                    <img *ngIf="!ele.url.endsWith('.mp4')" [src]="ele.url" alt="" class="imgCard1"
                        (click)="Modal(ele, ImagesList_data)">
                    <video #videoPlayer (play)="handlePlay($event.target)" controls *ngIf="ele.url.endsWith('.mp4')"
                        width="100%" height="250px">
                        <source src="{{ele.url}}" type="video/mp4" />
                    </video>
                    <br>
                </div>
            </div>
        </div>
        <div class="col-md-1"></div>
    </div>

    <!-- Modal -->
    <div>
        <div class="modal fade" id="staticBackdrop" data-backdrop="static" data-keyboard="false" tabindex="-1"
            aria-labelledby="staticBackdropLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered modal-lg">
                <div class="modal-content">
                    <div class="modal-header">
                        <!-- <span><img src="../../assets/news-media/downoad-arrow.svg" alt="" style="cursor: pointer;"
                        (click)="ImgDown()"></span> -->
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div id="carouselExampleControlsNoTouching" class="carousel slide" data-touch="false"
                            data-interval="false">
                            <div class="carousel-inner" *ngIf="ImageList && ImageList.length > 0">
                                <div class="carousel-item" *ngFor="let image of ImageList; let i = index"
                                    [class.active]="i === clickedImageIndex">
                                    <img *ngIf="!image.url.endsWith('.mp4')" [src]="image.url" alt="" class="imgCard2">
                                    <video *ngIf="image.url.endsWith('.mp4')" controls width="100%" height="250px">
                                        <source src="{{image.url}}" type="video/mp4" />
                                    </video>
                                </div>
                            </div>

                            <button class="carousel-control-prev" type="button"
                                data-target="#carouselExampleControlsNoTouching" data-slide="prev">
                                <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                                <span class="sr-only">Previous</span>
                            </button>
                            <button class="carousel-control-next" type="button"
                                data-target="#carouselExampleControlsNoTouching" data-slide="next">
                                <span class="carousel-control-next-icon" aria-hidden="true"></span>
                                <span class="sr-only">Next</span>
                            </button>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>


</div>