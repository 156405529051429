<!-- CNG header Images -->
<div id="cng" style="padding-top: 5rem;">
    <div class="hero-image"[ngStyle]="{'background-image': 'url(' + Overviewbg + ')'}">
    </div>
</div>


<div style="background: transparent;">

    <!-- <div fxLayout.xs="column" fxFlexFill>
        <div fxFlex="100" class="location-main">
            <div fxFlexOrder="1" fxLayout="row" fxLayout.xs="column">
                <div class="container-fluid" style="background: transparent;">
                    <div class="container" style="background: transparent;">
                        <p class="location-text">
                            <a class="location-home">
                                Home</a> >
                            <span class="breadcrumb-title">{{"rti-bradcrumbs" | translate}}</span>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div> -->


    <div class="container-fluid" style="padding-bottom: 50px;">

        <!-- <div class="row" style="padding-bottom: 30px;">

            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-12 mt-5">

                <div class="project-main">
                    <img src="../../../assets/operationModule/dewas/dewas-leaf.svg"> {{"rti-title" | translate}} {{"corporate-title" | translate}}
                </div>
                <br>
                <div class="project-main">

                </div>

            </div>

        </div> -->





        <div class="row">
            <div class="col-md-1"></div>
            <div class="col-md-10">

                <!-- Table content  -->

                <div class="container-fluid" style="background: transparent;">
                    <div class="container-fluid" style="background: transparent;">
                        <p class="location-text">
                            <span class="location-home">
                                Home</span> >
                            <span class="breadcrumb-title">{{"rti-bradcrumbs" | translate}}</span>
                        </p>
                    </div>
                </div>


                <div class="column" fxLayout="row" fxLayout.xs="column" fxFlexFill style="padding-bottom: 30px;">

                    <div fxFlex="100" fxFlex.xs="100" class="card-content-mb-comparison" style="background: #f4f4f9;">
                        <div class="project-main">
                            <img src="../../../assets/operationModule/dewas/dewas-leaf.svg"> {{"rti-title" | translate}}
                            {{"corporate-title" | translate}}
                        </div>
                        <div class="column" fxLayout="row" fxLayout.xs="column" fxFlexFill class="outlet-box"
                            style="overflow-x:auto;">
                            <div *ngIf="dataSource">
                                <table class='table-responsive mat-elevation-z8 outer-table' mat-table
                                    [dataSource]="dataSource" matSort>

                                    <ng-container matColumnDef="designation">
                                        <th mat-header-cell *matHeaderCellDef class="tableheader"> {{'corporateTabHead1'
                                            |
                                            translate}} </th>
                                        <td mat-cell *matCellDef="let element;let even = even;let odd = odd"
                                             style="vertical-align: top;">
                                            {{element.designation}} </td>
                                    </ng-container>

                                    <ng-container matColumnDef="rtiCentral">
                                        <th mat-header-cell *matHeaderCellDef class="tableheader"> {{'corporateTabHead2'
                                            |
                                            translate}} </th>
                                        <td mat-cell *matCellDef="let detail;let even = even;let odd = odd"
                                             class="notice-display"
                                            style="vertical-align: top;">
                                            <div *ngFor="let file of detail.rtiCentral" style="padding: 10px;">
                                                <table class="inner-table">
                                                    <tr >
                                                        <td style="vertical-align: top;border: none !important;">{{file.name}}</td>
                                                    </tr>
                                                </table>
                                               
                                            </div>
                                        </td>
                                    </ng-container>

                                    <ng-container matColumnDef="rtiCentral3">
                                        <th mat-header-cell *matHeaderCellDef class="tableheader"> {{'corporateTabHead5'
                                            |
                                            translate}} </th>
                                        <td mat-cell *matCellDef="let detail;let even = even;let odd = odd"
                                             class="notice-display"
                                            style="vertical-align: top;">
                                            <div *ngFor="let file of detail.rtiCentral" style="padding: 10px;">
                                                <table class="inner-table">
                                                    <tr>
                                                        <td style="vertical-align: top;border: none !important;">{{file.matter}}</td>
                                                    </tr>
                                                </table>
                                               
                                            </div>
                                        </td>
                                    </ng-container>

                                    <ng-container matColumnDef="rtiCentral1">
                                        <th mat-header-cell *matHeaderCellDef class="tableheader"> {{'corporateTabHead3'
                                            |
                                            translate}} </th>
                                        <td mat-cell *matCellDef="let detail;let even = even;let odd = odd"
                                             class="notice-display" style="vertical-align: top;">
                                            <div *ngFor="let file of detail.rtiCentral;" >
                                                <table class="inner-table">
                                                    <tr >
                                                        <td style="vertical-align: top;border: none !important;">{{file.address}}</td>
                                                    </tr>
                                                </table>
                                            
                                            </div>
                                        </td>
                                    </ng-container>

                                    <ng-container matColumnDef="rtiCentral2">
                                        <th mat-header-cell *matHeaderCellDef class="tableheader"> {{'corporateTabHead4'
                                            |
                                            translate}} </th>
                                        <td mat-cell *matCellDef="let detail;let even = even;let odd = odd"
                                             class="notice-display"
                                            style="vertical-align: top;">
                                            <div *ngFor="let file of detail.rtiCentral" style="padding-left: 10px;">
                                                <table class="inner-table">
                                                    <tr >
                                                        <td style="vertical-align: top; border: none !important;"> <a
                                                                href="mailto: {{file.email}}">
                                                                {{file.email}}</a> </td>
                                                    </tr>
                                                </table>
                                            </div>
                                        </td>
                                    </ng-container>

                                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                                </table>
                                <!-- <mat-paginator #paginator [pageSize]="5" [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator> -->
                            </div>

                            <!-- <table>
                                <tr class="table-header" style="text-align: left;">
                                    <th> {{'corporateTabHead1' | translate}} </th>
                                    <th> {{'corporateTabHead2' | translate}} </th>
                                    <th> {{'corporateTabHead3' | translate}} </th>
                                    <th> {{'corporateTabHead4' | translate}} </th>
                                </tr>
                                <tr *ngFor="let data of table1" class="table-data">
                                    <td> {{data.designation}} </td>
                                    <td> {{data.name}}</td>
                                    <td> {{data.address}} </td>
                                    <td> <a href="mailto: {{data.email}}"> {{data.email}}</a> </td>
                                </tr>
                            </table> -->
                        </div>



                    </div>
                </div>
                <!-- Table ends here  -->

            </div>
            <div class="col-md-1"></div>
        </div>



        <br>
        <!-- 2. Connection Installation -->

        <br>

        <div class="row mt-5">
            <div class="col-md-1"></div>
            <div class="col-md-10">

                <div class="column" fxLayout="row" fxLayout.xs="column" fxFlexFill
                    style="padding-bottom: 30px;background: #f4f4f9;">

                    <div fxFlex="100" fxFlex.xs="100" class="card-content-mb-comparison">

                        <div class="project-main">
                            <img src="../../../assets/operationModule/dewas/dewas-leaf.svg">{{'regional-title' |
                            translate}}
                        </div>

                        <div class="column" fxLayout="row" fxLayout.xs="column" fxFlexFill class="outlet-box"
                            style="overflow-x:auto;">

                            <table>
                                <tr class="table-header" style="text-align: left;" style="background-color: #068DA9;">
                                    <th class="colorsection"> {{'regionalTabHead1' | translate}} </th>
                                    <th class="colorsection"> {{'regionalTabHead2' | translate}} </th>
                                    <th class="colorsection"> {{'regionalTabHead3' | translate}} </th>
                                    <th class="colorsection"> {{'regionalTabHead4' | translate}} </th>
                                    <th class="colorsection"> {{'regionalTabHead5' | translate}} </th>
                                </tr>
                                <tr *ngFor="let data of table2;let i = index" [style.background-color]="data.color" class="table-data">

                                    <td> {{data.office}} </td>
                                    <td> {{data.designation}} </td>
                                    <td> {{data.name}} </td>
                                    <td> {{data.address}} </td>
                                    <td> <a href="mailto: {{data.email}}"> {{data.email}}</a> </td>
                                </tr>
                            </table>
                        </div>



                    </div>
                </div>



            </div>
            <div class="col-md-1"></div>
        </div>




        <!-- Connection Installation Ends here -->


    </div>


</div>