import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
// import { NgxSpinnerService } from 'ngx-spinner';

import { ChangeDetectionStrategy } from '@angular/compiler/src/core';
import { OperationService } from 'src/app/services/operation.service';


declare var $: any;
export interface trackdata {
  ComplaintID: string,
  Date: string,
  View: string,
  Status: string
}

@Component({
  selector: 'app-complaints-forms',
  templateUrl: './complaints-forms.component.html',
  styleUrls: ['./complaints-forms.component.scss']
})
export class ComplaintsFormsComponent implements OnInit {

  @ViewChild("trackpagenatore", { read: MatPaginator }) trackpagenatore: MatPaginator;
  @ViewChild("EmpaneledSort", { read: MatSort, static: true }) sortList5: MatSort;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  complaintform1: FormGroup;

  // public fmdata : jsonfmdata
  submitted: boolean = false;
  complaintsrc: string;
  complaintform2: FormGroup;
  complaintform3: FormGroup;
  camphtxt: any;

  displayedColumns: string[] = ['Sn', 'complaintid', 'Date', 'View', 'Status'];
  dataSource = new MatTableDataSource();
  displayedColumnsNew = this.displayedColumns;
  vendorCodeField: any;
  vendornumber: any;
  vendorcode: any;
  po_options: any;
  grievancedrop: any;
  selectedOption: any;
  cust_type: any;
  areaofnature: any;
  genotp: any;
  selectedPOValue: any = null;
  selectedarea: any = null;
  selectednature: any = null;
  fileSizeError: boolean = false;
  selectedcustype: any = null;
  selectestate: any = ''
  selectecuntry: string = 'India';
  getfile: File;
  shot_nm: any;
  location: any;
  formid: string;
  newid: number;
  receivedData: any;
  veiwcarddata: any;
  dataAvailable: boolean;
  ifsuccesssumbit: boolean;
  successmsg: any;
  ponum: any;
  identitikey: any;
  viewcmp: any;
  imgurl: any;
  apiUrl: string;
  Stateoption: string;
  showlocation: any;
  vieremarks: any;
  newtbldata: any;
  nocomplaints: boolean;
  trackpaylnum: string;
  tabledata: any;
  timerInterval: any;
  timer: number = 120;
  resendDisabled: boolean = true;
  buttonDisabled: boolean = false;
  cuntryoption: any;
  cuntryname: any;
  cuntrycode: any;
  cuntrycodeinit: void;
  fileurl: any;
  cmptypeforbtn: boolean = false;
  setmand: boolean = true;
  oic_user_id :any[] =[];
  vigilaceimgrul: string;
  Overviewbg: string;
  Vigilancechar: string;
  EnterOtp_Send: boolean = false;


  constructor(private route: ActivatedRoute, public fb: FormBuilder, public router: Router, private service: OperationService, public bm: ChangeDetectorRef, public toastr: ToastrService,) {

    this.apiUrl = this.service.url

    this.complaintform1 = this.fb.group({
      Vendorcode: ['', Validators.required],
      VendorName: ['', Validators.required],
      PONumber: [null, []],
      phone: ['', [Validators.required]],
      AreaofOp: ['', Validators.required],
      city_nmshow: ['', []],
      NofComplaint: ['', Validators.required],
      PreAward: ['', [Validators.required]],
      Email: ['', [Validators.required, this.emailValidator]],
      Upload: [null, []],
      Details: ['', [Validators.required, this.details]],
      MobileNumber: ['', [Validators.required, this.phonevalidation]],
      Otp: ['', Validators.required]
    });

    this.complaintform2 = this.fb.group({
      CustomerName: ['', Validators.required],
      CustomerType: ['', Validators.required],
      BP_Number: ['' , Validators.required],
      AreaofOp: ['', [Validators.required]],
      city_nmshow: ['', []],
      Email: ['', [Validators.required, this.emailValidator]],
      Upload: [null, []],
      Details: ['', [Validators.required, this.details]],
      MobileNumber: ['', [Validators.required, this.phonevalidation]],
      Otp: ['', Validators.required]
    });
    // const pincodePattern = /^[0-9_-]{6}$/

    this.complaintform3 = this.fb.group({
      EnterName: ['', Validators.required],
      EnterHouseNo: ['', [Validators.required]],
      BuildingStreet: ['', Validators.required],
      Area: ['', [Validators.required]],
      City: ['', Validators.required],
      State: ['', Validators.required],
      PinCode: ['', [Validators.required, this.pinvalidation]],
      Country: ['', Validators.required],
      Subject: ['', Validators.required],
      Email: ['', [Validators.required, this.emailValidator]],
      Upload: [null, []],
      Details: ['', [Validators.required, this.details]],
      MobileNumber: ['', [Validators.required, this.phonevalidation]],
      Otp: ['', Validators.required]
    });
  }


  ngAfterViewInit() {

    this.dataSource.paginator = this.trackpagenatore;

    if (this.complaintsrc === '3') {
      this.grievancearea()
      this.bm.detectChanges
      console.log("formid get")
    };

    if (this.complaintsrc === '4') {
      this.countrylst();
      this.statelstfm3()
      console.log(this.cuntrycodeinit)

      this.bm.detectChanges()
    }
  }


  ngOnInit(): void {

        
    this.complaintsrc = localStorage.getItem("porceedid");
    this.newid = parseInt(this.complaintsrc);



    const currentTime = Date.now();

    const trackcred = localStorage.getItem('trackcred')
    this.trackpaylnum = JSON.parse(trackcred)


    if (trackcred) {
      const trackcredTimestamp = parseInt(localStorage.getItem('trackcredTimestamp'));
      if (currentTime - trackcredTimestamp >= 30 * 60 * 1000) {
        localStorage.removeItem('trackcred');
        localStorage.removeItem('trackcredTimestamp');
      }
    }


    this.service.data$.subscribe((data) => {
      this.receivedData = data;
    });
    this.dataSource = new MatTableDataSource(this.receivedData)
    this.dataSource.paginator = this.trackpagenatore;
    this.dataSource.sort = this.sortList5;

    if (this.complaintsrc == '5') {
      this.loaderOn()
      if (this.trackpaylnum) {
        const obj = {
          "mobile_no": this.trackpaylnum
        }
        this.service.track_complaint(obj).subscribe((res: any) => {
          if (res.code == 200) {
            this.bm.detectChanges()
            if (res.data != "No Record Found") {
              this.nocomplaints = false
              this.tabledata = res.data
              console.log(this.tabledata , "apidata")
              this.bm.detectChanges()
              this.dataSource = new MatTableDataSource(this.tabledata)
              this.dataSource.paginator = this.trackpagenatore;
              this.dataSource.sort = this.sortList5;
              this.loaderOff()
            } else {
              this.nocomplaints = true
              this.bm.detectChanges()
            }
          }
        })
      } else {
        this.router.navigate(['/onlinecomplaint']);
      }
    }

    this.comph()

    /////// customer component part//////// 
    this.customer_type()
    this.grevencecomplnature()



    const lang = localStorage.getItem('language')
    console.log(this.complaintsrc)
   if(this.complaintsrc =='4'){
    if(lang == 'en'){
      this.Overviewbg = "../../../assets/submitForm/Vigilancebg.jpg"
      this.Vigilancechar = "../../../assets/submitForm/vigileancechart.jpg"
    }else{
      this.Overviewbg = "../../../assets/submitForm/vigilantbg.jpg"
      this.Vigilancechar = "../../../assets/submitForm/vigileancechart.jpg"
    }
   }else{
    if(lang == 'en'){
      this.Overviewbg = "../../../assets/submitForm/Griveancebg.jpg"
      this.Vigilancechar = "../../../assets/submitForm/customer.svg"
    }else{
      this.Overviewbg = "../../../assets/submitForm/grievancehindi.jpg"
      this.Vigilancechar = "../../../assets/submitForm/customer.svg"
    }
   }
   
  }


  reloadCurrentRoute() {
    let currentUrl = this.router.url;
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.router.navigate([currentUrl]);
    });
  }

  getpodorpdown() {
    const obj = {
      "vendorcode": this.vendorcode
    }
    this.loaderOn()
    this.service.grievancePoCheck(obj).subscribe((res: any) => {
      if (res.code == 200) {
        this.po_options = res.data
        this.bm.detectChanges()
        this.loaderOff()
      } else {
        this.selectedPOValue = null
      }
    })
  }

  grievancearea() {
    const obj = {
      "location_id": 0
    }
    this.loaderOn()
    this.service.grievance_area(obj).subscribe((res: any) => {
      if (res.code == 200) {
        this.grievancedrop = res.data
        console.log(this.grievancedrop)
        this.bm.detectChanges()
        this.loaderOff()
        console.log(this.grievancedrop)
      } else {
        return this.toastr.error(res.message)
      }
    })
  }

  comph() {
    switch (this.complaintsrc) {
      case "2":
        this.camphtxt = this.camph[0]
        this.genotp = ''
        break;
      case "3":
        this.camphtxt = this.camph[1]
        this.genotp = ''
        break;
      case "4":
        this.camphtxt = this.camph[2]
        this.genotp = ''
        break;
      case "5":
        this.camphtxt = this.camph[3]
        this.genotp = ''
        break;
      default:
    }
  }
  camph = ["Vendor Complaints", "Customer Complaints", "Vigilance Complaints", "Track Complaint"]

  emailValidator(control) {
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    if (!emailPattern.test(control.value)) {
      return { invalidEmail: true };
    }
    return null;
  }


  // pintcode(control) {
  //   const pintcode = /^[0-9-]{6,8}$/;
  //   if (!pintcode.test(control.value)) {
  //     return { invalidEmail: true };
  //   }
  //   return null;
  // }
  phonevalidation(control) {
    const phonePattern = /([+]?\d{1,3}[.-\s]?)?(\d{3}[.-]?){2}\d{4}/g;
    if (!phonePattern.test(control.value)) {
      return { invalidphone: true };
    }
    return null;

  }

  details(control) {
    const details = /^[,\-_\.A-Za-z0-9\s]{1,1000}$/
    if (!details.test(control.value)) {
      return { invalidDetails: true };
    }
    return null;
  }

  keydownnum(event) {
    (event.target.value.length <= 10) ? $('.mob').css('border', '1px solid red') : $('.mob').css('border', '2px solid lightgrey');
  }

  keyPressNumbers(event) {
    event.target.value.length < 9 ? $('.mob').css('border', '1px solid red') : $('.mob').css('border', '2px solid lightgrey');

    var charCode = (event.which) ? event.which : event.keyCode;
    if ((charCode < 48 || charCode > 57)) {
      event.preventDefault();
      return false;
    } else {
      return true;
    }
  }


  

  keyPressphone(event) {
    var charCode = (event.which) ? event.which : event.keyCode;
    if ((charCode < 43 || charCode > 57)) {
      event.preventDefault();
      return false;
    } else {
      return true;
    }
  }

  keyPressBP(event: KeyboardEvent) {
    const char = event.key;

    if (/^[0-9a-zA-Z]+$/.test(char) || char === '+' || char === '-' || char === '(' || char === ')' || char === ' ') {
    } else {
      event.preventDefault();
    }
  }


  gotomenu(id: string) {
    localStorage.setItem('ghomeid', id)
  }

  pinvalidation(control) {
    const pin = /^[0-9]+$/;
    if (!pin.test(control.value)) {
      return { invalidpin: true };
    }
    return null;
  }

  // preawar(control) {
  //   const areaof = /^[a-zA-Z0-9]+$/
  //   if (!areaof.test(control.value)) {
  //     return { invalidareaof: true };
  //   }
  //   return null;
  // }


  onOptionChange() {
    this.shot_nm = this.selectedarea.shot_nm
    this.location = this.selectedarea.business_area_primary
    this.showlocation = this.selectedarea.city_nm
    // this.oic_user_id.push(this.selectedarea.oic_user_id)
    if(this.oic_user_id.length == 0){
      this.oic_user_id.push(this.selectedarea.oic_user_id)
    }else{
      this.oic_user_id.pop()
      this.oic_user_id.push(this.selectedarea.oic_user_id)
    }
    // console.log(this.shot_nm, this.location , this.oic_user_id)
    this.bm.detectChanges()

  }

  onPochnage() {
    this.ponum = this.selectedPOValue
    console.log(this.ponum)
    this.bm.detectChanges()

  }



  onFileSelected(event: any) {
    const file = event.target.files[0];
    const acceptedFileTypes = ['image/jpeg', 'image/png', 'application/pdf'];

    if (file) {
      const fileSize = file.size;
      const maxSize = 500 * 1024;
      if (fileSize > maxSize) {
        this[`complaintform${this.newid - 1}`]?.controls['Upload'].setErrors({ invalid: true });
        $('.file').css("border", "1px solid red")
        return this.toastr.error("file size should be 500kb")
      } else if (!acceptedFileTypes.includes(file.type)) {
        this[`complaintform${this.newid - 1}`]?.controls['Upload'].setErrors({ invalid: true });
        $('.file').css("border", "1px solid red")
        return this.toastr.error("Wrong file type selected. Only PDF, PNG, and JPEG, formats are allowed. ")
      }
      else {
        this[`complaintform${this.newid - 1}`]?.controls['Upload'].setErrors(null);
        $('.file').css("border", "2px solid #c2bfbf")
        this.getfile = file
      }
    } else {
      this[`complaintform${this.newid - 1}`]?.controls['Upload'].setErrors(null);
      $('.file').css("border", "2px solid #c2bfbf")
    }
  }


  loaderOn() {
    document.getElementById("overlay").style.display = "block";
  }

  loaderOff() {
    document.getElementById("overlay").style.display = "none";
  }

  pintcodepat = /^[0-9-]{6}$/;
  Bpnum = /^[0-9-+a-zA-Z]$/;
  emailpat = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
  dtls = /^[,\-_\.A-Za-z0-9\s]{1,1000}$/;


  onSubmit1() {
    // const obj ={
      //   "grievance_file" : this.getfile ? this.getfile : null,
      //   "complaint_type" : "VENDOR",
      //   "shot_nm" : this.shot_nm,
      //   "location" : this.location,
      //   "vendor_code" : this.complaintform1.value.Vendorcode,
      //   "name": this.complaintform1.value.VendorName,
      //   "po_no":this.ponum ? this.ponum : null,
      //   "complaint_nature" : this.complaintform1.value.NofComplaint,
      //   "mobile_no": this.complaintform1.value.MobileNumber,
      //   "email":this.complaintform1.value.Email,
      //   "complaint_desc": this.complaintform1.value.Details,
      //   "award_type": this.complaintform1.value.PreAward,
      //   "vendor_phone_no" :this.complaintform1.value.phone ? this.complaintform1.value.phone : null,
      //   "assign_employee" :this.oic_user_id
      // }
    // let email = this.emailpat.test(this.complaintform1.value.Email);
    let mob = this.complaintform1.value.MobileNumber
    // let dtl = this.dtls.test(this.complaintform1.value.Details)
    this.submitted = true
    if (this.complaintform1.invalid) {
      return
    }
    // if (!this.complaintform1.value.Vendorcode) {
    //   return this.toastr.error("Enter vendorcode")
    // } else if (!this.complaintform1.value.VendorName) {
    //   return this.toastr.error("Enter vendorname")
    // } else if (!this.ponum) {
    //   return this.toastr.error("Enter PO Number")
    // } else if (!this.complaintform1.value.AreaofOp) {
    //   return this.toastr.error("select area of oparations")
    // } else if (!this.complaintform1.value.NofComplaint) {
    //   return this.toastr.error("Select Nature of complaint")
    // } else if (!this.complaintform1.value.PreAward) {
    //   return this.toastr.error("Enter pre_Award")
    // } else if (!this.complaintform1.value.Email) {
    //   return this.toastr.error("Enter  Email")
    // } else
    //  if (email == false) {
    //   return this.toastr.error("Invalid Email")
    // }
    // else if (!this.complaintform1.value.Upload) {
    //   return this.toastr.error("upload file")
    // } else if (!this.complaintform1.value.Details) {
    //   return this.toastr.error("Enter Details")
    // } else
    //  if (dtl == false) {
    //   return this.toastr.error("Speacial charectores are not allowed")
    // } 
    // else if (!this.complaintform1.value.MobileNumber) {
    //   return this.toastr.error("Enter Mobile number for otp")
    // }
    // else if (!this.complaintform1.value.Upload) {
    //   this.complaintform1.controls['Upload'].setErrors({ invalid: true });
    //   return
    // }
    else if (mob.length < 10 || mob.length > 14) {
      return this.toastr.error("Invalid Mobile number for otp")
    }
    // else if (!this.complaintform1.value.Otp) {
    //   return this.toastr.error("Enter otp")
    // } 
    // if (this.genotp != this.complaintform1.value.Otp) {
    //   this.complaintform1.controls['Otp'].setErrors({ invalid: true });
    //   return this.toastr.error("Otp is not matched")
    // }
    else {
      this.genotp = ''
      const formData = new FormData();
      formData.append('grievance_file', this.getfile ? this.getfile : null);
      // formData.append('grievance_file', this.getfile, this.getfile.name);
      formData.append('complaint_type', "VENDOR");
      formData.append('shot_nm', this.shot_nm);
      formData.append('location', this.location);
      formData.append('vendor_code', this.complaintform1.value.Vendorcode);
      formData.append('name', this.complaintform1.value.VendorName.replace(/\s+/g, ''));
      formData.append('po_no', this.ponum ? this.ponum : null);
      formData.append('complaint_nature', this.complaintform1.value.NofComplaint);
      formData.append('mobile_no', this.complaintform1.value.MobileNumber);
      formData.append('email', this.complaintform1.value.Email);
      formData.append('complaint_desc', this.complaintform1.value.Details);
      formData.append('award_type', this.complaintform1.value.PreAward);
      formData.append('vendor_phone_no', this.complaintform1.value.phone ? this.complaintform1.value.phone : null,);
      formData.append('assign_employee', JSON.stringify(this.oic_user_id));
      formData.append('otp', this.complaintform1.value.Otp);
      
      this.loaderOn()
      this.service.grievance_submit(formData).subscribe((res: any) => {
        this.loaderOff()
        if (res.code == 200) {
          this.successmsg = res.data
          $('#sumitsuccess').modal('show')
          this.bm.detectChanges()
          this.submitted = false
          this.complaintform1.reset()
          return this.toastr.success("Complaint Successfully Registered")
        } else {
          return this.toastr.error(res.message)
        }
      })
    }
  }


  onSubmit2() {
    let bpnum = this.Bpnum.test(this.complaintform2.value.BP_Number)
    // console.log('clicked inside before submit')
    let mob = this.complaintform2.value.MobileNumber
    this.submitted = true
    if (this.complaintform2.invalid) {
      return
    }
    // if (!this.complaintform2.value.CustomerName) {
    //   return this.toastr.error("Enter CustomerName")
    // } else if (!this.complaintform2.value.CustomerType) {
    //   return this.toastr.error("Enter CustomerType")
    // } else if (!this.complaintform2.value.AreaofOp) {
    //   return this.toastr.error("Select area Of oparation")
    // } else if (!this.complaintform2.value.NofComplaint) {
    //   return this.toastr.error("Select Nature of complaint")
    // } else if (!this.complaintform2.value.Email) {
    //   return this.toastr.error("Enter Email")
    // } else
    //  else if (email == false) {
    //   return this.toastr.error("Invalid Email")
    // } 
    // else if (!this.complaintform2.value.Upload) {
    //   this.complaintform2.controls['Upload'].setErrors({ invalid: true });
    //   return
    // }
    // else if (!this.complaintform2.value.Details) {
    //   return this.toastr.error("Enter Details")
    // }
    //  else if (dtl == false) {
    //   return this.toastr.error("Speacial charectores are not allowed")
    // }
    //  else if (!this.complaintform2.value.MobileNumber) {
    //   return this.toastr.error("Enter Mobile number for otp")
    // }
    // else if(bpnum == false){
    //   $('.BP_Number').css('border', '1px solid red')
    //   // this.complaintform2.controls['BP_Number'].setErrors({ invalid: true });
    //   return
    // }
    else if (!this.complaintform2.value.MobileNumber) {
      $('.mob').css('border', '1px solid red')
      return
    }
    else if (mob.length < 10 || mob.length > 14) {
      return this.toastr.error("Invalid Mobile number for Otp")
    }
    // if (this.genotp != this.complaintform2.value.Otp) {
    //   this.complaintform2.controls['Otp'].setErrors({ invalid: true });
    //   return this.toastr.error("Otp is not matched")
    // }
    else {
      // console.log('clicked inside after submit')
      this.genotp = ''
      const formData = new FormData();
      // formData.append('grievance_file', this.getfile ? this.getfile : null, this.getfile.name);
      formData.append('grievance_file', this.getfile ? this.getfile : null);
      formData.append('shot_nm', this.shot_nm);
      formData.append('location', this.location);
      formData.append("complaint_type", 'CUSTOMER');
      formData.append('customer_type', this.complaintform2.value.CustomerType);
      formData.append('name', this.complaintform2.value.CustomerName.replace(/\s+/g, ''));
      formData.append('complaint_nature', null);
      formData.append('mobile_no', this.complaintform2.value.MobileNumber);
      formData.append('email', this.complaintform2.value.Email);
      formData.append('complaint_desc', this.complaintform2.value.Details);
      formData.append('bp_number', this.complaintform2.value.BP_Number ? this.complaintform2.value.BP_Number : null);
      formData.append('assign_employee', JSON.stringify(this.oic_user_id));
      formData.append('otp', this.complaintform2.value.Otp);
      console.log(formData.getAll('assign_employee'))
      this.loaderOn()
      this.service.grievance_submit(formData).subscribe((res: any) => {
        this.loaderOff()
        if (res.code == 200) {
          this.successmsg = res.data
          this.submitted = false
          this.bm.detectChanges()
          $('#sumitsuccess').modal('show')
          this.toastr.success("Complaint Successfully Registered")
          this.complaintform2.reset()
        } else {
          return this.toastr.error(res.message)
        }
      })

    }
  }


  onSubmit3() {

    let pin = this.pintcodepat.test(this.complaintform3.value.PinCode);
    let email1 = this.emailpat.test(this.complaintform3.value.Email);
    let dtl = this.dtls.test(this.complaintform3.value.Details)
    let mob = this.complaintform3.value.MobileNumber
    this.submitted = true
    if (this.complaintform3.invalid) {
      return
    }
    // if (!this.complaintform3.value.EnterName) {
    //   return this.toastr.error("Enter EnterName")
    // } else if (!this.complaintform3.value.EnterHouseNo) {
    //   return this.toastr.error("Enter EnterHouseNo")
    // } else if (!this.complaintform3.value.BuildingStreet) {
    //   return this.toastr.error("Enter BuildingStreet")
    // } else if (!this.complaintform3.value.Area) {
    //   return this.toastr.error("Enter Area")
    // } else if (!this.complaintform3.value.City) {
    //   return this.toastr.error("Enter City")
    // } else if (!this.complaintform3.value.State) {
    //   return this.toastr.error("Enter State")
    // } 
    else if (pin = false) {
      return this.toastr.error("Invalid PinCode")
    }

    // else if (!this.complaintform3.value.Country) {
    //   return this.toastr.error("Enter Country")
    // } else if (!this.complaintform3.value.Subject) {
    //   return this.toastr.error("Enter subject")
    // } else if (!this.complaintform3.value.Email) {
    //   return this.toastr.error("Enter  Email")
    // } else
    if (email1 == false) {
      return this.toastr.error("Invalid Email")
    }
    // else if (!this.complaintform3.value.Upload) {
    //   this.complaintform3.controls['Upload'].setErrors({ invalid: true });
    //   return
    // }
    // else if (!this.complaintform3.value.Details) {
    //   return this.toastr.error("Enter Details")
    // } else 
    if (dtl == false) {
      return this.toastr.error("Speacial charectores are not allowed")
    }
    //  else if (!this.complaintform3.value.MobileNumber) {
    //   return this.toastr.error("Enter Mobile number for otp")
    // } else 
    if (mob.length < 10 || mob.length > 14) {
      return this.toastr.error("Invalid Mobile number for otp")
    }
    // if (this.genotp != this.complaintform3.value.Otp) {
    //   this.complaintform3.controls['Otp'].setErrors({ invalid: true });
    //   return this.toastr.error("Otp is not matched")
    // }
    else {
      this.genotp = ''
      const formData = new FormData();
      formData.append('grievance_file', this.getfile ? this.getfile : null);
      // formData.append('grievance_file', this.getfile, this.getfile.name);
      formData.append('complaint_type', "VIGILANCE");
      formData.append('name', this.complaintform3.value.EnterName.replace(/\s+/g, ''));
      formData.append('mobile_no', this.complaintform3.value.MobileNumber);
      formData.append('email', this.complaintform3.value.Email);
      formData.append('complaint_desc', this.complaintform3.value.Details);
      formData.append('house_no', this.complaintform3.value.EnterHouseNo);
      formData.append('area', this.complaintform3.value.Area);
      formData.append('street', this.complaintform3.value.BuildingStreet);
      formData.append('state', this.complaintform3.value.State);
      formData.append('pin_Code', this.complaintform3.value.PinCode);
      formData.append('country', this.complaintform3.value.Country.country_name);
      formData.append('subject', this.complaintform3.value.Subject);
      formData.append('city', this.complaintform3.value.City);
      formData.append('otp', this.complaintform3.value.Otp);
      this.loaderOn()

      this.service.grievance_submit(formData).subscribe((res: any) => {
        this.loaderOff()
        if (res.code == 200) {
          this.successmsg = res.data
          this.submitted = false
          this.bm.detectChanges()
          $('#sumitsuccess').modal('show')
          this.complaintform3.reset()
          this.toastr.success("Complaint Successfully Registered")
        } else {
          return this.toastr.error(res.message)
        }
      })

    }
  }


  onTypeOpChange() {
    const cmptype = this.complaintform2.value.CustomerType
    if (cmptype == 1) {
      this.cmptypeforbtn = true
    }else if(cmptype == 5){
      this.cmptypeforbtn = false
      this.setmand = false
      this.complaintform2.controls['BP_Number'].setErrors(null);
    }else{
      this.cmptypeforbtn = false
      this.setmand = true
      this.complaintform2.controls['BP_Number'].setErrors({ invalid: true });
    }
    // console.log(cmptype)
  }


  countrylst() {
    const obj = {}
    this.service.countrylst(obj).subscribe((res: any) => {
      this.cuntryoption = res.data
      const cuntryinitial = this.cuntryoption.find(item => item.country_id == 101);
      if (cuntryinitial) {
        this.selectecuntry = cuntryinitial;
      }

    })
  }

  onSelectcuntry() {
    this.cuntrycode = this.complaintform3.value.Country.country_id
    this.cuntryname = this.complaintform3.value.Country.country_name
    console.log(this.cuntryname, this.cuntrycode)
    if (this.complaintform3.value.Country) {
      this.statelstfm3()
      this.complaintform3.get('State').setValue("");
    }
  }

  statelstfm3() {
    const obj = {
      "country_id": this.cuntrycode ? this.cuntrycode : 101
    }
    console.log(obj.country_id)
    this.service.statelst(obj).subscribe((res: any) => {
      console.log(res.data)
      this.Stateoption = res.data
      this.bm.detectChanges()
    })
  }

  vendorOnFocusOutEvent(event: any) {
    console.log(event);
    const str = event.target.value;
    let newStr = str.substring(0, 1);
    // var regex = /[a-zA-Z0._%+-]+[a-zA-Z0-9.-]{10}$/g,
    var regex = /^[0-9]{10}$/,
      tst = regex.test(str);
    if (tst == false) {
      $('.vendorZone').css('border', '1px solid red');
      return;
    }
    else {
      if (newStr != 0 && !newStr.match(/[a-z]/i)) {
        $('.vendorZone').css('border', '1px solid red');
        return;
      }
      else {
        this.vendorcode = event.target.value
        let objVendorCode = {
          vendorcode: this.vendorcode,
        };
        console.log(objVendorCode, '--Object code');
        this.loaderOn()
        this.service.vendorPoCode(objVendorCode).subscribe((res: any) => {

          if (res.code === 200) {
            console.log(res);
            this.vendorCodeField = this.sanitizetextInput(res.data[0].VENDOR_NAME);
            this.vendornumber = res.data[0].PHONE_NO
            this.getpodorpdown()
            this.grievancearea()
            this.bm.detectChanges()
            this.loaderOff()
            console.log(this.vendorCodeField)
            $('.vendorZone').css('border', '1px solid grey');

          } else {
            this.loaderOff()
            console.log(res, "rednder apis");
            $('.vendorZone').css('border', '1px solid red');
            return this.toastr.error(res.message)
            this.vendorCodeField = undefined;
          }
        },
          err => {
            console.log(err, 'Print errorrr');
            this.vendorCodeField = undefined;
          },
        );
      }
    }

  }

  getotp() {

    const otpinput = this[`complaintform${this.newid - 1}`]?.value.MobileNumber
    if (!otpinput) {
      $('.mob').css('border', '1px solid red')
      return this.toastr.error("please enter phone number")
    }
    if (otpinput.length != 10) {
      $('.mob').css('border', '1px solid red')
      return this.toastr.error("Invalid phone number")
    } else {
      $('.mob').css('border', '1px solid grey')
      const obj = {
        "mobile_no": this[`complaintform${this.newid - 1}`]?.value.MobileNumber
      };
      // 7008751513
      // this.loaderOn()
      this.service.otpverfication(obj).subscribe((res: any) => {
        // this.loaderOff()
        // console.log(res)
        if (res.code == 200) {
          this.genotp = res.OTP
          // console.log(this.genotp)
          this.bm.detectChanges()
          this.buttonDisabled = true;
          this.timer = 120
          this.timerstart()
          this.EnterOtp_Send = true;
          return this.toastr.success("OTP sent to your mobile")
        } else {
          // Clear the MobileNumber field if the response code is not 200
          if (this[`complaintform${this.newid - 1}`]) {
            this[`complaintform${this.newid - 1}`].patchValue({
              MobileNumber: ''
            });
          }
          return this.toastr.error(res.message)
        }
      })
    }
  }

  formatTime(seconds: number): string {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
  }

  timerstart() {
    this.timerInterval = setInterval(() => {
      if (this.timer > 0) {
        this.timer--;
      } else {
        clearInterval(this.timerInterval);
        this.genotp = ''
        // console.log(this.genotp)
        this.resendDisabled = false
      }
    }, 1000);
  }

  grevencecomplnature() {
    this.loaderOn()
    this.service.grevancecomplaintnature().subscribe((res: any) => {
      this.loaderOff()
      if (res.code == 200) {
        this.areaofnature = res.data
        this.bm.detectChanges()
      } else {
        return this.toastr.error(res.message)
      }
    })
  }
  PreAward(event: any) {
    console.log(event)
  }

  ///////// // customer component part/////////////

  customer_type() {
    this.loaderOn()
    this.service.customer_type().subscribe((res: any) => {
      this.loaderOff()
      if (res.code == 200) {
        this.cust_type = res.data
        console.log(this.cust_type)
        this.bm.detectChanges()
      } else {
        return this.toastr.error(res.message)
      }
    })
  }





  viewdata(ele: any) {
    console.log(ele)
    const obj = {
      "complaint_type": ele.complaint_type,
      "complaint_id": ele.complaint_id,
      "mobile_no": this.trackpaylnum
    }
    this.identitikey = ele.complaint_type
    this.loaderOn()
    this.service.View_complaint(obj).subscribe((res: any) => {
      console.log(res)
      this.viewcmp = res.data[0]
      this.vieremarks = this.viewcmp.remarks ? this.viewcmp.remarks : "NA"
      this.fileurl = res.data[0].file

      if (this.fileurl) {
        if (this.fileurl.startsWith('/')) {
          this.imgurl = this.fileurl.substring(1);
          console.log(this.imgurl)
        } else {
          this.imgurl = this.fileurl
          console.log(this.imgurl)
        }
      }
      this.bm.detectChanges()
      $("#viewcompmodel").modal('show')
      this.loaderOff()
    })
  }


  closemodel() {
    this.bm.detectChanges
    $('#sumitsuccess').modal('hide')
    this.reloadCurrentRoute()
  }


  // closemodel2(){
  //   $('#sumitsuccess').modal('hide')
  //   this.reloadCurrentRoute()
  // }

  vigilanc() {
    localStorage.setItem("porceedid", '4')
    this.reloadCurrentRoute()
  }

  Vendo() {
    localStorage.setItem("porceedid", '2')
    this.reloadCurrentRoute()
  }

  gotohome() {
    localStorage.setItem('ghomeid', 'formmenu')
  }

  sanitizenumberInput(input: string): string {
    return input.toLowerCase().replace(/[^0-9]/g, '');
  }
  onNumberinput(event: any, controlName: string) {
    const input = event.target.value;
    const sanitizedInput = this.sanitizenumberInput(input);
    this[`complaintform${this.newid - 1}`].get(controlName).setValue(sanitizedInput);
  }
  
  
  // text input sanitizer
  sanitizetextInput(input: string): string {
    return input.toLowerCase().replace(/[^-_a-zA-Z0-9., / ]/g, '');
  }
  
  ontextinput(event: any, controlName: string) {
    const input = event.target.value;
    const sanitizedInput = this.sanitizetextInput(input);
    this[`complaintform${this.newid - 1}`].get(controlName).setValue(sanitizedInput);
  }
    
  
  // email input sanitizer
  
  sanitizeemailInput(input: string): string {
    return input.toLowerCase().replace(/[^a-z0-9._%+\-@]/g, '');
  }
  
  onInputEmail(event: any) {
    const input = event.target.value;
    const sanitizedInput = this.sanitizeemailInput(input);
    this[`complaintform${this.newid - 1}`].get('Email').setValue(sanitizedInput);
  }


}
