import { Location } from '@angular/common';
import { Component, HostListener, Input, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { ReplaySubject } from 'rxjs/internal/ReplaySubject';
import * as FileSaver from 'file-saver';
import { FooterComponent } from '../footer/footer.component';
import { PdfService } from '../services/pdf.service';
import { OperationService } from '../services/operation.service';
import { AppComponent } from '../app.component';



declare var $: any;
declare var jQuery: any;

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy {
  @ViewChild(FooterComponent) footerComponent: FooterComponent;

  menuItem: any;
  cityLst: any;
  language$ = new ReplaySubject<LangChangeEvent>(1);
  translate = this.translateService;
  language: any;
  currentLocation: any;
  menuItem1: any;
  menuItem2: any;
  removeClass: boolean;
  screenWidth: any;
  languageSm: any;
  selectedMenu: any;
  selectedSubMenu: any;
  path: string;
  newLocations: string;
  currentRoute: string;

  odiyaLangEnable: boolean = false;
  kanadaLangEnable: boolean = false;
  englishLangEnable: boolean = false;
  quickLinkSubmenu: any[] = [];

  constructor(private translateService: TranslateService, private route: ActivatedRoute, private router: Router, private location: Location, private pdfservice: PdfService) {
    this.language = localStorage.getItem('language');
    this.translateService.setDefaultLang(this.language);
    if (this.language == 'hn') {
      this.languageSm = 'English';
    } else {
      this.languageSm = 'हिन्दी';
    }
    this.screenWidth = window.innerWidth;

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {

        this.currentRoute = event['url'];
        console.log(this.currentRoute, "Header Component");


        if (this.currentRoute == '/operation/ganjam-nayagarh-puri' || this.currentRoute == '/operation/ganjam-nayagarh-puri-od' || this.currentRoute == '/operation/ganjam-nayagarh-puri-hn' || this.currentRoute == '/operation/sundergarh' ||
          this.currentRoute == '/operation/sundergarh-od' || this.currentRoute == '/operation/sundergarh-hn') {

          //  console.log("%cRamki Chkng Odiya Language Puri", "color:red");

          this.odiyaLangEnable = true;  // odiya language in language dropdown enabled
          this.kanadaLangEnable = false; // kanada language in language dropdown disabled


        } else if (this.currentRoute == '/operation/bengaluru' || this.currentRoute == '/operation/bengaluru-kn' ||
          this.currentRoute == '/operation/bengaluru-hn' || this.currentRoute == '/operation/dakshin-kannada' ||
          this.currentRoute == '/operation/dakshin-kannada-kn' || this.currentRoute == '/operation/dakshin-kannada-hn') {

          this.odiyaLangEnable = false; // odiya language in language dropdown disabled
          this.kanadaLangEnable = true; // Kanada language in language dropdown enabled

        } else {
          this.odiyaLangEnable = false;
          this.kanadaLangEnable = false;
        }


        if (this.currentRoute.includes('-hn') || this.currentRoute.includes('-od') || this.currentRoute.includes('-kn')) {
          // console.log("%cRamki Chkng" , "color:red");

          this.englishLangEnable = true;
        }
        else {
          this.englishLangEnable = false;
        }


      }
    });


  }

  ngOnInit(): void {

    let a = localStorage.getItem('route');
    console.log(a);

    this.getMenuList();



  }
  // @HostListener('window:resize', ['$event'])

  // onResize(event) {
  //   this.screenWidth = window.innerWidth;
  // }

  gotoNavMenu(hmenu, mnu, $event) {
    console.log(mnu, hmenu, $event);
    this.selectedMenu = hmenu.mnu_id;
    this.selectedSubMenu = mnu.sub_mnu_id;
    console.log(this.selectedSubMenu)
    this.removeClass = true;

    if (mnu.pdf_ind == 0) {
      this.router.navigateByUrl(mnu.url);
    } else if (mnu.pdf_ind == 1) {
      window.open(mnu.url, '_blank', '');
    } else {
      FileSaver.saveAs(mnu.url, mnu.sub_mnu_nm);
    }
    setTimeout(() => {
      this.removeClass = false;
    }, 100);
  }

  clearActiveMenu(mnus) {
    console.log(mnus.subMenu[5].subSubMenu);
    this.selectedMenu = '';
  }


  switchLanguage(lan, event) {
    console.log(lan, event);
    if (lan == 'hn') {
      this.languageSm = 'हिन्दी';
      console.log(this.languageSm);
      this.currentLocation = this.location.path();
      console.log(this.currentLocation, "Hindi");
      const slice = this.currentLocation.slice(this.currentLocation.length - 3);

      console.log(this.currentLocation);
      console.log(slice, "Hindi Slice");
      if (slice == '-hn') {
        let loc = this.currentLocation + "-hn";
        console.log(loc);
        this.router.navigateByUrl(loc);
      } else if (slice == '-od') {
        console.log(this.currentLocation, "Else If Condition 1ST");
        let slice1 = this.currentLocation.slice(0, this.currentLocation.length - 3);
        console.log(slice1, "Else if condition");

        console.log(this.currentLocation, "Else If Condition 2ND");
        let loc = slice1 + "-hn";
        console.log(loc);
        this.router.navigateByUrl(loc);


      } else if (slice == '-kn') {
        console.log(this.currentLocation, "Else If Condition 1ST");
        let slice1 = this.currentLocation.slice(0, this.currentLocation.length - 3);
        console.log(slice1, "Else if condition");

        console.log(this.currentLocation, "Else If Condition 2ND");
        let loc = slice1 + "-hn";
        console.log(loc);
        this.router.navigateByUrl(loc);


      } else {
        let loc = this.currentLocation + "-hn";
        console.log(loc);
        this.router.navigateByUrl(loc);
      }
    }


    else if (lan == 'od') {
      // this.odiyaLangEnable = true;
      this.languageSm = 'ଓଡିଆ';
      console.log(this.languageSm)
      this.currentLocation = this.location.path();

      console.log(this.currentLocation, "Odiya");
      const slice = this.currentLocation.slice(this.currentLocation.length - 3);

      console.log(slice, "Odiya Slice");
      if (slice == '-od') {
        let loc = this.currentLocation + "-od";
        this.router.navigateByUrl(loc);
      } else if (slice == '-hn') {
        console.log(this.currentLocation, "Else If Condition 1ST");
        let slice1 = this.currentLocation.slice(0, this.currentLocation.length - 3);
        console.log(slice1, "Else if condition");

        console.log(this.currentLocation, "Else If Condition 2ND");
        let loc = slice1 + "-od";
        console.log(loc);
        this.router.navigateByUrl(loc);


      } else if (slice == '-kn') {
        console.log(this.currentLocation, "Else If Condition 1ST");
        let slice1 = this.currentLocation.slice(0, this.currentLocation.length - 3);
        console.log(slice1, "Else if condition");

        console.log(this.currentLocation, "Else If Condition 2ND");
        let loc = slice1 + "-od";
        console.log(loc);
        this.router.navigateByUrl(loc);


      } else {
        let loc = this.currentLocation + "-od";
        this.router.navigateByUrl(loc);
      }
    }

    else if (lan == 'kn') {
      // this.kanadaLangEnable = false;
      this.languageSm = 'ಕನ್ನಡ';
      console.log(this.languageSm);
      this.currentLocation = this.location.path();
      console.log(this.currentLocation, "Odiya");
      const slice = this.currentLocation.slice(this.currentLocation.length - 3);
      console.log(slice, "Kannada Slice");
      if (slice == '-kn') {
        let loc = this.currentLocation + "-kn";
        this.router.navigateByUrl(loc);
      } else if (slice == '-hn') {
        console.log(this.currentLocation, "Else If Condition 1ST");
        let slice1 = this.currentLocation.slice(0, this.currentLocation.length - 3);
        console.log(slice1, "Else if condition");

        console.log(this.currentLocation, "Else If Condition 2ND");
        let loc = slice1 + "-kn";
        console.log(loc);
        this.router.navigateByUrl(loc);


      } else if (slice == '-od') {
        console.log(this.currentLocation, "Else If Condition 1ST");
        let slice1 = this.currentLocation.slice(0, this.currentLocation.length - 3);
        console.log(slice1, "Else if condition");

        console.log(this.currentLocation, "Else If Condition 2ND");
        let loc = slice1 + "-kn";
        console.log(loc);
        this.router.navigateByUrl(loc);


      } else {
        let loc = this.currentLocation + "-kn";
        this.router.navigateByUrl(loc);
      }
    }

    else {
      this.languageSm = 'English';
      console.log(this.languageSm);
      this.currentLocation = this.location.path();

      console.log(this.currentLocation, "English");

      const slice = this.currentLocation.slice(this.currentLocation.length - 3);

      console.log(slice, "English Slice");
      if (slice == '-hn') {
        if (slice == '-hn') {
          const loc = this.currentLocation.slice(0, -3);
          console.log(loc);
          this.router.navigateByUrl(loc);
        } else {
          let loc = this.currentLocation + "-hn";
          this.router.navigateByUrl(loc);
        }
      }


      if (slice == '-od') {
        if (slice == '-od') {
          const loc = this.currentLocation.slice(0, -3);
          this.router.navigateByUrl(loc);
        } else {
          let loc = this.currentLocation + "-od";
          this.router.navigateByUrl(loc);
        }
      }

      if (slice == '-kn') {
        if (slice == '-kn') {
          const loc = this.currentLocation.slice(0, -3);
          this.router.navigateByUrl(loc);
        } else {
          let loc = this.currentLocation + "-kn";
          this.router.navigateByUrl(loc);
        }
      }

    }
    if (event == 'small') {
      console.log(lan, "Small");
      if (lan == 'en') {
        console.log("hindi")
        this.language = 'hn';
      } else {
        this.language = 'en';
      }
      this.removeClass = true;
      setTimeout(() => {
        this.removeClass = false;
      }, 100);
    } else {
      console.log("else statement::::");
      console.log(lan);
      this.language = lan;
    }
    console.log(`%c${this.currentRoute}`, "color:red;font-size:50px");
    console.log(this.language);
    localStorage.setItem('language', this.language);
    this.translateService.setDefaultLang(this.language);
    this.pdfservice.setTitle(this.language);
    this.getMenuList();
  }

  Number() {
    window.location.href = "tel:1800-102-9282";
  }

  getMenuList() {
    this.translateService.get(["menuItem"]).subscribe(values => {
      const data = Object.keys(values).map(key => values[key]);
      this.menuItem = data[0];
    });
    // this.translateService.get(["menuItem"]).subscribe(values => {
    //   const data = Object.keys(values).map(key => values[key]);
    //   this.menuItem = data[0];
    // });
    this.translateService.get(["submenu1"]).subscribe(values => {
      const data = Object.keys(values).map(key => values[key]);
      this.menuItem1 = data[0];
    });
    // this.translateService.get(["submenu2"]).subscribe(values => {
    //   const data = Object.keys(values).map(key => values[key]);
    //   this.menuItem2 = data[0];
    // });
    this.translateService.get(["city"]).subscribe(values => {
      const data = Object.keys(values).map(key => values[key]);
      this.cityLst = data[0];
    });
  }

  goHome() {
    if (this.language == 'hn') {
      this.router.navigateByUrl('home-hn');
    } else {
      this.router.navigateByUrl('home');
    }
    this.selectedMenu = '';
    this.selectedSubMenu = ''
  }

  ngOnDestroy() {
    this.newLocations
  }
}
