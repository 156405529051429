import { Component, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { Title, Meta } from '@angular/platform-browser';

import * as AOS from 'aos';
import { Router } from '@angular/router';
import { PdfService } from 'src/app/services/pdf.service';
import { HttpClient } from '@angular/common/http';
import { OperationService } from 'src/app/services/operation.service';

declare var $: any;

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  title = 'GAIL Gas Limited - India’s Leading CGD Company';

  event_list: any;
  language: any;
  carouselMenuList: any;
  members: any;
  screenWidth: number;
  internalization: any;
  carouselList: any;
  pressReleaseNotes: any;

  pressReleaseNotesUpdated: any = []; // pushing 2 objects from pressReleaseNotes variable to display 1st 2 objcts
  EOI1: any;
  EOI4: any;
  EOI3: any;
  EOI2: any;
  EOIData:any=[]

  constructor(private service:OperationService,private translateService: TranslateService, private titleService: Title, private metaTagService: Meta, private router: Router, private PdfService: PdfService , private http: HttpClient) {
    this.screenWidth = window.innerWidth;
    console.log(this.screenWidth)
    // this.getEoiDetails()
    this.eoiTableData()
  }

  ngOnInit(): void {
    this.PdfService.footer.emit();

    this.titleService.setTitle(this.title);
    this.metaTagService.updateTag(
      { name: 'description', content: 'GAIL Gas Limited - India’s Leading CGD Company' }
    );
    this.metaTagService.addTags([
      { name: 'keywords', content: '' },
      { name: 'robots', content: 'index, follow' },
      { name: 'author', content: 'Dataevolve' },
      { name: 'viewport', content: 'width=device-width, initial-scale=1' },
      { name: 'date', content: '2021-03-08', scheme: 'YYYY-MM-DD' },
      { charset: 'UTF-8' }
    ]);

    $(document).ready(function () {
      $('.carousel').carousel({ interval: 5000, cycle: true });
    });

    AOS.init();
    this.language = localStorage.getItem('language');
    console.log(this.language, "Overview com");

    // if (this.language == 'od' || this.language == 'kn') {
    //   this.language = 'en';
    // } else {
    //   this.language = this.language;
    // }

    this.translateService.setDefaultLang(this.language);

    this.translateService.get(["members"]).subscribe(values => {
      var data = Object.keys(values).map(key => values[key]);
      this.internalization = data[0];
      console.log(this.internalization)
    });
    if (this.language == 'hn') {
      this.pressReleaseNotes = [
        {
          id: 8,
          img: "./../../../assets/news-media/press/glass-removebg-preview.png",
          imgList: [],
          note: "गेल गैस लिमिटेड सीएनजी और घरेलू पीएनजी की कीमतों को कम करके अपने ग्राहकों को कम घरेलू गैस मूल्य निर्धारण का लाभ देती है|",
          dt: "08.04.23",
          moreContent: [
            {
              nm:"8 अप्रैल, 2023: नोएडा: गेल गैस लिमिटेड भी अपने ग्राहकों को नए घरेलू गैस मूल्य निर्धारण लाभों को पारित करने के लिए भारत सरकार के दिशानिर्देशों के अनुरूप अपने मूल्य निर्धारण तंत्र का संचालन कर रहा है। गेल गैस लिमिटेड ने अपने घरेलू पीएनजी की कीमतों में रुपये की कमी की घोषणा की है। कर्नाटक जीएएस को छोड़कर सभी भौगोलिक क्षेत्रों में 6 प्रति एससीएम, जहां कटौती रुपये है। 7 प्रति एससीएम। इसी तरह, सभी जीएएस में सीएनजी की कीमतों में 6 रुपये प्रति किलोग्राम की कमी की गई है, जबकि कर्नाटक गैस और सोनीपत में 7 रुपये प्रति किलोग्राम की कमी की गई है। कीमतों में यह कटौती आज यानी 9 अप्रैल, 2023 की आधी रात से लागू हो जाएगी।."
            },
            {
              nm:"नए दिशानिर्देशों का उद्देश्य उपयोगकर्ता उद्योगों और सीजीडी क्षेत्र को प्रोत्साहन के साथ-साथ प्रतिकूल बाजार में उतार-चढ़ाव से उत्पादकों को पर्याप्त सुरक्षा प्रदान करते हुए घरेलू गैस उपभोक्ताओं के लिए एक स्थिर मूल्य निर्धारण व्यवस्था स्थापित करना है। यह पसंदीदा ईंधन के रूप में सीएनजी और पीएनजी के विस्तार में तेजी लाएगा और कार्बन फुटप्रिंट को कम करने में भी योगदान देगा।"
            },
            {
              nm:"गेल गैस लिमिटेड देश भर में 16 भौगोलिक क्षेत्रों में काम करती है और भारत में गैस आधारित अर्थव्यवस्था की दिशा में काम कर रही है।"
            },
            {
              nm:"संशोधित नई सीएनजी कीमतें 9 अप्रैल 2023 से मेरठ और सोनीपत के लिए 85 रुपये प्रति किलोग्राम होंगी; देवास, ताज ट्रेपेज़ियम ज़ोन और देहरादून के लिए 92 रुपये प्रति किलोग्राम; बेंगलुरु और दक्षिण कन्नड़ के लिए 82.50 रुपये प्रति किलो; मिर्जापुर के लिए 87 रुपये प्रति किलोग्राम, रायसेन, धनबाद, आदित्यपुर, पुरी और राउरकेला के लिए 91 रुपये प्रति किलोग्राम।"
            },
            {
              nm:"संशोधित नई पीएनजी कीमतें 9 अप्रैल, 2023 से देवास, मेरठ, सोनीपत, ताज ट्रेपेज़ियम ज़ोन, रायसेन, मिर्जापुर, धनबाद, आदित्यपुर और राउरकेला में 52.50 रुपये प्रति एससीएम और बेंगलुरु और दक्षिण कन्नड़ के लिए 51.50 रुपये प्रति एससीएम होंगी।"
            }
          ]
        },
        {
          id: 7,
          img: "./../../../assets/news-media/press/goutom1.png",
          imgList: [{
            img: './../../../assets/news-media/press/goutom1.png'
          }],
          note: "श्री गौतम चक्रवर्ती ने गेल गैस लिमिटेड के मुख्य कार्यकारी अधिकारी का पदभार संभाला",
          dt: "03.04.23",
          moreContent: [{
            nm: "3 अप्रैल, 2023: नोएडा: श्री गौतम चक्रवर्ती ने गेल गैस लिमिटेड के मुख्य कार्यकारी अधिकारी (सीईओ) के रूप में पदभार ग्रहण किया है, जो गेल (इंडिया) लिमिटेड की पूर्ण स्वामित्व वाली सहायक कंपनी है, जो सिटी गैस वितरण व्यवसाय में लगी हुई है। श्री रमन चड्ढा से।"
          },
          {
            nm: "इस कार्यभार से पहले, श्री चक्रवर्ती मूल कंपनी गेल (इंडिया) लिमिटेड में सिटी गैस डिस्ट्रीब्यूशन (सीजीडी) बिजनेस वर्टिकल के कार्यकारी निदेशक के रूप में कार्यरत थे।"
          },
          {
            nm: "उनके पास प्राकृतिक गैस मूल्य श्रृंखला में 30 से अधिक वर्षों का समृद्ध और विविध अनुभव है, जिसमें क्रॉस-कंट्री नेचुरल गैस पाइपलाइन और जीपीयू के ओ एंड एम, पवन और सौर ऊर्जा और विपणन में गेल का नवीकरणीय पोर्टफोलियो शामिल है। सीजीडी के 4 पीएस (उत्पाद, मूल्य, स्थान और प्रचार) में विपणन रणनीतियों को लागू करने और गैस की बिक्री में वृद्धि के लिए अत्याधुनिक प्रौद्योगिकी का लाभ उठाने में उनका महत्वपूर्ण योगदान रहा है।"
          },
          {
            nm: "श्री चक्रवर्ती वर्तमान में बंगाल गैस कंपनी लिमिटेड और त्रिपुरा नेचुरल गैस कंपनी लिमिटेड (गेल (इंडिया) लिमिटेड की संयुक्त उद्यम कंपनी) के निदेशक मंडल में भी हैं।"
          },
          {
            nm: "एक प्रक्रिया और परिणाम उन्मुख पेशेवर, श्री चक्रवर्ती ने गेल में सेंट्रल पाइपलाइन इंटीग्रिटी मैनेजमेंट की स्थापना में महत्वपूर्ण भूमिका निभाई थी और गेल के जटिल गैस व्यवसाय की देखभाल करने वाले राष्ट्रीय गैस प्रबंधन केंद्र (एनजीएमसी) का नेतृत्व किया था। उन्होंने गेल में प्राकृतिक गैस और उत्पाद मूल्य श्रृंखला और ट्रांसमिशन सिस्टम ऑपरेटर (TSO) में वाणिज्यिक प्रणालियों और प्रक्रियाओं के विकास में महत्वपूर्ण भूमिका निभाई।"
          },
          {
            nm: "श्री चक्रवर्ती मार्केटिंग में विशेषज्ञता के साथ बिजनेस एडमिनिस्ट्रेशन (एमबीए) में मास्टर्स के साथ एक इलेक्ट्रिकल इंजीनियर हैं।"
          }

          ]
        },
      ];
        this.eoiTableData().subscribe((res:any)=>{
          console.log(res['eoi']);
  
          this.EOI1 =  res['eoi'][0].notice_nm;
          this.EOI2 =  res['eoi'][1].notice_nm;
          this.EOI3 =  res['eoi'][2].notice_nm;
          this.EOI4 =  res['eoi'][3].notice_nm;
        });
    } else {
      this.pressReleaseNotes = [
        {
          id: 8,
          img: "./../../../assets/news-media/press/glass-removebg-preview.png",
          imgList: [],
          note: "GAIL Gas Limited passed the benefit of lower domestic gas pricing to its customers by reducing CNG and Domestic PNG Prices",
          dt: "08.04.23",
          moreContent: [{
            nm:"8th April, 2023: Noida: GAIL Gas Limited is also steering its pricing mechanism in line with the GOI’s guideline to pass on new Domestic gas pricing benefits to its customers. GAIL Gas Limited has announced a reduction in its Domestic PNG prices by Rs. 6 per SCM in all geographical areas, except for Karnataka GAs where the reduction is Rs. 7 per SCM. Similarly, CNG Prices are also reduced by Rs 6 per kg in all the GAs wherein reduced by Rs 7 per kg in Karnataka GAs and Sonipat. This reduction in prices will come into effect from midnight today i.e from 9th April, 2023."
          },
          {
            nm:"The new guidelines aim to establish a stable pricing regime for domestic gas consumers while providing sufficient protection to producers from adverse market fluctuations, along with incentives to user industries and CGD sector. It will accelerate the expansion of CNG & PNG as a preferred fuel, and will also contribute to reducing the carbon footprint."
          },
          {
            nm:"GAIL Gas Limited operates in 16 geographical areas across the country and is working towards gas based economy in India."
          },
          {
            nm:"Revised New CNG Prices w.e.f 9th April 2023 will be Rs 85 per kg for Meerut & Sonipat; Rs 92 per Kg for Dewas, Taj Trapezium Zone & Dehradun; Rs.82.50 per kg for Bengaluru & Dakshin Kannada; Rs 87 per kg for Mirzapur, Rs 91 per kg for Raisen, Dhanbad, Adityapur, Puri and Rourkela."
          },
          {
            nm:"Revised New PNG Prices w.e.f 9th April, 2023 will be Rs 52.50 per SCM in Dewas, Meerut, Sonipat, Taj Trapezium Zone, Raisen, Mirzapur, Dhanbad, Adityapur and Rourkela and Rs 51.50 per SCM for Bengaluru & Dakshin Kannada."
          }

          ]
        },
        {
          id: 7,
          img: "./../../../assets/news-media/press/goutom1.png",
          imgList: [{
            img: './../../../assets/news-media/press/goutom1.png'
          }],
          note: "Shri Goutom Chakraborty takes over as Chief Executive Officer of GAIL Gas Limited",
          dt: "03.04.23",
          moreContent: [{
            nm: "3rd April, 2023: Noida: Shri Goutom Chakraborty has taken over as Chief Executive Officer (CEO) of GAIL Gas Limited, a wholly-owned subsidiary of GAIL (India) Limited, engaged in the City Gas Distribution business He has taken over the charge from Shri Raman Chadha."
          },
          {
            nm: "Prior to this assignment, Shri Chakraborty was serving as Executive Director for City Gas Distribution (CGD) Business Vertical in parent company GAIL (India) Limited"
          },
          {
            nm: "He has rich and diverse experience encompassing more than 30 years in Natural Gas value chain including O&M of Cross-Country Natural Gas Pipeline & GPU, Renewable portfolio of GAIL in Wind and Solar Energy and Marketing. He has been instrumental in implementing marketing strategies across 4 Ps (Product, Price, Place & Promotion) of CGD & leveraging state of the art technology leading to increase in gas sales."
          },
          {
            nm: "Mr. Chakraborty is presently also in the Board of Directors of Bengal Gas Company Ltd. and Tripura Natural Gas Company Ltd (Joint Venture Companies of GAIL (India) Limited)."
          },
          {
            nm: "A process and result oriented professional, Shri Chakraborty was instrumental in the setting up of Central Pipeline Integrity Management in GAIL and leading the National Gas Management Centre (NGMC) taking care of complex Gas Business of GAIL. He played a significant role in development of Commercial Systems & Procedures in GAIL across the Natural Gas & product value chain and Transmission System Operator (TSO)."
          },
          {
            nm: "Shri Chakraborty is an Electrical Engineer along with Masters in Business Administration (MBA) with specialization in Marketing."
          }

          ]
        },

      ];
      // {
      //   id: 2,
      //   img: "./../../../assets/news-media/press/press-release-one-1.jpg",
      //   imgList: [{
      //     img: './../../../assets/news-media/press/press-release-one-1.jpg'
      //   }, {
      //     img: './../../../assets/news-media/press/press-release-one-2.jpg'
      //   }, {
      //     img: './../../../assets/news-media/press/press-release-one-3.jpg'
      //   }, {
      //     img: './../../../assets/news-media/press/press-release-one-4.jpg'
      //   }, {
      //     img: './../../../assets/news-media/press/press-release-one-5.jpg'
      //   }, {
      //     img: './../../../assets/news-media/press/press-release-one-6.jpg'
      //   }],
      //   note: "Community Centre & Kalyan Mandap inaugurated by Hon’ble Union Minister of Petroleum and Natural Gas & Steel Shri Dharmendra Pradhan",
      //   dt: "28.02.21",
      //   moreContent: [{
      //     nm: "Puri (Odisha), February 28, 2021: With an objective to facilitate the basic amenities & improve the lifestyle of the residents in Puri, under CSR initiative of GAIL Gas Limited, Community Centre, Jhadeswari Club and Kalyan Mandap, Baseli Sahi was inaugurated today by Hon’ble Union Minister of Petroleum and Natural Gas & Steel Shri Dharmendra Pradhan. Hon’ble MLA of Puri, Shri Jayanta Kumar Sarangi, Hon’ble MLA Brahmagiri Shri Lalitendu Vidyadhar Mahopatra, Shri Sambit Patra, BJP Spokesperson; Shri Partha Sarathi Mohopatra, President Jhadeshwari Club, Shri Krushna Chandra Das, Secretary, Jhadeswari Club, Shri MP Chaudhari, CMD, MOIL, Shri K.B Singh, Executive Director GAIL (India) Limited and Shri M. V Ravi Someswarudu, CEO, GAIL Gas were also present at the occasion."
      //   }, {
      //     nm: "The construction of Community Centre, Jhadeswari Club and renovation and development Kalyan Mandap, Baseli Sahi was done by GAIL Gas Limited under its Corporate Social Responsibility (CSR) initiative at an investment of Rs. 40.4 lakh. The other projects are  Construction of 20 seated Community Toilet at Paranolia Sahi, Renovation and Development of public water bodies at Mangala Ghat, Construction of Community Centre  at Jagannath Basti. The total cost of the CSR projects is around Rs 92 lakhs. These projects are undertaken through an NGO M/s Gramya Prava.."
      //   }]
      // }

      this.eoiTableData().subscribe((res:any)=>{
        console.log(res['eoi']);

        this.EOI1 =  res['eoi'][0].notice_nm;
        this.EOI2 =  res['eoi'][1].notice_nm;
        this.EOI3 =  res['eoi'][2].notice_nm;
        this.EOI4 =  res['eoi'][3].notice_nm;
      });
    }

    if (this.pressReleaseNotes.length >= 2) {
      // this.pressReleaseNotesUpdated.push(this.pressReleaseNotes[1]);
      this.pressReleaseNotesUpdated.push(this.pressReleaseNotes[0], this.pressReleaseNotes[1]);
    }
    console.log(this.pressReleaseNotesUpdated);

  }

  getEoiDetails(){
    this.service.get_Eoi_Detaiols_Fun({}).subscribe((res:any)=>{
      if(res.code==200){
        let data = res.data
        this.EOIData = []
        for(let i=0;i<5;i++){
          this.EOIData.push(data[i].Notice)
        }        
        // console.log(data)
        // console.log(this.EOIData)
      }     
    })
  }

  sahajUrl() {
    window.open('https://admin.gailgaspng.com', '_blank');
  }

  savingsCalculator(event) {
    this.router.navigate(['/calculator/savings-calculator'], { state: { tabIndex: event } });
  }

  pressReleaseEvent(event) {
    if (event == 'more') {
      if (this.language == 'hn') {
        this.router.navigate(['/news-media/media-events-hn'], { state: { activity: undefined } });
      } else {
        this.router.navigate(['/news-media/media-events'], { state: { activity: undefined } });
      }
    } else {

      console.log(event);
      if (this.language == 'hn') {
        this.router.navigate(['/news-media/media-events-hn'], { state: { activity: event.id } });
      } else {
        this.router.navigate(['/news-media/media-events'], { state: { activity: event.id } });
      }

    }
  }

  cngOutletRoute(event) {
    console.log(event);
    if (this.language == 'hn') {
      this.router.navigate(['/products/cng-hn'], { state: { activityNew: event } });
    } else {
      this.router.navigate(['/products/cng'], { state: { activityNew: event } });
    }
  }

  eoiTableData() {
    if (this.language == "hn") {
      return this.http.get('https://gailgaspdfdownloads.s3.ap-south-1.amazonaws.com/eoi_hn.json');
    }
    if (this.language == "en") {
     return this.http.get('https://gailgaspdfdownloads.s3.ap-south-1.amazonaws.com/eoi.json');
    }
  }
}
