<div fxLayout.xs="column" fxFlexFill class="mobile-display">
    <div fxFlex="100" class="bg-img">
        <div class="container-fluid" style="background: transparent;">
            <div class="container container-bg">
            </div>
        </div>
    </div>
</div>

<div class="column" fxLayout="row" fxLayout.xs="column" fxFlexFill style="background:white;">
    <div class="container-fluid" style="background: transparent;">
        <div class="container mobile-screens" style="background: transparent;">
            <!-- [formGroup]="formData" -->
            <form  [formGroup]="FeedbackformData" class="form-signin">

                <div fxLayout="row">
                    <div fxFlex="80">

                        <p class="location-text">
                            Gail Gas Feedback/Grievance System
                        </p>
                        <div fxLayoutAlign="space-around center">
                            <mat-form-field appearance="outline" class="fieldWidhtOne">
                                <mat-label>Location</mat-label>
                                <!-- formControlName="companyName" -->
                                <mat-select formControlName="Location">
                                    <mat-option *ngFor="let data of geoGraphy" value="{{data.ga_id}}">{{data.ga_nm}}</mat-option>
                                </mat-select>
                                <!-- <input matInput  id="" name=""> -->
                            </mat-form-field>

                            <mat-form-field appearance="outline" class="fieldWidhtOne">
                                <mat-label>Feedback/Grievance Type</mat-label>
                                <mat-select formControlName="feedbackType">
                                    <mat-option value="Domestic" >Domestic PNG</mat-option>
                                    <mat-option value="Commercial" >Commercial PNG</mat-option>
                                    <mat-option value="Industrial" >Industrial PNG</mat-option>
                                    <mat-option value="CNG" >CNG</mat-option>
                                    <mat-option value="Others" >Others</mat-option>
                                    <!-- <mat-option *ngFor="let item of feedbackTypeDropDown" value="{{item}}">{{item}}</mat-option> -->
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div fxLayoutAlign="space-around center">
                            
                            <mat-form-field appearance="outline" class="fieldWidhtOne">
                                <mat-label>Customer Name</mat-label>
                                <input placeholder="Name"  formControlName="CustomerName" matInput type="text" id="" name="" (keypress)="removeSpecialCharacter($event)">
                               
                            </mat-form-field>
                            <mat-form-field appearance="outline" class="fieldWidhtOne">
                                <mat-label>Customer Email id</mat-label>
                                <input placeholder="Ex. pat@example.com"  formControlName="CustomerEmail" matInput type="email" pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9]+\.[a-zA-Z0-9-._%+-@$&]{0,61}$"  id="" name="" (keypress)="emailFormate($event)">
                                <mat-error
                                    *ngIf="(emailName.invalid &&
                                    (emailName.dirty || emailName.touched || emailName.errors?.pattern)) ">
                                    <!-- *ngIf="emailFormControl.hasError('email') && !emailFormControl.hasError('required')"> -->
                                    Please enter a valid email address
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div fxLayoutAlign="start" class="ml-md-4">                           
                            

                            <mat-form-field appearance="outline" class="fieldWidhtOne">
                                <mat-label>Customer Mobile No</mat-label>
                                <input formControlName="CustomerNumber" type="tel" pattern="[0-9]{10}" matInput  id="" name=""  minlength="10" maxlength="10" (keypress)="NumbersOnly($event)">
                                <mat-error
                                *ngIf="(mobileNumber.invalid &&
                                (mobileNumber.dirty || mobileNumber.touched || mobileNumber.errors?.pattern)) ">
                                <!-- *ngIf="emailFormControl.hasError('email') && !emailFormControl.hasError('required')"> -->
                                Please enter a valid mobile number
                            </mat-error>
                            </mat-form-field>
                        </div>
                        <div fxLayoutAlign="space-around center">
                            <mat-form-field appearance="outline" class="fieldWidhtOne" style="width: 95%;">
                                <mat-label>Feedback/Grievance Description</mat-label>
                                <textarea formControlName="FeedbackDesc" (input)="inputText($event)"   (keypress)="removeSpecialCharacter($event)" matInput placeholder="Ex. Please Give Feedback" rows="7"></textarea>
                            </mat-form-field>

                        </div>
                        <div class="text-right">
                            <button mat-stroked-button class="submitBtn mr-md-4" (click)="Feedbackupload()">Submit</button>
                        </div>
                </div>

                    <div fxFlex="5"></div>
                    <div fxFlex="25">
                        <div class="rightImg">
                            <img src="../../assets/submitForm/cngDtlsPinkBg.svg" class="dtlsImg">
                        </div>
                    </div>
                </div>

                

               

                

                <br><br>
            </form>
            
              
        </div>
    </div>
</div>
<div id="overlay">
    <div class="loader"></div>
</div>
<!-- <ngx-spinner bdColor="rgba(51, 51, 51, 0.8)" size="default" type="ball-spin-clockwise">
    <p style="color: white">Please Wait... </p>
</ngx-spinner> -->
