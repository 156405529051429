import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators, RequiredValidator, FormGroupDirective } from '@angular/forms';

import { OperationService } from '../services/operation.service';

import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
// import { NgxSpinnerService } from 'ngx-spinner';
declare var $: any;
@Component({
  selector: 'app-feedback-grievance-c',
  templateUrl: './feedback-grievance-c.component.html',
  styleUrls: ['./feedback-grievance-c.component.scss']
})
export class FeedbackGrievanceCComponent implements OnInit {

  FeedbackformData: FormGroup;
  LocationDropDown:string[]=["Vijayawada","Hyderabad"]
  feedbackTypeDropDown:string[]=["1","2"]
  geoGraphy: any = [];
  copypasteError: boolean;
  
  get emailFormControl() {
    return this.FeedbackformData.get('CustomerEmail');
  }
  get emailName() {

    return this.FeedbackformData.get('CustomerEmail')
  }
  get mobileNumber() {

    return this.FeedbackformData.get('CustomerNumber')
  }

  // constructor(public service: OperationService, public toastr: ToastrService, public router: Router, public dialog: MatDialog,private SpinnerService: NgxSpinnerService) { 
  constructor(public service: OperationService, public toastr: ToastrService, public router: Router, public dialog: MatDialog) { 
    this.FeedbackData();
    let obj = {};
    this.service.geoGraphyArea(obj).subscribe((res: any) => {
      if (res.code === 200) {
        console.log(res.data);
        this.geoGraphy = res.data;
      } else {
        console.log(res);
      }
    }, (err) => {
      console.log(err);
    });
  }
  

  ngOnInit(): void {
  }
  FeedbackData(){
    this.FeedbackformData=new FormGroup({
      Location: new FormControl("",[Validators.required]),
      feedbackType: new FormControl("",[Validators.required]),
      CustomerName: new FormControl("",[Validators.required]),
      CustomerEmail: new FormControl("",[Validators.required, Validators.email, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]),
      CustomerNumber: new FormControl("",[Validators.required]),
      FeedbackDesc: new FormControl("",[Validators.required])
    })
  }
  Feedbackupload(){
    if (!this.FeedbackformData.value.Location) {
      return this.toastr.error("Please Enter Location","", {
        titleClass: "center",
        messageClass: "center"
      });
    }
    if (!this.FeedbackformData.value.feedbackType) {
      return this.toastr.error("Please Enter FeedbackType","", {
        titleClass: "center",
        messageClass: "center"
      });
    }
    if (!this.FeedbackformData.value.CustomerName) {
      return this.toastr.error("Please Enter Name","", {
        titleClass: "center",
        messageClass: "center"
      });
    }
    if (this.emailName.invalid == true) {
      if (!this.FeedbackformData.value.CustomerEmail) {
        return this.toastr.error("Please Enter Email","", {
          titleClass: "center",
          messageClass: "center"
        });
      }
      return this.toastr.error("Please Enter Valid Email","", {
        titleClass: "center",
        messageClass: "center"
      });
    }
    
    if (this.mobileNumber.invalid == true) {
      if (!this.FeedbackformData.value.CustomerNumber) {
        return this.toastr.error("Please Enter CustomerNumber","", {
          titleClass: "center",
          messageClass: "center"
        });
      }
      return this.toastr.error("Please Enter Valid Number","", {
        titleClass: "center",
        messageClass: "center"
      });
    }
    
    if (!this.FeedbackformData.value.FeedbackDesc) {
      return this.toastr.error("Please Enter FeedbackDesc","", {
        titleClass: "center",
        messageClass: "center"
      });
    }   
    this.copypasteError = false
    var regex = new RegExp("^[a-zA-Z0-9]+$");   
    
    if ( !regex.test(this.FeedbackformData.value.CustomerName) || !regex.test(this.FeedbackformData.value.FeedbackDesc) ){
      this.copypasteError = true      
      
   }else{
    this.copypasteError = false
   }

   if(this.copypasteError){
   // return this.toastr.error("Invalid Text Formate")
  }

    let obj={
      "Location_ID": this.FeedbackformData.value.Location,
  
      "Feedback_Type": this.FeedbackformData.value.feedbackType,

      "customer_name": this.FeedbackformData.value.CustomerName,
  
      "Email_ID": this.FeedbackformData.value.CustomerEmail,
  
      "Mobile_No": this.FeedbackformData.value.CustomerNumber,
  
      "Feedback_Desc": this.FeedbackformData.value.FeedbackDesc  
  }
  
  console.log(obj)  
  // this.SpinnerService.show();
  this.loaderOn()
  this.service.feedback_submit(obj).subscribe((res: any) => {
    // this.SpinnerService.hide();
    this.loaderOff()
    if (res.code === 200) {
      console.log(res.data);
      this.toastr.success(res.message,"", {
        titleClass: "center",
        messageClass: "center"
      });
      this.reloadCurrentRoute();
      this.copypasteError = false;
    } else {
      this.toastr.error(res.message);
    }
  }, (err) => {
    console.log(err);
  });
  }
  reloadCurrentRoute() {
    let currentUrl = this.router.url;
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.router.navigate([currentUrl]);
    });
  }  
  restrictChar(event):boolean{
    
    const charCode = (event.which) ? event.which : event.keyCode;
    if(charCode==34 ||charCode==39 || charCode==44){
      return false;
    }
    return true;
  }
  inputText(event){
    // console.log(event)
    if(event.inputType=="insertFromPaste"){
      let data = this.FeedbackformData.value.FeedbackDesc
      data = data.split("\"").join("")
      data = data.split("\'").join("")
      data = data.split(",").join(" ")
      data = data.split("  ").join(" ")
      // console.log(data)
      this.FeedbackformData.get("FeedbackDesc").setValue(data);
    }
  }
  loaderOn() {
    document.getElementById("overlay").style.display = "block";
  }
  
  loaderOff() {
    document.getElementById("overlay").style.display = "none";
  }

  removeSpecialCharacter(event){
    var regex = new RegExp("^[a-zA-Z0-9]+$");
    if(event.charCode == 32){
      return true
    }
    var key = String.fromCharCode(!event.charCode ? event.which : event.charCode)
    if (!regex.test(key)) {
       event.preventDefault();
       return false;
    }
  }
  NumbersOnly(event){
    var regex = new RegExp("^[0-9]+$");
    var key = String.fromCharCode(!event.charCode ? event.which : event.charCode)
    if (!regex.test(key)) {
       event.preventDefault();
       return false;
    }
  }
  emailFormate(event){
    var regex = new RegExp("^[a-zA-Z0-9]+$");
    if(event.charCode == 64 || event.charCode ==46){
      return true
    }
    var key = String.fromCharCode(!event.charCode ? event.which : event.charCode)
    if (!regex.test(key)) {
       event.preventDefault();
       return false;
    }
  }
}
