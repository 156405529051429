<div fxLayout.xs="column" fxFlexFill class="mobile-display">
    <div fxFlex="100" class="bg-img" [ngStyle]="{'background-image': 'url(' + Overviewbg + ')'}">
        <div class="container-fluid" style="background: transparent;">
            <div class="container container-bg">
            </div>
        </div>
    </div>
</div>

<div class="column" fxLayout="row" fxLayout.xs="column" fxFlexFill style="background:white;">
    <div class="container-fluid df" style="background: transparent;">
        <div style="max-width:1600px;width: 100%;padding-top: 1.5rem;">
            <!-- <div class="complhead">
                <h4>Online Complaints</h4>
            </div> -->
            <p class="intro" *ngIf="eng == 'en'">Introduction</p>
            <p class="introdec" *ngIf="eng == 'en'">Do not pay bribes. If any employee/ personnel of this office asks for
                bribe or if you have any information on corruption in this office or if you are a victim of corruption
                in this office, you can complain to the Head of this department, or the Chief Vigilance Officer/ The
                Superintendent of Police, Central Bureau of Investigation and the Secretary, Central Vigilance
                Commission </p>
            <p class="intro" *ngIf="eng != 'en'">परिचय</p>
            <p class="introdec" *ngIf="eng != 'en'">रिश्वत न दें। यदि इस कार्यालय का कोई कर्मचारी रिश्वत मांगता है या यदि आपको इस कार्यालय में भ्रष्टाचार के बारे में कोई जानकारी है या यदि आप इस कार्यालय में भ्रष्टाचार के शिकार हैं, तो आप इस विभाग के प्रमुख, मुख्य सतर्कता अधिकारी/ पुलिस अधीक्षक, केंद्रीय जांच ब्यूरो या सचिव, केंद्रीय सतर्कता आयोग को शिकायत कर सकते हैं। </p>
            <div style="display: flex;align-items: center;justify-content: center;padding: 0;">
                <div class="scroll-container">
                    <div class="table table-bordered"
                        style="margin-top: 3rem;min-width: 1024px; max-width:1600px; width: 100%;font-weight: 600;">
                        <thead class="table-header">
                            <td>S.No.</td>
                            <td *ngIf="eng != 'en'">पदनाम और पता </td>
                            <td *ngIf="eng == 'en'">Designation and Address</td>
                            <td style="width: 331px !important;">Phone No and email </td>
                        </thead>
                        <tbody class="table-data" style="width: 100%;">
                            <tr>
                                <td>01</td>
                                <td *ngIf="eng != 'en'">मुख्य कार्यकारी अधिकारी तीसरी मंजिल,<br> इन्फोहब बिल्डिंग, गेल
                                    जुबली टॉवर बी - 35 और 36,
                                    सेक्टर -1, नोएडा -201301, (उत्तर प्रदेश) </td>
                                <td *ngIf="eng == 'en'">Chief Executive Officer <br> 3rd Floor, Infohub Building, GAIL
                                    Jubilee Tower B- 35 & 36,
                                    Sector -1, NOIDA -201301, (U.P) </td>
                                <td>
                                    <p>+91-120-2446400, +91-120-4862400</p>
                                    <p>ceogailgas@gail.co.in</p>
                                </td>
                            </tr>
                            <tr>
                                <td>02</td>
                                <td *ngIf="eng != 'en'">मुख्य सतर्कता अधिकारी <br> 16, भीकाएजी कमा प्लेस, नई दिल्ली -
                                    ११००६६ </td>
                                <td *ngIf="eng == 'en'">Chief Vigilance Officer <br> 16, Bhikaiji Cama Place, New Delhi
                                    - 110066 </td>
                                <td>
                                    <p>Tel No +91- 11-26194153</p>
                                    <p>cvogail@gail.co.in </p>
                                </td>

                            </tr>
                            <tr>
                                <td>03</td>
                                <td *ngIf="eng != 'en'">पुलिस अधीक्षक (ए सी बी ) <br> केंद्रीय अन्वेषण ब्यूरो भ्रष्टाचार
                                    विरोधी शाखा सी जी ओ काम्प्लेक्स - 1 हापुड़ रोड कमला नेहरू नगर, ग़ज़िआबाद (यु पी) </td>
                                <td *ngIf="eng == 'en'">Superintendent of Police (ACB) <br> Central Bureau of
                                    Investigation Anti-Corruption Branch CGO Complex-1, Hapur Road,
                                    Kamla Nehru Nagar, Ghaziabad (U.P) </td>

                                <td>
                                    <p>Tel No: +91-120-2704011 , Fax No; +91-120-2752825 </p>
                                    <p> hobacgzd@cbi.gov.in </p>
                                </td>
                            </tr>
                            <tr>
                                <td>04</td>
                                <td *ngIf="eng != 'en'">सचिव <br> केंद्रीय सतर्कता आयोग सतर्कता भवन , ब्लॉक - A जी पी ओ
                                    काम्प्लेक्स, आई एन ए, नई दिल्ली - 110023 </td>
                                <td *ngIf="eng == 'en'">Secretary <br> Central Vigilance Commission, Satarkta Bhawan,
                                    Block-A, GPO Complex, INA, New Delhi - 110023</td>
                                <td>
                                    <p>Tel No +91-11-24651020 , Toll Free 1800110180, 1964</p>
                                    <p>Fax: +91-11 24651186 </p>
                                </td>
                            </tr>
                        </tbody>
                    </div>
                </div>
            </div>

            <div class="flexboxrow" style="margin-top: 5rem;" #formmenu>
                <div class="complaintsbox" [class.complainactive]="active == '1'" (click)="complaint('1')">
                    <div class="imgcontain">
                        <img *ngIf="active != '1'" src="../../assets/submitForm/grivince blue.svg" width="60px"
                            alt="Grievance Redressal">
                        <img *ngIf="active == '1'" src="../../assets/submitForm/graviance white.svg" width="60px"
                            alt="Grievance Redressal">
                    </div>

                    <div style=" padding: 5px 0px;">Grievance Redressal Forum</div>
                </div>

                <div class="complaintsbox" [class.complainactive]="active == '2'" (click)="complaint('2')">
                    <div class="imgcontain">
                        <img *ngIf="active != '2'" src="../../assets/submitForm/vendor blue.svg" width="60px"
                            alt="Vendor Complaints">
                        <img *ngIf="active == '2'" src="../../assets/submitForm/vendor white.svg" width="60px"
                            alt="Vendor Complaints">
                    </div>
                    <div style=" padding: 5px 0px;">Vendor Complaints</div>
                </div>

                <div class="complaintsbox" [class.complainactive]="active == '3'" (click)="complaint('3')">
                    <div class="imgcontain">
                        <img *ngIf="active != '3'" src="../../assets/submitForm/customer blue.svg" width="60px"
                            alt="Customer Complaints">
                        <img *ngIf="active == '3'" src="../../assets/submitForm/customerwhite.svg" width="60px"
                            alt="Customer Complaints">
                    </div>
                    <div style=" padding: 5px 0px;">Customer Complaints</div>
                </div>

                <!-- <div class="complaintsbox" [class.complainactive]="active == '4'" (click)="complaint('4')">
                    <div class="imgcontain">
                        <img *ngIf="active != '4'" src="../../assets/submitForm/Vigilance Complaints blue.svg"
                            width="60px" alt="Vigilance Complaints">
                        <img *ngIf="active == '4'" src="../../assets/submitForm/vigilance white.svg" width="60px"
                            alt="Vigilance Complaints">
                    </div>
                    <div style=" padding: 5px 0px;">Vigilance Complaints</div>
                </div> -->

                <div class="complaintsbox" 
                    [class.complainactive]="active == '5'" (click)="complaint('5')" (click)="proceed('5')">
                    <div class="imgcontain" (click)="img()">
                        <img *ngIf="active != '5'"  src="../../assets/submitForm/track blue.svg" width="60px"
                            alt="Track Complaint">
                        <img *ngIf="active == '5'"  src="../../assets/submitForm/track white.svg" width="60px"
                            alt="Track Complaint">
                    </div>
                    <div style=" padding: 5px 0px;">Track Complaint</div>
                </div>
            </div>
            <div style="margin-top: 3rem;margin-bottom: 2rem;font-size: 18px;">
                <div *ngIf="active == '1'">
                    <p style="padding: 5px 10px;">{{complaintdec[0].complaintdec1}}</p>
                </div>
                <div *ngIf="active == '2'">
                    <p style="padding: 5px 10px;">{{complaintdec[0].complaintdec2}}</p>
                    <div class="df" style="margin-top: 1.5rem;">
                        <navigate routerLink="/complaints" class="proceed" (click)="proceed('2')">Proceed To File
                            Complaint</navigate>
                    </div>
                </div>


                <div *ngIf="active == '3'">
                    <div style="padding: 5px 10px;">
                        <p>Dear Madam/ Sir, "Let Us Improve Your Experience"</p>
                        <p>Welcome to GAIL Gas’ Customer Complaint Portal. We appreciate your feedback and are committed
                            to
                            ensuring your experience with us is exceptional. Your input helps us grow and serve you
                            better.
                        </p>
                        <p> At GAIL Gas, we believe that every customer's voice should be heard. Your feedback is
                            invaluable
                            as it helps us understand what we are doing right and where we can make improvements. </p>

                        <p>How to Submit a Complaint:</p>

                        <p>Submitting a complaint is easy. Please use the form below to detail your concern. Rest
                            assured;
                            your information will be kept confidential. </p>
                    </div>
                    <div class="df" style="margin-top: 1.5rem;">
                        <navigate routerLink="/complaints" class="proceed" (click)="proceed('3')">Proceed To File
                            Complaint</navigate>
                    </div>
                </div>
                <div *ngIf="active == '4'">
                    <p style="padding: 5px 10px;">{{complaintdec[0].complaintdec4}}</p>
                    <div class="df" style="margin-top: 0rem;">
                        <navigate routerLink="/complaints" class="proceed" (click)="proceed('4')">Proceed To File
                            Complaint</navigate>
                    </div>
                </div>
                <!-- <div *ngIf="active == '5'">
                    <p>{{complaintdec[0].complaintdec5}}</p>
                    <div class="df" style="margin-top: 5rem;">
                        <navigate routerLink="/complaints" class="proceed" (click)="proceed('5')">Proceed To File Complaint</navigate>
                    </div>
                </div> -->
            </div>
        </div>
    </div>
</div>
<div id="overlay">
    <div class="loader"></div>
</div>
<!-- <ngx-spinner bdColor="rgba(51, 51, 51, 0.8)" size="default" type="ball-spin-clockwise">
    <p style="color: white">Please Wait... </p>
</ngx-spinner> -->





<!-- Trackmodal -->
<div class="modal " style="backdrop-filter: blur(16px);" id="Trackmodal" tabindex="-1"
    aria-labelledby="exampleModalLabel" aria-hidden="true" data-backdrop="static" data-keyboard="false">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" data-dismiss="modal" aria-label="Close"
                    (click)="clearformndmodle()">
                    <span style="margin-right: 16px;" aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div>
                    <div class="df">
                        <div style="height: 30px;"><img src="../../assets/gailgas-logo.svg" alt=""></div>
                    </div>
                    <div class="numberinpt">
                        <div style="padding: 5px;font-weight: 500;">Enter your Phone Number</div>
                        <input type="text" class="phonnum" style="letter-spacing:10px;" (keydown)="keydownnum($event)"
                            minlength="10" maxlength="10" #number (keypress)="keyPressNumbers($event)">
                        <p class="generateotp" [ngClass]="{ 'disabled': buttonDisabled }" (click)="Generateotp()">
                            Generate
                            OTP</p>
                        <p style="text-align: left;font-weight: 500;">OTP Verification <span
                                style="margin-left: 10px;color:#0784C7;" *ngIf="timerInterval && timer > 0">{{
                                formatTime(timer) }}</span></p>
                        <div class="otpfeilds">
                            <input [disabled]="EnterOtp_Send == false" [ngClass]="{'disabled-button': EnterOtp_Send == false}" #digit1 type="text" maxlength="1" class="otp-input" />
                            <input [disabled]="EnterOtp_Send == false" [ngClass]="{'disabled-button': EnterOtp_Send == false}" #digit2 type="text" maxlength="1" class="otp-input" />
                            <input [disabled]="EnterOtp_Send == false" [ngClass]="{'disabled-button': EnterOtp_Send == false}" #digit3 type="text" maxlength="1" class="otp-input" />
                            <input [disabled]="EnterOtp_Send == false" [ngClass]="{'disabled-button': EnterOtp_Send == false}" #digit4 type="text" maxlength="1" class="otp-input" />
                            <input [disabled]="EnterOtp_Send == false" [ngClass]="{'disabled-button': EnterOtp_Send == false}" #digit5 type="text" maxlength="1" class="otp-input" />
                            <input [disabled]="EnterOtp_Send == false" [ngClass]="{'disabled-button': EnterOtp_Send == false}" #digit6 type="text" maxlength="1" class="otp-input" />
                        </div>
                        <div class="dntrecvotp">
                            <!-- <span>Didn't Receive OTP ?</span> -->
                        </div>

                        <button class="Verifyotp" (click)="verifyOTP()" [disabled]="EnterOtp_Send == false" [ngClass]="{'disabled-button': EnterOtp_Send == false}">Verify & Proceed</button>
                        <p class="haventotp">Didn't Receive OTP ?</p>
                        <div style="text-align: center;"><span class="resend" (click)="resendotp()"
                                [ngClass]="{ 'disabled': resendDisabled }">Resend Code</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>



<div id="overlay">
    <div class="loader"></div>
</div>










<!-- <div fxLayout.xs="column" fxFlexFill class="mobile-display">
    <div fxFlex="100" class="bg-img">
        <div class="container-fluid" style="background: transparent;">
            <div class="container container-bg">
            </div>
        </div>
    </div>
</div>

<div class="column" fxLayout="row" fxLayout.xs="column" fxFlexFill style="background:white;">
    <div class="container-fluid" style="background: transparent;">
        <div class="container mobile-screens" style="background: transparent;">
           
            <form  [formGroup]="FeedbackformData" class="form-signin">

                <div fxLayout="row">
                    <div fxFlex="80">

                        <p class="location-text">
                            Gail Gas Feedback/Grievance System
                        </p>
                        <div fxLayoutAlign="space-around center">
                            <mat-form-field appearance="outline" class="fieldWidhtOne">
                                <mat-label>Location</mat-label>
                               
                                <mat-select formControlName="Location">
                                    <mat-option *ngFor="let data of geoGraphy" value="{{data.ga_id}}">{{data.ga_nm}}</mat-option>
                                </mat-select>
                                
                            </mat-form-field>

                            <mat-form-field appearance="outline" class="fieldWidhtOne">
                                <mat-label>Feedback/Grievance Type</mat-label>
                                <mat-select formControlName="feedbackType">
                                    <mat-option value="Domestic" >Domestic PNG</mat-option>
                                    <mat-option value="Commercial" >Commercial PNG</mat-option>
                                    <mat-option value="Industrial" >Industrial PNG</mat-option>
                                    <mat-option value="CNG" >CNG</mat-option>
                                    <mat-option value="Others" >Others</mat-option>
                                    
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div fxLayoutAlign="space-around center">
                            
                            <mat-form-field appearance="outline" class="fieldWidhtOne">
                                <mat-label>Customer Name</mat-label>
                                <input placeholder="Name"  formControlName="CustomerName" matInput type="text" id="" name="" (keypress)="removeSpecialCharacter($event)">
                               
                            </mat-form-field>
                            <mat-form-field appearance="outline" class="fieldWidhtOne">
                                <mat-label>Customer Email id</mat-label>
                                <input placeholder="Ex. pat@example.com"  formControlName="CustomerEmail" matInput type="email" pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9]+\.[a-zA-Z0-9-._%+-@$&]{0,61}$"  id="" name="" (keypress)="emailFormate($event)">
                                <mat-error
                                    *ngIf="(emailName.invalid &&
                                    (emailName.dirty || emailName.touched || emailName.errors?.pattern)) ">
                                    
                                    Please enter a valid email address
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div fxLayoutAlign="start" class="ml-md-4">                           
                            

                            <mat-form-field appearance="outline" class="fieldWidhtOne">
                                <mat-label>Customer Mobile No</mat-label>
                                <input formControlName="CustomerNumber" type="tel" pattern="[0-9]{10}" matInput  id="" name=""  minlength="10" maxlength="10" (keypress)="NumbersOnly($event)">
                                <mat-error
                                *ngIf="(mobileNumber.invalid &&
                                (mobileNumber.dirty || mobileNumber.touched || mobileNumber.errors?.pattern)) ">
                               
                                Please enter a valid mobile number
                            </mat-error>
                            </mat-form-field>
                        </div>
                        <div fxLayoutAlign="space-around center">
                            <mat-form-field appearance="outline" class="fieldWidhtOne" style="width: 95%;">
                                <mat-label>Feedback/Grievance Description</mat-label>
                                <textarea formControlName="FeedbackDesc" (input)="inputText($event)"   (keypress)="removeSpecialCharacter($event)" matInput placeholder="Ex. Please Give Feedback" rows="7"></textarea>
                            </mat-form-field>

                        </div>
                        <div class="text-right">
                            <button mat-stroked-button class="submitBtn mr-md-4" (click)="Feedbackupload()">Submit</button>
                        </div>
                </div>

                    <div fxFlex="5"></div>
                    <div fxFlex="25">
                        <div class="rightImg">
                            <img src="../../assets/submitForm/cngDtlsPinkBg.svg" class="dtlsImg">
                        </div>
                    </div>
                </div>

                

               

                

                <br><br>
            </form>
            
              
        </div>
    </div>
</div>
<div id="overlay">
    <div class="loader"></div>
</div> -->
<!-- <ngx-spinner bdColor="rgba(51, 51, 51, 0.8)" size="default" type="ball-spin-clockwise">
    <p style="color: white">Please Wait... </p>
</ngx-spinner> -->