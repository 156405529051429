import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-image-view-modal',
  templateUrl: './image-view-modal.component.html',
  styleUrls: ['./image-view-modal.component.scss']
})
export class ImageViewModalComponent implements OnInit {
  image: any;
  count: number = 0;
  Img: any;
  p: any;
  Point: any;
  x: any;
  public zoom: number = 1;
  newTab: boolean = false;


  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private dom:DomSanitizer) {
    this.image=this.dom.bypassSecurityTrustResourceUrl(data);
    // this.image = data;
    console.log(this.image);
    console.log(window.screen.width);
  }

  ngOnInit(): void {

    if(window.screen.width < 1024){
      this.newTab = true;
    }else {
      this.newTab = false;
    }

  }

  // imageClick(event) {
  //   console.log(event);
  //   if (event == 'plus') {
  //     let a = document.getElementById('imageStyles');
  //     if (this.count < 6) {
  //       this.count++;
  //       if (this.count >= 1) {

  //         console.log(`%c${this.count}`, "font-size:30px;color:red;");

  //         if (window.screen.width >= 768 && window.screen.width < 1200) {
  //           console.log("IF");
  //           if (this.count == 1) return a.style.width = "220%";
  //           if (this.count == 2) return a.style.width = "230%";
  //           if (this.count == 3) return a.style.width = "240%";
  //           if (this.count == 4) return a.style.width = "250%";
  //           if (this.count == 5) return a.style.width = "260%";
  //           if (this.count == 6) return a.style.width = "270%";
  //         } else if(window.screen.width < 768){
  //           if (this.count == 1) return a.style.width = "300%";
  //           if (this.count == 2) return a.style.width = "350%";
  //           if (this.count == 3) return a.style.width = "400%";
  //           if (this.count == 4) return a.style.width = "450%";
  //           if (this.count == 5) return a.style.width = "480%";
  //           if (this.count == 6) return a.style.width = "500%";
  //         }
  //          else {

  //           if (this.count == 1) return a.style.width = "110%";
  //           if (this.count == 2) return a.style.width = "120%";
  //           if (this.count == 3) return a.style.width = "130%";
  //           if (this.count == 4) return a.style.width = "140%";
  //           if (this.count == 5) return a.style.width = "150%";
  //           if (this.count == 6) return a.style.width = "160%";
  //         }
  //       }
  //     }

  //   } else {
  //     let a = document.getElementById('imageStyles');

  //     if (this.count >= 1) {
  //       this.count--;
  //       console.log(`%c${this.count}`, "font-size:50px;color:blue;");
  //       if (window.screen.width >= 768 && window.screen.width < 1200) {
  //         console.log("IF");
  //         if (this.count == 0) return a.style.width = "90%";
  //         if (this.count == 1) return a.style.width = "220%";
  //         if (this.count == 2) return a.style.width = "230%";
  //         if (this.count == 3) return a.style.width = "240%";
  //         if (this.count == 4) return a.style.width = "250%";
  //         if (this.count == 5) return a.style.width = "260%";
  //         if (this.count == 6) return a.style.width = "270%";
  //         if (this.count > 7) return;
  //       } else if(window.screen.width < 768){
  //         if (this.count == 0) return a.style.width = "90%";
  //         if (this.count == 1) return a.style.width = "300%";
  //         if (this.count == 2) return a.style.width = "350%";
  //         if (this.count == 3) return a.style.width = "400%";
  //         if (this.count == 4) return a.style.width = "450%";
  //         if (this.count == 5) return a.style.width = "480%";
  //         if (this.count == 6) return a.style.width = "500%";
  //         if (this.count > 7) return;
  //       }
  //        else {

  //         if (this.count == 0) return a.style.width = "90%";
  //         if (this.count == 1) return a.style.width = "110%";
  //         if (this.count == 2) return a.style.width = "120%";
  //         if (this.count == 3) return a.style.width = "130%";
  //         if (this.count == 4) return a.style.width = "140%";
  //         if (this.count == 5) return a.style.width = "150%";
  //         if (this.count == 6) return a.style.width = "160%";
  //         if (this.count > 7) return;
  //       }
  //     }

  //   }

  // }

}
