<style>
    /* .img-head {
        width: auto;
        height: auto;
    }

    .tableImg {
        width: 100%;
        height: 100%;
    } */

    .indicative-list {
        font-family: MerriweatherBold;
        font-size: 28px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: normal;
        color: #0f4a5b;
    }

    .thead {
        font-family: MerriweatherBold;
        font-size: 14px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: normal;
        color: #0f4a5b;
    }

    td {
        font-family: ApexBook;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.75;
        letter-spacing: normal;
        color: #080808;
        /* text-align: center; */
    }

    /* @media (min-width: 1024px) and (max-width: 1200px) {
        ::ng-deep .modal-content-new {
            height: auto !important;
            width: 700px !important;
        }
    }

    @media (min-width: 1201px) and (max-width: 1439px) {
        ::ng-deep .modal-content-new {
            height: auto !important;
            width: 1000px !important;
        }
    }

    @media (min-width:1440px) and (max-width: 1529px) {
        ::ng-deep .modal-content-new {
            height: 600px !important;
            width: 1100px !important;
        }
    }

    @media (min-width: 1530px) and (max-width:1700px) {
        ::ng-deep .modal-content-new {
            height: 600px !important;
            width: 1200px !important;
        }
    }

    @media (min-width: 1701px) and (max-width:2000px) {
        ::ng-deep .modal-content-new {
            height: 600px !important;
            width: 1400px !important;
        }
    }

    @media (min-width: 2001px) {
        ::ng-deep .modal-content-new {
            height: 600px !important;
            width: 1500px !important;
        }
    } */
</style>

<div class="modal-content-new">
    <div class="modal-header">
        <svg mat-dialog-close xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor"
            class="bi bi-x-circle" viewBox="0 0 16 16" style="margin-left: auto;
        margin-right: 1%;">
            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
            <path
                d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
        </svg>
    </div>
    <div class="modal-body">
        <div class="container-fluid ">
            <div class="container">
                <div class="bg">
                    <div class="indicative-list-bg">
                        <h1 class="indicative-list">INDICATIVE LIST OF DOCUMENT TO BE SUBMITTED</h1>
                    </div>
                    <table class="table table-bordered">
                        <thead>
                            <tr>
                                <th scope="col" class="thead">SI.NO.</th>
                                <th scope="col" class="thead">Type of Document</th>
                                <th scope="col" class="thead">Remark</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td scope="row">01</td>
                                <td>Proof of age (Date of Birth)</td>
                                <td>In case of Individual</td>
                            </tr>
                            <tr>
                                <td scope="row">02</td>
                                <td>Proof of Educational Qualification</td>
                                <td>In case of Individual</td>
                            </tr>
                            <tr>
                                <td scope="row">03</td>
                                <td>Copy of PAN CARD</td>
                                <td></td>
                            </tr>
                            <tr>
                                <td scope="row">04</td>
                                <td>Copy of GST Number</td>
                                <td></td>
                            </tr>
                            <tr>
                                <td scope="row">05</td>
                                <td>MOA/ AOA/ Partnership Deed/ Certificate of Incorporation/ Board resolution/ PoA</td>
                                <td>In case of Companies/ Firms</td>
                            </tr>
                            <tr>
                                <td scope="row">06</td>
                                <td>Registration Certificate and Copy of Bylaws</td>
                                <td>In case of RWA</td>
                            </tr>
                            <tr>
                                <td scope="row">07</td>
                                <td>Individual/ Company Profile clearly stating its various Relevant Experience</td>
                                <td></td>
                            </tr>
                            <tr>
                                <td scope="row">08</td>
                                <td>Statement of Purpose</td>
                                <td>Why an Individual / Company/ Firm wants tobe associated with GAIL Gas.</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>