import { Component, Input, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { PdfService } from '../services/pdf.service';

import { Router, NavigationEnd } from '@angular/router';

import { Location } from '@angular/common';



@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  @Input() count: any
  menuItem: any;
  menuItem1: any;
  menuItem3: any;
  menuItem2: any;
  language: string;
  screenWidth: number;

  selectedOption = '1';
  currentRoute: string;
  currentLocationF: string;
  selectLocEnable: boolean = false;
  locData: any=[];
  selectedCity: any;
  clearEventLocations: any;

  constructor(private translateService: TranslateService, private pdfservice: PdfService , private router: Router , private location: Location) {

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.currentRoute = event['url'];
        // console.log(`%c${this.currentRoute}`, "font-size:50px;color:red");
      }

      if(this.currentRoute === "/operation/meerut" || this.currentRoute === "/operation/sonipat" || this.currentRoute === "/operation/dewas" || this.currentRoute === "/operation/trapezium" || this.currentRoute === "/operation/bengaluru" || this.currentRoute === "/operation/dakshin-kannada" || this.currentRoute === "/operation/dehradun" || this.currentRoute === "/operation/ganjam-nayagarh-puri" || this.currentRoute === "/operation/sundergarh" || this.currentRoute === "/operation/giridih-dhanbad" || this.currentRoute === "/operation/mirzapur" || this.currentRoute === "/operation/raisen-shajapur-sehore" || this.currentRoute === "/operation/saraikela" ) {

        this.selectLocEnable = true;
        
      } else {
        this.selectLocEnable = false;
      }

      
      // for(let locName of this.locData){

      //   if(this.currentRoute === locName.url){
      //     this.selectedCity = locName.p_nm;
      //     this.selectLocEnable = true;
      //     console.log(this.selectedCity , "IF");
      //   } else {
      //     console.log("Else");
      //     this.selectLocEnable = false;
      //   }
      // }
    });

    
  }

  ngOnInit(): void {

    this.screenWidth = window.innerWidth;
    this.pdfservice.langEvent.subscribe((event) => {
      if (event == 'initial') {
        this.language = localStorage.getItem('language');
        this.translateService.setDefaultLang(this.language);
        this.currentLanguage();
      } else {
        this.language = event;
        this.translateService.setDefaultLang(this.language);
        this.currentLanguage();
      }
    });

  }

  currentLanguage() {

    this.translateService.get(["footerMenuItem"]).subscribe(values => {
      const data = Object.keys(values).map(key => values[key]);
      this.menuItem = data[0];
      // console.log(this.menuItem);
    });

    this.translateService.get(["footerMenuItem1"]).subscribe(values => {
      const data = Object.keys(values).map(key => values[key]);
      this.menuItem1 = data[0];
      // console.log(this.menuItem1[0]);

      // this.locData.push(this.menuItem1[0].product);
      // this.locData=this.menuItem1[0].product;
    });

    this.translateService.get(["footerMenuItem2"]).subscribe(values => {
      const data = Object.keys(values).map(key => values[key]);
      this.menuItem2 = data[0];
      // console.log(this.menuItem2)
    });

    this.translateService.get(["footerbelowHr1"]).subscribe(values => {
      const data = Object.keys(values).map(key => values[key]);
      this.menuItem3 = data[0];
    });
  }

  // locationChange(event){
  //   console.log(event);
  //   if (event.isUserInput) {
  //     this.clearEventLocations = event.source.value;
  //     console.log(this.clearEventLocations);

  //     this.menuItem1[0].product.filter(res=>{
  //       console.log(res);
  //       if(res.p_nm === this.clearEventLocations){
  //         res.p_nm = this.clearEventLocations;
  //       }
  //     });


  //   }
    

  // }

}
