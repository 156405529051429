import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { PdfService } from '../services/pdf.service';
import { Router, NavigationEnd } from '@angular/router';

import { Location } from '@angular/common';

@Component({
  selector: 'app-cng-scheme-c',
  templateUrl: './cng-scheme-c.component.html',
  styleUrls: ['./cng-scheme-c.component.scss']
})
export class CngSchemeCComponent implements OnInit {
  currentRoute: string;
  selectLocEnable: boolean = false;
  screenWidth: number;
  language: string;
  schemeData: any;
  points: any;
  tableData: any;
  tableHead: any;

  
  constructor(private translateService: TranslateService, private pdfservice: PdfService , private router: Router , 
    private location: Location) {

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.currentRoute = event['url'];
        // console.log(`%c${this.currentRoute}`, "font-size:50px;color:red");
      }

      if(this.currentRoute === "/operation/meerut" || this.currentRoute === "/operation/sonipat" || this.currentRoute === "/operation/dewas" || this.currentRoute === "/operation/trapezium" || this.currentRoute === "/operation/bengaluru" || this.currentRoute === "/operation/dakshin-kannada" || this.currentRoute === "/operation/dehradun" || this.currentRoute === "/operation/ganjam-nayagarh-puri" || this.currentRoute === "/operation/sundergarh" || this.currentRoute === "/operation/giridih-dhanbad" || this.currentRoute === "/operation/mirzapur" || this.currentRoute === "/operation/raisen-shajapur-sehore" || this.currentRoute === "/operation/saraikela" ) {

        this.selectLocEnable = true;
        
      } else {
        this.selectLocEnable = false;
      }

      
      // for(let locName of this.locData){

      //   if(this.currentRoute === locName.url){
      //     this.selectedCity = locName.p_nm;
      //     this.selectLocEnable = true;
      //     console.log(this.selectedCity , "IF");
      //   } else {
      //     console.log("Else");
      //     this.selectLocEnable = false;
      //   }
      // }
    });

    
  }

  ngOnInit(): void {

    this.screenWidth = window.innerWidth;
    this.pdfservice.langEvent.subscribe((event) => {
      if (event == 'initial') {
        this.language = localStorage.getItem('language');
        this.translateService.setDefaultLang(this.language);
        this.currentLanguage();
      } else {
        this.language = event;
        this.translateService.setDefaultLang(this.language);
        this.currentLanguage();
      }
    });

  }

  currentLanguage() {

    this.translateService.get(["cngScheme"]).subscribe(values => {
      const data = Object.keys(values).map(key => values[key]);
      let cngData = data[0]
      this.schemeData = cngData[0];
      
      this.points = this.schemeData['cng-Scheme'];
      this.tableHead=this.schemeData['schemeTableHead'];
      this.tableData = this.schemeData['cngSchemeTable']
      console.log(this.tableData);
    });
  }

}
