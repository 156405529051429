

<!-- CNG header Images -->
<div id="cng">
  <div class="hero-image" style=" background-image: url(../../../assets/gileModule/overview/overview-banner-img.svg); background-position: top;
      background-size: 100% 100%;
      background-repeat: no-repeat;
      height: 260px;
    ">
  </div>
</div>


<div style="background: #f4f4f9;">

  <!-- Breadcrumbs -->

  <!-- <div fxLayout.xs="column" fxFlexFill>
    <div fxFlex="100" class="location-main">
        <div fxFlexOrder="1" fxLayout="row" fxLayout.xs="column">
            <div class="container-fluid" style="background: transparent;">
                <div class="container" style="background: transparent;">
                    <p class="location-text">
                        <a class="location-home">
                            Home</a> > 
                          <span class="breadcrumb-title">{{"commercial-title" | translate}}</span>
                    </p>
                </div>
            </div>
        </div>
    </div>
  </div> -->

  <!-- Breadcrumbs Ends -->


  <div class="container">

    <div class="row" style="padding-bottom: 50px;">

        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 col-12 mt-5 text-center">

          <p class="notfound-title">404</p>

          <p class="cng-copy">Page that you're looking for is not found</p>

          <button class="btn home-btn" [routerLink]="['/home']" routerLinkActive="router-link-active" > Home </button>

        </div>

      </div>

  </div>

</div>


