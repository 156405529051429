<div fxLayout.xs="column" fxFlexFill class="mobile-display">
    <div fxFlex="100" class="bg-img">
        <div class="container-fluid container-p-t" style="background: transparent;">
            <div class="container container-bg">
                <!-- <p class="banner-text">
                    Fueling <span class="banner-txt-growth"> Growth </span>
                    <span> With <span class="banner-txt-growth"> Green Energy </span></span>
                </p> -->
            </div>
        </div>
    </div>
</div>

<div class="section mobile-p-t" style="background:#f4f4f9;" *ngIf="disc">
    <div class="column" fxLayout="row" fxLayout.xs="column" fxFlexFill>
        <div class="container-fluid" style="background: transparent;">
            <div class="container mobile-screens" style="background: transparent;">
                <div fxFlex="100">
                    <p class="location-text">
                        <span routerLink="/home" style="cursor: pointer;">{{disc.homedesc}}</span>
                        <span class="location-home"> &nbsp;<img src="./../../assets/operationModule/dewas/ic-chevron-right.svg">&nbsp;
                            <span class="location-arrow"> {{disc.disclaimer}} </span>
                        </span>
                    </p>
                </div>

                <div class="row">
                    <div class="col-sm" style="height: 10px;"></div>
                    <div class="col-md" style="height: 20px;"></div>
                </div>
            </div>
        </div>
    </div>


    <div class="column" fxLayout="row" fxLayout.xs="column" fxFlexFill>
        <div class="container-fluid" style="background: transparent;">
            <div class="container mobile-screens" style="background: transparent;">
                <div fxFlex="100">
                    <p class="dewas-text">
                        {{disc.disclaimer}}
                    </p>
                    <hr class="hr-1">
                </div>
            </div>

        </div>
    </div>

    <!-- <div class="row">
        <div class="col-sm" style="height: 10px;"></div>
        <div class="col-md" style="height: 20px;"></div>
    </div> -->

    <div class="column" fxLayout="row" fxLayout.xs="column" fxFlexFill>
        <div class="container-fluid" style="background: transparent;">
            <div class="container mobile-screens" style="background: transparent;">

                <div fxFlex="100">
                    <div *ngFor="let a of disAry">
                        <p class="disclaimer-txt"> {{a.data}} </p>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-sm" style="height: 10px;"></div>
                <div class="col-md" style="height: 50px;"></div>
            </div>

        </div>
    </div>



    <div class="column" fxLayout="row" fxLayout.xs="column" fxFlexFill>
        <div class="container-fluid" style="background: transparent;">
            <div class="container mobile-screens" style="background: transparent;">
                <div fxFlex="100">
                    <p class="dewas-text">
                        <img src="../../assets/gileModule/overview/overview-leave.svg"> {{disc.limitation}}
                    </p>
                </div>
            </div>

            <div class="row">
                <div class="col-sm" style="height: 10px;"></div>
                <div class="col-md" style="height: 20px;"></div>
            </div>

        </div>
    </div>



    <div class="column" fxLayout="row" fxLayout.xs="column" fxFlexFill>
        <div class="container-fluid" style="background: transparent;">
            <div class="container mobile-screens" style="background: transparent;">

                <div fxFlex="100">
                    <div *ngFor="let a of liabiltyAry">
                        <p class="disclaimer-txt"> {{a.data}} </p>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-sm" style="height: 10px;"></div>
                <div class="col-md" style="height: 50px;"></div>
            </div>
        </div>
    </div>




    <div class="column" fxLayout="row" fxLayout.xs="column" fxFlexFill>
        <div class="container-fluid" style="background: transparent;">
            <div class="container mobile-screens" style="background: transparent;">
                <div fxFlex="100">
                    <p class="dewas-text">
                        <img src="../../assets/gileModule/overview/overview-leave.svg"> {{disc.no}}
                    </p>
                </div>
            </div>

            <div class="row">
                <div class="col-sm" style="height: 10px;"></div>
                <div class="col-md" style="height: 20px;"></div>
            </div>
        </div>
    </div>



    <div class="column" fxLayout="row" fxLayout.xs="column" fxFlexFill>
        <div class="container-fluid" style="background: transparent;">
            <div class="container mobile-screens" style="background: transparent;">

                <div fxFlex="100">
                    <div *ngFor="let a of no">
                        <p class="disclaimer-txt"> {{a.data}} </p>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-sm" style="height: 10px;"></div>
                <div class="col-md" style="height: 50px;"></div>
            </div>
        </div>
    </div>



    <div class="column" fxLayout="row" fxLayout.xs="column" fxFlexFill>
        <div class="container-fluid" style="background: transparent;">
            <div class="container mobile-screens" style="background: transparent;">
                <div fxFlex="100">
                    <p class="dewas-text">
                        <img src="../../assets/gileModule/overview/overview-leave.svg"> {{disc.gailGas}}
                    </p>
                </div>
            </div>

            <div class="row">
                <div class="col-sm" style="height: 10px;"></div>
                <div class="col-md" style="height: 20px;"></div>
            </div>
        </div>
    </div>



    <div class="column" fxLayout="row" fxLayout.xs="column" fxFlexFill>
        <div class="container-fluid" style="background: transparent;">
            <div class="container mobile-screens" style="background: transparent;">

                <div fxFlex="100">
                    <div *ngFor="let a of gail">
                        <p class="disclaimer-txt"> {{a.data}} </p>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-sm" style="height: 10px;"></div>
                <div class="col-md" style="height: 20px;"></div>
            </div>
        </div>
    </div>


</div>