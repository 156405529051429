import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { OperationService } from '../services/operation.service';

@Component({
  selector: 'app-personal-details-c',
  templateUrl: './personal-details-c.component.html',
  styleUrls: ['./personal-details-c.component.scss'],
})
export class PersonalDetailsCComponent implements OnInit {
  personalForm: FormGroup;
  filePreviewOne: { url: string, type: string } | null = null;
  filePreviewTwo: { url: string, type: string } | null = null;
  filePreviewThree: { url: string, type: string } | null = null;
  filePreviewFour: { url: string, type: string } | null = null;
  filePreviewFive: { url: string, type: string } | null = null;
  // To store file references
  uploadFileOne: File | null = null;
  uploadFileTwo: File | null = null;
  uploadFileThree: File | null = null;
  uploadFileFour: File | null = null;
  uploadFileFive: File | null = null;
  geoGraphy: any;
  newid: number;
  timer: number = 120;
  timerInterval: any;
  genotp: any;
  buttonDisabled: boolean = false;
  EnterOtp_Send: boolean = false;
  resendDisabled: boolean = false;

  constructor(private toastr: ToastrService, private service: OperationService, public bm: ChangeDetectorRef) { }

  ngOnInit(): void {
    this.personalForm = new FormGroup({
      Location_Applied: new FormControl(''),
      Name: new FormControl('', [Validators.required, Validators.pattern('^[a-zA-Z ]*$'), Validators.minLength(3)]),
      Father_Name: new FormControl('', [Validators.required, Validators.pattern('^[a-zA-Z ]*$'), Validators.minLength(3)]),
      Date_Birth: new FormControl('', [Validators.required]),
      Gender: new FormControl('', [Validators.required]),
      Marital_Status: new FormControl('', [Validators.pattern('^[a-zA-Z ]*$'), Validators.minLength(3)]),
      Nationality: new FormControl('', [Validators.required, Validators.pattern('^[a-zA-Z ]*$'), Validators.minLength(3)]),
      Mobile_Number: new FormControl('', [Validators.required, Validators.minLength(10), Validators.pattern('^[0-9]*$')]),
      Email: new FormControl('', [Validators.required, Validators.email]),
      Educational_Qualification: new FormControl('', [Validators.required,Validators.pattern('^[a-zA-Z ]*$'), Validators.minLength(3)]),
      Current_Address: new FormControl('', [Validators.required, Validators.minLength(3)]),
      District: new FormControl('', [Validators.required, Validators.pattern('^[a-zA-Z ]*$'), Validators.minLength(3)]),
      Pin_Code: new FormControl('', [Validators.required, Validators.pattern('^[0-9]{6}$')]),
      State: new FormControl('', [Validators.required, Validators.pattern('^[a-zA-Z ]*$'), Validators.minLength(3)]),
      PAN_Number: new FormControl('', [Validators.required, Validators.pattern('^[A-Z]{5}[0-9]{4}[A-Z]{1}$'), Validators.minLength(8)]),
      Aadharr_No: new FormControl('', [Validators.required, Validators.pattern('^[0-9]{12}$'),]),
      Upload_report_One: new FormControl('', [Validators.required]),
      Upload_report_Two: new FormControl('', [Validators.required]),
      Upload_report_three: new FormControl('', [Validators.required]),
      Upload_report_four: new FormControl('', [Validators.required]),
      Upload_report_five: new FormControl('', [Validators.required]),
      otp: new FormControl('')
    });

    this.get_Location_API();
    this.loaderOff();
    // this.loaderOn();
  }

  Gender_Status = [
    { name: 'Male', id: 1 },
    { name: 'Female', id: 2 },
  ];

  get_Location_API() {
    this.service.geoGraphyArea({}).subscribe((res: any) => {
      if (res.code == 200) {
        this.geoGraphy = res.data;
      }
    }, (err: any) => { })
  }

  loaderOn() {
    document.getElementById("overlay").style.display = "block";
  }

  loaderOff() {
    document.getElementById("overlay").style.display = "none";
  }

  onFileSelected(event: any, fileNumber: number) {
    const file = event.target.files[0];

    if (!file) return;

    const allowedTypes = ['application/pdf', 'image/jpeg', 'image/png'];
    if (!allowedTypes.includes(file.type)) {
      return this.toastr.error('Wrong file type selected. Only PDF, PNG, and JPEG formats are allowed.');
    }

    if (file.size > 500 * 1024) {
      return this.toastr.error('File size should not be more than 500KB.');
    }

    const filePreview = {
      url: URL.createObjectURL(file),
      type: file.type
    };

    switch (fileNumber) {
      case 1:
        this.filePreviewOne = filePreview;
        this.uploadFileOne = file;
        break;
      case 2:
        this.filePreviewTwo = filePreview;
        this.uploadFileTwo = file;
        break;
      case 3:
        this.filePreviewThree = filePreview;
        this.uploadFileThree = file;
        break;
      case 4:
        this.filePreviewFour = filePreview;
        this.uploadFileFour = file;
        break;
      case 5:
        this.filePreviewFive = filePreview;
        this.uploadFileFive = file;
        break;
    }
  }

  getotp() {

    const otpinput = this.personalForm.value.Mobile_Number
    if (!otpinput) {

      return this.toastr.error("please enter phone number")
    }

    if (!this.personalForm.valid) {
      return this.toastr.error('Please fill all required inputs!');
    }

    const obj = {
      "mobile_no": this.personalForm.value.Mobile_Number
    };
    this.loaderOn()
    if (this.buttonDisabled) {
      return this.toastr.error(`Please wait ${this.timer}!`)
    }
    this.service.otpverfication(obj).subscribe((res: any) => {
      this.loaderOff()
      // console.log(res)
      if (res.code == 200) {
        this.genotp = res.OTP
        // console.log(this.genotp)
        this.bm.detectChanges()
        this.buttonDisabled = true;
        this.timer = 120
        this.timerstart()
        this.EnterOtp_Send = true;
        return this.toastr.success("OTP sent to your mobile")
      } else {
        // Clear the MobileNumber field if the response code is not 200
        if (this[`complaintform${this.newid - 1}`]) {
          this[`complaintform${this.newid - 1}`].patchValue({
            MobileNumber: ''
          });
        }
        return this.toastr.error(res.message)
      }
    })
  }
  formatTime(seconds: number): string {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
  }

  timerstart() {
    this.timerInterval = setInterval(() => {
      if (this.timer > 0) {
        this.timer--;
      } else {
        clearInterval(this.timerInterval);
        this.genotp = ''
        this.buttonDisabled = false
        // console.log(this.genotp)
        this.resendDisabled = false
      }
    }, 1000);
  }

  SubmitClick() {

    if (!this.personalForm.valid) {
      return this.toastr.error('Please fill all required inputs!');
      return false
    }


    if (!this.personalForm.value.otp) {
      return this.toastr.error('Enter OTP');
    }
    if (!this.personalForm.value.Mobile_Number) {
      return this.toastr.error('Enter Mobile Number');
    }
    if (!this.personalForm.value.Email) {
      return this.toastr.error('Enter Email');
    } else {
      const formData = new FormData();
      formData.append('name', this.personalForm.value.Name);
      formData.append('father_name', this.personalForm.value.Father_Name);
      const date = new Date(this.personalForm.value.Date_Birth);
      const formattedDate = `${date.getFullYear()}-${('0' + (date.getMonth() + 1)).slice(-2)}-${('0' + date.getDate()).slice(-2)}`;
      formData.append('birthdate', formattedDate);
      formData.append('otp', this.personalForm.value.otp)
      formData.append('gender', this.personalForm.value.Gender.name);
      formData.append('marital_status', this.personalForm.value.Marital_Status);
      formData.append('nationality', this.personalForm.value.Nationality);
      formData.append('mobile_no', this.personalForm.value.Mobile_Number);
      formData.append('email', this.personalForm.value.Email);
      formData.append('educational_qualification', this.personalForm.value.Educational_Qualification);
      formData.append('address', this.personalForm.value.Current_Address);
      formData.append('district', this.personalForm.value.District);
      formData.append('pin_code', this.personalForm.value.Pin_Code);
      formData.append('state', this.personalForm.value.State);
      formData.append('aadhar_no', this.personalForm.value.Aadharr_No);
      formData.append('pancard_no', this.personalForm.value.PAN_Number);
      formData.append('location_for', this.personalForm.value.Location_Applied.ga_id);

      // Append files
      if (this.uploadFileOne) {
        formData.append('file_upload_1', this.uploadFileOne, this.uploadFileOne.name);
      }
      if (this.uploadFileTwo) {
        formData.append('file_upload_2', this.uploadFileTwo, this.uploadFileTwo.name);
      }
      if (this.uploadFileThree) {
        formData.append('file_upload_3', this.uploadFileThree, this.uploadFileThree.name);
      }
      if (this.uploadFileFour) {
        formData.append('file_upload_4', this.uploadFileFour, this.uploadFileFour.name);
      }
      if (this.uploadFileFive) {
        formData.append('file_upload_5', this.uploadFileFive, this.uploadFileFive.name);
      }

      this.loaderOn();
      this.service.event_05_09_2024(formData).subscribe(
        (res: any) => {
          this.loaderOff();

          if (res.code === 200) {
            this.toastr.success("Form Submitted Successfully");
            // page reload
            window.location.reload();
            // this.personalForm.reset(); // Reset the form after successful submission
            // Reset file previews and file references
            this.filePreviewOne = null;
            this.filePreviewTwo = null;
            this.filePreviewThree = null;
            this.filePreviewFour = null;
            this.filePreviewFive = null;
            this.uploadFileOne = null;
            this.uploadFileTwo = null;
            this.uploadFileThree = null;
            this.uploadFileFour = null;
            this.uploadFileFive = null;

            this.personalForm.controls.Mobile_Number.setValue('');
            this.personalForm.controls.Mobile_Number.updateValueAndValidity();
            this.personalForm.controls.Mobile_Number.clearValidators();

            this.personalForm.controls.Email.setValue('');
            this.personalForm.controls.Email.updateValueAndValidity();
            this.personalForm.controls.Email.clearValidators();

          } else {
            this.toastr.error(res.message);
          }
        },
        (error) => {
          this.loaderOff();
          this.toastr.error('An error occurred. Please try again later.');
        }
      );
    }
  }

  gotomenu(id: string) {
    localStorage.setItem('ghomeid', id)
  }
}
