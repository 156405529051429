import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DisclaimerComponent } from './disclaimer/disclaimer.component';
import { FaqComponent } from './faq/faq.component';
import { RtiComponent } from './rti/rti.component';
import { CodeOfConductComponent } from './code-of-conduct/code-of-conduct.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { HomeComponent } from './content-m/home/home.component';
import { EmailServiceComponent } from './email-service/email-service.component';
import { CngSchemeCComponent } from './cng-scheme-c/cng-scheme-c.component';
import { FeedbackGrievanceCComponent } from './feedback-grievance-c/feedback-grievance-c.component';
import { PrivacyPolicyCComponent } from './privacy-policy-c/privacy-policy-c.component';
import { ComplaintshomeComponent } from './feedback_complaints/complaintshome/complaintshome.component';
import { ComplaintsFormsComponent } from './feedback_complaints/complaints-forms/complaints-forms.component';
import { VigilanceComponent } from './feedback_complaints/vigilance/vigilance.component';
import { PngNewCComponent } from './png-new-c/png-new-c.component';
import { TrackvendorstatusComponent } from './vendorempanelment/trackvendorstatus/trackvendorstatus.component';
import { PersonalDetailsCComponent } from './personal-details-c/personal-details-c.component';
import { CngPromotionalComponent } from './cng-promotional/cng-promotional.component';
// import { AuthGuard } from './vendorempanelment/authgard';


const routes: Routes = [

  { path: '', redirectTo: '/home', pathMatch: 'full' },

  { path: 'about', redirectTo: '/about/overview', pathMatch: 'full' },
  { path: 'operation', redirectTo: '/operation/meerut', pathMatch: 'full' },
  { path: 'hse', redirectTo: '/hse/hse-policy', pathMatch: 'full' },
  { path: 'csr', redirectTo: '/csr/policy', pathMatch: 'full' },
  { path: 'eoi', redirectTo: '/eoi/expression-interest', pathMatch: 'full' },
  { path: 'careers', redirectTo: '/careers/careers-in', pathMatch: 'full' },

  { path: 'quick-links', redirectTo: '/home', pathMatch: 'full' },


  {
    path: '',
    pathMatch: 'full',
    component: HomeComponent
  },



  {
    path: 'products',
    loadChildren: () => import('./product-m/product-m.module').then(m => m.ProductMModule) // Lazy Loading Module for Products
  },
  {
    path: 'news-media',
    loadChildren: () => import('./news-media-m/news-media-m.module').then(m => m.NewsMediaMModule) // Lazy Loading Module for Products
  },
  {
    path: 'eoi',
    loadChildren: () => import('./eoi-m/eoi-m.module').then(m => m.EoiMModule)  // Lazy Loading Module for EOI
  },
  {
    path: 'eoi-hn',
    loadChildren: () => import('./eoi-m/eoi-m.module').then(m => m.EoiMModule)  // Lazy Loading Module for EOI
  },

  {
    path: 'csr',
    loadChildren: () => import('./csr-m/csr-m.module').then(m => m.CSRMModule)  // Lazy Loading Module for CSR
  },
  {
    path: 'csr-hn',
    loadChildren: () => import('./csr-m/csr-m.module').then(m => m.CSRMModule)  // Lazy Loading Module for CSR
  },


  {
    path: 'products-hn',
    loadChildren: () => import('./product-m/product-m.module').then(m => m.ProductMModule) // Lazy Loading Module for Products
  },
  {
    path: 'contact',
    loadChildren: () => import('./contact-m/contact-m.module').then(m => m.ContactMModule) // Lazy Loading Module for Contact
  },
  {
    path: 'contact-hn',
    loadChildren: () => import('./contact-m/contact-m.module').then(m => m.ContactMModule) // Lazy Loading Module for Contact
  },
  {
    path: 'careers',
    loadChildren: () => import('./careers-m/careers-m.module').then(m => m.CareersMModule) // Lazy Loading Module for Careers
  },
  {
    path: 'careers-hn',
    loadChildren: () => import('./careers-m/careers-m.module').then(m => m.CareersMModule) // Lazy Loading Module for Careers
  },
  {
    path: 'industrial-scenario',
    loadChildren: () => import('./industrial-scenario-m/industrial-scenario-m.module').then(m => m.IndustrialScenarioMModule) // Lazy Loading Module for Contact
  },
  {
    path: 'about',
    loadChildren: () => import('../app/about-gayle/about-gayle.module').then(m => m.AboutGayleModule)
  },
  {
    path: 'reports-publications',
    loadChildren: () => import('../app/reports-publications-m/reports-publications-m.module').then(m => m.ReportsPublicationsMModule)
  },
  {
    path: 'empanelment',
    loadChildren: () => import('./vendorempanelment/vendorempanelment.module').then(m => m.VendorempanelmentModule)
  },
  {
    path: 'home',
    loadChildren: () => import('./content-m/content-m.module').then(m => m.ContentMModule) // Lazy Module for Home page
  },
  {
    path: 'home-hn',
    loadChildren: () => import('./content-m/content-m.module').then(m => m.ContentMModule) // Lazy Module for Home page
  }, {
    path: 'stakeholder-zone',
    loadChildren: () => import('./stakeholder-zone-m/stakeholder-zone-m.module').then(m => m.StakeholderZoneMModule) // Lazy Load Module for stackeholder zone
  },
  {
    path: 'operation',
    loadChildren: () => import('./operation-m/operation-m.module').then(m => m.OperationMModule) // Lazy Load Module for Area of Operation
  },
  {
    path: 'hse',
    loadChildren: () => import('./hse-m/hse-m.module').then(m => m.HseMModule) // Lazy Module for Home page
  },
  {
    path: 'calculator',
    loadChildren: () => import('./calculator-m/calculator-m.module').then(m => m.CalculatorMModule) // Lazy Module for Home page
  },
  
  {
    path: 'disclaimer',
    component: DisclaimerComponent
  },
  {
    path: 'disclaimer-hn',
    component: DisclaimerComponent
  }, {
    path: 'faq',
    component: FaqComponent
  }, {
    path: 'faq-hn',
    component: FaqComponent
  },
  {
    path: 'code-of-conduct',
    component: CodeOfConductComponent
  },
  {
    path: 'code-of-conduct-hn',
    component: CodeOfConductComponent
  },

  {
    path: 'rti',
    component: RtiComponent
  },
  {
    path: 'privacy-policy',
    component: PrivacyPolicyCComponent
  },
  {
    path: 'rti-hn',
    component: RtiComponent
  },
  {
    path: 'submitForm',
    component: EmailServiceComponent
  },
  {
    path: 'feedbackForm',
    component: FeedbackGrievanceCComponent
  },
  {
    path: 'cngScheme',
    component: CngSchemeCComponent
  },
  {
    path: 'cngScheme-hn',
    component: CngSchemeCComponent
  },
  {
    path: 'Png_New',
    component: PngNewCComponent
  },
  {
    path: 'cng-promotional',
    component: CngPromotionalComponent
  },
  {
    path: 'Png_New-hn',
    component: PngNewCComponent
  },
  {
    path: 'onlinecomplaint',
    component: ComplaintshomeComponent
  },
  {
    path: 'onlinecomplaint-hn',
    component: ComplaintshomeComponent
  },
  {
    path: 'complaints',
    component: ComplaintsFormsComponent
  },{
    path: 'complaints-hn',
    component: ComplaintsFormsComponent
  },
  // {
  //   path: '**', redirectTo: 'home', pathMatch: 'full'
  // },
  {
    path: 'vigilance',
    component: VigilanceComponent
  },
  {
    path: 'vigilance-hn',
    component: VigilanceComponent
  },
  {
    path:'per_det',
    component:PersonalDetailsCComponent
  },
  {
    path: '**',
    component: PageNotFoundComponent
  },
  

];

@NgModule({
  imports: [RouterModule.forRoot(routes), RouterModule.forRoot(routes, { scrollPositionRestoration: 'top', })],
  exports: [RouterModule]
})
export class AppRoutingModule { }


