import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators, RequiredValidator, FormGroupDirective } from '@angular/forms';

import { OperationService } from '../services/operation.service';

import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';

declare var $: any;
// import AWS from 'aws-sdk/global';


@Component({
  selector: 'app-email-service',
  templateUrl: './email-service.component.html',
  styleUrls: ['./email-service.component.scss']
})
export class EmailServiceComponent implements OnInit {
  formData: FormGroup;
  selectedFiles: any;
  FOLDER: any;

  dropdownValue: any = []
  selectedPratinidhi: any;
  fileData: any = [];
  selectedGeoArea: any;
  files: any = [];
  name1: any;
  // uploadNames: any = [
  //   'UploadFile', 'UploadFile', 'UploadFile', 'UploadFile', 'UploadFile', 'UploadFile', 'UploadFile', 'UploadFile', 'UploadFile', 'UploadFile', 'UploadFile', 'UploadFile', 'UploadFile', 'UploadFile', 'UploadFile', 'UploadFile'
  // ];

  uploadNames: any = [];
  name2: any;
  name3: any;
  count: number;
  filesLength: any = 0;
  redColorEnable: boolean;
  geoGraphy: any = [];
  selectedGeoArea_Id: any;
  newFileData: any = [];
  newArray: any;
  dropdownActivity: any = [];
  selectedActivity: any;
  resetFormEvent: any;

  @ViewChild(FormGroupDirective) formGroupDirective: FormGroupDirective;
  errorTxtEnable: boolean = false;
  copypasteError: boolean;
  formateNOTValide: boolean;


  constructor(public service: OperationService,
    public toastr: ToastrService, public router: Router, public dialog: MatDialog
  ) {
    this.dropdownValue = [
      { value: 'RWA' },
      { value: 'Shop keeper' },
      { value: 'Commission Agent' },
      { value: 'Company' },
      { value: 'Individual' },
      { value: 'Others' }
    ];

    this.dropdownActivity = [
      { value: 'Direct Marketing(DPNG)' },
      { value: 'On Spot Billing & Invoice Generation(DPNG)' }
    ]

    this.count = 0;

    let obj = {};
    this.service.geoGraphyArea(obj).subscribe((res: any) => {
      if (res.code === 200) {
        console.log(res.data);
        this.geoGraphy = res.data;
      } else {
        console.log(res);
      }
    }, (err) => {
      console.log(err);
    });
  }

  ngOnInit(): void {

    this.formData = new FormGroup({
      companyName: new FormControl(""),
      surName: new FormControl(""),
      Name: new FormControl(""),
      emailFormControl: new FormControl("", [Validators.email]),
      Phone: new FormControl(""),
      mobile: new FormControl(""),
      houseNo: new FormControl(""),
      address: new FormControl(""),
      address2: new FormControl(""),
      address3: new FormControl(""),
      address4: new FormControl(""),
      district: new FormControl(""),
      state: new FormControl(""),
      pinCode: new FormControl(""),
      area: new FormControl(""),
      pratinidhiList: new FormControl(""),
      activityType: new FormControl(""),
      message: new FormControl(""),
      file: new FormControl(""),
    });


  }

  get companyName() {
    return this.formData.get('companyName');
  }

  get surName() {
    return this.formData.get('surName');
  }

  get Name() {
    return this.formData.get('Name');
  }

  get pratinidhiList() {
    return this.formData.get('pratinidhiList');
  }

  get emailFormControl() {
    return this.formData.get('emailFormControl');
  }

  get address() {
    return this.formData.get('address');
  }

  get address2() {
    return this.formData.get('address2');
  }

  get address3() {
    return this.formData.get('address3');
  }

  get address4() {
    return this.formData.get('address4');
  }

  get Phone() {
    return this.formData.get('Phone');
  }
  get mobile() {
    return this.formData.get('mobile');
  }

  get houseNo() {
    return this.formData.get('houseNo');
  }

  get district() {
    return this.formData.get('district');
  }

  get state() {
    return this.formData.get('state');
  }


  get pinCode() {
    return this.formData.get('pinCode');
  }


  get area() {
    return this.formData.get('area');
  }

  get message() {
    return this.formData.get('message');
  }

  get file() {
    return this.formData.get('file');
  }

  changeEvent(event) {
    console.log(event);
    this.selectedPratinidhi = event;
    console.log(this.selectedPratinidhi);
  }

  changeEventActivity(event) {
    console.log(event);
    this.selectedActivity = event;
    console.log(this.selectedActivity);
  }

  changeEventArea(event) {
    console.log(event);
    if (event) {
      this.selectedGeoArea = event.ga_nm;
      this.selectedGeoArea_Id = event.ga_id;
      console.log(this.selectedGeoArea);
    }
  }


  keyPress(e) {
    console.log(e);
    if (( e.keyCode == 34 || e.keyCode == 39)) {

      this.errorTxtEnable = true;
      event.returnValue = false;
      return false;
    } else {
      this.errorTxtEnable = false;
    }
  }
  // selectFile(event) {
  //   console.log(event);
  //   this.selectedFiles = event.target.files;
  //   console.log(this.selectedFiles);
  // }

  // selectFile(files: any) {
  //   console.log(files);
  //     this.selectedFiles = files.target.files;
  //   let temp_fileData = <File>files.target.files[0];
  //   console.log(temp_fileData.type);
  //   if ( temp_fileData.type === 'image/jpeg' || temp_fileData.type === 'image/png' || temp_fileData.type === 'application/pdf') {

  //     this.fileData = <File>files.target.files[0];

  //     console.log(this.fileData);

  //   } else {
  //     this.fileData = null;
  //     this.toastr.error("not accepeted");
  //     console.log(this.fileData);
  //   }

  // }

  selectFile(event) {
    this.formateNOTValide = false
    // console.log(event.target.files.type);
    // console.log(event.target.files);
    // if(this.count==0){ 
    console.log(this.files.length);
    for (var i = 0; i < event.target.files.length; i++) {
      

      if (event.target.files[i].type === 'image/jpeg' || event.target.files[i].type === 'image/png' || event.target.files[i].type === 'application/pdf') {
        this.files.push(event.target.files[i]);
        this.uploadNames.push(event.target.files[i].name);
      }else{
        this.formateNOTValide = true
      }
      if(this.uploadNames.length == 10){

      }else{

      }
      if(this.formateNOTValide){
        return this.toastr.error("Invalid Formate")
      }
      // else {
      //   this.files.splice(event.target.files.length,1)
      //   this.uploadNames.splice(event.target.files.length-1,1);

      // }

      // if (this.files.length > 10) {
      //   this.files.splice(10 ,1);
      //   this.uploadNames.splice(10,1);
      // }

      console.log(this.files.length);
      if (this.files.length > 10) {
        this.files.splice(10, 1);
        this.uploadNames.splice(10, 1);
      }

    }
    


    this.filesLength = this.files.length;

    if (this.filesLength > 0) {
      this.redColorEnable = false;
    } else {
      this.redColorEnable = true;
    }

    // } 
    // else {
    //   for (var i = 0; i < 10; i++) {
    //     if (i < 10) {
    //       this.files.push(event.target.files[i]);
    //       this.uploadNames.push(this.files[i].name);
    //     } else {
    //       this.toastr.warning("Max 10 uploads Only");
    //     }

    //   }
    // }




    // this.uploadNames[this.uploadNames.indexOf(this.files[this.count].name)] = newValue'

    // this.uploadNames.push(this.uploadNames.splice(this.uploadNames.indexOf(this.count), 1, this.files[this.count].name));

    // console.log(this.uploadNames.splice(this.uploadNames.indexOf('UploadFile'), 1, this.files[this.count].name));



    this.count++;


    console.log(this.files);

  }

  remove(a, i) {
    if(this.uploadNames.length < 10){

    }
    console.log(a);

    console.log(this.files);
    console.log(this.uploadNames);
    this.uploadNames.splice(this.uploadNames.indexOf(a), 1);
    console.log(this.uploadNames);
    this.files.splice(i, 1);
    // if (a == this.files[i].name) {
    //   this.files.splice(i, 1);
    // }
    console.log(this.files);
    this.count--;

  }

  cancel(event) {

    // this.formData.reset();
    setTimeout(() => this.formGroupDirective.resetForm(), 0)
    this.selectedFiles = null;
    this.fileData = null;
    this.files = [];
    this.uploadNames = [];
    event.preventDefault();
    this.redColorEnable = false;
  }

  upload(event) {
    console.log(event);

    this.resetFormEvent = event;
    if (this.filesLength == 0) {
      this.redColorEnable = true;
    }else if(this.files.length > 10){
      return this.toastr.error("maximum upload files 10 only")
    }
     else {
      this.redColorEnable = false;
    }

    const formData = new FormData();

    console.log(this.selectedPratinidhi);

    // if (this.fileData == null) {
    //   return this.selectedFiles = null;
    // }
    
      
    
    if (!this.formData.value.companyName) {
      return this.toastr.error("Company Is Mandatory", "", {
        titleClass: "center",
        messageClass: "center"
      });
    } else if (!this.selectedPratinidhi) {
      return this.toastr.error("Type Of Pratinidhi Is Mandatory", "", {
        titleClass: "center",
        messageClass: "center"
      });
    } else if (!this.selectedActivity) {
      return this.toastr.error("Type Of Activity Is Mandatory", "", {
        titleClass: "center",
        messageClass: "center"
      });
    }
    else if (!this.formData.value.surName) {
      return this.toastr.error("Surname Is Mandatory", "", {
        titleClass: "center",
        messageClass: "center"
      });
    } else if (!this.formData.value.Name) {
      return this.toastr.error("Name Is Mandatory", "", {
        titleClass: "center",
        messageClass: "center"
      });
    } else if (!this.formData.value.emailFormControl) {
      return this.toastr.error("Email Is Mandatory", "", {
        titleClass: "center",
        messageClass: "center"
      });
    }
    else if (!this.formData.value.mobile) {
      return this.toastr.error("Mobile No. Is Mandatory", "", {
        titleClass: "center",
        messageClass: "center"
      });
    }
    // else if (!this.formData.value.Phone) {
    //   return this.toastr.error("Mobile No. Is Mandatory", "", {
    //     titleClass: "center",
    //     messageClass: "center"
    //   });
    // }
    else if (!this.formData.value.houseNo) {
      return this.toastr.error("House No. Is Mandatory", "", {
        titleClass: "center",
        messageClass: "center"
      });
    } else if (!this.formData.value.address) {
      return this.toastr.error("Address1 Is Mandatory", "", {
        titleClass: "center",
        messageClass: "center"
      });
    }
    // else if (!this.formData.value.address2) {
    //   return;
    // } 
    // else if (!this.formData.value.address3) {
    //   return;
    // } 
    // else if (!this.formData.value.address4) {
    //   return;
    // }
    else if (!this.formData.value.district) {
      return this.toastr.error("District Is Mandatory", "", {
        titleClass: "center",
        messageClass: "center"
      });
    } else if (!this.formData.value.state) {
      return this.toastr.error("State Is Mandatory", "", {
        titleClass: "center",
        messageClass: "center"
      });
    } else if (!this.formData.value.pinCode) {
      return this.toastr.error("Pincode Is Mandatory", "", {
        titleClass: "center",
        messageClass: "center"
      });
    }


    // else if (!this.formData.value.area) {
    //   return this.toastr.error("Area Is Mandatory", "", {
    //     titleClass: "center",
    //     messageClass: "center"
    //   });
    // }
    else if (!this.selectedGeoArea) {
      return this.toastr.error("Goographical Area Is Mandatory", "", {
        titleClass: "center",
        messageClass: "center"
      });
    }
    else if (!this.formData.value.message || this.errorTxtEnable) {
      return this.toastr.error("Message Is Mandatory", "", {
        titleClass: "center",
        messageClass: "center"
      });
    }

    // else if (!this.fileData) {
    //   this.selectedFiles = null;
    //   return
    // } 
    else if (this.filesLength == 0) {
      return this.toastr.error("Upload File Is Mandatory", "", {
        titleClass: "center",
        messageClass: "center"
      });
    }
    // else if (!this.file) {
    //   return;
    // }
    var regex = new RegExp("^[a-zA-Z0-9]+$");   
    
    if ( !regex.test(this.formData.value.companyName) || !regex.test(this.formData.value.surName)
    || !regex.test(this.formData.value.Name) || !regex.test(this.formData.value.houseNo) || !regex.test(this.formData.value.address2) 
    || !regex.test(this.formData.value.address3) || !regex.test(this.formData.value.address4) || !regex.test(this.formData.value.district) 
    || !regex.test(this.formData.value.state) || !regex.test(this.formData.value.message)
    ) {
      this.copypasteError = true      
      
   }else{
    this.copypasteError = false
   }
    
   if(this.copypasteError){
    return this.toastr.error("Invalid Text Formate")
  }
    else {
      console.log(this.files);
      

      for (var i = 0; i < this.files.length; i++) {
        formData.append("file", this.files[i]);
      }

      formData.append('company_nm', this.formData.value.companyName);
      formData.append('type_of_pratinidhi', this.selectedPratinidhi);
      formData.append('type_of_activity', this.selectedActivity);
      formData.append('surname', this.formData.value.surName);


      formData.append('name', this.formData.value.Name);

      formData.append('email', this.formData.value.emailFormControl);

      formData.append('mobile', this.formData.value.mobile);

      formData.append('phone', this.formData.value.Phone);

      formData.append('h_no', this.formData.value.houseNo);

      formData.append('address1', this.formData.value.address);
      formData.append('address2', this.formData.value.address2);
      formData.append('address3', this.formData.value.address3);
      formData.append('address4', this.formData.value.address4);
      formData.append('district', this.formData.value.district);
      formData.append('state', this.formData.value.state);

      formData.append('pin', this.formData.value.pinCode);

      formData.append('geographical_area', this.selectedGeoArea);
      formData.append('g_id', this.selectedGeoArea_Id);
      formData.append('message', this.formData.value.message);
      // formData.append('file', this.newFileData);
      // formData.append("file", this.files);

      this.service.dropdownScope(formData).subscribe((res: any) => {
        if (res.code === 200) {
          this.copypasteError = false
          console.log(res);

          setTimeout(() => this.formGroupDirective.resetForm(), 0)
          this.selectedFiles = null;
          this.fileData = null;
          this.files = [];
          this.uploadNames = [];
          this.filesLength = 0;

          this.toastr.success(res['message'], "", {
            titleClass: "center",
            messageClass: "center"
          });

          this.copypasteError=false
        } else {
          console.log(res);
          // this.toastr.error(res.message);
          this.toastr.error(res['message'], "", {
            titleClass: "center",
            messageClass: "center"
          });
          // this.toastr.error(res['message']);
        }
      }, (err) => {
        console.log(err);
        this.toastr.error(err['message'], "", {
          titleClass: "center",
          messageClass: "center"
        });
        // this.toastr.error(err);
      });
    }

  }

  removeSpecialCharacter(event){
    var regex = new RegExp("^[a-zA-Z0-9]+$");
    if(event.charCode == 32){
      return true
    }
    var key = String.fromCharCode(!event.charCode ? event.which : event.charCode)
    if (!regex.test(key)) {
       event.preventDefault();
       return false;
    }
  }
  NumbersOnly(event){
    var regex = new RegExp("^[0-9]+$");
    var key = String.fromCharCode(!event.charCode ? event.which : event.charCode)
    if (!regex.test(key)) {
       event.preventDefault();
       return false;
    }
  }
  emailFormate(event){
    var regex = new RegExp("^[a-zA-Z0-9]+$");
    if(event.charCode == 64 || event.charCode ==46){
      return true
    }
    var key = String.fromCharCode(!event.charCode ? event.which : event.charCode)
    if (!regex.test(key)) {
       event.preventDefault();
       return false;
    }
  }
  // inputTextCopy(event){
  //   // var regex = new RegExp("/^[A-Za-z0-9][A-Za-z0-9 ]\*[A-Za-z0-9]\*$/");   
  //   var regex = new RegExp("^[a-zA-Z0-9]+$");   
    
  //   if ( !regex.test(this.formData.value.companyName) || !regex.test(this.formData.value.surName)
  //   || !regex.test(this.formData.value.Name) || !regex.test(this.formData.value.houseNo) || !regex.test(this.formData.value.address2) 
  //   || !regex.test(this.formData.value.address3) || !regex.test(this.formData.value.address4) || !regex.test(this.formData.value.district) 
  //   || !regex.test(this.formData.value.state) || !regex.test(this.formData.value.message)
  //   ) {
  //     this.copypasteError = true
  //     return this.toastr.error("Invalid Formate")
      
  //  }else{
  //   this.copypasteError = false
  //  }
  // }

  tentativeModal() {
    this.openDialog();
  }

  openDialog() {
    const dialogRef = this.dialog.open(SubmitFormModalDialog);
    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
    });
  }


}



@Component({
  selector: 'submit-form-modal-dialog',
  templateUrl: '../submit-form-modal-dialog.html',
})
export class SubmitFormModalDialog {
  event_list: { img: string; }[];
  constructor(public dialogRef: MatDialogRef<SubmitFormModalDialog>) {

  }

  close(): void {
    this.dialogRef.close();
  }

  CheckNumeric(e) {
    console.log(e);
    // if (window.event) // IE
    // {
    //   if ((e.keyCode < 48 || e.keyCode > 57) && e.keyCode != 8) {
    //     event.returnValue = false;
    //     return false;
    //   }
    // }
    // else { // Fire Fox
    //   if ((e.which < 48 || e.which > 57) && e.which != 8) {
    //     e.preventDefault();
    //     return false;
    //   }
    // }
  }
  


}

