<style>
    .carousel-control-next {
        width: 10%;
    }

    .carousel-control-prev {
        width: 10%;
    }

    ::ng-deep .carousel-control-prev-icon {
        background-image: url('./assets/carousel/carouselMenu/left.svg') !important;
        width: 25px !important;
        height: 25px !important;
    }

    ::ng-deep .carousel-control-next-icon {
        background-image: url('./assets/carousel/carouselMenu/right.svg') !important;
        width: 25px !important;
        height: 25px !important;
    }


    @media only screen and (min-width:760px) and (max-width:1020px) {
        .carousel-control-next {
            right: 0px;
            z-index: 9999;
            opacity: 1;
            position: fixed;
        }

        .carousel-control-prev {
            left: 0px;
            z-index: 9999;
            opacity: 1;
            position: fixed;
        }
    }

    @media only screen and (min-width:1024px) and (max-width:1200px) {
        .carousel-control-next {
            right: 18px;
            z-index: 9999;
            opacity: 1;
            position: fixed;
        }

        .carousel-control-prev {
            left: 13px;
            z-index: 9999;
            opacity: 1;
            position: fixed;
        }
    }

    @media only screen and (min-width:1201px) and (max-width:1439px) {
        .carousel-control-next {
            right: 150px;
            z-index: 9999;
            opacity: 1;
            position: fixed;
        }

        .carousel-control-prev {
            left: 150px;
            z-index: 9999;
            opacity: 1;
            position: fixed;
        }
    }

    @media only screen and (min-width:1440px) and (max-width:1529px) {
        .carousel-control-next {
            right: 170px;
            z-index: 9999;
            opacity: 1;
            position: fixed;
        }

        .carousel-control-prev {
            left: 170px;
            z-index: 9999;
            opacity: 1;
            position: fixed;
        }
    }

    @media only screen and (min-width:1530px) and (max-width:1700px) {
        .carousel-control-next {
            right: 118px;
            z-index: 9999;
            opacity: 1;
            position: fixed;
        }

        .carousel-control-prev {
            left: 118px;
            z-index: 9999;
            opacity: 1;
            position: fixed;
        }
    }

    @media only screen and (min-width:1701px) and (max-width:2000px) {
        .carousel-control-next {
            right: 210px;
            z-index: 9999;
            opacity: 1;
            position: fixed;
        }

        .carousel-control-prev {
            left: 210px;
            z-index: 9999;
            opacity: 1;
            position: fixed;

        }
    }


    /* @media only screen and (min-width:1950px) and (max-width:1970px) {
        #txtt {
            background-color: red;
        }
    } */


    @media (min-width:2000px) {
        .carousel-control-next {
            right: 530px;
            z-index: 9999;
            opacity: 1;
            position: fixed;
        }

        .carousel-control-prev {
            left: 530px;
            z-index: 9999;
            opacity: 1;
            position: fixed;
        }
    }

    /* .firstImg {
        background: url('../assets/images/slider-first.png');
        background-size: 100% 100%;
        background-repeat: no-repeat;
    } */
</style>






<div class="modal-content">
    <div class="modal-header">
        <svg mat-dialog-close xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor"
            class="bi bi-x-circle" viewBox="0 0 16 16" style="margin-left: auto;
    margin-right: 1%;" id="txtt">
            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
            <path
                d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
        </svg>
    </div>
    <div class="modal-body">
        <div id="carouselExampleIndicators" class="carousel" data-ride="carousel">
            <div class="carousel-inner text-center">


                <div class="carousel-item item active">
                    <!-- <span mat-dialog-close> -->
                        <img class="d-block w-100 firstImg" style="height:670px;" src="../assets/Media.jfif" alt="latest slide">
                    <!-- </span> -->
                </div>


                <div class="carousel-item item ">

                    <span mat-dialog-close>
                        <img class="d-block w-100 firstImg" src="../assets/pop_up_3.png" alt="first slide">
                    </span>

                    <!-- <a mat-dialog-close *ngIf="language=='en'">
                        <img class="d-block w-100 firstImg" src="../assets/English.png" alt="Second slide">
                    </a>

                    <a mat-dialog-close *ngIf="language=='hn'">
                        <img class="d-block w-100 firstImg" src="../assets/Hindi.png" alt="Second slide">
                    </a> -->


                    <!-- <div class="carousel-item item">
                    <img class="d-block w-100" src="../assets/images/828537 (1).png" alt="First slide">
                </div> -->
                </div>
                <div class="carousel-item item">
                    <img class="d-block w-100 firstImg" src="../assets/publi_3.png" alt="Second slide">
                </div>
                <div class="carousel-item item">
                    <img class="d-block w-100 firstImg" src="../assets/publi_1.png" alt="three slide">
                </div>
                <!-- <div class="carousel-item">
                    <a mat-dialog-close>
                        <img class="d-block w-100 firstImg" src="../assets/English.png" alt="Second slide">
                    </a>
                </div> -->
                <a class="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev">
                    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span class="sr-only">Previous</span>
                </a>
                <a class="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next">

                    <span class="carousel-control-next-icon" aria-hidden="true">
                    </span>
                    <span class="sr-only">Next</span>
                </a>
            </div>
        </div>
    </div>


</div>